import BaseButton from '@/components/base/button.component';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { ReactComponent as ChatIcon } from '@/assets/icons/chats/chat.svg';
import { ReactComponent as WebcameraIcon } from '@/assets/icons/chats/webcamera.svg';
import { ReactComponent as PhoneOffIcon } from '@/assets/icons/chats/phone_off.svg';


import { ReactComponent as ChatWhiteIcon } from '@/assets/icons/chats/chat-white.svg';
import { ReactComponent as WebcameraWhiteIcon } from '@/assets/icons/chats/webcamera-white.svg';

import { ReactComponent as MailIcon } from '@/assets/icons/chats/mail.svg';
import { ReactComponent as GiftsIcon } from '@/assets/icons/chats/gift.svg';
import { ReactComponent as ContactsIcon } from '@/assets/icons/chats/contacts.svg';
import clsx from 'clsx';

const useStyles = makeStyles<Theme, { isOpenVideoChat: boolean }>(
  (theme: Theme) => ({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '14px',
      marginTop: (props) => (props.isOpenVideoChat ? '-50px' : '0'),
      padding: (props) => (props.isOpenVideoChat ? '0 28px 0 28px' : '14px 0 0 0'),
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    chatTypeWrapper: {},
    rightButtons: {
      borderRadius: '20px',
      width: '36px',
      height: '36px',
    },
    rightIcons: {
      display: 'flex',
      width: '274px',
      justifyContent: 'space-between',
    },
    togglePhoto: {
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '15px',
      color: '#503EB6',
      background: '#EEEEEE',
      padding: '10px 9px 11px 12px',
      borderRadius: '20px',
      cursor: 'pointer',
    },
    chatsWrapper: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '74px',
      background: '#EEEEEE',
      borderRadius: '20px',
    },
  })
);

type ActionsButtonsComponentProps = {
  contactsClickHandler?: () => void;
  giftClickHandler?: () => void;
  mailClickHandler?: () => void;
  togglePhotoHandler?: () => void;
  toggleVideoChat?: () => void;
  disconnectTextChat?: () => void;
  isPhotosSliderOpen?: boolean;
  isOpenVideoChat?: boolean;
};

const ActionsButtonsComponent = ({
  contactsClickHandler = () => {},
  giftClickHandler = () => {},
  mailClickHandler = () => {},
  togglePhotoHandler = () => {},
  toggleVideoChat = () => {},
  disconnectTextChat = () => {},
  isOpenVideoChat = false,
  isPhotosSliderOpen,
}: ActionsButtonsComponentProps) => {
  const classes = useStyles({
    isOpenVideoChat,
  });

  return (
    <div className={clsx(classes.container)}>
      <div className={classes.chatsWrapper}>
        <div className={classes.chatTypeWrapper}>
          <BaseButton
            text=''
            inverted={isOpenVideoChat}
            size='small'
            onClick={isOpenVideoChat ? toggleVideoChat : disconnectTextChat}
            Icon={isOpenVideoChat ? <ChatIcon /> : <ChatWhiteIcon />}
          />
        </div>
        <div className={classes.chatTypeWrapper}>
          <BaseButton
            text=''
            inverted={!isOpenVideoChat}
            size='small'
            onClick={toggleVideoChat}
            Icon={!isOpenVideoChat ? <WebcameraIcon /> : <PhoneOffIcon />}
          />
        </div>
      </div>
      <div className={classes.rightIcons}>
        <BaseButton
          text=''
          inverted
          size='small'
          onClick={mailClickHandler}
          className={classes.rightButtons}
          Icon={<MailIcon />}
        />
        <BaseButton
          text=''
          inverted
          size='small'
          onClick={giftClickHandler}
          className={classes.rightButtons}
          Icon={<GiftsIcon />}
        />
        <BaseButton
          text=''
          inverted
          size='small'
          onClick={contactsClickHandler}
          className={classes.rightButtons}
          Icon={<ContactsIcon />}
        />
        <div className={classes.togglePhoto} onClick={togglePhotoHandler}>
          {isPhotosSliderOpen ? 'Hide photos' : 'Show photos'}
        </div>
      </div>
    </div>
  );
};

export default ActionsButtonsComponent;
