import { AnyAction } from "redux";
import {
  GET_GIFTS_REQUEST,
  GET_GIFTS_SUCCESS,
  GET_GIFTS_FAILURE,
  SEND_GIFT_REQUEST,
  SEND_GIFT_SUCCESS,
  SEND_GIFT_FAILURE,
  CHECK_GIFT,
  CHECK_FEW_GIFTS,
  SET_IS_BOOKMARKS,
  SET_SEARCH_PROFILE_ID,
  SET_SORTING,
  RESET_PAGINATION_AND_GIFTS,
  SET_PAGINATION,
  SET_PAGINATION_IS_OVER,
  SET_SELECTED_GIFTS,
  RESET_SELECTED_GIFTS,
  MARK_AS_READ_SELECTED_GIFTS_SUCCESS,
  ADD_TO_BOOKMARKS_SUCCESS,
  GET_USER_GIFT_REQUEST,
  GET_USER_GIFT_SUCCESS,
  GET_USER_GIFT_FAIL,
  RESET_USER_GIFT_FAIL,
  GET_GIFT_COUNTERS_SUCCESS,
  SOCKET_UPDATE_GIFTS_COUNT,

  GET_AVAILABLE_TO_SEND_GIFTS_REQUEST,
  GET_AVAILABLE_TO_SEND_GIFTS_SUCCESS,
  GET_AVAILABLE_TO_SEND_GIFTS_FAILURE,
  GET_GIFTS_FROM_SOCKET_REQUEST,
  GET_GIFTS_FROM_SOCKET_SUCCESS,
  GET_GIFTS_FROM_SOCKET_FAILURE,
} from "./gifts.types";

const initialState = {
  isLoading: false,
  isSending: false,
  inboxGifts: [],
  outboxGifts: [],
  gifts: [],
  error: null,
  chosenIds: new Set(),
  pagination: {
    limit: 20,
    offset: 0
  },
  isPaginationOver: false,
  searchProfileId: null,
  sorting: null,
  selectedGifts: null,
  userGift: null,
  isUserGiftLoading: false,
  giftCounters: null,
};

const gifts = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SEND_GIFT_REQUEST:
      return {
        ...state,
        isSending: true,
      };
    case SEND_GIFT_SUCCESS:
      return {
        ...state,
        isSending: false,
        outboxGifts: [...state.outboxGifts, action.payload],
      };
    case SEND_GIFT_FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.error,
      };
    case GET_GIFTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_GIFTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        [action.kind + "Gifts"]: [...state[action.kind + "Gifts"], ...action.payload],
      };
    case GET_GIFTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
      case GET_GIFTS_FROM_SOCKET_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case GET_GIFTS_FROM_SOCKET_SUCCESS:
        return {
          ...state,
          isLoading: false,
          [action.kind + "Gifts"]: [action.payload, ...state[action.kind + "Gifts"]],
        };
      case GET_GIFTS_FROM_SOCKET_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.error,
        };
    case SET_IS_BOOKMARKS:
      return {
        ...state,
        isBookmarks: action.payload,
      };
    case RESET_PAGINATION_AND_GIFTS:
      return {
        ...state,
        pagination: {
          limit: 20,
          offset: 0
        },
        isPaginationOver: false,
        [action.payload + "Gifts"]: [],
      };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: {
          limit: 20,
          offset: action.payload
        },
      };
    case SET_PAGINATION_IS_OVER:
      return {
        ...state,
        isPaginationOver: true,
      };
    case SET_SEARCH_PROFILE_ID:
      return {
        ...state,
        searchProfileId: action.payload
      };
    case SET_SORTING:
      return {
        ...state,
        sorting: action.payload
      };
    case CHECK_GIFT: {
      let tempSelectedGifts = new Set(state.chosenIds);

      //@ts-ignore
      const isGiftAlreadySeted = tempSelectedGifts.has(action.payload);

      if (isGiftAlreadySeted) {
        //@ts-ignore
        tempSelectedGifts.delete(action.payload);
      } else {
        //@ts-ignore
        tempSelectedGifts.add(action.payload);
      }

      return {
        ...state,
        chosenIds: tempSelectedGifts,
      };
    }
    case CHECK_FEW_GIFTS: {
      return {
        ...state,
        chosenIds: new Set(action.payload),
      };
    }
    case SET_SELECTED_GIFTS: {
      return {
        ...state,
        selectedGifts: action.payload,
      };
    }
    case RESET_SELECTED_GIFTS: {
      return {
        ...state,
        selectedGifts: null,
      };
    }
    case MARK_AS_READ_SELECTED_GIFTS_SUCCESS: {
      const { kind, chosenIds } = action.payload;

      const tempGifts = [...state[kind + "Gifts"]];

      const selectedGifts = tempGifts.filter(item => chosenIds.has(item.id))

      selectedGifts.forEach(gift => gift.seen = new Date());

      return {
        ...state,
        [action.kind + "Gifts"]: tempGifts,
      };
    }
    case ADD_TO_BOOKMARKS_SUCCESS: {
      const { id, kind } = action.payload;

      const tempGifts = [...state[kind + "Gifts"]];

      const selectedGift = tempGifts.find(item => item.id === id)

      selectedGift.isBookmark = true;

      return {
        ...state,
        [action.kind + "Gifts"]: tempGifts,
      };
    }
    case GET_USER_GIFT_REQUEST: {
      return {
        ...state,
        isUserGiftLoading: true,
      };
    }
    case GET_USER_GIFT_SUCCESS: {
      return {
        ...state,
        userGift: action.payload,
        isUserGiftLoading: false,
      };
    }
    case GET_USER_GIFT_FAIL: {
      return {
        ...state,
        userGift: null,
        isUserGiftLoading: false,
      };
    }
    case RESET_USER_GIFT_FAIL: {
      return {
        ...state,
        userGift: null,
        isUserGiftLoading: false,
      };
    }
    case GET_GIFT_COUNTERS_SUCCESS: {
      return {
        ...state,
        giftCounters: action.payload
      };
    }
    case SOCKET_UPDATE_GIFTS_COUNT: {
      return {
        ...state,
        giftCounters: action.payload
      };
    }

    //gifts
    case GET_AVAILABLE_TO_SEND_GIFTS_REQUEST: 
      return {
        ...state,
        giftsIsLoading:true
      };
    case GET_AVAILABLE_TO_SEND_GIFTS_SUCCESS:
      return {
        ...state,
        giftsIsLoading: false,
        gifts: action.gifts || [],
      };
    case GET_AVAILABLE_TO_SEND_GIFTS_FAILURE:
      return {
        ...state,
        giftsIsLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default gifts;
