import { Button, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as VideoIcon } from "@/assets/icons/video.svg";
import { ReactComponent as CrossIcon } from "@/assets/icons/cross.svg";

import clsx from "clsx";
import DialogVideoFileUpload from "@/components/dialogs/upload-video-file.dialog.component";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  deleteVideosRequest,
  getVideosRequest,
  postVideosRequest,
} from "@/app/users/users.actions";
import { UserVideoType } from "@/app/users/users.types";
import useIsStaffUserRole from "@/hooks/useIsStaffUserRole";

type AlbumVideoCoverProps = {
  thumbnail?: string;
  type: UserVideoType;
  videosCount?: number;
  handleWrapperClick?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: 10,
    height: "100%",
    backgroundColor: "#F6F6F6",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      opacity: 0.9,
    },
  },
  uploaderWrapper: {
    // margin: 16,
    overflow: "scroll",
  },
  stubWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  albumCta: {
    marginTop: 16,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  ctaText: {
    textTransform: "capitalize",
  },
  ctaBadge: {
    padding: 2,
    fontSize: 14,
    minWidth: 40,
    borderRadius: 20,
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    display: "inline-flex",
    justifyContent: "center",
    marginLeft: 8,
  },
  metaCtaIcon: {
    marginLeft: 8,
    "& > .MuiButton-startIcon": {
      margin: 0,
    },
  },
}));
const AlbumVideoCover = ({
  thumbnail = "",
  type,
  videosCount = 0,
  handleWrapperClick = () => { },
}: AlbumVideoCoverProps) => {
  const classes = useStyles();
  const isStaff = useIsStaffUserRole();
  const [isModalVisible, setModalVisible] = useState(false);
  const userId = useAppSelector((state) => state.users.currentUser?.id);
  const videos = useAppSelector((state) => state.users[type + "Videos"]);
  const staffRestriction = useMemo(() => type === 'public' && isStaff, [isStaff, type])

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getVideosRequest(userId, type, 0));
  }, [type, userId, getVideosRequest]);

  const handleFileUploading = useCallback((video: File) => {
    dispatch(postVideosRequest(type, video));
  }, []);

  const handleRemoveFile = useCallback((id: string) => {
    dispatch(deleteVideosRequest(type, id));
  }, []);

  const handleClose = () => setModalVisible(false);
  const handleOpen = () => setModalVisible(true);
  return (
    <Fragment>
      <DialogVideoFileUpload
        className={classes.uploaderWrapper}
        handleFileUploading={handleFileUploading}
        handleRemoveFile={handleRemoveFile}
        defaultValue={videos}
        isOpen={isModalVisible}
        onClose={handleClose}
      />

      {thumbnail ? (
        <div
          className={classes.wrapper}
          onClick={handleWrapperClick}
          style={{
            backgroundImage: `url(${thumbnail})`,
            cursor: thumbnail ? "pointer" : "inherit",
          }}
        />
      ) : (
        <div className={clsx(classes.wrapper, classes.stubWrapper)}>
          <VideoIcon width={'100px'} height={'100px'} />
        </div>
      )}
      <div className={classes.albumCta}>
        <Typography className={classes.ctaText} variant="body3">
          {type}:<span className={classes.ctaBadge}>{videosCount}</span>
        </Typography>

        {
          !staffRestriction &&
          <div>
            <Typography className={classes.ctaText} variant="body3">
              Add Videos
            </Typography>
            <Button
              onClick={handleOpen}
              component="span"
              style={{
                minWidth: 28,
                minHeight: 28,
                padding: 0,
                marginLeft: 12,
              }}
              disableRipple
              disableElevation
              color="info"
              className={classes.metaCtaIcon}
              variant="contained"
              startIcon={<CrossIcon />}
            />
          </div>
        }
      </div>
    </Fragment>
  );
};

export default AlbumVideoCover;
