import React, { useCallback, useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { makeStyles } from '@mui/styles';
import { IQuestions } from '@/app/users/users.dto';
import { useAppSelector } from '@/app/hooks';
import { UserProfileDTO } from '@/app/users/users.reducer';
import SelectTextField from '../base/select-text-field.component';
import {
  bodyList,
  childrenList,
  drinkList,
  educationList,
  eyeColorOptions,
  familyList,
  hairList,
  religionList,
  smokeList,
} from '@/pages/edit-profile/edit.consts';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  filterQuestionsRequest,
  postPhotosRequest,
  updateAvatarRequest,
  updateCurrentUserRequest,
} from '@/app/users/users.actions';
import { UserProfile } from '@/types/user-profile.types';
import BaseButton from '../base/button.component';
import DatePicker from '../base/date-picker.component';
import clsx from 'clsx';
import { DateOfBirth } from '@/services/utils';

import { Checkbox, FormControl, FormControlLabel, Slider, Theme, Typography } from '@mui/material';
import SecondaryTextFieldComponent from '../base/secondary-text-field.component';
import countryList from '@/utils/countryList';
import {
  AGE_MAX,
  AGE_MIN,
  HEIGHT_MAX,
  HEIGHT_MIN,
  TEXT_AREA_EDIT_MAX,
  WEIGHT_MAX,
  WEIGHT_MIN,
} from '@/utils/validationSchemas';
import { LanguageOptions } from '@/types/edit-select.types';
import BaseTextarea from '../base/base-textarea.component';
import DialogUploadFile from '@/components/dialogs/upload-photo-file.dialog.component';
import CloseIcon from '@mui/icons-material/Close';
import { useAppBreakpoints } from '@/hooks';
import { UserPhotoType } from '@/app/users/users.types';

const useStyles = makeStyles(() => ({
  textMessage: {
    fontSize: '14px',
  },
  container: {
    minWidth: '300px',
  },
  formContainer: {
    width: '100%',
  },
  formControl: {
    '& > $inputField:not(:first-child)': {
      marginTop: 20,
    },
  },
  saveButton: {
    width: '100%',
    maxWidth: 144,
    marginTop: 32,
  },
  inputAppendElement: {
    flexShrink: 0,
    padding: '0 6px',
    minWidth: 118,
  },
  inputField: {},
  datePickerContainer: {
    paddingLeft: 20,
  },
  aboutMeContainer: {
    display: 'flex',
  },
  optionsTitlte: {
    flexShrink: 0,
    fontWeight: '400 !important',
    paddingLeft: 20,
  },
  aboutMeWrapper: {
    width: '100%',
    marginLeft: 80,
  },
  uploadWrapper: {
    marginBottom: 15,
  },

  photoButtonContainer: {
    position: 'absolute',
    bottom: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  metaCtaIcon: {
    maxWidth: 42,
    maxHeight: 42,

    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },
  uploaderWrapper: {
    overflow: 'scroll',
  },
}));

const normalizeDateValue = (value: number) => {
  if (value === -1) return '-';

  if (value < 10) return `0${value}`;

  return value;
};

const normalizeDate = (date: DateOfBirth) => {
  const formatedDate = [];

  formatedDate.push(normalizeDateValue(date.year));
  formatedDate.push(normalizeDateValue(date.month));
  formatedDate.push(normalizeDateValue(date.day));

  const formatedData = formatedDate.join('-');

  return formatedData === '-----' ? null : formatedData;
};

export const QuestionsDialog = ({
  open,
  onClose,
  onCloseModalHandler,
  questions,
}: {
  open: boolean;
  onClose: () => void;
  onCloseModalHandler: () => void;
  questions: IQuestions;
}) => {
  const { profile }: UserProfileDTO = useAppSelector((state) => state.users.currentUser) || {};
  const userIsLoading = useAppSelector((state) => state.users.userIsLoading);

  const languageList: LanguageOptions[] = useMemo(
    () => [
      { value: '', name: 'Not chosen' },
      { value: 'basic', name: 'Basic' },
      { value: 'upper intermediate', name: 'Upper Intermediate' },
      { value: 'fluent', name: 'Fluent' },
    ],
    []
  );

  const userBirthday = useMemo(() => {
    if (profile?.birthday) {
      const date = new Date(profile?.birthday);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return {
        day,
        month,
        year,
      };
    } else {
      return {
        day: -1,
        month: -1,
        year: -1,
      };
    }
  }, [profile]);

  const initialData = {
    body: profile.body,
    birthday: profile.birthday ? profile.birthday : userBirthday,
    celebrationBirthday: profile.celebrationBirthday,
    celebrationChristmasDay: profile.celebrationChristmasDay,
    celebrationEaster: profile.celebrationEaster,
    celebrationHalloween: profile.celebrationHalloween,
    celebrationNewYearsDay: profile.celebrationNewYearsDay,
    celebrationValentinesDay: profile.celebrationValentinesDay,
    celebrationWomensDay: profile.celebrationWomensDay,
    children: profile.children,
    city: profile.city,
    country: profile.country,
    drink: profile.drink,
    education: profile.education,
    eyeColor: profile.eyeColor,
    gender: profile.gender,
    hairColor: profile.hairColor,
    height: profile.height,
    langEnglish: profile.langEnglish,
    langFrench: profile.langFrench,
    langGerman: profile.langGerman,
    langPortuguese: profile.langPortuguese,
    langSpanish: profile.langSpanish,
    name: profile.name,
    surname: profile.surname,
    nickname: profile.nickname,
    pageId: profile.pageId,
    perfectAgeFrom: profile.perfectAgeFrom,
    perfectAgeTo: profile.perfectAgeTo,
    perfectBody: profile.perfectBody,
    perfectChildren: profile.perfectChildren,
    perfectEyes: profile.perfectEyes,
    perfectHair: profile.perfectHair,
    perfectHeightFrom: profile.perfectHeightFrom,
    perfectHeightTo: profile.perfectHeightTo,
    perfectHeight:
      profile.perfectHeightFrom && profile.perfectHeightTo
        ? [profile.perfectHeightFrom, profile.perfectHeightTo]
        : [HEIGHT_MIN, HEIGHT_MAX],
    perfectAge:
      profile.perfectAgeFrom && profile.perfectAgeTo
        ? [profile.perfectAgeFrom, profile.perfectAgeTo]
        : [AGE_MIN, AGE_MAX],
    perfectLangEnglish: profile.perfectLangEnglish,
    perfectType: profile.perfectType,
    perfectWeight: profile.perfectWeight,
    profession: profile.profession,
    religion: profile.religion,
    smoke: profile.smoke,
    status: profile.status,
  };

  const [imageData, setImageData] = useState(null);
  const { mdAndUp, smAndDown } = useAppBreakpoints();

  const images = useAppSelector((state) => state.users.publicPhotos);
  const [isModalVisible, setModalVisible] = useState(false);

  const dispatch = useDispatch();

  const handleFileUploading = useCallback(
    (image: FormData) => {
      dispatch(postPhotosRequest(UserPhotoType.PUBLIC_VIEW, image));
    },
    [dispatch]
  );

  const clearImageData = useCallback(() => {
    setImageData(null);
  }, []);

  const handleClose = useCallback(() => {
    clearImageData();
    onCloseModalHandler();
  }, [clearImageData, onCloseModalHandler]);

  const handleClickPhoto = useCallback(
    (id: string, small: string, origin: string, openHandler: () => void) => {
      dispatch(updateAvatarRequest({ photoId: id }));
    },
    [dispatch]
  );

  const getQuestion = useCallback(
    (values, errors, setFieldValue, handleChange) => {
      const questionKeys = Object.keys(questions).filter(
        (key) =>
          !['id', 'isActive', 'reward', 'audience', 'audienceFromDate', 'createdAt']?.includes(key) && questions[key]
      );

      if (questionKeys.includes('body') && !profile.body) {
        return (
          <div className={classes.inputField}>
            <SelectTextField
              label='body'
              key='body'
              placeholder='Select body type'
              list={bodyList}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Body type:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('birthday') && !profile.birthday) {
        return (
          <div className={clsx(classes.inputField, classes.datePickerContainer)}>
            <DatePicker setFieldValue={setFieldValue} birthday={values.birthday} error={errors.birthday} />
          </div>
        );
      }

      if (questionKeys.includes('celebrationBirthday') && !profile.celebrationBirthday) {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={values.celebrationBirthday}
                value={values.celebrationBirthday}
                name='celebrationBirthday'
                onChange={(e) => setFieldValue('celebrationBirthday', e.target.checked)}
                color='warning'
              />
            }
            label='Celebration Birthday'
            key='CelebrationBirthday'
          />
        );
      }

      if (questionKeys.includes('celebrationEaster') && !profile.celebrationEaster) {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={values.celebrationEaster}
                value={values.celebrationEaster}
                name='celebrationEaster'
                onChange={(e) => setFieldValue('celebrationEaster', e.target.checked)}
                color='warning'
              />
            }
            label='Celebration Easter'
            key='CelebrationEaster'
          />
        );
      }

      if (questionKeys.includes('celebrationChristmasDay') && !profile.celebrationChristmasDay) {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={values.celebrationChristmasDay}
                value={values.celebrationChristmasDay}
                name='celebrationChristmasDay'
                onChange={(e) => setFieldValue('celebrationChristmasDay', e.target.checked)}
                color='warning'
              />
            }
            label='Celebration Christmas Day'
            key='CelebrationChristmas'
          />
        );
      }

      if (questionKeys.includes('celebrationHalloween') && !profile.celebrationHalloween) {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={values.celebrationHalloween}
                value={values.celebrationHalloween}
                name='celebrationHalloween'
                onChange={(e) => setFieldValue('celebrationHalloween', e.target.checked)}
                color='warning'
              />
            }
            label='Celebration Halloween'
            key='CelebrationHalloween'
          />
        );
      }

      if (questionKeys.includes('celebrationNewYearsDay') && !profile.celebrationNewYearsDay) {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={values.celebrationNewYearsDay}
                value={values.celebrationNewYearsDay}
                name='celebrationNewYearsDay'
                onChange={(e) => setFieldValue('celebrationNewYearsDay', e.target.checked)}
                color='warning'
              />
            }
            label='Celebration New Years Day'
            key='CelebrationNewYearsDay'
          />
        );
      }

      if (questionKeys.includes('celebrationValentinesDay') && !profile.celebrationValentinesDay) {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={values.celebrationValentinesDay}
                value={values.celebrationValentinesDay}
                name='celebrationValentinesDay'
                onChange={(e) => setFieldValue('celebrationValentinesDay', e.target.checked)}
                color='warning'
              />
            }
            label='Celebration Valentines Day'
            key='CelebrationValentinesDay'
          />
        );
      }

      if (questionKeys.includes('celebrationWomensDay') && !profile.celebrationWomensDay) {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={values.celebrationWomensDay}
                value={values.celebrationWomensDay}
                name='celebrationWomensDay'
                onChange={(e) => setFieldValue('celebrationWomensDay', e.target.checked)}
                color='warning'
              />
            }
            label='Celebration Womens Day'
            key='CelebrationWomensDay'
          />
        );
      }

      if (questionKeys.includes('family') && !profile.family) {
        return (
          <div className={classes.inputField}>
            <SelectTextField
              label='family'
              key='family'
              placeholder='family'
              list={familyList}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  family:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('children') && !profile.children) {
        return (
          <div className={classes.inputField}>
            <SelectTextField
              label='children'
              key='children'
              placeholder='Children'
              list={childrenList}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Children:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('country') && !profile.country) {
        return (
          <div className={classes.inputField}>
            <SelectTextField
              label='country'
              key='country'
              placeholder='Select your country'
              list={countryList}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Country:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('city') && !profile.city) {
        return (
          <div className={classes.inputField}>
            <SecondaryTextFieldComponent
              label='city'
              key='city'
              placeholder='City'
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  City:
                </Typography>
              }
              onChange={handleChange}
              type='text'
            />
          </div>
        );
      }

      if (questionKeys.includes('drink') && !profile.drink) {
        return (
          <div className={classes.inputField}>
            <SelectTextField
              label='drink'
              key='drink'
              caseSensetive={true}
              placeholder='Select how often you drink'
              list={drinkList}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Alcohol:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('education') && !profile.education) {
        return (
          <div className={classes.inputField}>
            <SelectTextField
              label='education'
              key='education'
              caseSensetive={true}
              placeholder='Select your education'
              list={educationList}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Education:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('eyeColor') && !profile.eyeColor) {
        return (
          <div className={classes.inputField}>
            <SelectTextField
              label='eyeColor'
              key='eyeColor'
              placeholder='Select eyes color'
              list={eyeColorOptions}
              caseSensetive={false}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Eyes color:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('hairColor') && !profile.hairColor) {
        return (
          <div className={classes.inputField}>
            <SelectTextField
              label='hairColor'
              key='hairColor'
              placeholder='Select hair color'
              list={hairList}
              caseSensetive={false}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Hair color:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('height') && !profile.height) {
        return (
          <div className={classes.inputField} style={{ maxWidth: '100%', padding: '0 11px' }}>
            <Typography variant='body1'>
              Height: <span style={{ color: '#808080' }}>{values.height || HEIGHT_MIN}</span>
            </Typography>

            <Slider
              name='height'
              min={HEIGHT_MIN}
              max={HEIGHT_MAX}
              step={0.1}
              getAriaLabel={() => 'height range'}
              value={values.height || HEIGHT_MIN}
              onChange={(event, value) => setFieldValue('height', value)}
              valueLabelDisplay='auto'
              disableSwap
            />
          </div>
        );
      }

      if (questionKeys.includes('langEnglish') && !profile.langEnglish) {
        return (
          <div key={'langEnglish'} className={classes.inputField}>
            <SelectTextField
              placeholder={`English level`}
              label={'langEnglish'}
              list={languageList}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  English:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('langFrench') && !profile.langFrench) {
        return (
          <div key={'langFrench'} className={classes.inputField}>
            <SelectTextField
              placeholder={`French level`}
              label={'langFrench'}
              list={languageList}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  French:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('langGerman') && !profile.langGerman) {
        return (
          <div key={'langGerman'} className={classes.inputField}>
            <SelectTextField
              placeholder={`German level`}
              label={'langGerman'}
              list={languageList}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  German:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }
      if (questionKeys.includes('langPortuguese') && !profile.langPortuguese) {
        return (
          <div key={'langPortuguese'} className={classes.inputField}>
            <SelectTextField
              placeholder={`Portuguese level`}
              label={'langPortuguese'}
              list={languageList}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Portuguese:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('langSpanish') && !profile.langSpanish) {
        return (
          <div key={'langSpanish'} className={classes.inputField}>
            <SelectTextField
              placeholder={`Spanish level`}
              label={'langSpanish'}
              list={languageList}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Spanish:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('name') && !profile.name) {
        return (
          <div className={classes.inputField}>
            <SecondaryTextFieldComponent
              label='name'
              key='name'
              placeholder='Name'
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Your name:
                </Typography>
              }
              onChange={handleChange}
              type='text'
            />
          </div>
        );
      }

      if (questionKeys.includes('aboutMe') && !profile.aboutMe) {
        return (
          <div className={clsx(classes.inputField, classes.aboutMeContainer)}>
            <Typography variant='body1' className={classes.optionsTitlte}>
              About me:
            </Typography>
            <div className={classes.aboutMeWrapper}>
              <BaseTextarea
                label='aboutMe'
                key='aboutMe'
                placeholder={`About me (${TEXT_AREA_EDIT_MAX} symbols max)`}
                onChange={handleChange}
              />
            </div>
          </div>
        );
      }

      if (questionKeys.includes('perfectBody') && !profile.perfectBody) {
        return (
          <div className={classes.inputField}>
            <SelectTextField
              label='perfectBody'
              key='perfectBody'
              placeholder='Select body type'
              list={bodyList}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Body type:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('perfectChildren') && !profile.perfectChildren) {
        return (
          <div className={classes.inputField}>
            <SelectTextField
              label='perfectChildren'
              key='perfectChildren'
              placeholder='Children'
              list={childrenList}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Children:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('perfectEyes') && !profile.perfectEyes) {
        return (
          <div className={classes.inputField}>
            <SelectTextField
              label='perfectEyes'
              key='perfectEyes'
              placeholder='Select eyes color'
              list={eyeColorOptions}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Eyes color:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('perfectHair') && !profile.perfectHair) {
        return (
          <div className={classes.inputField}>
            <SelectTextField
              label='perfectHair'
              key='perfectHair'
              caseSensetive={true}
              placeholder='Select hair color'
              list={hairList}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Hair color:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('perfectLangEnglish') && !profile.perfectLangEnglish) {
        return (
          <div className={classes.inputField}>
            <SelectTextField
              placeholder={`English level`}
              label={'perfectLangEnglish'}
              list={languageList}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  English:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('perfectType') && !profile.perfectType) {
        return (
          <div className={clsx(classes.inputField, classes.aboutMeContainer)}>
            <Typography variant='body1' className={classes.optionsTitlte}>
              Perfect type:
            </Typography>
            <div className={classes.aboutMeWrapper}>
              <BaseTextarea
                label='perfectType'
                key='perfectType'
                placeholder={`Perfect type (${TEXT_AREA_EDIT_MAX} symbols max)`}
                onChange={handleChange}
              />
            </div>
          </div>
        );
      }

      if (questionKeys.includes('perfectWeight') && !profile.perfectWeight) {
        return (
          <div className={classes.inputField}>
            <SecondaryTextFieldComponent
              label='perfectWeight'
              key='perfectWeight'
              placeholder='lbs'
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Weight:
                </Typography>
              }
              onChange={handleChange}
              type='number'
              min={WEIGHT_MIN}
              max={WEIGHT_MAX}
            />
          </div>
        );
      }

      if (questionKeys.includes('profession') && !profile.profession) {
        return (
          <div className={classes.inputField}>
            <SecondaryTextFieldComponent
              label='profession'
              key='profession'
              placeholder='Profession'
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Profession:
                </Typography>
              }
              onChange={handleChange}
              type='text'
            />
          </div>
        );
      }
      if (questionKeys.includes('religion') && !profile.religion) {
        return (
          <div className={classes.inputField}>
            <SelectTextField
              label='religion'
              key='religion'
              caseSensetive={true}
              placeholder='Select your religion'
              list={religionList}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Religion:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('smoke') && !profile.smoke) {
        return (
          <div className={classes.inputField}>
            <SelectTextField
              label='smoke'
              key='smoke'
              caseSensetive={true}
              placeholder='Select how often you smoke'
              list={smokeList}
              setFieldValue={setFieldValue}
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Smoking:
                </Typography>
              }
              inputAppendElement={classes.inputAppendElement}
            />
          </div>
        );
      }

      if (questionKeys.includes('perfectHeightFrom') && !profile.perfectHeightFrom) {
        return (
          <div className={classes.inputField} style={{ maxWidth: '100%', padding: '0 11px' }}>
            <Typography variant='body1'>
              Height:{' '}
              <span style={{ color: '#808080' }}>{`${values.perfectHeight[0]} to ${values.perfectHeight[1]}`}</span>
            </Typography>

            <Slider
              name='perfectHeight'
              min={HEIGHT_MIN}
              max={HEIGHT_MAX}
              step={0.1}
              getAriaLabel={() => 'perfect height range'}
              value={values.perfectHeight}
              onChange={(event, value) => setFieldValue('perfectHeight', value)}
              valueLabelDisplay='auto'
              disableSwap
            />
          </div>
        );
      }

      if (questionKeys.includes('perfectAgeFrom') && !profile.perfectAgeFrom) {
        return (
          <div className={classes.inputField} style={{ maxWidth: '100%', padding: '0 11px' }}>
            <Typography variant='body1'>
              Age: <span style={{ color: '#808080' }}>{`${values.perfectAge[0]} to ${values.perfectAge[1]}`}</span>
            </Typography>

            <Slider
              name='perfectAge'
              min={AGE_MIN}
              max={AGE_MAX}
              step={1}
              getAriaLabel={() => 'age range'}
              value={values.perfectAge}
              onChange={(event, value) => setFieldValue('perfectAge', value)}
              valueLabelDisplay='auto'
              disableSwap
            />
          </div>
        );
      }

      if (questionKeys.includes('avatar') && !profile.avatar) {
        return (
          <DialogUploadFile
            title={<>upload your avatar</>}
            className={classes.uploaderWrapper}
            titleClassName={classes.uploaderWrapper}
            handleFileUploading={handleFileUploading}
            handleClickPhoto={handleClickPhoto}
            defaultValue={images}
            isOpen={true}
            onClose={handleClose}
          />
        );
      }
      if (questionKeys.includes('weight') && !profile.weight) {
        return (
          <div className={classes.inputField}>
            <SecondaryTextFieldComponent
              label='weight'
              key='weight'
              placeholder='lbs'
              prepend={
                <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                  Weight:
                </Typography>
              }
              onChange={handleChange}
              type='number'
              min={WEIGHT_MIN}
              max={WEIGHT_MAX}
            />
          </div>
        );
      }
      return null;
    },
    [questions, profile, images]
  );

  const classes = useStyles();

  const prepareData = useCallback((values) => {
    console.log('values', values);
    const preparedValues = values as UserProfile;
    for (const iterator of Object.keys(values)) {
      if (!preparedValues[iterator]) {
        delete preparedValues[iterator];
      }
      delete preparedValues.gender;
    }

    // for (const iterator of Object.keys(values)) {
    //   if (preparedValues[iterator]) {
    //     preparedValues[iterator] = true;
    //   }
    // }

    if ((preparedValues.birthday as unknown as DateOfBirth)) {
      preparedValues.birthday = profile.birthday
        ? profile.birthday
        : normalizeDate(preparedValues.birthday as unknown as DateOfBirth);
    }

    console.log('preparedValues', preparedValues);

    if (['Often', 'Rarely', 'Occasionally'].includes(String(preparedValues.drink)))
      //@ts-ignore
      preparedValues.drink = preparedValues.drink?.toLowerCase();
    if (['Often', 'Rarely'].includes(String(preparedValues.smoke)))
      //@ts-ignore
      preparedValues.smoke = preparedValues.smoke?.toLowerCase();
    //@ts-ignore
    if (preparedValues.children === 'no children') preparedValues.children = 'no';
    //@ts-ignore
    if (preparedValues.children === 'no but i want to have children') {
      //@ts-ignore
      preparedValues.children = 'no but I want to have children';
    }

    if (preparedValues.perfectHair) {
      //@ts-ignore
      preparedValues.perfectHair = preparedValues.perfectHair.toLowerCase();
    }

    //@ts-ignore
    if (preparedValues.perfectChildren === 'no children') preparedValues.perfectChildren = 'no';
    //@ts-ignore
    if (preparedValues.perfectChildren === 'no but i want to have children') {
      preparedValues.perfectChildren = 'no but I want to have children';
    }

    return preparedValues;
  }, [profile]);

  const getReward = useCallback(() => {
    dispatch(filterQuestionsRequest(questions.id));
  }, [questions]);

  const getForm = (values, errors, setFieldValue, handleChange, handleSubmit) => {
    return (
      <>
        <FormControl fullWidth className={classes.formControl}>
          {getQuestion(values, errors, setFieldValue, handleChange)}
        </FormControl>
        <div style={{ marginTop: 32 }}>
          {getQuestion(values, errors, setFieldValue, handleChange) ? (
            <BaseButton
              loading={userIsLoading}
              color='primary'
              text='Save'
              className={classes.saveButton}
              onClick={() => handleSubmit()}
            />
          ) : (
            <div>
              {' '}
              <BaseButton
                loading={userIsLoading}
                color='primary'
                text='Get Reward'
                className={classes.saveButton}
                onClick={() => getReward()}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <Dialog open={open} onClose={onCloseModalHandler}>
      <DialogTitle>Answer the questions and get free credits</DialogTitle>
      <DialogContent className={classes.container}>
        <Formik
          initialValues={initialData}
          validateOnChange={false}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(updateCurrentUserRequest(prepareData(values) as UserProfile));
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <form onSubmit={handleSubmit} className={classes.formContainer}>
              {getForm(values, errors, setFieldValue, handleChange, handleSubmit)}
            </form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={onCloseModalHandler}>
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
