import { Badge, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";
import { useDialogMessageNavigationList, useMessageNavigationList } from "../messages.hooks";

const useStyles = makeStyles((theme: Theme) => ({
  navItem: {
    color: "#000",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      margin: 6,
      textDecoration: "none",
    },
    "& > $navItemText": {
      marginLeft: 16,

      "&::first-child": {
        marginLeft: 12,
      },
    },

    "& > svg": {
      width: 18,
      height: 18,
    },
  },
  navItemText: {},
  activeLink: {
    color: theme.palette.primary.main,
    "& path": {
      fill: theme.palette.primary.main,
    },
  },
  defaultLink: {
    color: "#000",

    "& path": {
      fill: "#503EB6",
    },
  },
  navList: {
    display: "flex",
    padding: 0,
    marginTop: 0,
    flexDirection: "column",
    "& > a": {
      textDecoration: "none",
    },

    "& > $navItem:not(:first-child)": {
      marginTop: 16,
    },
  },
}));

const MessagesNavbarDialog = ({ setMessageOptionEl, setKind, kind }) => {
  const messageNavigation = useDialogMessageNavigationList();
  const classes = useStyles();

  return (
    <ul className={classes.navList}>
      {messageNavigation.map((navItem, _) => (
        <div
          onClick={() => setKind(navItem.kind)}
          className={clsx(
            classes.navItem,

            kind.includes(navItem.kind)
              ? classes.activeLink
              : classes.defaultLink
          )}
        >
          {navItem.counter && (
            <Badge
              badgeContent={navItem.count}
              color="primary"
              style={{ padding: "2px 4px" }}
            >
              {/* @ts-ignore */}
              <navItem.icon />
            </Badge>
          )}
          {!navItem.counter && (
            <>
              {/* @ts-ignore */}
              <navItem.icon />
            </>
          )}

          <Typography variant="body3" className={classes.navItemText}>
            {navItem.name}
          </Typography>
        </div>
      ))}
    </ul>
  );
};

export default MessagesNavbarDialog;
