import * as io from 'socket.io-client';
import { getSocketUrl, getWebsocketsPath } from 'helpers/helpers';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';

export interface ISocket {
  connect: () => Promise<unknown>;
  emit: (eventName: string, data?: Record<string, any>) => void;
  // eslint-disable-next-line
  on: <T>(eventName: string, callback: (data: T) => void) => void;
  off: (eventName: string, callback?: () => void) => void;
  reconnect: () => void;
  disconnect: () => void;
  socket?: SocketIOClient.Socket;
  socketConnect: () => Promise<unknown>;
}

const socketConnect$ = new BehaviorSubject<null | boolean>(null);

/* eslint-disable @typescript-eslint/no-use-before-define */
const Socket: ISocket = {
  connect,
  emit,
  on,
  reconnect,
  disconnect,
  socketConnect,
  off,
};

/* eslint-enable @typescript-eslint/no-use-before-define */
function connect() {
  if (Socket.socket && Socket.socket.connected) {
    return Promise.resolve();
  }

  return new Promise(resolve => {
    const token = StorageService.getToken();
    // Setup a server for testing.
    Socket.socket = io.connect(getSocketUrl(process.env.REACT_APP_WS_BASEURL), {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    Socket.on('connect', (e: any) => {
      console.log('connect');
      if (Socket.socket) {
        Socket.socket.sendBuffer = [];
      }
      resolve(e);

      socketConnect$.complete();
    });
  });
}

function off(eventName: string, callback?: () => void) {
  if (Socket.socket) {
    Socket.socket.off(eventName, callback);
  }
}

function on<T>(eventName: string, callback: (data: T) => void) {
  if (Socket.socket) {
    Socket.socket.on(eventName, (data: T) => {
      callback(data);
    });
  }
}

function emit(eventName: string, data?: Record<string, any>) {
  if (Socket.socket && Socket.socket.connected) {
    Socket.socket.emit(eventName, data);
  }
}

function reconnect() {
  if (Socket.socket) {
    Socket.socket.disconnect();
    Socket.socket.close();
    connect();
  }
}

function disconnect() {
  if (Socket.socket && Socket.socket.connected) {
    Socket.socket.disconnect();
    Socket.socket.close();
  }
}

function socketConnect() {
  return socketConnect$.toPromise();
}

export default Socket;
