import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import objectSupport from "dayjs/plugin/objectSupport";
import * as yup from "yup";
import { object, string, array, number } from "yup";
dayjs.extend(customParseFormat);
dayjs.extend(objectSupport);

//WEIGHT
export const WEIGHT_MIN = 1;
export const WEIGHT_MAX = 400;
//HEIGHT
export const HEIGHT_MIN = 3;
export const HEIGHT_MAX = 10;
//AGE
export const AGE_MIN = 18;
export const AGE_MAX = 100;
//About me
export const TEXT_AREA_EDIT_MAX = 1000;

export const signUpSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8, "Seems a bit short").required(),
  name: yup.string().required(),
  gender: yup.string().required(),
  agreed: yup.boolean().isTrue("Agreement is required for registration"),
});

export const resetPasswordSchema = yup.object().shape({
  code: yup.string().required(),
  password: yup.string().min(8, "Seems a bit short").required(),
});

export const editUserSchema = object({
  name: string().required().label("Name"),
  country: string().nullable().label("Country"),
  city: string().nullable().label("City"),
  birthday: object().test("isValidDate", "Enter all birthday fields", (value, { parent }) => {
    const isAnyFieldFilled = Object.values(parent.birthday).some((item) => item !== undefined && item !== -1);
    const isAllFieldsFilled = Object.values(parent.birthday).every((item) => item !== undefined && item !== -1);

    if (isAnyFieldFilled && !isAllFieldsFilled) {
      return false;
    }

    if (isAnyFieldFilled) {
      return Object.values(value).every((item) => item > 0 && !isNaN(item));
    }

    return true;
  }),
  langEnglish: string().nullable().label("English"),
  langSpanish: string().nullable().label("Spanish"),
  langFrench: string().nullable().label("French"),
  langPortuguese: string().nullable().label("Portuguese"),
  langGerman: string().nullable().label("German"),
});

export const aboutUserSchema = object({
  religion: string().nullable().label("Religion"),
  profession: string().nullable().label("Profession"),
  education: string().nullable().label("Education"),
  smoke: string().nullable().label("Smoking"),
  drink: string().nullable().label("Alcohol"),
  // celebrationBirthday: boolean().nullable().label("Birthday"),
  // celebrationChristmasDay: boolean().nullable().label("Christmas Day"),
  // celebrationNewYearsDay: boolean().nullable().label("New Years Day"),
  // celebrationEaster: boolean().nullable().label("Easter"),
  // celebrationValentinesDay: boolean().nullable().label("Valentines Day"),
  // celebrationHalloween: boolean().nullable().label("Halloween"),
  // celebrationWomensDay: boolean().nullable().label("Womens Day"),
  children: string().nullable().label("Children"),
  aboutMe: string().nullable().max(TEXT_AREA_EDIT_MAX).label("About Me"),
});

export const appearanceUserSchema = object({
  hairColor: string().nullable().label("Hair color"),
  eyesColor: string().nullable().label("Eyes color"),
  bodyType: string().nullable().label("Profession"),
  height: number().min(HEIGHT_MIN).max(HEIGHT_MAX),
  weight: number().min(WEIGHT_MIN).max(WEIGHT_MAX),
});

export const settingsUserSchema = object({
  password: yup.string()
    .min(8, 'Password must be 8 symbols min')
    .required('Enter password'),
    resetPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Password mismatch')
    .required('Confirm password'),
});

export const perfectWomanSchema = object({
  perfectAge: array().test("is in range", "Not in range", function (value) {
    const [min, max] = value;
    return min >= AGE_MIN && max <= AGE_MAX;
  }),
  perfectHair: string().nullable().label("Hair color"),
  perfectEyes: string().nullable().label("Eyes color"),
  perfectBody: string().nullable().label("Body type"),
  perfectHeight: array().test("is in range", "Not in range", function (value) {
    const [min, max] = value;
    return min >= HEIGHT_MIN && max <= HEIGHT_MAX;
  }),
  perfectWeight: number().min(WEIGHT_MIN).max(WEIGHT_MAX),
  perfectLangEnglish: string().nullable().label("English"),
  perfectChildren: string().nullable().label("Children"),
  perfectType: string().nullable().max(TEXT_AREA_EDIT_MAX).label("Perfect type"),
});

export const validationSchemaLogin = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8, "Seems a bit short").required(),
});

export const validationSchemaForgotPassword = yup.object().shape({
  email: yup.string().email().required(),
});

export const writeMessageSchema = object({
  receiverId: object().required().label("Receiver"),
  subject: string().label("Subject"),
  text: string().required().label("Message"),
  photoIds: array(),
});


export const writeGiftSchema = object({
  receiverId: object().required().label("Receiver"),
  text: string().required().label("Message"),
  virtualGiftId: array(),
});


export const validationSchemaChatMessage = yup.object().shape({
  message: yup.string(),
});

export const contactUsSchema = yup.object().shape({
  email: yup.string().email().required(),
  subject: yup.string(),
  message: yup.string(),
});