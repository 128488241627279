import { CountersShape, GiftShape } from '@/services/gifts.service';
import {
  GET_GIFTS_REQUEST,
  GET_GIFTS_FAILURE,
  GET_GIFTS_SUCCESS,
  CHECK_GIFT,
  CHECK_FEW_GIFTS,
  SET_IS_BOOKMARKS,
  SET_SEARCH_PROFILE_ID,
  SET_SORTING,
  RESET_PAGINATION_AND_GIFTS,
  SET_PAGINATION,
  SET_PAGINATION_IS_OVER,
  MARK_AS_READ_SELECTED_GIFTS,
  MARK_AS_READ_SELECTED_GIFTS_SUCCESS,
  SET_SELECTED_GIFTS,
  RESET_SELECTED_GIFTS,
  ADD_TO_BOOKMARKS,
  ADD_TO_BOOKMARKS_SUCCESS,
  GET_USER_GIFT_REQUEST,
  GET_USER_GIFT_SUCCESS,
  GET_USER_GIFT_FAIL,
  RESET_USER_GIFT_FAIL,
  GiftKind,
  SortlistNames,
  GET_CLOSED_ATTACH_REQUEST,
  GET_CLOSED_ATTACH_SUCCESS,
  GET_CLOSED_ATTACH_FAIL,
  GET_GIFT_COUNTERS,
  GET_GIFT_COUNTERS_SUCCESS,
  SOCKET_UPDATE_GIFTS_COUNT,
  GET_AVAILABLE_TO_SEND_GIFTS_REQUEST,
  GET_AVAILABLE_TO_SEND_GIFTS_SUCCESS,
  GET_AVAILABLE_TO_SEND_GIFTS_FAILURE,
  POST_USER_GIFT_REQUEST,
  POST_USER_GIFT_SUCCESS,
  POST_USER_GIFT_FAILURE,
  PostGiftDTO,
  GiftsDTO,
  GET_GIFTS_FROM_SOCKET_REQUEST,
  GET_GIFTS_FROM_SOCKET_SUCCESS,
  GET_GIFTS_FROM_SOCKET_FAILURE,
} from './gifts.types';

export const getGiftsRequest = (kind: GiftKind) => ({
  type: GET_GIFTS_REQUEST,
  payload: kind,
});

export const getGiftsSuccess = (kind: GiftKind, payload: Array<GiftShape>) => ({
  type: GET_GIFTS_SUCCESS,
  payload,
  kind,
});

export const getGiftsFailure = (error: unknown) => ({
  type: GET_GIFTS_FAILURE,
  error,
});


export const getGiftsFromSocketRequest = (kind: GiftKind, gift: GiftShape) => ({
  type: GET_GIFTS_FROM_SOCKET_REQUEST,
  payload: { kind, gift}
});

export const getGiftsFromSocketSuccess = (kind: GiftKind, payload: GiftShape) => ({
  type: GET_GIFTS_FROM_SOCKET_SUCCESS,
  payload,
  kind,
});

export const getGiftsFromSocketFailure = (error: unknown) => ({
  type: GET_GIFTS_FROM_SOCKET_FAILURE,
  error,
});

export const checkGift = (giftId: string) => ({
  type: CHECK_GIFT,
  payload: giftId,
});

export const checkFewGifts = (giftIds: Array<string>) => ({
  type: CHECK_FEW_GIFTS,
  payload: giftIds,
});

export const setIsBookmarksAction = (isBookmarksState: boolean) => ({
  type: SET_IS_BOOKMARKS,
  payload: isBookmarksState,
});

export const setSearchProfileIdAction = (id: string | null) => ({
  type: SET_SEARCH_PROFILE_ID,
  payload: id,
});

export const setSortingAction = (sort: SortlistNames | null) => ({
  type: SET_SORTING,
  payload: sort,
});

export const resetPaginationAndGiftsAction = (kind: GiftKind) => ({
  type: RESET_PAGINATION_AND_GIFTS,
  payload: kind,
});

export const setPaginationAction = (paginationOffset: number) => ({
  type: SET_PAGINATION,
  payload: paginationOffset,
});

export const setPaginationOverAction = (isOver: boolean) => ({
  type: SET_PAGINATION_IS_OVER,
  payload: isOver,
});

export const markAsReadSelectedGiftsAction = (kind: GiftKind) => ({
  type: MARK_AS_READ_SELECTED_GIFTS,
  payload: kind,
});

export const markAsReadSelectedGiftsSuccessAction = (kind: GiftKind, chosenIds: Set<string>) => ({
  type: MARK_AS_READ_SELECTED_GIFTS_SUCCESS,
  payload: { kind, chosenIds },
});

export const setSelectedGifts = (type: string) => ({
  type: SET_SELECTED_GIFTS,
  payload: type,
});

export const resetSelectedGifts = () => ({
  type: RESET_SELECTED_GIFTS,
});

export const addToBookmarksAction = (id: string, kind: string) => ({
  type: ADD_TO_BOOKMARKS,
  payload: { id, kind },
});

export const addToBookmarksSuccessAction = (payload: { id: string; kind: string }) => ({
  type: ADD_TO_BOOKMARKS_SUCCESS,
  payload,
});

export const getUserGiftRequest = (id: string, type: string) => ({
  type: GET_USER_GIFT_REQUEST,
  payload: { id, type },
});

export const getUserGiftSuccess = (payload: GiftShape) => ({
  type: GET_USER_GIFT_SUCCESS,
  payload,
});

export const getUserGiftFail = () => ({
  type: GET_USER_GIFT_FAIL,
});

export const resetUserGift = () => ({
  type: RESET_USER_GIFT_FAIL,
});

export const getClosedAttachRequest = (attachId: string, giftId: string) => ({
  type: GET_CLOSED_ATTACH_REQUEST,
  payload: { attachId, giftId },
});

export const getClosedAttachSuccess = (payload: GiftShape) => ({
  type: GET_CLOSED_ATTACH_SUCCESS,
  payload,
});

export const getClosedAttachFail = () => ({
  type: GET_CLOSED_ATTACH_FAIL,
});

export const getGiftCountersAction = () => ({
  type: GET_GIFT_COUNTERS,
});

export const getGiftCountersSuccessAction = (payload: CountersShape) => ({
  type: GET_GIFT_COUNTERS_SUCCESS,
  payload,
});

export const socketUpdateGiftsCount = (payload: CountersShape) => ({
  type: SOCKET_UPDATE_GIFTS_COUNT,
  payload,
});

export const getAvailableToSendGiftsRequest = () => ({
  type: GET_AVAILABLE_TO_SEND_GIFTS_REQUEST,
});

export const getAvailableToSendGiftsSuccess = (gifts: Array<GiftsDTO>) => ({
  type: GET_AVAILABLE_TO_SEND_GIFTS_SUCCESS,
  gifts,
});

export const getAvailableToSendGiftsFailure = (error: unknown) => ({
  type: GET_AVAILABLE_TO_SEND_GIFTS_FAILURE,
  error,
});

export const postGiftRequest = (body: PostGiftDTO) => ({
  type: POST_USER_GIFT_REQUEST,
  body,
});

export const postGiftSuccess = (gift: PostGiftDTO) => ({
  type: POST_USER_GIFT_SUCCESS,
  gift,
});

export const postGiftFailure = (error: unknown) => ({
  type: POST_USER_GIFT_FAILURE,
  error,
});
