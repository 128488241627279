import { AlertColor } from '@mui/material';
import {
  TOGGLE_AVAILABLE_USERS_BAR,
  TOGGLE_LAST_USERS_BAR,
  TOGGLE_SNACKBAR_CLOSE,
  TOGGLE_SNACKBAR_OPEN,
  TOGGLE_VIDEO_CHAT_OPEN,
  TOGGLE_MYSTREAM,
  CLOSE_NOT_ENOUGH_BALANCE,
  OPEN_NOT_ENOUGH_BALANCE,
  TOGGLE_MODAL_CLOSE,
  TOGGLE_MODAL_OPEN,
} from './ui.types';

export const toggleSnackbarOpen = (message: string, type: AlertColor) => ({
  type: TOGGLE_SNACKBAR_OPEN,
  payload: {
    message,
    type,
  },
});

export const toggleSnackbarClose = () => ({
  type: TOGGLE_SNACKBAR_CLOSE,
});

export const toggleModalOpen = (message: string, type: AlertColor, userId: string) => ({
  type: TOGGLE_MODAL_OPEN,
  payload: {
    message,
    type,
    userId,
  },
});

export const toggleModalClose = () => ({
  type: TOGGLE_MODAL_CLOSE,
});

export const toggleAvailableUsersBar = () => ({
  type: TOGGLE_AVAILABLE_USERS_BAR,
});

export const toggleLastUsersBar = () => ({
  type: TOGGLE_LAST_USERS_BAR,
});

export const toggleVideoChatOpen = () => ({
  type: TOGGLE_VIDEO_CHAT_OPEN,
});

export const toggleMyStream = (stream: MediaStream) => ({
  type: TOGGLE_MYSTREAM,
  payload: {
    stream,
  }
});

export const closeNotEnoughBalance = () => ({
  type: CLOSE_NOT_ENOUGH_BALANCE,
});

export const openNotEnoughBalance = (notEnoughBalance: number) => ({
  type: OPEN_NOT_ENOUGH_BALANCE,
  payload: {
    notEnoughBalance,
  }
});
