import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  textMessage: {
    fontSize: '14px',
  },
  container: {
    minWidth: '300px',
  },
}));

export const InAppDialog = ({ open, onClose, title, body }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.container}>
        <div
          className={classes.textMessage}
          dangerouslySetInnerHTML={{
            __html: body,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={onClose}>
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
