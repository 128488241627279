import { IUserPhotosData, UserPhotosDTO, defaultUserPhotosData } from '@/app/users/users.dto';
import { ReactComponent as ArrowLeftIcon } from '@/assets/icons/left-arrow.svg';
import { ReactComponent as ArrowRightIcon } from '@/assets/icons/right-arrow.svg';
import { useAppBreakpoints } from '@/hooks';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { Item } from 'react-photoswipe-gallery';
import { Navigation, Scrollbar, SwiperOptions } from 'swiper';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import Avatar from '../base/avatar.component';
import BaseButton from '../base/button.component';

const useStyles = makeStyles((theme: Theme) => ({
  slideWrapper: {
    maxWidth: 'none',

    [theme.breakpoints.down('sm')]: {
      maxWidth: 92,
    },
  },
  disabledNavigationBtn: {
    backgroundColor: 'rgb(229, 229, 229) !important',
    cursor: 'not-allowed !important',
    boxShadow: 'none !important',
  },
  sliderNavigationControl: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '76px',
    minWidth: '76px',
  },
  arrowButtons: {
    boxShadow: 'none!important',
    padding: '0!important',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  },
  line: {
    background: '#E7E6E6',
    width: '100%',
    paddingLeft: '10px',
    height: '1px',
    marginRight: '10px',
  },
}));

type ChatProfileUserSliderProps = {
  defaultValue: IUserPhotosData;
};

const ChatProfileUserSlider = ({
  defaultValue = defaultUserPhotosData,
}: ChatProfileUserSliderProps) => {
  const { mdAndUp } = useAppBreakpoints();
  const classes = useStyles();
  const modules = [Scrollbar, Navigation];

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const swiperOption: SwiperOptions = {
    slidesPerView: 'auto',
    breakpoints: {
      0: {
        slidesPerView: 2,
        spaceBetween: 8,
      },

      390: {
        slidesPerView: 2,
        spaceBetween: 8,
      },
      640: {
        slidesPerView: 5,
        spaceBetween: 8,
      },
      768: {
        slidesPerView: 5,
        spaceBetween: 8,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 8,
      },
      1280: {
        slidesPerView: 5,
        spaceBetween: 8,
      },
    },
  };

  return (
    <div>
      <div className={classes.buttonWrapper}>
        <div className={classes.line}></div>
        <div className={clsx(classes.sliderNavigationControl)}>
          <BaseButton
            className={clsx(classes.arrowButtons)}
            text=''
            variant='contained'
            size='small'
            ref={navigationPrevRef}
            Icon={<ArrowLeftIcon />}
            color='primary'
            // disableElevation
          />
          <BaseButton
            className={classes.arrowButtons}
            text=''
            variant='contained'
            size='small'
            ref={navigationNextRef}
            Icon={<ArrowRightIcon />}
            color='primary'
            // disableElevation
          />
        </div>
      </div>
      <Swiper
        modules={modules}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
          disabledClass: classes.disabledNavigationBtn,
        }}
        onBeforeInit={(swiper: any) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        slidesPerView={swiperOption.slidesPerView}
        spaceBetween={8}
        breakpoints={swiperOption.breakpoints}
        freeMode={true}
        mousewheel={true}
        scrollbar={{ draggable: true }}
      >
        {defaultValue.data.map(({ small, origin }, idx) => (
          <SwiperSlide key={idx} className={classes.slideWrapper}>
            <Item original={origin} thumbnail={small} width='1024' height='768'>
              {({ ref, open }) => <Avatar size={!mdAndUp ? 'medium' : 'square'} url={small} onClick={open} ref={ref} />}
            </Item>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ChatProfileUserSlider;
