import { Fade, CircularProgress, Theme, Badge } from '@mui/material';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { makeStyles } from '@mui/styles';
import UploadGift from '../base/upload-gift';
import { GiftsDTO, GiftsEnum, giftLabel } from '@/app/gifts/gifts.types';

export interface StyleProps {
  isExpended: boolean;
  isEditable: boolean;
}

type UploadGiftLayoutProps = {
  className: string;
  handleFileUploading: (image: FormData) => void;
  handleRemoveFile: (id: string) => void;
  defaultValue: Array<GiftsDTO>;
  isUploading: boolean;
  handleDrop: (files: any) => void;
  isExpended?: boolean;
  isEditable?: boolean;
  isDelitable?: boolean;
  handleSelect?: (file: unknown) => void;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: ({ isExpended }) => ({
    maxWidth: isExpended ? '100%' : 376,
    border: isExpended ? `2px dashed ${theme.palette.radioSelected.main}` : '',
    padding: isExpended ? 8 : 12,
    borderRadius: 10,
    flexWrap: 'wrap',
    display: 'flex',
    flexwrap: 'wrap',
    alignContent: 'space-between',
  }),
  dropzone: ({ isExpended }) => ({
    cursor: 'pointer',
    color: theme.palette.radioSelected.main,
    outline: 'none',
    margin: 12,
    borderRadius: 15,
    width: isExpended ? 180 : 96,
    height: isExpended ? 180 : 96,
    backgroundColor: theme.palette.info.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  innerZone: {
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    borderRadius: 'inherit',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
  },
  dropIcon: {
    fontSize: '2.7rem',
  },
  imageType: {
    borderRadius: 10,
    color: '#fff',
    fontSize: 12,
    fontWeight: 600,
    padding: '2px 6px',
  },
  publicImage: {
    backgroundColor: theme.palette.success.main,
  },
  privateImage: {
    backgroundColor: theme.palette.primary.main,
  },
  categoryBlock: {
    width: '100%',
  },
  categoryItemContainer: {
    display: 'flex',
    overflowX: 'scroll',
  },
}));

const UploadGiftLayout = ({
  handleDrop,
  className,
  defaultValue,
  handleRemoveFile,
  handleSelect,
  isUploading,
  isExpended = true,
  isEditable = true,
  isDelitable = false,
}: UploadGiftLayoutProps) => {
  const classes = useStyles({ isExpended, isEditable });

  // Grouping the gifts by category
  const giftsByCategory: Record<string, GiftsDTO[]> = useMemo(() => {
    const groupedGifts = defaultValue.reduce((acc, gift) => {
      const { category } = gift;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(gift);
      return acc;
    }, {});
    return groupedGifts;
  }, [defaultValue]);

  const windowWidth = useMemo(() => window.innerWidth, []);
  const windowHeight = useMemo(() => window.innerHeight - 90, []);

  return (
    <Dropzone onDrop={handleDrop} accept='image/png, image/gif, image/jpeg'>
      {({ getRootProps, getInputProps }) => (
        <section className={clsx(classes.root, className)}>
          {Object.entries(giftsByCategory).map(([category, gifts]) => (
            <div key={category} className={classes.categoryBlock}>
              <h2>{giftLabel[category as GiftsEnum]}</h2>
              <div className={classes.categoryItemContainer}>
                {gifts.map(({ ...rest }) => (
                  <UploadGift
                    key={rest.id}
                    {...rest}
                    handleRemoveFile={handleRemoveFile}
                    handleSelect={handleSelect}
                    windowWidth={windowWidth}
                    windowHeight={windowHeight}
                    withoutIcon={isDelitable}
                  />
                ))}
              </div>
            </div>
          ))}
          <Fade in={isUploading}>
            <div className={classes.dropzone}>
              <CircularProgress color='inherit' size={24} />
            </div>
          </Fade>
        </section>
      )}
    </Dropzone>
  );
};

export default UploadGiftLayout;
