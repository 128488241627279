import { UserPhotosDTO } from "@/app/users/users.dto";
import { useAppBreakpoints } from "@/hooks";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Item, useGallery } from "react-photoswipe-gallery";
import { Scrollbar, SwiperOptions } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import Avatar from "../base/avatar.component";
import { useAppSelector } from '@/app/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  slideWrapper: {
    maxWidth: "none",
    cursor: 'pointer',

    [theme.breakpoints.down("sm")]: {
      maxWidth: 92,
    },
  },
}));

type ProfileUserSliderProps = {
  defaultValue: Array<{
    small: string;
    origin: string;
  }>;
  windowHeight: number;
  windowWidth: number;
  openRegistrationForm?: (status: boolean) => void;
};

const ProfileUserSlider = ({ defaultValue = [], windowWidth, windowHeight, openRegistrationForm = (status: boolean) => {}}: ProfileUserSliderProps) => {
  const { mdAndUp } = useAppBreakpoints();
  const currentUser = useAppSelector((state) => state.users.currentUser);
  const classes = useStyles();
  const modules = [Scrollbar];

  const swiperOption: SwiperOptions = {
    slidesPerView: "auto",
    breakpoints: {
      0: {
        slidesPerView: 2,
        spaceBetween: 28,
      },

      390: {
        slidesPerView: 2,
        spaceBetween: 32,
      },
      640: {
        slidesPerView: 5,
        spaceBetween: 112,
      },
      768: {
        slidesPerView: 5,
        spaceBetween: 8,
      },
    },
  };

  return (
    <Swiper
      modules={modules}
      slidesPerView={swiperOption.slidesPerView}
      spaceBetween={8}
      breakpoints={swiperOption.breakpoints}
      freeMode={true}
      mousewheel={true}
      scrollbar={{ draggable: true }}
    >
      {defaultValue.map(({ small, origin }, idx) => (
        <SwiperSlide key={idx} className={classes.slideWrapper}>
          <Item original={origin} thumbnail={small} width={windowWidth} height={windowHeight}>
            {({ ref, open }) => (
              <Avatar
                size={!mdAndUp ? "medium" : "square"}
                url={small}
                onClick={() => {
                  if(!currentUser) {
                    openRegistrationForm(true);
                    return;
                  }
                  open();
                }
                }
                ref={ref}
              />
            )}
          </Item>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ProfileUserSlider;
