import { useCallback, useState } from "react";

type BaseGiftDTO = {
  id: string;
  name: string;
  credits: number;
  image: string;
};

type UseFileUtils = [
  BaseGiftDTO | null,
  (fileToRemove: unknown) => void,
  (fileToAdd: unknown) => void,
  () => void
];

const useFileGiftUtils = <T extends unknown & BaseGiftDTO>(): UseFileUtils => {
  const [file, setFile] = useState<T | null>(null);

  const removeFile = useCallback(
    () => {
      setFile(null);
    },
    [setFile]
  );

  const appendFile = useCallback(
    (fileToAdd: T) => {
      setFile(fileToAdd);
    },
    [setFile]
  );

  const resetAttachments = () => setFile(null);
  return [file, removeFile, appendFile, resetAttachments];
};

export default useFileGiftUtils;
