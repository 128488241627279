import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { CSSProperties, useCallback, useMemo } from 'react';
import { Item } from 'react-photoswipe-gallery';
import { DeleteIconStyled } from '../dialogs/upload-photo-file.dialog.component';
import { StyleProps } from '../layouts/upload-photo.layout';

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  dropzone: ({ isExpended }) => ({
    cursor: 'pointer',
    color: theme.palette.radioSelected.main,
    outline: 'none',
    margin: 12,
    borderRadius: isExpended ? 15 : 6,
    width: isExpended ? 180 : 86,
    height: isExpended ? 180 : 86,
    backgroundColor: theme.palette.info.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  innerZone: {
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    borderRadius: 'inherit',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
  },
  dropIcon: {
    fontSize: '2.7rem',
  },
  imageType: {
    borderRadius: 10,
    color: '#fff',
    fontSize: 12,
    fontWeight: 600,
    padding: '2px 6px',
  },
  publicImage: {
    backgroundColor: theme.palette.success.main,
  },
  privateImage: {
    backgroundColor: theme.palette.primary.main,
  },
}));

type UploadPhotoItemProps = {
  id: string;
  isExpended: boolean;
  small: string;
  origin: string;
  large?: string;
  smallBlur?: string;
  originBlur?: string;
  largeBlur?: string;
  paidAt?: string | null;
  profileId?: string | null;
  handleRemoveFile?: (id: string) => void;
  handleSelect?: (file: unknown) => void;
  isPrivate: boolean;
  isEditable: boolean;
  withoutIcon?: boolean;
  handleClick?: ({
    id,
    small,
    origin,
    large,
    paidAt,
    profileId,
    open,
    smallBlur,
    originBlur,
    largeBlur,
  }: {
    id: string;
    small: string;
    origin: string;
    large: string;
    paidAt: string | null;
    profileId: string | null;
    open: () => void;
    smallBlur?: string;
    originBlur?: string;
    largeBlur?: string;
  }) => void;
  windowWidth?: number;
  windowHeight?: number;
};

const UploadPhotoItem = ({
  id,
  isExpended,
  isEditable,
  small,
  origin,
  large,
  smallBlur,
  originBlur,
  largeBlur,
  paidAt,
  profileId,
  handleRemoveFile,
  handleSelect,
  isPrivate,
  withoutIcon,
  handleClick,
  windowWidth,
  windowHeight,
}: UploadPhotoItemProps) => {
  const overridedHandleSelect = (file) => {
    typeof handleSelect === 'function' && handleSelect(file);
  };

  const itemWidth = useMemo(() => windowWidth || 1024, [windowWidth]);
  const itemHeight = useMemo(() => windowHeight || 768, [windowHeight]);

  const classes = useStyles({ isExpended, isEditable });

  const getPosition = () => {
    if (isExpended) return 10;
    if (!isExpended && isEditable) return 10;
    if (!isExpended) return 0;
  };

  const stylesOverrides = {
    closeIcon: {
      position: 'absolute',
      right: getPosition(),
      top: getPosition(),
      zIndex: 1000,
    },
  };

  const getIcon = useCallback(() => {
    if (withoutIcon) return null;

    if (isExpended || isEditable) return <DeleteIconStyled />;

    return (
      <span className={clsx(classes.imageType, isPrivate ? classes.privateImage : classes.publicImage)}>
        {isPrivate ? 'private' : 'public'}
      </span>
    );
  }, [classes.imageType, classes.privateImage, classes.publicImage, isEditable, isExpended, isPrivate, withoutIcon]);

  const handleRemove = useCallback(
    (id: string) => {
      if (handleRemoveFile) {
        handleRemoveFile(id);
      }
    },
    [handleRemoveFile]
  );

  return (
    <div
      key={small}
      style={{ position: 'relative' }}
      onClick={() =>
        overridedHandleSelect({
          id,
          small,
          origin,
          large,
          smallBlur,
          originBlur,
          largeBlur,
          private: isPrivate,
        })
      }
    >
      <Item original={origin} thumbnail={small} width={itemWidth} height={itemHeight}>
        {({ ref, open }) => (
          <div className={classes.dropzone}>
            <div
              ref={ref as any}
              onClick={() =>
                handleClick ? handleClick({ id, small, origin, large, paidAt, profileId, open }) : open()
              }
              className={classes.innerZone}
              style={{
                backgroundImage: `url("${small}")`,
              }}
            ></div>
          </div>
        )}
      </Item>
      <div style={stylesOverrides.closeIcon as CSSProperties} onClick={() => handleRemove(id)}>
        {getIcon()}
      </div>
    </div>
  );
};

export default UploadPhotoItem;
