import { calculateAge } from "@/helpers/helpers";
import useBlankProfileAvatart from "@/hooks/useBlankProfileAvatart";
import { UserSliderProfile } from "@/types/user-profile.types";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { differenceInMinutes } from "date-fns";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "./avatar.component";
import StatusOnline from "./statuses/status-online.component";
const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    paddingRight: 10,
    cursor: 'pointer'
  },

  cardInner: {
    textDecoration: "none",
    padding: "10px 0",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgb(80, 62, 182)",
    transition: "all",
    borderRadius: 9,
    border: "2px solid #fff",
    maxWidth: 158,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 9,
      opacity: 0,
      transition: "opacity",
    },
    "&:focus": {
      outline: "none",
      opacity: 1,
    },

    "&:hover": {
      opacity: 1,
    },
  },
  cardWrapper: {
    position: "relative",
    zIndex: 1,
  },
  avatarContainer: {
    transform: "translateX(10px)",
    marginBottom: 20,
    "&::after": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 10,
      opacity: 0,
      transition: "opacity",
      backgroundColor:
        "linear-gradient(to right bottom, rgb(80, 62, 182), rgb(234, 65, 96))",
      "&:focus, &:hover": {
        opacity: 0.7,
      },
    },
  },
  avatarStatuses: {
    display: "flex",
    flexDirection: "column",

    position: "absolute",
    bottom: 6,
    left: 6,
    zIndex: 1,
  },
  avatarMeta: {
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 600,
    color: "#fff",
  },
  avatarId: {
    fontSize: 14,
    lineHeight: "20px",
    margin: 0,
  },
  avatarProfileInfo: {
    display: "flex",
    fontSize: 18,
    lineHeight: "28px",
    margin: 0,
  },
  avatarName: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  avatarAge: {
    flexShrink: 0,
  },
}));

type ProfileCardProps = {
  disableId?: boolean | undefined;
  user?: UserSliderProfile | null;
};
const ProfileCard = ({ disableId = false, user = null }: ProfileCardProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { oppositeUserPicture } = useBlankProfileAvatart();

  const isUserActiveNow = useMemo(() => user?.activeAt && differenceInMinutes(new Date(), new Date(user.activeAt)) < 15, [user?.activeAt])

  const navigateToProfile = () => {
    if (user) {
      navigate(`/user/${user.userId}`);
    }
  };

  return (
    <div className={classes.cardContainer} onClick={navigateToProfile}>
      <div className={classes.cardInner}>
        <div className={classes.cardWrapper}>
          <Avatar url={user?.avatar || oppositeUserPicture} containerClass={classes.avatarContainer}>
            <div className={classes.avatarStatuses}>
              {/* <StatusCamera /> */}
              {isUserActiveNow && <StatusOnline />}
            </div>
          </Avatar>

          <div className={classes.avatarMeta}>
            {!disableId && user?.pageId && <p className={classes.avatarId}>ID: {user.pageId}</p>}
            <p className={classes.avatarProfileInfo}>
              <span className={classes.avatarName}>
                {user && user.name ? user.name : "Username"}
              </span>
              {!disableId && user?.birthday ? <span className={classes.avatarAge}>{`, ${calculateAge(user.birthday)}`}</span> : null}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
