import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { toggleSnackbarOpen } from '@/app/ui/ui.actions';
import { getEmailEventsBlacklistRequest } from '@/app/users/users.actions';
import { EmailEventEnum, EmailEventsBlacklist, UserProfileDTO } from '@/app/users/users.reducer';
import BaseButton from '@/components/base/button.component';
import SecondaryTextField from '@/components/base/secondary-text-field.component';
import EmailEventsBlacklistService from '@/services/emailEventsBlacklist.service';

import { appearanceUserSchema } from '@/utils/validationSchemas';
import { Checkbox, Divider, FormControl, FormControlLabel, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: '100%',
  },
  formControl: {
    '& > $inputField:not(:first-child)': {
      marginTop: 20,
    },
  },
  inputField: {},
  inputAppendElement: {
    flexShrink: 0,
    padding: '0 6px',
    minWidth: 118,

    [theme.breakpoints.down('md')]: {
      minWidth: 80,
    },
  },
  inputFieldShort: {
    maxWidth: '50% ',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100% ',
    },
  },

  optionsWrapper: {
    display: 'grid',
    marginLeft: 'auto',
    width: '100%',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gap: 8,
    paddingLeft: 12,

    [theme.breakpoints.down('md')]: {
      width: '100%',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
  },
  optionsTitlte: {
    flexShrink: 0,
    fontWeight: '400 !important',
    paddingLeft: 20,
  },
  divider: {
    paddingTop: 15,
  },
  saveButton: {
    width: '100%',
    maxWidth: 144,
    marginTop: 32,
  },
}));

export interface UserNotificationsType {
  reportDaily?: boolean | undefined;
  birthdayLetter?: boolean | undefined;
  emailFreeCredits?: boolean | undefined;
  emailVirtualGift?: boolean | undefined;
  emailMessage?: boolean | undefined;
  creditsBought?: boolean | undefined;
}

const UserNotifications = () => {
  const classes = useStyles();
  const emailEventsBlackList = useAppSelector<EmailEventsBlacklist[]>((state) => state.users.emailEventsBlacklist || []);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEmailEventsBlacklistRequest());
  }, [dispatch]);

  const setUserNotificationsData = useCallback(() => {
    return {
      reportDaily: emailEventsBlackList.find((element) => element.emailEvent === EmailEventEnum.reportDaily)
        ? true
        : false,
      birthdayLetter: emailEventsBlackList.find((element) => element.emailEvent === EmailEventEnum.birthdayLetter)
        ? true
        : false,
      emailFreeCredits: emailEventsBlackList.find((element) => element.emailEvent === EmailEventEnum.emailFreeCredits)
        ? true
        : false,
      emailVirtualGift: emailEventsBlackList.find((element) => element.emailEvent === EmailEventEnum.emailVirtualGift)
        ? true
        : false,
      emailMessage: emailEventsBlackList.find((element) => element.emailEvent === EmailEventEnum.emailMessage)
        ? true
        : false,
      creditsBought: emailEventsBlackList.find((element) => element.emailEvent === EmailEventEnum.creditsBought)
        ? true
        : false,
    };
  }, [emailEventsBlackList]);

  const [initialData, setInitialData] = useState<UserNotificationsType>(setUserNotificationsData);

  const onSubmit = useCallback(
    async (values: any, actions) => {
      for await (const key of Object.keys(values)) {
        const notificationKey = emailEventsBlackList.find((element) => element.emailEvent === key);
        if (!notificationKey && values[key]) {
          await EmailEventsBlacklistService.addEmailEventBlackList(key);
        } else if (values[key] === false && notificationKey) {
          await EmailEventsBlacklistService.deleteEmailEventBlackList(notificationKey.id);
        }
      }
      dispatch(getEmailEventsBlacklistRequest());
      dispatch(toggleSnackbarOpen('Success!', 'success'));
    },
    [dispatch, emailEventsBlackList]
  );

  useEffect(() => {
    setInitialData(setUserNotificationsData());
  }, [emailEventsBlackList, setUserNotificationsData]);

  if (!emailEventsBlackList) {
    return null;
  }

  return (
    <div>
      <div><h5>You will not receive email letters from the following sources:</h5></div>
      <Formik initialValues={initialData} enableReinitialize validateOnChange={false} onSubmit={onSubmit}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit} className={classes.formContainer}>
            <FormControl fullWidth className={classes.formControl}>
              <div className={classes.optionsWrapper}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.reportDaily}
                      value={values.reportDaily}
                      name='reportDaily'
                      onChange={handleChange}
                      color='warning'
                    />
                  }
                  label='Daily Report'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.birthdayLetter}
                      value={values.birthdayLetter}
                      name='birthdayLetter'
                      onChange={handleChange}
                      color='warning'
                    />
                  }
                  label='Birthday Letter'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.emailFreeCredits}
                      value={values.emailFreeCredits}
                      name='emailFreeCredits'
                      onChange={handleChange}
                      color='warning'
                    />
                  }
                  label='Free Credits'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.emailVirtualGift}
                      value={values.emailVirtualGift}
                      name='emailVirtualGift'
                      onChange={handleChange}
                      color='warning'
                    />
                  }
                  label='Virtual Gift'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.emailMessage}
                      value={values.emailMessage}
                      name='emailMessage'
                      onChange={handleChange}
                      color='warning'
                    />
                  }
                  label='Messages'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.creditsBought}
                      value={values.creditsBought}
                      name='creditsBought'
                      onChange={handleChange}
                      color='warning'
                    />
                  }
                  label='Bought credits'
                />
              </div>
              <div style={{ marginTop: 32 }}>
                <BaseButton color='primary' text='Save' className={classes.saveButton} onClick={() => handleSubmit()} />
              </div>
            </FormControl>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UserNotifications;
