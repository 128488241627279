import { getMeRequest, signOutRequest } from '@/app/auth/auth.actions';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { chargeBalanceRequest, getBookmarksRequest } from '@/app/users/users.actions';
import { ReactComponent as ArrowDown } from '@/assets/icons/arrow-down.svg';
import { ReactComponent as CoinIcon } from '@/assets/icons/coins.svg';
import { ReactComponent as MenuIcon } from '@/assets/icons/menu.svg';
import SearchIcon from '@/assets/icons/search.svg';
import { ReactComponent as SoundIcon } from '@/assets/icons/sound.svg';
import { ReactComponent as SoundIconWhite } from '@/assets/icons/sound-white.svg';

import BaseButton from '@/components/base/button.component';
import PrimaryBaseTextField from '@/components/base/text-field-base.component';
import headerMenuLinks from '@/components/core/header-config';
import { useAppBreakpoints } from '@/hooks';
import { StorageService } from '@/services/storage.service';
import Logo from '@/ui/wd-logo.ui';
import {
  AppBar,
  Avatar,
  Container,
  Divider,
  IconButton,
  Link,
  SwipeableDrawer,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import jwtDecode from 'jwt-decode';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Clock } from './clock.component';
import { UserRolesEnum } from '../enums/user-roles.enum';
import Socket from '@/services/Socket';
import useIsStaffUserRole from '@/hooks/useIsStaffUserRole';
import ChargeBalance from './charge-balance.components';
import BalanceCheckComponent from '@/components/dialogs/not-enough-balance.dialog';

//Todo skeleton fade, zoom check in

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    marginTop: 0,
    marginBottom: 0,
  },
  searchedWrapper: {
    display: 'flex',
    maxHeight: 40,
    marginLeft: 64,

    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  headerMenuContainer: {
    listStyleType: 'none',
    width: '100%',
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'row',
    padding: 0,

    marginLeft: 68,
    '& > $headerMenuItem:first-child': {
      marginLeft: 0,
    },
  },
  menuBtn: {
    marginRight: '8px !important',
    backkground: 'transparent',
    width: 50,
    height: 50,
  },
  headerMenuItem: {
    padding: 0,
    marginLeft: 28,
    fontSize: 14,
    textDecoration: 'none',
  },
  toolbarInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  activeUserBar: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  rightSide: {
    flex: '1 0 auto',
    marginLeft: 'auto',
    '& > $headerButtonStyle:first-child': {
      marginRight: 20,
    },
    [theme.breakpoints.down('lg')]: {
      flex: 'none',
      marginLeft: 'auto',
    },
  },
  //props
  headerButtonStyle: {
    [theme.breakpoints.up('md')]: {
      minWidth: '150px !important',
    },

    [theme.breakpoints.down('md')]: {
      maxWidth: '100px !important',
    },
  },
  headerProfileOptionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  headerNotificationButton: {
    maxWidth: 42,
    maxHeight: 42,

    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },
  headerBalanceButton: {
    maxWidth: 100,
  },
  headerAvatarWrapper: {
    display: 'flex',
    marginLeft: 66,

    [theme.breakpoints.down('lg')]: {
      marginLeft: 18,
    },
  },

  headerTextStyle: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px !important',
    },
  },
  logoutBtn: {
    marginLeft: 10,
  },
  searchedInput: {
    paddingRight: 36,
    backgroundSize: 14,
    outline: ' 8px ridge rgba(170, 50, 220, .6)',
    backgroundPosition: '94% 50%',
    background: `url(${SearchIcon}) no-repeat right`,
  },
  headerMenuContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    paddingRight: 40,

    '& > $headerMenuItemMobile:first-child': {
      marginTop: 0,
    },
  },
  headerMenuItemMobile: {
    marginTop: 12,
  },
}));

type DialogTypes = 'sign-up';
type HtmlElementRefType = ((instance: any) => void) | React.MutableRefObject<any> | null;
const ChatHeader = () => {
  const isStaff = useIsStaffUserRole();
  const navigate = useNavigate();

  const { currentUser, usersWithActiveTextChat, wallet, videoActive } = useAppSelector((state) => state.users);

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { smAndDown, mdAndDown, lg } = useAppBreakpoints();

  let { id, otherId } = useParams();

  const [isActiveClock, setIsActiveClock] = useState(false);
  useEffect(() => {
    if (usersWithActiveTextChat.length || (videoActive && videoActive.videoProfileId)) {
      setIsActiveClock(true);
    } else {
      setIsActiveClock(false);
    }
  }, [usersWithActiveTextChat]);

  useEffect(() => {
    const userToken = StorageService.getToken();
    if (userToken && !currentUser) {
      const { id: userId }: any = jwtDecode(userToken);
      dispatch(getMeRequest());
    }
    dispatch(getBookmarksRequest({}));
  }, []);

  const [isTurnOnAudio, toggleAudio] = useState(true);

  useEffect(() => {
    const isTurnOnAudio = StorageService.getAudio();
    toggleAudio(!!isTurnOnAudio);
  }, []);

  const onSoundIconClick = useCallback(() => {
    if (isTurnOnAudio) {
      StorageService.removeAudio();
      toggleAudio(false);
    } else {
      StorageService.setAudio();
      toggleAudio(true);
    }
  }, [isTurnOnAudio]);

  let rightSide = (
    <div className={classes.activeUserBar}>
      <Clock isRotating={isActiveClock} />
      {!isStaff ? (
        <div>
          <BaseButton
            text={`${wallet?.balance ? wallet?.balance : 0}`}
            color='primary'
            className={classes.headerBalanceButton}
            textStyle={classes.headerTextStyle}
            onClick={() => navigate('/user/credits')}
            Icon={<CoinIcon />}
          />
        </div>
      ) : null}
      <div className={classes.headerAvatarWrapper}>
        <BaseButton
          inverted={isTurnOnAudio}
          text=''
          className={classes.headerNotificationButton}
          textStyle={classes.headerTextStyle}
          onClick={onSoundIconClick}
          Icon={isTurnOnAudio ? <SoundIcon /> : <SoundIconWhite />}
        />
      </div>
    </div>
  );

  return (
    <AppBar position='static' className={classes.appBar} color='inherit' elevation={0}>
      <Container maxWidth={lg ? 'lg' : 'xl'} disableGutters>
        <Toolbar>
          {currentUser.role !== UserRolesEnum.STAFF && <ChargeBalance />}
          <div className={classes.toolbarInner}>
            <div className={classes.logoContainer}>
              <Logo small={smAndDown} vertical={mdAndDown && !smAndDown} />
            </div>

            {rightSide}
          </div>
        </Toolbar>
      </Container>
      <BalanceCheckComponent />
    </AppBar>
  );
};

export default ChatHeader;
