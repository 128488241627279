import axios, {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  CancelToken,
  CancelTokenSource,
  CancelTokenStatic,
} from "axios";
import { StorageService } from "./storage.service";

interface CancelSources {
  [key: string]: CancelTokenSource;
}

export default class AxiosService {
  private _401interceptor = 0;
  private _cancelToken: CancelTokenStatic = axios.CancelToken;
  readonly $axios: AxiosInstance;
  readonly cancelSources: CancelSources = {};
  readonly isCancel: <T>(value: T) => boolean = axios.isCancel;

  constructor(config: AxiosRequestConfig) {
    this.$axios = axios.create(config);
    this.$axios.interceptors.request.use(
      function (config) {
        const token = StorageService.getToken();
        if (config.headers) {
          token
            ? (config.headers.Authorization = `Bearer ${token}`)
            : delete config.headers.Authorization;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    this.mount401Interceptor();
  }

  async get(
    resource: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosPromise> {
    return this.$axios.get(resource, config);
  }

  async post<D = any>(
    resource: string,
    data?: D,
    config?: AxiosRequestConfig
  ): Promise<AxiosPromise> {
    return this.$axios.post(resource, data, config);
  }

  async putToCustomUrl<D = any>(
    url: string,
    data: D,
    config?: AxiosRequestConfig
  ): Promise<AxiosPromise> {
    return axios.put(url, data, config);
  }

  async getCustomUrl<D = any>(
    url: string,
    data: D,
  ): Promise<AxiosPromise> {
    return axios.get(url, data);
  }

  async patch<D = any>(
    resource: string,
    data?: D,
    config?: AxiosRequestConfig
  ): Promise<AxiosPromise> {
    return this.$axios.patch(resource, data, config);
  }

  async put<D = any>(
    resource: string,
    data?: D,
    config?: AxiosRequestConfig
  ): Promise<AxiosPromise> {
    return this.$axios.put(resource, data, config);
  }

  async delete(
    resource: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosPromise> {
    return this.$axios.delete(resource, config);
  }

  async customRequest(config: AxiosRequestConfig): Promise<AxiosPromise> {
    return this.$axios(config);
  }

  getCancelToken(cancelSource: string): CancelToken {
    return this.cancelSources[cancelSource].token;
  }

  cancelSourceInit(cancelSource: string): void {
    this.cancel(cancelSource);
    this.cancelSources[cancelSource] = this._cancelToken.source();
  }

  cancelSourceClear(cancelSource: string): void {
    delete this.cancelSources[cancelSource];
  }

  cancel(cancelSource: string, message?: string): void {
    if (this.cancelSources[cancelSource]) {
      this.cancelSources[cancelSource].cancel(message);
    }
  }

  mount401Interceptor(): void {
    this._401interceptor = this.$axios.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        const originalConfig = err.config;

        if (err.response) {
          // Access Token was expired
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            StorageService.removeToken();

            if (window.location.pathname !== "/") {
              window.location.replace("/");
            }
          }
        }

        return Promise.reject(err);
      }
    );
  }

  unmount401Interceptor(): void {
    this.$axios.interceptors.response.eject(this._401interceptor);
  }
}
export const HttpService = new AxiosService({
  baseURL: process.env.REACT_APP_VITE_API_BASE_URL as string,
});

export const WsService = new AxiosService({
  baseURL: process.env.REACT_APP_WS_BASEURL as string,
});
