import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { UserProfileDTO } from '@/app/users/users.reducer';
import { ReactComponent as ContactsIcon } from '@/assets/icons/chats/contacts-black.svg';
import { ReactComponent as ContactsWhiteIcon } from '@/assets/icons/chats/contacts-white.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/search.svg';
import profilePicture from '@/assets/images/demo-avatar.jpeg';
import BaseButton from '@/components/base/button.component';
import PrimaryBaseTextField from '@/components/base/text-field-base.component';
import { useBinarySwitcher } from '@/hooks/useBinarySwitcher';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import UserLink from './user-link';
import { UserDialog, UserProfile } from '@/types/user-profile.types';
import { getSearchUsersRequest } from '@/app/users/users.actions';
import { IconButton } from '@mui/material';

type AvailableUserComponentProps = {
  onClick?: () => void;
  mobile?: boolean;
  availableUsers: any;
  searchUsers: { data: UserProfile[]; total: number };
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '272px',
    padding: '14px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 0 0 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    overflowY: 'scroll',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '56px',
    fontSize: '22px',
    fontWeight: '600',
    color: '#000000',
  },
  buttonsWrapper: {
    paddingTop: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mainButton: {
    width: '80px',
    height: '24px',
    minHeight: '24px!important',
    padding: '0',
    boxShadow: 'none!important',
  },
  searchUsers: {
    marginTop: '14px',
  },
  usersWrapper: {
    marginTop: '27px',
    overflowY: 'scroll',
    height: 'calc(100vh - 352px)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100vh - 200px)',
    },
  },
  searchedWrapper: {
    display: 'flex',
    maxHeight: 30,
    width: '100%',
  },
  searchedInput: {
    width: '100%',
    paddingRight: 6,
    backgroundSize: 14,
    lineHeight: '30px',
    outline: ' 8px ridge rgba(170, 50, 220, .6)',
    backgroundPosition: '94% 50%',
  },
  shown: {
    '& span': {
      color: 'white!important',
    },
  },
  hide: {
    '& span': {
      color: 'black!important',
    },
  },
  searchButtonBottom: {
    width: 40,
    lineHeight: '30px',
    height: 30,
    marginLeft: '10px!important',
  },
}));

const AvailableUsers = ({ mobile = false, onClick, availableUsers = [], searchUsers }: AvailableUserComponentProps) => {
  const classes = useStyles();
  const currentUser = useAppSelector((state) => state.users.currentUser);
  const bookmarks = useAppSelector((state) => state.users.bookmarks);

  const currentProfile: UserProfileDTO = useAppSelector((state) => state.users.currentUser);

  const gender = currentProfile?.profile.gender;

  const [availableUsersToShow, setAvailableUsersToShow] = useState(availableUsers || []);

  const [viewState, setViewState] = useState('all');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (viewState === 'online') {
      setAvailableUsersToShow(availableUsers);
      return;
    }
    const prepareBookMark = (bookmarks || []).map((bookmark: UserProfileDTO) => {
      return bookmark.id;
    });

    const available = availableUsers.filter((availableUser) => prepareBookMark.includes(availableUser.id));
    setAvailableUsersToShow(available);
  }, [bookmarks, viewState, availableUsers]);
  const [filterUsers, setFilterUsers] = useState<any>('');

  const handleSubmit = useCallback(() => {
    let tempValues = {
      name: '',
      pageId: '',
    };

    if (filterUsers) {
      if (isNaN(filterUsers)) {
        tempValues.name = filterUsers;
        delete tempValues.pageId;
      } else {
        tempValues.pageId = filterUsers;
        delete tempValues.name;
      }
    } else {
      delete tempValues.pageId;
      delete tempValues.name;
    }

    dispatch(getSearchUsersRequest(tempValues));
  }, [dispatch, filterUsers]);

  const genderLabel = gender === 'male' ? ' Girls' : 'Mens';
  return (
    <div className={classes.container} style={mobile ? { paddingBottom: 23, paddingTop: 27 } : {}}>
      <div className={classes.title}>{genderLabel} Online</div>
      <div className={classes.buttonsWrapper}>
        <BaseButton
          text='All'
          color={viewState === 'all' ? 'primary' : 'info'}
          variant='contained'
          wrapperClass={clsx(classes.mainButton, viewState === 'all' ? classes.shown : classes.hide)}
          onClick={() => setViewState('all')}
        />
        <BaseButton
          text='Online'
          color={viewState === 'online' ? 'primary' : 'info'}
          variant='contained'
          onClick={() => setViewState('online')}
          wrapperClass={clsx(classes.mainButton, viewState === 'online' ? classes.shown : classes.hide)}
          Icon={viewState === 'online' ? <ContactsWhiteIcon /> : <ContactsIcon />}
        />
        <BaseButton
          text={bookmarks?.length || 0}
          color={viewState === 'bookmarks' ? 'primary' : 'info'}
          variant='contained'
          onClick={() => setViewState('bookmarks')}
          wrapperClass={clsx(classes.mainButton, viewState === 'bookmarks' ? classes.shown : classes.hide)}
          Icon={viewState === 'bookmarks' ? <ContactsWhiteIcon /> : <ContactsIcon />}
        />
      </div>
      <div className={classes.searchUsers}>
        <div className={classes.searchedWrapper}>
          <PrimaryBaseTextField
            onChange={(event) => setFilterUsers(event.target.value)}
            label='Name or ID '
            inputClassName={classes.searchedInput}
          />
          {/* <BaseButton
            color='primary'
            text='Search'
            loading={userIsLoading}
            className={classes.searchButtonBottom}
            onClick={(e: any) => handleSubmit()}
          /> */}

          <IconButton
            onClick={(e: any) => handleSubmit()}
            className={classes.searchButtonBottom}
          >
            <SearchIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.usersWrapper}>
        {viewState === 'all' &&
          searchUsers.data.map((user) => (
            <UserLink
              onClick={onClick}
              userName={user.name}
              user={currentUser}
              key={user.id}
              uid={user.id}
              isNewMessage={false}
              avatar={user.avatar || profilePicture}
              pageId={`${user.pageId}`}
            />
          ))}
        {viewState === 'online' &&
          availableUsersToShow
            .filter(
              (user: { name: string; pageId: string; gender: string }) =>
                (user?.name.includes(filterUsers) || `${user?.pageId}`.includes(filterUsers)) &&
                currentUser.gender !== user.gender
            )
            .map(({ name, id, avatar, pageId, itHasNewMessage }) => (
              <UserLink
                onClick={onClick}
                userName={name}
                user={currentUser}
                key={id}
                uid={id}
                avatar={avatar || profilePicture}
                pageId={pageId}
                isNewMessage={itHasNewMessage}
              />
            ))}
        {viewState === 'bookmarks' &&
          availableUsersToShow
            .filter(
              (user: { name: string; pageId: string; gender: string }) =>
                (user?.name.includes(filterUsers) || `${user?.pageId}`.includes(filterUsers)) &&
                currentUser.gender !== user.gender
            )
            .map(({ name, id, avatar, pageId, itHasNewMessage }) => (
              <UserLink
                onClick={onClick}
                userName={name}
                user={currentUser}
                key={id}
                uid={id}
                avatar={avatar || profilePicture}
                pageId={pageId}
                isNewMessage={itHasNewMessage}
              />
            ))}
      </div>
    </div>
  );
};

export default AvailableUsers;
