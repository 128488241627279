import { CloseIconStyled } from '@/components/dialogs/sign-up-dialog.component';
import DialogMails from '@/pages/messages/dialog-mails';

import { Dialog, Theme, Typography, Zoom } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '18px 10px',
    overflowY: 'scroll',

    [theme.breakpoints.down('sm')]: {
      padding: '28px 12px',
    },
  },
  paper: {
    padding: 0,
    width: 'calc(100% - 20px)!important',
    maxWidth: '1200px!important',
    [theme.breakpoints.down('sm')]: {
      width: '100%!important',
    },
  },
  dialogPaper: {
    overflowY: 'visible',
  },
  dialogTitle: {
    textAlign: 'center',
    height: 48,
  },
}));

type DialogOpenMailPageProps = {
  open: boolean;
  closeHandler: () => void;
};

const DialogOpenMailPage = ({ open, closeHandler }: DialogOpenMailPageProps) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        root: classes.dialogPaper,
        paper: classes.paper,
      }}
      TransitionComponent={Zoom}
      open={open}
      onClose={() => closeHandler()}
    >
      <div className={classes.container}>
        <div className={classes.dialogTitle}>
          <Typography variant='h2' component='h2' gutterBottom>
            Mails
          </Typography>
        </div>
        <Zoom in={open}>
          <div className={classes.container}>{<DialogMails />}</div>
        </Zoom>
      </div>
      <CloseIconStyled onClick={closeHandler} />
    </Dialog>
  );
};

export default DialogOpenMailPage;
