import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { MailKind } from "@/app/mails/mails.types";
import { ReactComponent as MailIcon } from "@/assets/icons/drawer-icons/mail.svg";
import { ReactComponent as SentIcon } from "@/assets/icons/sent.svg";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getMailsRequest, getMessageCountersAction, resetPaginationAndMailsAction } from "../../app/mails/mails.actions";

const LIMIT_MAILS_PER_REQUEST = 20;

export const useMessageNavigationList = () => {
  const inboxNewMessagesCount = useAppSelector((state) => state.mails.messageCounters?.unseen);

  return [
    {
      id: 1,
      path: "/user/messages/inbox",
      name: "Inbox",
      icon: MailIcon,
      counter: !!inboxNewMessagesCount,
      count: inboxNewMessagesCount,
    },
    {
      id: 2,
      path: "/user/messages/outbox",
      name: "Sent",
      icon: SentIcon,
      counter: false,
    },
  ];
};

export const useDialogMessageNavigationList = () => {
  const inboxNewMessagesCount = useAppSelector((state) => state.mails.messageCounters?.unseen);

  return [
    {
      id: 1,
      kind: "inbox",
      name: "Inbox",
      icon: MailIcon,
      counter: !!inboxNewMessagesCount,
      count: inboxNewMessagesCount,
    },
    {
      id: 2,
      kind: "outbox",
      name: "Sent",
      icon: SentIcon,
      counter: false,
    },
  ];
};

export const useMessages = (
  limit: number = LIMIT_MAILS_PER_REQUEST
): [MailKind] => {
  const isBookmarks = useAppSelector((state) => state.mails.isBookmarks);
  const searchProfileId = useAppSelector((state) => state.mails.searchProfileId);
  const headerSorting = useAppSelector((state) => state.mails.sorting);
  const location = useLocation();
  const { kind } = useParams<{
    kind: MailKind;
  }>();
  const dispatch = useAppDispatch();
  const { id: messageId } = useParams();


  useEffect(() => {
    dispatch(getMessageCountersAction());

    if (!messageId){
      dispatch(resetPaginationAndMailsAction(kind));

      dispatch(getMailsRequest(kind));
    }
  }, [dispatch, kind, location.pathname, isBookmarks, searchProfileId, headerSorting, messageId]);

  // const loadMorePosts = useCallback(async () => {
  //   setIsLoading(true);

  // }, []);

  return [kind];
};

export const useDialogMessages = (
  kind: MailKind,
  messageId: string,
): [MailKind] => {
  const isBookmarks = useAppSelector((state) => state.mails.isBookmarks);
  const searchProfileId = useAppSelector((state) => state.mails.searchProfileId);
  const headerSorting = useAppSelector((state) => state.mails.sorting);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMessageCountersAction());

    if (!messageId){
      dispatch(resetPaginationAndMailsAction(kind));

      dispatch(getMailsRequest(kind));
    }
  }, [dispatch, kind, location.pathname, isBookmarks, searchProfileId, headerSorting, messageId]);

  // const loadMorePosts = useCallback(async () => {
  //   setIsLoading(true);

  // }, []);

  return [kind];
};
