import { ProfileListTypes } from "@/types/profile-list.types";
import { List, ListItem, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";

type ProfileListInfoProps = {
  list: ProfileListTypes[];
  col?: number | string;
};

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    display: "grid",
    gap: 3,
    [theme.breakpoints.down("xl")]: {
      gap: 2,
    },
  },
  listSmall: {
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
  },
  listMedium: {
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
  },
  listItem: {
    lineHeight: "32px",
    color: theme.palette.info.dark,
    display: 'block !important'
  },
  listItemGrow: {
    [theme.breakpoints.down("md")]: {
      gridColumn: "span 2 / span 2",
    },
  },
  aboutMeItem: {
    gridColumn: "span 2 / span 2",
    [theme.breakpoints.down("sm")]: {
      gridColumn: "auto",
    },
  },
  listItemText: {
    fontWeight: "500",
    color: "#000",
    marginRight: 4,
  },
}));

const ProfileListInfo = ({ col = 1, list }: ProfileListInfoProps) => {
  const classes = useStyles();

  return (
    <List
      className={clsx(
        classes.list,
        +col === 1 ? classes.listSmall : classes.listMedium
      )}
    >
      {list.map((item, idx) =>
      (
        <ListItem
          disablePadding
          key={idx}
          className={clsx(
            classes.listItem,
            item.full && +col === 2 ? classes.listItemGrow : "",
            item?.label === "About me" ? classes.aboutMeItem : "",
          )}
        >
          <span className={classes.listItemText}>{item.label}: </span>
          {item.value}
        </ListItem>
      ))}
    </List>
  );
};

export default ProfileListInfo;
