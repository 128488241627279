import { CloseIconStyled } from '@/components/dialogs/sign-up-dialog.component';
import { calculateAge } from '@/helpers/helpers';
import { UserProfile } from '@/types/user-profile.types';
import { Dialog, DialogProps, Theme, Zoom } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
        padding: '0 16px',
      },
  },
  paper: {
    padding: 0,
  },
  dialogPaper: {
    overflowY: 'visible',
  },
  dialogContainer: {
    padding: '24px 28px',
    width: '90%',
    maxWidth: '1030px!important',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 12px',
    },
  },
  nameWrapper: {},
  birthday: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '32px',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  name: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '84px',
    display: 'flex',
    alignItems: 'center',
    color: '#000000',
  },
  divider: {
    height: '1px',
    background: '#E7E6E6',
    width: '100%',
  },
  mainInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  firstColumn: {
    width: '33%',
  },
  secondColumn: {
    width: '33%',
  },
  thirdColumn: {
    width: '33%',
  },
  bottomColumn: {
    width: '48%',
    paddingRight: '1%',
  },
  secondaryInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  smallWrapper: {
    position: 'relative',
    paddingLeft: '10px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '7px',
      height: '7px',
      left: '-10px',
      background: '#503EB6',
      borderRadius: '7px',
    },
  },
  title: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '32px',
    color: '#000000',
    paddingRight: '4px',
  },
  value: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '32px',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  close: {
    position: 'relative',
  },
  mobileColumn: {
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
}));

type DialogProfileInfoProps = {
  open: boolean;
  closeHandler: () => void;
  referenceUser: UserProfile;
} & DialogProps;

const DialogProfileInfo = ({
  open,
  closeHandler,
  referenceUser,
}: DialogProfileInfoProps) => {
  const classes = useStyles();
  const genderLabel = referenceUser.gender === 'male' ? ' Girls' : 'Mens';

  return (
    <Dialog
      classes={{
        root: classes.dialogPaper,
        paper: classes.dialogContainer,
      }}
      TransitionComponent={Zoom}
      open={open}
      onClose={() => closeHandler()}
    >
      <div className={classes.container}>
        <div className={classes.nameWrapper}>
          <div className={classes.name}>
            {referenceUser.name}, {calculateAge(referenceUser.birthday)}
          </div>
        </div>
        <div className={classes.divider}></div>
        <div className={classes.mainInfo}>
          <div className={clsx(classes.firstColumn, classes.mobileColumn)}>
            <div className={classes.smallWrapper}>
              <div className={classes.title}>Country: </div>
              <div className={classes.value}>
                {referenceUser.country ? referenceUser.country : 'Not fill'}
              </div>
            </div>
            <div className={classes.smallWrapper}>
              <div className={classes.title}>City: </div>
              <div className={classes.value}>
                {referenceUser.city ? referenceUser.city : 'Not fill'}
              </div>
            </div>
            <div className={classes.smallWrapper}>
              <div className={classes.title}>Martial: </div>
              <div className={classes.value}>
                {referenceUser.family ? referenceUser.family : 'Not fill'}
              </div>
            </div>
          </div>
          <div className={clsx(classes.secondColumn, classes.mobileColumn)}>
            <div className={classes.smallWrapper}>
              <div className={classes.title}>Children: </div>
              <div className={classes.value}>
                {referenceUser.children ? referenceUser.children : 'Not fill'}
              </div>
            </div>
            <div className={classes.smallWrapper}>
              <div className={classes.title}>Occupation: </div>
              <div className={classes.value}>
                {referenceUser.profession
                  ? referenceUser.profession
                  : 'Not fill'}
              </div>
            </div>
            <div className={classes.smallWrapper}>
              <div className={classes.title}>Religion: </div>
              <div className={classes.value}>
                {referenceUser.religion ? referenceUser.religion : 'Not fill'}
              </div>
            </div>
          </div>
          <div className={clsx(classes.thirdColumn, classes.mobileColumn)}>
            <div className={classes.smallWrapper}>
              <div className={classes.title}>Interests: </div>
              <div className={classes.value}>
                {referenceUser?.interests
                  ? referenceUser.interests
                  : 'Not fill'}
              </div>
            </div>
            <div className={classes.smallWrapper}>
              <div className={classes.title}>Goals: </div>
              <div className={classes.value}>
                {referenceUser?.goals ? referenceUser.goals : 'Not fill'}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.divider}></div>
        <div className={classes.secondaryInfo}>
          <div className={clsx(classes.bottomColumn, classes.mobileColumn)}>
            <div className={classes.smallWrapper}>
              <div className={classes.title}>About me:</div>
              <div className={classes.value}>
                {referenceUser.aboutMe ? referenceUser.aboutMe : 'Not fill'}
              </div>
            </div>
          </div>
          <div className={clsx(classes.bottomColumn, classes.mobileColumn)}>
            <div className={classes.smallWrapper}>
              <div className={classes.title}>Type of {genderLabel}: </div>
              <div className={classes.value}>
                {referenceUser.interests ? referenceUser.interests : 'Not fill'}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CloseIconStyled onClick={closeHandler} />
    </Dialog>
  );
};

export default DialogProfileInfo;
