import { useAppSelector } from "@/app/hooks";
import { UserProfileDTO } from "@/app/users/users.reducer";
import useIsStaffUserRole from "@/hooks/useIsStaffUserRole";
import { UserProfile } from "@/types/user-profile.types";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { useCallback } from "react";
import DrawerLink from "./drawer-link.component";
import usePersistentTab from '@/components/base/use-persistance-tab-hook';
import useDialogPersistentTab from '@/components/base/use-dialog-persistance-tab-hook';
type DrawerNavProps = {
  user: UserProfileDTO;
  onToggleMenu?: (event: any) => void;
  mobile?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    padding: "36px 0",
  },
}));

const DrawerNav = ({
  user,
  onToggleMenu = () => { },
  mobile = false,
}: DrawerNavProps) => {
  const inboxNewMessagesCount = useAppSelector((state) => state.mails.messageCounters?.unseen);
  const inboxNewDialogCount = useAppSelector((state) => state.users.dialogCounters?.unseen);

  const inboxNewGiftsCount = useAppSelector((state) => state.gifts.giftCounters?.unseen);
  const isStaff = useIsStaffUserRole();

  const classes = useStyles();
  let gender = user?.profile?.gender || 'empty';

  const userProfileId = user?.profile?.id || '';
  const navItems = user?.id ? [
    {
      route: "/user/search",
      label: "Users",
      iconName: gender === "male" ? "woman" : "men",
      counter: false,
    },
    {
      route: `/chat/${userProfileId}`,
      label: "Go To Chat",
      iconName: "chat",
      counter: false,
    },
    {
      route: `/dialog/${userProfileId}`,
      label: "Go To Dialog",
      iconName: "dialog",
      counter: false,
      unreadCount: inboxNewDialogCount,
    },
    {
      route: "/user/messages/inbox",
      label: "Mail",
      iconName: "mail",
      counter: false,
      unreadCount: inboxNewMessagesCount
    },
    {
      route: "/user/contacts",
      label: "Contacts",
      iconName: "contacts",
      counter: false,
      count: 349,
    },
    {
      route: "/user/guests",
      label: "Guests",
      iconName: "guests",
      counter: false,
      count: 48,
    },
    {
      route: "/user/gifts/inbox",
      label: "Gifts",
      iconName: "gifts",
      counter: false,
      unreadCount: inboxNewGiftsCount
    },
    {
      route: "/user/news",
      label: "News",
      iconName: "news",
      counter: false,
    },
    {
      route: "/user/notifications",
      label: "Notifications",
      iconName: "notifications",
      counter: false,
    },
    {
      route: "/user/order-history",
      label: "Order history",
      iconName: "profile",
      counter: false,
    },
    {
      route: '/user/blacklist',
      label: "Blacklist",
      iconName: "blacklist",
      counter: false,
    },
  ] : [];

  const [openTab] = usePersistentTab(`/chat/${user?.profile?.id}`);

  const [openDialogTab] = useDialogPersistentTab(`/dialog/${user?.profile?.id}`);

  const openTabCallback = useCallback(() => {
    openTab(`/chat/${user?.profile?.id}`);
  }, [openTab, user?.profile?.id]);


  const openDialogTabCallback = useCallback(() => {
    openDialogTab(`/dialog/${user?.profile?.id}`);
  }, [openDialogTab, user?.profile?.id]);

  return (
    <div
      className={classes.container}
      style={mobile ? { paddingBottom: 23, paddingTop: 27 } : {}}
    >
      {navItems.map(({ route, iconName, label, counter, count, unreadCount }) => {
        if (isStaff && (label === 'Order history')) return [];

        return (
          <DrawerLink
            onToggleMenu={onToggleMenu}
            route={route}
            label={label}
            iconName={iconName}
            key={iconName}
            user={user}
            counter={counter}
            count={`${count}` || null}
            unreadCount={unreadCount}
            openTab={openTabCallback}
            openDialogTab={openDialogTabCallback}
          />
        )
      })}
    </div>
  );
};

export default DrawerNav;
