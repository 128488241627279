import AuthService, { ResetPasswordDTO } from "@/services/auth.service";
import { StorageService } from "@/services/storage.service";
import { UserProfile } from "@/types/user-profile.types";
import jwtDecode from "jwt-decode";
import { call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { toggleSnackbarOpen } from "../ui/ui.actions";
import {
  clearAvailableUsersSuccess,
  clearUserSuccess,
  getOwnerProfileSuccess,
  getPhotosRequest,
  getVideosRequest,
  getUserProfileFailure,
  getUserProfileSuccess,
} from "../users/users.actions";
import { UserPhotoType, UserVideoType } from "../users/users.types";
import {
  resetPasswordFailure,
  resetPasswordRequest,
  changePasswordRequest,
  resetPasswordSuccess,
  signInFailure,
  signInRequest,
  signInSuccess,
  signOutFailure,
  signOutRequest,
  signOutSuccess,
  signUpFailure,
  signUpRequest,
  signUpSuccess,
} from "./auth.actions";
import {
  GET_ME_REQUEST,
  RESET_PASSWORD_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  SIGN_IN_REQUEST,
  SIGN_OUT_REQUEST,
  SIGN_UP_REQUEST,
} from "./auth.types";

function* getUserProfileSaga(userId: string) {
  try {
    const user: UserProfile = yield call(() => AuthService.me());
    yield put(getUserProfileSuccess(user));
    yield put(getPhotosRequest(userId, UserPhotoType.PUBLIC_VIEW, 0));
    yield put(getVideosRequest(userId, UserVideoType.PRIVATE_VIEW, 0));
    return user;
  } catch (e) {
    yield put(getUserProfileFailure(e));
  }
}

function* getMeWorkerSaga(action: ReturnType<typeof signInSuccess>) {
  yield getUserProfileSaga("");
}

function* signUpWorkerSaga(action: ReturnType<typeof signUpRequest>) {
  try {
    const { payload: registerData } = action;
    const { accessToken } = yield call(() => AuthService.signup(registerData));
    StorageService.setToken(accessToken);
    const userId: any = jwtDecode(accessToken);

    if (userId) {
      //@ts-ignore
      const user = yield* getUserProfileSaga(userId);
      yield put(getOwnerProfileSuccess(user as unknown as UserProfile));

      yield put(signUpSuccess(userId.profile.id));
      if(registerData.referralProfileId) {
        yield action.payload.navigate(`/user/${registerData.referralProfileId}`);  
        registerData.closeHandler && registerData.closeHandler();
        return;
      }
      yield action.payload.navigate(`/user/${userId.id}`);
      registerData.closeHandler && registerData.closeHandler();
    }
  } catch (e) {
    yield put(signUpFailure(e));
    if(e.message) {
      yield put(
        toggleSnackbarOpen(
          e.message,
          "error"
        )
      );
      return;
    }
    yield put(
      toggleSnackbarOpen(
        "Something goes wrong during request execution",
        "error"
      )
    );
  }
}

function* resetPasswordWorkerSaga(
  action: ReturnType<typeof resetPasswordRequest>
) {
  try {
    const { email } = action.payload;

    const response: ResetPasswordDTO = yield call(() =>
      AuthService.resetPassword(email)
    );

    yield put(resetPasswordSuccess());
    yield put(
      toggleSnackbarOpen(
        "We have sent validation code to your email, please check your inbox",
        "success"
      )
    );
    yield action.payload.navigate(`/?resetPassword=true`);
  } catch (e) {
    yield put(resetPasswordFailure(e));
    yield put(
      toggleSnackbarOpen(
        "Something goes wrong during request execution",
        "error"
      )
    );
  }
}

function* changePasswordWorkerSaga(
  action: ReturnType<typeof changePasswordRequest>
) {
  try {
    const { code, password } = action.payload;

    yield call(() =>
      AuthService.changePassword(code, password)
    );

    yield put(
      toggleSnackbarOpen(
        "You can login with your new password",
        "success"
      )
    );
    yield action.payload.navigate(`/`);
  } catch (e) {
    yield put(resetPasswordFailure(e));
    yield put(
      toggleSnackbarOpen(
        "Something goes wrong during request execution",
        "error"
      )
    );
  }
}

function* signInWorkerSaga(action: ReturnType<typeof signInRequest>) {
  try {
    const { payload: loginData } = action;
    const { accessToken } = yield call(() => AuthService.login(loginData));
    StorageService.setToken(accessToken);
    const userId: any = jwtDecode(accessToken);

    if (userId) {
      //@ts-ignore
      const user = yield* getUserProfileSaga(userId);
      yield put(getOwnerProfileSuccess(user as unknown as UserProfile));

      yield put(signInSuccess(userId.profile.id));
      yield action.payload.navigate(`/user/${userId.profile.userId}`);
      loginData.closeHandler && loginData.closeHandler();
    }
  } catch (e) {
    console.log('e', e);
    yield put(signInFailure(e));
    yield put(
      toggleSnackbarOpen(
        e?.message === 'Incorrect login or password' ?
        'Incorrect login or password'
        :
        "Something goes wrong during request execution",
        "error"
      )
    );
  }
}

function* signOutWorkerSaga(action: ReturnType<typeof signOutRequest>) {
  try {
    yield StorageService.removeToken();
    yield AuthService.logout();
    yield action.payload.navigate("/");
    yield put(signOutSuccess());
    yield put(clearUserSuccess());
    yield put(clearAvailableUsersSuccess());
  } catch (e) {
    yield put(signOutFailure(e));
  }
}

function* signUpWatcherSaga() {
  yield takeEvery(SIGN_UP_REQUEST, signUpWorkerSaga);
}

function* signInWatcherSaga() {
  yield takeEvery(SIGN_IN_REQUEST, signInWorkerSaga);
}

function* signOutWatcherSaga() {
  yield takeEvery(SIGN_OUT_REQUEST, signOutWorkerSaga);
}

function* getMeWatcherSaga() {
  yield takeLatest(GET_ME_REQUEST, getMeWorkerSaga);
}

function* resetPasswordWatchersaga() {
  yield takeEvery(RESET_PASSWORD_REQUEST, resetPasswordWorkerSaga);
}

function* changePasswordWatchersaga() {
  yield takeEvery(CHANGE_PASSWORD_REQUEST, changePasswordWorkerSaga);
}

const authSagas = [
  fork(signUpWatcherSaga),
  fork(getMeWatcherSaga),
  fork(signInWatcherSaga),
  fork(signOutWatcherSaga),
  fork(changePasswordWatchersaga),
  fork(resetPasswordWatchersaga),
];

export default authSagas;
