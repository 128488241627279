import React, { memo, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { chargeBalanceRequest, disconnectChat } from '@/app/users/users.actions';

import Socket from '@/services/Socket';
import { ChatDialog } from '@/types/user-profile.types';
import { isAfterLastMinutesParams, isWithinLastMinutesParams } from '@/services/utils';

const ChargeBalance = memo(() => {
  const dispatch = useAppDispatch();
  const { wallet, videoActive, usersWithActiveTextChat, availableUsers } = useAppSelector((state) => state.users);

  const [textChatTimers, setTextChatTimers] = useState({});
  const [videoTimer, setVideoTimer] = useState(null);

  useEffect(() => {
    // Text chat timer handling
    const textChatInterval = setInterval(() => {
      const updatedTimers = {};
      let shouldDispatchAllTimer = false;
      for (const user of usersWithActiveTextChat) {
        const userChat: Array<ChatDialog> = availableUsers
          .find((availableUser) => availableUser.id === user.id && availableUser?.chat && availableUser?.chat?.length)
          ?.chat.filter(
            (message) =>
              (message.id !== user.id && !['currentDate', 'start', 'end'].includes(message.id)) || message?.img?.isPaid
          )
          .sort((a, b) => {
            return new Date(a.date).getTime() > new Date(b.date).getTime() ? 1 : 0;
          });

        if (
          userChat &&
          isAfterLastMinutesParams(new Date(userChat[userChat.length - 1].date), 7)
          // && !(videoActive && videoActive?.videoProfileId)
        ) {
          dispatch(disconnectChat(user.id));
          return;
        }

        let shouldDispatch = false;
        const { id, date } = user;
        const currentTime = new Date().getTime();
        const timerKey = `textChatTimer_${id}`;

        if (!textChatTimers[timerKey] && date) {
          // First time entry for the user
          updatedTimers[timerKey] = date;
          if (currentTime - updatedTimers[timerKey] >= 60000) {
            updatedTimers[timerKey] = currentTime;
            shouldDispatch = true;
          }
        }
        if (currentTime - textChatTimers[timerKey] >= 60000) {
          // 60 seconds passed, dispatch chargeBalanceRequest and update the timer
          updatedTimers[timerKey] = currentTime;
          shouldDispatch = true;
        } else {
          // Timer still active
          updatedTimers[timerKey] = textChatTimers[timerKey];
        }

        if (shouldDispatch) {
          shouldDispatchAllTimer = true;

          if (!wallet.isLoading) {
            dispatch(chargeBalanceRequest('chat-text-min', user.id));
          }
        }
      }
      if (shouldDispatchAllTimer) {
        setTextChatTimers(updatedTimers);
      }
      shouldDispatchAllTimer = false;
    }, 1000);
    // Video timer handling
    if (videoActive && videoActive?.videoProfileId) {
      const videoInterval = setInterval(() => {
        const currentTime = new Date().getTime();

        if (!videoTimer || currentTime - videoTimer >= 60000) {
          // 60 seconds passed, dispatch chargeBalanceRequest
          setVideoTimer(currentTime);
          if (!wallet.isLoading) {
            dispatch(chargeBalanceRequest('chat-video-min', videoActive?.videoProfileId));
          }
        }
      }, 1000);

      return () => {
        clearInterval(videoInterval);
      };
    }

    return () => {
      clearInterval(textChatInterval);
    };
  }, [dispatch, usersWithActiveTextChat, textChatTimers, videoActive, videoTimer, wallet.isLoading, availableUsers]);

  useEffect(() => {
    // Socket handling
    if (videoActive && videoActive?.videoProfileId) {
      if (usersWithActiveTextChat.length && Socket && Socket.socket) {
        for (const user of usersWithActiveTextChat) {
          Socket.emit('chat:close:server', {
            toProfileId: user.id,
            data: {},
          });
        }
      }
    }
  }, [usersWithActiveTextChat, videoActive]);

  return null;
});

export default ChargeBalance;
