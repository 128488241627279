import BaseCard from "@/components/base/base-card.component";
import DialogEditProfile from "@/components/dialogs/edit-navigation.dialog.component";
import { useAppBreakpoints, useContainerWidth } from "@/hooks";
import { MenuItemTypes } from "@/types/menu-item.types";
import { Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { useRef, useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { ReactComponent as UserSettingsIcon } from "@/assets/icons/userSettings.svg";
import { ReactComponent as NotificationsIcon } from "@/assets/icons/notifications.svg";

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    [theme.breakpoints.down("xl")]: {
      marginTop: 24,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 14,
    },
  },
  editWidgetContainer: {
    flex: "1 1 auto",
  },
  pageTitle: {
    display: "block",
    marginBottom: "28px !important",
    [theme.breakpoints.down("lg")]: {
      fontSize: "24px !important",
      textUnderlineOffset: "2px",
      textDecorationStyle: "dotted",
      textDecorationColor: "#ea4160",
      textDecorationLine: "underline",
    },
  },
  pageSubTitle: {
    fontSize: 20,
    lineHeight: "28px",
  },

  navWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    // [theme.breakpoints.up("lg")]:{

    // },
  },

  //   <div class="hidden lg:block w-1/4 flex-shrink-0">

  navigationListWrapper: {
    width: "25%",
    flexShrink: 0,

    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  navList: {
    display: "flex",
    padding: 0,
    marginTop: 0,
    flexDirection: "column",
    "& > a": {
      textDecoration: "none",
    },

    "& > $navItem:not(:first-child)": {
      marginTop: 16,
    },
  },
  navItem: {
    color: "#000",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      margin: 6,
      textDecoration: "none",
    },
    "& > $navItemText": {
      marginLeft: 12,
    },
    "& > svg": {
      width: 18,
      height: 18,
    },
  },
  navItemText: {},
  activeLink: {
    color: theme.palette.primary.main,
    "& path": {
      fill: theme.palette.primary.main,
    },
  },
  defaultLink: {
    color: "#000",

    "& path": {
      fill: "#503EB6",
    },
  },
}));

const stylesOverrides = {
  contentWrapper: {
    padding: 0,
    maxWidth: 968,
  },
};
type HtmlElementRefType =
  | ((instance: any) => void)
  | React.MutableRefObject<any>
  | null;

const UserSettings = () => {
  const classes = useStyles();
  const location = useLocation();
  const [profileOptionsEl, setProfileOptionsEl] = useState(null);
  const width = useContainerWidth();
  const { downLg } = useAppBreakpoints();
  const profileOptionsRef = useRef<HTMLDivElement>();
  const handleClickProfileOptions = (event: any) => {
    setProfileOptionsEl(profileOptionsRef.current as any);
  };

  const editNavigation: MenuItemTypes[] = [
    { path: "/user/settings", name: "General", icon: UserSettingsIcon },
    {
      path: "/user/settings/notifications",
      name: "Notifications",
      icon: NotificationsIcon,
    },
  ];
  const list = editNavigation.map((navItem, idx) => (
    <NavLink
      onClick={() => setProfileOptionsEl(null)}
      className={clsx(
        classes.navItem,

        location.pathname === navItem.path
          ? classes.activeLink
          : classes.defaultLink
      )}
      to={navItem.path}
    >
      {/*// @ts-ignore */}

      <navItem.icon />
      <span className={classes.navItemText}>{navItem.name}</span>
    </NavLink>
  ));
  return (
    <Container
      maxWidth={width}
      className={classes.contentWrapper}
      style={stylesOverrides.contentWrapper}
    >
      <BaseCard wrapperOverrideStyle={{ margin: 0, paddingBottom: 10 }}>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <span onClick={(e: any) => downLg && handleClickProfileOptions(e)}>
            <Typography
              variant="h2"
              className={classes.pageTitle}
              ref={profileOptionsRef as HtmlElementRefType}
            >
              Settings
            </Typography>
          </span>

          <div className={classes.navWrapper}>
            <div className={classes.navigationListWrapper}>
              <ul className={classes.navList}>{list}</ul>
            </div>

            <div className={classes.editWidgetContainer}>
              <Outlet />
            </div>
          </div>
        </div>
        {profileOptionsEl && (
          <DialogEditProfile
            handleLogout={() => {}}
            list={list}
            anchorEl={profileOptionsEl}
            setAnchorEl={setProfileOptionsEl}
          />
        )}
      </BaseCard>
    </Container>
  );
};

export default UserSettings;
