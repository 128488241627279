import { useNavigate } from "react-router-dom";
import useIsStaffUserRole from "./useIsStaffUserRole";

const useStaffCheck = (redirectRoute = '/') => {
  const isStaff = useIsStaffUserRole();
  const navigate = useNavigate();

  if (isStaff) {
    navigate(redirectRoute, {replace: true});
  }

};

export default useStaffCheck;
