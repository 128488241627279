import { useAppSelector } from '@/app/hooks';
import StatusOnline from '@/components/base/statuses/status-online.component';
import { makeStyles } from '@mui/styles';

import useBlankProfileAvatart from '@/hooks/useBlankProfileAvatart';
import GuestsService, { GuestShape } from '@/services/guests.service';
import clsx from 'clsx';
import { differenceInMinutes } from 'date-fns';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Theme } from '@mui/material';
import { calculateAge } from '@/helpers/helpers';
import BaseButton from '@/components/base/button.component';
import MoreVert from '@mui/icons-material/MoreVert';
import { ReactComponent as MessageIcon } from '@/assets/icons/profile-actions/message.svg';
import { ReactComponent as SMSIcon } from '@/assets/icons/profile-actions/sms-dialog.svg';
import { ReactComponent as EmailMiniIcon } from '@/assets/icons/profile-actions/email-mini.svg';
import { ReactComponent as PresentIcon } from '@/assets/icons/profile-actions/present.svg';
import GuestDialog from '@/components/dialogs/guest.dialog';
import { ReactComponent as Close } from '@/assets/icons/Close.svg';
import usePersistentTab from '@/components/base/use-persistance-tab-hook';
import useDialogPersistentTab from '@/components/base/use-dialog-persistance-tab-hook';

export interface StyleProps {
  avatar: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  profilePhoto: ({ avatar }) => ({
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 10,
    height: '100%',
    backgroundImage: `url("${avatar}")`,
  }),
  photoWrapper: {
    position: 'relative',
    maxWidth: 72,
    maxHeight: 76,
    width: '72px',
    marginRight: 20,
    aspectRatio: '116 / 130',
  },
  photoInner: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 14,
    borderRadius: 10,
    marginBottom: 10,
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      '&:hover $profileCta': {
        opacity: 1,
      },
    },
  },
  messageInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0',
  },
  messageHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  messageFooter: {
    color: '#808080',

    marginTop: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },
  profileInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  profileSubject: {
    width: '25vw',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    [theme.breakpoints.down('lg')]: {
      width: '60vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50vw',
    },
  },
  profileText: {
    width: '25vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [theme.breakpoints.down('lg')]: {
      width: '60vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50vw',
    },
  },
  profileWrapper: {
    width: '100%',
  },
  profileActions: {
    display: 'flex',
    gap: 10,
    color: '#808080',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  checkBox: {
    marginRight: '0 !important',
  },
  profileMeta: {
    display: 'flex',
    alignItems: 'center',
    gap: 9,
  },
  divider: {
    paddingTop: 15,
  },
  metaCtaIcon: {
    maxWidth: 42,
    maxHeight: 42,

    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },
  profileCta: {
    display: 'flex',
    gap: 24,
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      backgroundColor: 'white',
      bottom: '5px',
      right: '5px',
      padding: '10px',
      borderRadius: '10px',
      boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
      opacity: 0,
      transition: 'opacity .3s',
    },
  },
  profilePhotoStatus: {
    position: 'absolute',
    bottom: 6,
    left: 6,
  },
  statusUnread: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
  },
  statusRead: {
    backgroundColor: '#F6F6F6',
  },
  leftSide: {
    height: '76px',
    display: 'flex',
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
  },
  profileName: {
    fontSize: 18,
    lineHeight: '20px',
    margin: 0,
    marginBottom: 5,
  },
  profileNameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  avatarId: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
}));

type HtmlElementRefType = ((instance: any) => void) | React.MutableRefObject<any> | null;

type GuestCardProps = {
  user?: GuestShape | null;
  windowWidth: number;
  windowHeight: number;
  openDialoghandler: (data: { id: string; name: string; avatar: string }) => void;
  openGiftDialoghandler: (data: { id: string; name: string; avatar: string }) => void;
  deleteHandler: (id: string) => void;
};

const MobileContactCard = ({
  user = null,
  windowWidth,
  windowHeight,
  openDialoghandler,
  openGiftDialoghandler,
  deleteHandler,
}: GuestCardProps) => {
  const navigate = useNavigate();

  const { oppositeUserPicture } = useBlankProfileAvatart();
  const myUser = useAppSelector((state) => state.users.currentUser);

  const classes = useStyles({ avatar: user?.profileAvatar || oppositeUserPicture });

  const [messageOptionEl, setMessageOptionEl] = useState(null);
  const messageOptionsRef = useRef<HTMLDivElement>();
  const handleMessageOptions = (event: any) => {
    setMessageOptionEl(messageOptionsRef.current as any);
  };
  const [openTab] = usePersistentTab(`/chat/${myUser?.profile?.id}/${user.profileId}`);
  const [openDialogTab] = useDialogPersistentTab(`/dialog/${myUser?.profile?.id}/${user.profileId}`);

  const openTabCallBack = useCallback(() => {
    openTab(`/chat/${myUser?.profile?.id}/${user.profileId}`);
  }, [openTab, myUser?.profile?.id, user.profileId]);

  const openDialogTabCallBack = useCallback(() => {
    openDialogTab(`/dialog/${myUser?.profile?.id}/${user.profileId}`);
  }, [openDialogTab, myUser?.profile?.id, user.profileId]);

  const metaActions = useMemo(
    () => [
      {
        icon: <Close />,
        onClick: () => deleteHandler(user.profileId),
      },
      {
        icon: <MessageIcon />,
        onClick: () => openTabCallBack(),
      },
      {
        icon: <SMSIcon />,
        onClick: () => openDialogTabCallBack(),
      },
      {
        icon: <EmailMiniIcon />,
        onClick: (data: { id: string; name: string; avatar: string }) => openDialoghandler(data),
      },
      {
        icon: <PresentIcon />,
        onClick: (data: { id: string; name: string; avatar: string }) => openGiftDialoghandler(data),
      },
    ],
    [
      deleteHandler,
      myUser?.profile?.id,
      openDialoghandler,
      user.profileId,
      windowHeight,
      windowWidth,
      openTabCallBack,
      openDialogTabCallBack,
    ]
  );

  const metaButtons = useMemo(
    () => (
      <div className={classes.profileCta}>
        {metaActions.map((action, idx) => (
          <BaseButton
            key={idx}
            inverted
            text=''
            className={classes.metaCtaIcon}
            textStyle=''
            onClick={(e) => {
              e.stopPropagation();
              action.onClick({ id: user.profileId, name: user.profileName, avatar: user.profileAvatar });
            }}
            Icon={action.icon}
          />
        ))}
      </div>
    ),
    [classes.metaCtaIcon, classes.profileCta, metaActions, user.profileAvatar, user.profileId, user.profileName]
  );

  const isUserActiveNow = useMemo(
    () => user?.activeAt && differenceInMinutes(new Date(), new Date(user.activeAt)) < 15,
    [user?.activeAt]
  );

  const navigateToProfile = async () => {
    if (user?.profileId) {
      await GuestsService.markGuestAsSeen(user.profileId);
      navigate(`/user/${user.profileId}`);
    }
  };

  return (
    <div className={clsx(classes.cardContainer, user.seen ? classes.statusRead : classes.statusUnread)}>
      {messageOptionEl && (
        <GuestDialog
          anchorEl={messageOptionEl}
          setAnchorEl={setMessageOptionEl}
          metaButtons={metaButtons}
          // need for layout
          isBookmark={false}
        />
      )}

      <div className={classes.leftSide}>
        <div className={classes.photoWrapper}>
          <div className={classes.photoInner} onClick={navigateToProfile}>
            <div className={classes.profilePhoto} />
          </div>
          {isUserActiveNow ? (
            <div className={classes.profilePhotoStatus}>
              <StatusOnline small />
            </div>
          ) : null}
        </div>
        <div className={classes.profileNameWrapper}>
          <div className={classes.profileName}>
            {user.profileName}
            {user?.birthday ? <span className={classes.avatarAge}>{`, ${calculateAge(user.birthday)}`}</span> : null}
          </div>
          <div>{user?.profilePageId && <p className={classes.avatarId}>ID: {user.profilePageId}</p>}</div>
        </div>
      </div>

      <div className={classes.rightSide}>
        <BaseButton
          inverted
          text=''
          className={classes.metaCtaIcon}
          textStyle=''
          Icon={<MoreVert />}
          ref={messageOptionsRef as HtmlElementRefType}
          onClick={(e: any) => handleMessageOptions(e)}
        />
      </div>
    </div>
  );
};

export default MobileContactCard;
