import AppBootstrap from '@/components/core/app-bootstrap';
import React from 'react';
import SocketSubscriber from './components/SocketSubscriber';
import MainRouter from './routes';
import { BrowserRouter as Router } from 'react-router-dom';
import InappSubscriber from './components/InappSubscriber';
import QuestionsSubscriber from './components/QuestionsSubscriber';
import LiveChatWidget from './components/LiveChatWidget';

function App() {
  return (
    <AppBootstrap>
      <Router>
        <SocketSubscriber />
        <InappSubscriber />
        <QuestionsSubscriber />
        <LiveChatWidget />
        <MainRouter />
      </Router>
    </AppBootstrap>
  );
}

export default App;
