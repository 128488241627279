import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * @constant CAST_MILISECONDS_TO_HOUR how many miliseconds in one hour
 */
 export const CAST_MILISECONDS_TO_HOUR = 3600000;

 /**
  * @constant MILLISECONDS_IN_DAY how many milliseconds in one day
  */
 export const MILLISECONDS_IN_DAY = 86400000;
 
 /**
  * @constant MILLISECONDS_IN_MINUTE how many milliseconds in minute
  */
 export const MILLISECONDS_IN_MINUTE = 60000;

const MS_IN_MINUTE = MILLISECONDS_IN_MINUTE;
const MS_IN_DAY = MILLISECONDS_IN_DAY;

export const useInterval = (
  interval: number,
  callback: (time: number) => void
) => {
  const [startTime] = useState(Date.now());
  const [isTimerDisabled, setIsTimerDisabled] = useState(false);

  useEffect(() => {
    if (isTimerDisabled === false) {
      const intervalId = setInterval(() => {
        callback(Date.now() - startTime);
      }, interval);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [interval, callback, startTime, isTimerDisabled]);

  return useCallback(() => {
    setIsTimerDisabled(true);
  }, [setIsTimerDisabled]);
};
/*
  ATTENTION:
  Timer do ticks not every second.
  It depends on remaining time.
  If remaining time more than a day tick will have one minute interval otherwise one second.
 */

export const useTimerTicker = <TimeFormat,>(
  timeMs: number,
  formatTime: (time: number) => TimeFormat,
  onTimerOver?: () => void
) => {
  useEffect(() => {
    if (timeMs <= 0) {
      onTimerOver && onTimerOver();
    }
  }, [timeMs, onTimerOver]);

  const [isTimerEnd, setIsTimerEnd] = useState(timeMs <= 0);

  const [remindTime, setRemindTime] = useState(
    timeMs > MS_IN_DAY ? MS_IN_MINUTE : 1000
  );

  const remainingTime = useRef(timeMs);

  const [remainingTimeObj, setRemainingTimeObj] = useState(formatTime(timeMs));

  const updateTime = useCallback(
    (time: number) => {
      const newRemainingTime = timeMs - time;

      if (newRemainingTime <= MS_IN_DAY && remindTime === MS_IN_MINUTE) {
        setRemindTime(1000);
      }

      if (newRemainingTime <= 0) {
        setIsTimerEnd(true);
        onTimerOver && onTimerOver();
        remainingTime.current = 0;
        setRemainingTimeObj(formatTime(0));
      } else {
        remainingTime.current = newRemainingTime;
        setRemainingTimeObj(formatTime(newRemainingTime));
      }
    },
    [timeMs, remindTime, onTimerOver, formatTime]
  );

  const disableInterval = useInterval(remindTime, updateTime);

  useEffect(() => {
    isTimerEnd && disableInterval();
  }, [isTimerEnd, disableInterval]);

  return [remainingTimeObj, remainingTime.current] as const;
};
