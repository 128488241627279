import { useAppSelector } from '@/app/hooks';
import { ISettings } from '@/types/user-profile.types';
import { Container } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 'calc(100% - 56px)',
    padding: '28px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  headTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '26px',
    paddingBottom: '33px',
    width: '100%',
    textAlign: 'center',
  },
  paragraphFirst: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
  },
  paragraph: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
    paddingLeft: '40px',
  },
  greyParagraph: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
  },
  headline: {
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '56px',
    color: '#000000',
    marginTop: '54px',
    '& > ::marker': {
      color: '#EA4160',
    },
  },
  listItem: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#808080',
  },
  breakline: {
    background: '#E7E6E6',
    height: '1px',
    width: '100%',
    marginBottom: '10px',
  },
  verySmallItem: {
    display: 'flex',
    padding: '6px 0px 6px 60px',
  },
  smallItem: {
    display: 'flex',
    padding: '12px 0px 12px 40px',
  },
  sml: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    marginRight: '6px',
    minWidth: '40px',
  },
  smallContent: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#808080',
    '& > b': {
      color: 'black',
    },
  },
  additionalText: {
    padding: '10px 40px 0 40px',
  },
  link: {
    color: '#ea4160',
  },
}));

const CancellationPolicy = () => {
  const classes = useStyles();
  const { siteName } = useAppSelector<ISettings>((state) => state.users.settings);
  return (
    <Container maxWidth={'xl'}>
      <div className={classes.container}>
        <div className={classes.headTitle}>Cancellation Policy</div>
        <ol start={1} className={classes.headline}>
          <li>
            Typically, the payment received from you cannot be returned if there is no reason for this according to our
            Refund Policy. Refund Policy on this Site is a part of this Agreement. We reserve the right to change or
            modify the Refund Policy at any time. All changes become valid upon posting.
          </li>
        </ol>
        <div className={classes.breakline} />
        <ol start={2} className={classes.headline}>
          <li>
            Your money can be refunded to you only in cases stipulated by this agreement. You agree that the Site
            administration is not obliged to offset expenses of any purchased credits if the Services have been properly
            and fully provided to you.
          </li>
        </ol>
        <div className={classes.breakline} />
        <ol start={3} className={classes.headline}>
          <li>The Site is not obliged to refund in case of your failure to develop a relationship on the website.</li>
        </ol>
        <div className={classes.breakline} />
        <ol start={4} className={classes.headline}>
          <li>The site policy for refunding is determined by your consent with following provisions.</li>
        </ol>
        <div className={classes.breakline} />
        <div className={classes.smallItem}>
          <div className={classes.sml}>4.1</div>
          <div className={classes.smallContent}>
            You do not have the right for refunding if you are only discontent with the Site or any of Services offered
            through the site.
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>4.2</div>
          <div className={classes.smallContent}>
            letters, sms’s in dialog chat, virtual gifts, private photos sent to a lady on the site, which remain unread
            or unanswered are not eligible for a refund of credits.
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>4.3</div>
          <div className={classes.smallContent}>Bonus credits credited to your billing account cannot be refunded.</div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>4.4</div>
          <div className={classes.smallContent}>
            When you send an letters, sms’s in dialog chat, virtual gifts, private photos and they are forwarded to a
            recipient's mailbox, this means that a letters, sms’s in dialog chat, virtual gifts, private photos are
            formatted correctly and thus you are not entitled to a refund. This includes, but is not limited to, cases
            such as accidentally sending duplicate letters, virtual gifts, private photos or a chat session that
            accidentally has not been stopped.
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>4.5</div>
          <div className={classes.smallContent}>
            The Site Administration reserves the right to offer you to replace a refund by charging on your account
            bonus credits.
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>4.6</div>
          <div className={classes.smallContent}>
            The Site is not responsible for the loss of credits in case you have not learnt all the potentialities of
            the Site, and as a result, have not use the Services properly.
          </div>
        </div>
        <div className={classes.breakline} />
        <ol start={5} className={classes.headline}>
          <li>
            In all other cases, you are encouraged to report to the administration on your claim and the grounds for
            repayment. Consideration of claims by the Site administration takes place within 7 days with the exception
            of weekends. The Site administration reserves the right to request additional information about the claim,
            and the proof of its eligibility.
          </li>
        </ol>
        <div className={classes.breakline} />
        <ol start={6} className={classes.headline}>
          <li>
            The Site administration reserves the right to claim compensation for losses and expenses incurred in
            connection with your actions on the site and take any legal action available for this.
          </li>
        </ol>
        <div className={classes.breakline} />
        <ol start={7} className={classes.headline}>
          <li>
            You are entitled to a refund to your account if the Services you paid for have not been provided to you by
            our fault, apart from those that failed due to technical problems of equipment, servers, Internet channels
            and other equipment not owned by{' '}
            <Link to={'/'} className={classes.link}>
              {siteName}
            </Link>
          </li>
        </ol>
        <div className={classes.breakline} />
        <ol start={8} className={classes.headline}>
          <li>You have the right to a refund if you adduced an evidence of unsatisfactory services.</li>
        </ol>
      </div>
    </Container>
  );
};

export default CancellationPolicy;
