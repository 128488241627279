import { useField } from "formik";
import React from "react";

type useFormikField = {
  label: string;
};

const useFormikField = ({ label }: useFormikField): any => {
  const [field, meta] = useField(label);
  const errorText = meta.touched && meta.error ? meta.error : "";
  return [field, meta, errorText];
};

export default useFormikField;
