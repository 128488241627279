import { ReactComponent as CheckmarkIcon } from "@/assets/icons/checkmark.svg";
import { ReactComponent as ArrowIcon } from "@/assets/icons/option-arrow.svg";
import { useFocus, useFormikField } from "@/hooks";
import { FormHelperText, TextField, Theme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { CSSProperties, useMemo } from "react";

type SelectFieldProps = {
  list: Array<any>;
  inputAppendElement?: string;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  caseSensetive?: boolean | undefined;
  label: string;
  placeholder: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  additionalClassName?: string
};

const useStyles = makeStyles((theme: Theme) => ({
  base: {
    margin: "0 4px",
    width: "100%",

    "& > .svg": {
      fill: theme.palette.radioSelected.main,
    },
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    padding: "0 10px",
    fontSize: 14,
    backgroundColor: "#F9F8FD",
    overflow: "hidden",
  },
  focused: {
    borderColor: theme.palette.radioSelected.main,
    boxShadow: `rgb(255 255 255) 0px 0px 0px 0px, ${theme.palette.radioSelected.main} 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
  },
}));

const styleOverrides = {
  innerInput: {
    padding: "7px 16px",
    fontWeight: 400,
    left: -6,
    position: "relative",
  },
};

const SelectTextField = ({
  list,
  inputAppendElement,
  prepend = null,
  append = null,
  caseSensetive = false,
  label,
  placeholder,
  setFieldValue,
  additionalClassName
}: SelectFieldProps) => {
  const classes = useStyles();
  const [field, meta, errorText] = useFormikField({ label });
  const { onFocus, onBlur, isFocused } = useFocus(field.onBlur);

  const baseValue = useMemo(() => {
    return list.find((item) => {
      if (item.value && field.value)
        return item.value.toLowerCase() === field.value.toLowerCase();
    });
  }, [field, list]);

  return (
    <Autocomplete
      value={baseValue}
      className={clsx(classes.base, additionalClassName)}
      options={list}
      autoHighlight
      popupIcon={<ArrowIcon style={{ width: 18, height: 18 }} />}
      getOptionLabel={(option) => option.name}
      onInputChange={(_, value) => {
        setFieldValue(label, caseSensetive ? value : value.toLowerCase());
      }}
      renderOption={(props, option) => {
        const hasProp = baseValue?.value && option?.value;
        return (
          <Box
            component="li"
            {...props}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{option.name}</span>
            {hasProp && baseValue.value === option.value && (
              <CheckmarkIcon style={{ width: 18, height: 18 }} />
            )}
          </Box>
        );
      }}
      renderInput={(params) => {
        return (
          <>
            <label
              className={clsx(
                classes.inputWrapper,
                isFocused && classes.focused
              )}
            >
              {prepend && prepend}
              <TextField
                variant="filled"
                onBlur={onBlur}
                onFocus={onFocus}
                placeholder={placeholder}
                {...params}
                inputProps={{
                  ...field,
                  ...params.inputProps,
                  style: styleOverrides.innerInput as CSSProperties,
                }}
              />
              {append && append}
            </label>
            {errorText && (
              <FormHelperText error={true}>{errorText}</FormHelperText>
            )}
          </>
        );
      }}
    />
  );
};

export default SelectTextField;
