import { ReactComponent as DefaultLogo } from "@/assets/logo/default.svg";
import { ReactComponent as SmallLogo } from "@/assets/logo/small.svg";
import { ReactComponent as VerticalLogo } from "@/assets/logo/vertical.svg";
import React from "react";

type WdLogoProps = {
  vertical?: boolean;
  small?: boolean;
};
const WdLogo = ({ vertical = false, small = false }: WdLogoProps) => {
  if (vertical) return <VerticalLogo />;
  if (small) return <SmallLogo />;
  return <DefaultLogo />;
};

export default WdLogo;
