import React, { useCallback, useMemo, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@/app/hooks';
import { SxProps, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { IVideoInviteProfile, UserProfileState } from '@/types/user-profile.types';
import { Avatar } from '@mui/material';
import BaseButton from './button.component';

import { ReactComponent as ChatWhiteIcon } from '@/assets/icons/chats/chat-white.svg';
import { ReactComponent as WebcameraWhiteIcon } from '@/assets/icons/chats/webcamera-white.svg';
import ProgressBar from './progress-bar';
import { useTimerTicker } from './useTimerTicker';
import { getRemainingDateParts } from '@/helpers/helpers';
import { setVideoInviteRemove } from '@/app/users/users.actions';
import usePersistentTab from './use-persistance-tab-hook';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '288px',
    overflow: 'hidden',
    padding: '18px',
    zIndex: 1000,
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
  },
  name: {
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000',
    display: 'inline',
    paddingRight: '5px',
  },
  pageId: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
    color: '#000000',
    display: 'inline',
  },
  avatar: {
    width: '64px',
    height: '64px',
    borderRadius: '10px',
  },
  contentWrapper: {
    display: 'flex',
    marginTop: '10px',
  },
  message: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#000000',
    paddingLeft: '24px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    borderRadius: '10px',
    width: '100%',
  },
  wrapperButtons: {
    marginTop: '12px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  videoButton: {},
  textButton: {},
  progressWrapper: {},
  progressBar: {},
}));

interface IProps {
  invite: IVideoInviteProfile;
  timeEnd: number;
  timeStart: number;
}

const VideoInvitesCard = ({ invite, timeEnd, timeStart }: IProps) => {
  const classes = useStyles();
  const currentUser = useAppSelector((state) => state.users.currentUser);

  const remainingTime = useMemo(() => timeEnd - Date.now(), [timeEnd]);
  const allTime = useMemo(() => timeEnd - timeStart, [timeEnd, timeStart]);

  const [rTimeObj, rTime] = useTimerTicker(remainingTime, getRemainingDateParts, () => {
    removeInvites();
  });

  const progressPercent = 100 - (rTime / allTime) * 100;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const toggleInviteAccepted = useCallback(
    (event) => {
      dispatch(setVideoInviteRemove(invite.profileId));
      event.preventDefault();
      navigate(`/chat/${currentUser?.profile.id}/${invite?.profileId}/?openVideoFromInvite=${true}`)
    },
    [invite, dispatch, navigate]
  );

  const removeInvites = useCallback(() => {
    dispatch(setVideoInviteRemove(invite.profileId));
  }, [invite, dispatch]);

  interface sxStyles {
    [key: string]: SxProps<Theme>;
  }

  const sxStyles: sxStyles = {
    listItem: {
      '& > img': { mr: 2, flexShrink: 0 },
    },
    avatar: {
      width: 64,
      height: 64,
      borderRadius: '10px',
      marginRight: 1,
    },
  };
  return (
    <div className={classes.container}>
      <div>
        <div className={classes.name}>{invite.name}</div>
      </div>
      <div>
        <div className={classes.contentWrapper}>
          <Avatar alt={invite.name} src={invite.avatar} sx={sxStyles.avatar} />
        </div>
      </div>
      <div className={classes.wrapperButtons}>
        <BaseButton text='Accept Video Call' textStyle='' onClick={toggleInviteAccepted} Icon={<WebcameraWhiteIcon />} />
      </div>
      <ProgressBar width={progressPercent} color={'#00E899'} className={classes.progressBar} />
    </div>
  );
};

export default VideoInvitesCard;
