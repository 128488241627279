import { useCallback, useEffect, useMemo, useState } from "react";
import BaseCard from "@/components/base/base-card.component";
import { useAppBreakpoints, useContainerWidth } from "@/hooks";
import { Button, CircularProgress, Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BaseButton from "@/components/base/button.component";
import { toggleSnackbarOpen } from "@/app/ui/ui.actions";
import NewsService, { NewsShape } from "@/services/news.service";
import Birthdays from "./components/Birthdays";
import PhotoNews from "./components/PhotoNews";
import { useAppDispatch } from "@/app/hooks";


const useStyles = makeStyles((theme: Theme) => ({
  cardOverrides: {
    paddingBottom: 0,
  },

  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    [theme.breakpoints.down("xl")]: {
      marginTop: 24,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 14,
    },
  },

  header: {
    marginBottom: 27,
    [theme.breakpoints.down("md")]: {
      marginBottom: 32,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
    },
  },

  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  headerButtonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 26
  },

  subHeaderButton: {
    '&:first-child': {
      marginRight: 12
    }
  },

  iconWrapper: {
    paddingRight: '28px',
    '& svg': {
      width: '16px',
      height: '12px',
      '& path': {
        fill: 'rgb(80, 62, 182)'
      }
    }
  },

  avatarInner: {
    borderRadius: "50%",
  },

  name: {
    wordBreak: 'break-word',
    color: '#000000'
  },

  loadMoreWrapper: {
    alignSelf: "center",
    minWidth: "200px !important",
  },

  loadMoreBtn: {
    maxWidth: 200,
    minWidth: 200,
  },

  loadButtonSection: {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    marginTop: '28px'
  },

  noNotifications: {
    margin: '0 auto',
    border: '2px dashed #503EB6',
    padding: '10px',
    width: '200px',
    display: 'flex',
    justifyContent: 'center',
  },
}));



const Notifications = () => {
  const { xs } = useAppBreakpoints();
  const dispatch = useAppDispatch();

  const stylesOverrides = useMemo(() => (
    {
      contentWrapper: {
        padding: 0,
        maxWidth: 968,
      },
      subHeaderButton: {
        width: '100%',
        height: xs ? 24 : 36,
        minHeight: xs ? 24 : 36,
      },
    }
  ), [xs]);

  const classes = useStyles();
  const width = useContainerWidth();

  const windowHeight = useMemo(() => window.innerHeight, []);
  const windowWidth = useMemo(() => window.innerWidth, []);

  const newsMapper = useCallback((news: NewsShape) => {
    switch (news.type) {
      case 'photo': {
        return <PhotoNews news={news} windowHeight={windowHeight - 90} windowWidth={windowWidth}/>
      }
    }
  }, [windowHeight, windowWidth])

  const [news, setNews] = useState([]);
  const [isPaginationOver, setIsPaginationOver] = useState<boolean>(false);
  const [isBookmarks, setIsBookmarks] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationOffset, setPaginationOffset] = useState<number>(0);

  useEffect(() => {
    setPaginationOffset(0);
    setNews([]);
    setIsPaginationOver(false);
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBookmarks])

  const loadMore = useCallback(
    async (offset = 0) => {
      setIsLoading(true);

      try {
        const response = await NewsService.getNews(offset, isBookmarks);

        if (response.length < 20) {
          setIsPaginationOver(true);
        }

        setNews(!offset ? response : news.concat(response));

      } catch {
        dispatch(
          toggleSnackbarOpen(
            "Something goes wrong during request execution",
            "error"
          )
        )
      }

      setPaginationOffset(offset);

      setIsLoading(false);
    },
    [isBookmarks, news]
  );

  const getLoadButton = useMemo(() => {
    if (isPaginationOver || !news.length) return null;
    if (isLoading) return <CircularProgress color="secondary" style={{ margin: "0 auto" }} />;

    return <BaseButton
      text="Load More (20)"
      color="primary"
      wrapperClass={classes.loadMoreWrapper}
      className={classes.loadMoreBtn}
      onClick={() => loadMore(paginationOffset + 20)}
    />

  }, [classes.loadMoreBtn, classes.loadMoreWrapper, isLoading, isPaginationOver, loadMore, news.length, paginationOffset])

  return <Container
    maxWidth={width}
    className={classes.contentWrapper}
    style={stylesOverrides.contentWrapper}
  >
    <BaseCard wrapperOverrideStyle={{ margin: 0, marginBottom: 10 }} cardOverrideStyle={classes.cardOverrides}>
      <div
        className={classes.cardWrapper}
      >
        <header className={classes.header}>
          <Typography variant="h2">News</Typography>
        </header>

        <section className={classes.headerButtonWrapper}>
          <Button
            disableElevation
            color={isBookmarks ? 'info' : "primary"}
            variant="contained"
            style={stylesOverrides.subHeaderButton}
            className={classes.subHeaderButton}
            onClick={() => {
              setIsBookmarks(false);
            }}
          >
            <Typography color="currentColor">All</Typography>
          </Button>

          <Button
            disableElevation
            color={isBookmarks ? "primary" : 'info'}
            variant="contained"
            style={stylesOverrides.subHeaderButton}
            className={classes.subHeaderButton}
            onClick={() => {
              setIsBookmarks(true);
            }}
          >
            <Typography color="currentColor">Contacts</Typography>
          </Button>
        </section>
      </div>
    </BaseCard>

    <Birthdays />

    <div>
      {
        !news.length ?
          <div className={classes.noNotifications}>There are no news</div>
          :
          news.map(newsMapper)
      }
    </div>
    <div className={classes.loadButtonSection}>
      {getLoadButton}
    </div>
  </Container>
}

export default Notifications;
