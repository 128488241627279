import AboutUs from '@/components/additional/about-us.component';
import AntiScamPolicy from '@/components/additional/anti-scam-policy';
import ContactUs from '@/components/additional/contact-us';
import CookiePolicy from '@/components/additional/cookie-policy';
import FAQ from '@/components/additional/faq';
import PrivacyPolicy from '@/components/additional/privacy-policy';
import RefundPolicy from '@/components/additional/refund-policy';
import TermsOfUse from '@/components/additional/terms-of-use.component';
import ChatContainer from '@/components/chat-container';
import Chat from '@/components/chat-container/components/chat.component';
import EmptyChat from '@/components/chat-container/components/empty-chat.component';
import DrawerContainer from '@/components/drawer';
import ChatLayout from '@/components/layouts/chat.layout';
import MainLayout from '@/components/layouts/main.layout';
import SecureRoute from '@/hocs/secure-route.hoc';
import UnsecureRoute from '@/hocs/unsecured-route.hoc';
import UserPhotoAlbums from '@/pages/photo-albums';
import AlbumPhotoDirectory from '@/pages/photo-albums/components/photo-album-directory';
import UserVideoAlbums from '@/pages/video-albums';
import AlbumVideoDirectory from '@/pages/video-albums/components/video-album-directory';
import EditProfile from '@/pages/edit-profile';
import AboutProfile from '@/pages/edit-profile/components/about-profile.component';
import AppearanceProfile from '@/pages/edit-profile/components/appearance-profile.component';
import EditMainInfo from '@/pages/edit-profile/components/edit-main-info.component';
import PerfectWoman from '@/pages/edit-profile/components/perfect-woman.component';
import Home from '@/pages/home';
import Messages from '@/pages/messages';
import Notifications from '@/pages/notifications';
import News from '@/pages/news';
import MessagesHeader from '@/pages/messages/components/messages-header';
import SearchUsers from '@/pages/search-users';
import UserSettings from '@/pages/settings';
import AccountInfo from '@/pages/settings/components/general-info.component';
import UserProfile from '@/pages/user-profle';
import Guests from '@/pages/guests';
import Contacts from '@/pages/contacts';
import { BrowserRouter as Router, Outlet, Route, Routes } from 'react-router-dom';
import OrderHistory from '@/pages/order-history';
import Credits from '@/pages/credits';
import PaymentHistory from '@/pages/payment-history';
import UserNotifications from '@/pages/settings/components/user-notifications';
import Gifts from '@/pages/gifts';
import GiftsHeader from '@/pages/gifts/components/gifts-header';
import DialogLayout from '@/components/layouts/dialog.layout';
import DialogContainer from '@/components/dialog-container';
import EmptyDialog from '@/components/chat-container/components/empty-dialog.component';
import Dialog from '@/components/dialog-container/components/dialog.component';
import ComplaintPolicy from '@/components/additional/complaint-policy';
import CancellationPolicy from '@/components/additional/cancellation-policy';
import RedirectSignIn from '@/pages/auth-redirects-sign-in';
import Blacklist from '@/pages/blacklist';
import PriceList from '@/components/additional/price-list';

export const MainRouter = () => {
  // const hasChildren = (route: any) => route.children && route.children.length;

  return (
    <Routes>
      <Route
        path='/'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <Home />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path='/terms-of-use'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <TermsOfUse />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path='/privacy-policy'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <PrivacyPolicy />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path='/complaint-policy'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <ComplaintPolicy />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path='/anti-scam-policy'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <AntiScamPolicy />
              </MainLayout>
            )}
          />
        }
      />
      <Route
        path='/cookie-policy'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <CookiePolicy />
              </MainLayout>
            )}
          />
        }
      />
      <Route
        path='/faq'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <FAQ />
              </MainLayout>
            )}
          />
        }
      />
      <Route
        path='/refund-policy'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <RefundPolicy />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path='/price-list'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <PriceList />
              </MainLayout>
            )}
          />
        }
      />
      <Route
        path='/cancellation-policy'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <CancellationPolicy />
              </MainLayout>
            )}
          />
        }
      ></Route>

      <Route
        path='/about'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <AboutUs />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path='/contacts'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <ContactUs />
              </MainLayout>
            )}
          />
        }
      />
      <Route
        path='/chat'
        element={
          <SecureRoute
            component={() => (
              <ChatLayout>
                <ChatContainer>
                  <Outlet />
                </ChatContainer>
              </ChatLayout>
            )}
          />
        }
      >
        <Route path='/chat' element={<EmptyChat />} />
        <Route path='/chat/:id' element={<EmptyChat />} />
        <Route path='/chat/:id/:otherId' element={<Chat />} />
        <Route path='/chat/:id/:otherId/?message=:message' element={<Chat />} />
      </Route>
      <Route
        path='/dialog'
        element={
          <SecureRoute
            component={() => (
              <DialogLayout>
                <DialogContainer>
                  <Outlet />
                </DialogContainer>
              </DialogLayout>
            )}
          />
        }
      >
        <Route path='/dialog' element={<EmptyDialog />} />
        <Route path='/dialog/:id' element={<EmptyDialog />} />
        <Route path='/dialog/:id/:otherId' element={<Dialog />} />
        <Route path='/dialog/:id/:otherId/?message=:message' element={<Dialog />} />
      </Route>
      <Route
        path='/user'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <DrawerContainer>
                  <Outlet />
                </DrawerContainer>
              </MainLayout>
            )}
          />
        }
      >
        <Route path='/user/search' element={<SearchUsers />} />
        <Route path='/user/wallet' element={<SearchUsers />} />
        <Route path='/user/:id' element={<UserProfile />} />
        <Route path='/user/photo-albums/:id' element={<UserPhotoAlbums />} />
        <Route path='/user/photos/:type/:id' element={<AlbumPhotoDirectory />} />
        <Route path='/user/video-albums/:id' element={<UserVideoAlbums />} />
        <Route path='/user/videos/:type/:id' element={<AlbumVideoDirectory />} />
        <Route path='/user/credits' element={<Credits />} />

        <Route path='/user/sign-in-redirect/:token' element={<RedirectSignIn />} />
        <Route path='/user/mail' element={<div>mail</div>} />
        <Route path='/user/contacts' element={<Contacts />} />
        <Route path='/user/guests' element={<Guests />} />
        <Route path='/user/gifts' element={<div>gifts</div>} />
        <Route path='/user/blacklist' element={<Blacklist />} />
        <Route path='/user/news' element={<News />} />
        <Route path='/user/notifications' element={<Notifications />} />

        <Route path='/user/order-history' element={<OrderHistory />} />
        <Route path='/user/payments' element={<PaymentHistory />} />

        <Route path='/user/messages/:kind' element={<Messages />}>
          <Route index element={<MessagesHeader />} />
          <Route path='/user/messages/:kind/:id' element={<MessagesHeader enableMessageLayout={true} />} />
        </Route>

        <Route path='/user/gifts/:kind' element={<Gifts />}>
          <Route index element={<GiftsHeader />} />
          <Route path='/user/gifts/:kind/:id' element={<GiftsHeader enableGiftLayout={true} />} />
        </Route>

        <Route path='/user/edit' element={<EditProfile />}>
          <Route index element={<EditMainInfo />} />
          <Route path='/user/edit/about' element={<AboutProfile />} />
          <Route path='/user/edit/appearance' element={<AppearanceProfile />} />
          <Route path='/user/edit/perfect' element={<PerfectWoman />} />
        </Route>

        <Route path='/user/settings' element={<UserSettings />}>
          <Route index element={<AccountInfo />} />
          <Route path='/user/settings/notifications' element={<UserNotifications />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default MainRouter;
