export class Camera {
  static constraints = { video: { width: 1680, height: 720 }, audio: true };
  static stream = null;

  constructor() {}
  static async getCamera(showMyVideo: boolean) {
    if (this.stream && !showMyVideo) {
      this.stream.getTracks().forEach((t) => {
        t.stop();
        t.enabled = false;
      });
    }
    try {
      if (this.stream) {
        if (!showMyVideo) {
          this.stream = createMediaStreamFake();
        } else {
          this.stream = await navigator.mediaDevices.getUserMedia(
            this.constraints
          );
        }
        return this.stream;
      } else {
        const stream = showMyVideo
          ? await navigator.mediaDevices.getUserMedia(this.constraints)
          : createMediaStreamFake();
        this.stream = stream;
        return this.stream;
      }
    } catch (error) {
      console.log('err', error);
    }
  }

  getCurrentStream() {
    return Camera.stream;
  }

}
function createMediaStreamFake() {
  return new MediaStream([createEmptyVideoTrack({ width: 640, height: 480 })]);
}

function createEmptyVideoTrack({ width, height }) {
  const canvas = Object.assign(document.createElement('canvas'), {
    width,
    height,
  });
  canvas.getContext('2d').fillRect(0, 0, width, height);

  //@ts-ignore
  const stream = canvas.captureStream();
  const track = stream.getVideoTracks()[0];

  return Object.assign(track, { enabled: false });
}
