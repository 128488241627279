import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { toggleModalClose } from "@/app/ui/ui.actions";
import { Box, Button, Modal, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ModalBar = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.ui.toggleModal);
  const message = useAppSelector((state) => state.ui.modalMessage);
  const type = useAppSelector((state) => state.ui.modalType);
  const userId = useAppSelector((state) => state.ui.userId);
  const navigate = useNavigate();

  function handleClose() {
    dispatch(toggleModalClose());
  }

  function handleRedirect() {
    navigate(`/user/${userId}`);
    handleClose();
  }

  return (
    <Modal open={isOpen || false} onClose={handleClose} aria-labelledby='modal-title' aria-describedby='modal-description'>
      <Box sx={style}>
        <Typography id='modal-title' variant='h6' component='h2'>
          System message
        </Typography>
        <Typography id='modal-description' sx={{ mt: 2 }}>
          {message}
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant='contained' color='secondary'  onClick={handleClose}>
            Close
          </Button>
          <Button variant='contained' color='secondary' onClick={handleRedirect}>
            Go to User
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalBar;
