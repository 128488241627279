import React, { useRef, useState } from "react";
import { useAppSelector } from "@/app/hooks";
import { UserProfileDTO } from "@/app/users/users.reducer";
import { useAppBreakpoints, useContainerWidth } from "@/hooks";
import {
  Container,
  Breakpoint,
  Theme,
  Typography,
  ListItem,
  List,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import BaseCard from "@/components/base/base-card.component";
import { MenuItemTypes } from "@/types/menu-item.types";

import { ReactComponent as ManIcon } from "@/assets/icons/edit-icons/person.svg";
import { ReactComponent as InfoIcon } from "@/assets/icons/edit-icons/info.svg";
import { ReactComponent as WomanIcon } from "@/assets/icons/edit-icons/perfect.svg";
import { ReactComponent as ListIcon } from "@/assets/icons/edit-icons/list.svg";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import clsx from "clsx";
import DialogProfileOptions from "@/components/dialogs/profile-options.dialog.component";
import DialogEditProfile from "@/components/dialogs/edit-navigation.dialog.component";
import useStaffCheck from "@/hooks/useStaffCheck";

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    [theme.breakpoints.down("xl")]: {
      marginTop: 24,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 14,
    },
  },
  editWidgetContainer: {
    flex: "1 1 auto",
  },
  pageTitle: {
    display: "block",
    marginBottom: "28px !important",
    [theme.breakpoints.down("lg")]: {
      fontSize: "24px !important",
      textUnderlineOffset: "2px",
      textDecorationStyle: "dotted",
      textDecorationColor: "#ea4160",
      textDecorationLine: "underline",
    },
  },
  pageSubTitle: {
    fontSize: 20,
    lineHeight: "28px",
  },

  navWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    // [theme.breakpoints.up("lg")]:{

    // },
  },

  //   <div class="hidden lg:block w-1/4 flex-shrink-0">

  navigationListWrapper: {
    width: "25%",
    flexShrink: 0,

    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  navList: {
    display: "flex",
    padding: 0,
    marginTop: 0,
    flexDirection: "column",
    "& > a": {
      textDecoration: "none",
    },

    "& > $navItem:not(:first-child)": {
      marginTop: 16,
    },
  },
  navItem: {
    color: "#000",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      margin: 6,
      textDecoration: "none",
    },
    "& > $navItemText": {
      marginLeft: 12,
    },
    "& > svg": {
      width: 18,
      height: 18,
    },
  },
  navItemText: {},
  activeLink: {
    color: theme.palette.primary.main,
    "& path": {
      fill: theme.palette.primary.main,
    },
  },
  defaultLink: {
    color: "#000",

    "& path": {
      fill: "#503EB6",
    },
  },
}));

const stylesOverrides = {
  contentWrapper: {
    padding: 0,
    maxWidth: 968,
  },
};
type HtmlElementRefType =
  | ((instance: any) => void)
  | React.MutableRefObject<any>
  | null;

const EditProfile = () => {
  useStaffCheck();
  const classes = useStyles();
  const location = useLocation();
  const [profileOptionsEl, setProfileOptionsEl] = useState(null);
  const width = useContainerWidth();
  const { downLg } = useAppBreakpoints();
  const profileOptionsRef = useRef<HTMLDivElement>();
  const handleClickProfileOptions = (event: any) => {
    setProfileOptionsEl(profileOptionsRef.current as any);
  };
  const currentProfile: UserProfileDTO = useAppSelector(
    (state) => state.users.currentUser
  );
  const currentUserSex = currentProfile?.profile?.gender;

  const editNavigation: MenuItemTypes[] = [
    { path: "/user/edit", name: "Main Info", icon: InfoIcon },
    {
      path: "/user/edit/about",
      name: "About Me",
      icon: currentUserSex === "male" ? ManIcon : WomanIcon,
    },
    { path: "/user/edit/appearance", name: "Appearance", icon: ListIcon },
    {
      path: "/user/edit/perfect",
      name: `Perfect ${currentUserSex === "male" ? "Woman" : "Man"}`,
      icon: currentUserSex === "male" ? WomanIcon : ManIcon,
    },
  ];
  const list = editNavigation.map((navItem, idx) => (
    <NavLink
      onClick={() => setProfileOptionsEl(null)}
      className={clsx(
        classes.navItem,

        location.pathname === navItem.path
          ? classes.activeLink
          : classes.defaultLink
      )}
      to={navItem.path}
    >
      {/*// @ts-ignore */}

      <navItem.icon />
      <span className={classes.navItemText}>{navItem.name}</span>
    </NavLink>
  ));
  return (
    <Container
      maxWidth={width}
      className={classes.contentWrapper}
      style={stylesOverrides.contentWrapper}
    >
      <BaseCard wrapperOverrideStyle={{ margin: 0, paddingBottom: 10 }}>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <span onClick={(e: any) => downLg && handleClickProfileOptions(e)}>
            <Typography
              variant="h2"
              className={classes.pageTitle}
              ref={profileOptionsRef as HtmlElementRefType}
            >
              Edit Profile
            </Typography>
          </span>

          <div className={classes.navWrapper}>
            <div className={classes.navigationListWrapper}>
              <ul className={classes.navList}>{list}</ul>
            </div>

            <div className={classes.editWidgetContainer}>
              <Outlet />
            </div>
          </div>
        </div>
        {profileOptionsEl && (
          <DialogEditProfile
            handleLogout={() => {}}
            list={list}
            anchorEl={profileOptionsEl}
            setAnchorEl={setProfileOptionsEl}
          />
        )}
      </BaseCard>
    </Container>
  );
};

export default EditProfile;
