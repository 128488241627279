import { calculateAge } from '@/helpers/helpers';
import useBlankProfileAvatart from '@/hooks/useBlankProfileAvatart';
import GuestsService, { GuestShape } from '@/services/guests.service';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { differenceInMinutes } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '../../../components/base/avatar.component';
import BaseButton from '../../../components/base/button.component';
import StatusOnline from '../../../components/base/statuses/status-online.component';
import { ReactComponent as MessageIcon } from '@/assets/icons/profile-actions/message.svg';
import { ReactComponent as SMSIcon } from '@/assets/icons/profile-actions/sms-dialog.svg';
import { ReactComponent as EmailMiniIcon } from '@/assets/icons/profile-actions/email-mini.svg';
import { ReactComponent as AddContactIcon } from '@/assets/icons/profile-actions/add-contact.svg';
import { ReactComponent as PresentIcon } from '@/assets/icons/profile-actions/present.svg';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import BookmarksService from '@/services/bookmarks.service';
import { toggleSnackbarOpen } from '@/app/ui/ui.actions';
import usePersistentTab from '@/components/base/use-persistance-tab-hook';
import useDialogPersistentTab from '@/components/base/use-dialog-persistance-tab-hook';
import { IBlackList } from '@/types/user-profile.types';

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    marginBottom: 32,
    cursor: 'pointer',
    boxSizing: 'border-box',
    width: 170,
  },

  cardInner: {
    textDecoration: 'none',
    padding: '10px 0',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgb(80, 62, 182)',
    transition: 'all',
    borderRadius: 9,
    border: '2px solid #fff',
    maxWidth: 158,
    width: 158,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 9,
      opacity: 0,
      transition: 'opacity',
    },
    '&:focus': {
      outline: 'none',
      opacity: 1,
    },

    '&:hover': {
      opacity: 1,
    },
    '&:hover $hoverElement': {
      opacity: '1',
    },
  },
  hoverElement: {
    transition: 'all .3s',

    background: 'linear-gradient(161.26deg, rgba(80, 62, 182, 0.7) 8.37%, rgba(234, 65, 96, 0.7) 86.39%)',
    width: '100%',
    height: '100%',
    opacity: '0',
    position: 'relative',
    borderRadius: '10px',
    zIndex: 2,
  },
  cardNew: {
    background:
      'linear-gradient(-45deg, hsl(254deg 61% 62%), hsl(349, 80%, 59%), hsl(307deg 62% 41%), hsl(349, 80%, 59%))',
    backgroundSize: '1000% 100%',
    animation: '$gradient 5s ease infinite',
  },
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
  cardWrapper: {
    position: 'relative',
    zIndex: 1,
  },
  avatarContainer: {
    transform: 'translateX(10px)',
    marginBottom: 20,
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 10,
      opacity: 0,
      transition: 'opacity',
      backgroundColor: 'linear-gradient(to right bottom, rgb(80, 62, 182), rgb(234, 65, 96))',
      '&:focus, &:hover': {
        opacity: 0.7,
      },
    },
  },
  avatarStatuses: {
    display: 'flex',
    flexDirection: 'column',

    position: 'absolute',
    bottom: 6,
    left: 6,
    zIndex: 1,
  },
  avatarMeta: {
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 600,
    color: '#fff',
  },
  avatarId: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  avatarProfileInfo: {
    display: 'flex',
    fontSize: 18,
    lineHeight: '28px',
    margin: 0,
  },
  avatarName: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  avatarAge: {
    flexShrink: 0,
  },
  metaCtaIcon: {
    maxWidth: 32,
    maxHeight: 32,
    minHeight: '32px !important',
    minWidth: '0 !important',
    margin: '6px 6px 0 0 !important',

    '& > .MuiButton-startIcon': {
      margin: 0,
    },
  },
  hoverButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'self-end',
  },
}));

type GuestCardProps = {
  disableId?: boolean | undefined;
  user?: IBlackList | null;
  windowWidth: number;
  windowHeight: number;
};

const BlacklistCard = ({
  disableId = false,
  user = null,
  windowWidth,
  windowHeight,
}: GuestCardProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const myUser = useAppSelector((state) => state.users.currentUser);

  const { oppositeUserPicture } = useBlankProfileAvatart();

  const navigateToProfile = async () => {
    if (user?.profileId) {
      navigate(`/user/${user.profileId}`);
    }
  };

  return (
    <div className={classes.cardContainer} onClick={navigateToProfile}>
      <div className={clsx(classes.cardInner)}>
        <div className={classes.cardWrapper}>
          <Avatar url={user?.profileAvatar || oppositeUserPicture} containerClass={classes.avatarContainer}>
            <div className={classes.avatarStatuses}>
            </div>
          </Avatar>

          <div className={classes.avatarMeta}>
            <p className={classes.avatarProfileInfo}>
              <span className={classes.avatarName}>{user && user.profileName ? user.profileName : 'Username'}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlacklistCard;
