import { HttpService } from "./http.service";
import { ErrorWrapper } from "./utils";

export interface BookmarkShape {
  id: string;
  createdAt: Date;
  profileId: string;
  profileName: string;
  profileAvatar: string;
  profilePageId: number;
  seen: Date;
  activeAt: Date;
  birthday?: string;
}

export default class BookmarksService {
  static get entity(): string {
    return "bookmarks";
  }

  static async getBookmarks(): Promise<Array<BookmarkShape>> {
    const request = `/${this.entity}`

    try {
      const response = await HttpService.get(request);
      return response.data;
    } catch (error: any) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async addToBookmark(
    profileId: string
  ): Promise<Array<boolean>> {
    const request = `/${this.entity}`

    try {
      const response = await HttpService.post(request, {
        profileId
      });

      return response.data;
    } catch (error: any) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async deleteContact(
    profileId: string
  ): Promise<boolean> {
    const request = `/${this.entity}`

    try {
      const response = await HttpService.delete(`${request}/${profileId}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
