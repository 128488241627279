import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { ReactComponent as EditIcon } from '@/assets/icons/edit.svg';
import { ReactComponent as NewContactsIcon } from '@/assets/icons/new-mail-contacts.svg';
import { ReactComponent as PresentIcon } from '@/assets/icons/profile-actions/present.svg';

import { ReactComponent as ArchiveIcon } from '@/assets/icons/archive.svg';
import { ReactComponent as TrashIcon } from '@/assets/icons/trash.svg';

import BaseCard from '@/components/base/base-card.component';
import BaseButton from '@/components/base/button.component';
import DialogMessageMenu from '@/components/dialogs/message-menu.dialog';
import DialogWriteMail from '@/components/dialogs/write-mail.dialog.component';
import { useAppBreakpoints, useContainerWidth } from '@/hooks';
import { useBinarySwitcher } from '@/hooks/useBinarySwitcher';
import { Button, Container, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useRef, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import MessagesNavbarDialog from './components/messages-navbar-dialog';
import DialogMessagesSection from './components/dialog-messages-section';
import { useDialogMessages, useMessages } from './messages.hooks';
import UserMessageOpenned from './user-message-openned/user-message-openned.component';
import { ReactComponent as ArrowIcon } from '@/assets/icons/option-arrow.svg';
import { setIsBookmarksAction } from '@/app/mails/mails.actions';
import { deleteSelectedMessagesAction, markAsReadSelectedMessagesAction } from '@/app/mails/mails.actions';
import DialogGift from '@/components/dialogs/send-gift.dialog.component';
import { MailKind } from '@/app/mails/mails.types';
import MessagesDialogHeader from './components/messages-dialog-header';
import DialogUserMessageOpenned from './user-message-openned/user-message-openned-dialog.component';

type HtmlElementRefType = ((instance: any) => void) | React.MutableRefObject<any> | null;

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    [theme.breakpoints.down('xl')]: {
      marginTop: 24,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 14,
    },
  },

  navWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 46,
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  },

  navigationListWrapper: {
    width: '25%',
    flexShrink: 0,

    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },

  messageWidgetContainer: {
    flexBasis: '75%',
  },
  navigationHeader: {
    width: '75%',
  },
  headerCta: {
    padding: '10px 55px !important',
  },
  subHeader: {
    display: 'flex',
    margin: '24px 0',
    justifyContent: 'space-between',
    width: '100%',
  },
  menu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > .arrow': {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
  },
  menuWrapper: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '14px',
      alignSelf: 'flex-start',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      minHeight: '42px',
      alignItems: 'center',
    },
  },
  menuIconWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: '95px',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  ctaIcon: {
    maxWidth: 42,
    maxHeight: 42,
    color: `${theme.palette.radioSelected.main} !important`,
    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },
}));

const stylesOverrides = {
  contentWrapper: {
    padding: 0,
    maxWidth: 968,
  },
  subHeaderButton: {
    minWidth: 80,
    minHeight: 0,
    height: 24,
  },
};

interface DialogMailsProps {
  kind: MailKind;
}

const DialogMails = () => {
  const width = useContainerWidth();
  const classes = useStyles();
  const { downLg, smAndUp } = useAppBreakpoints();
  const dispatch = useAppDispatch();
  const [kind, setKind] = useState(MailKind.INBOX);
  const [messageOptionEl, setMessageOptionEl] = useState(null);
  const messageOptionsRef = useRef<HTMLDivElement>();
  const [isWriteDialogOpen, openDialog, hideDialog] = useBinarySwitcher();
  const [isGiftDialogOpen, openGiftDialog, hideGiftDialog] = useBinarySwitcher();

  const handleMessageOptions = (event: any) => {
    setMessageOptionEl(messageOptionsRef.current as any);
  };

  const [messageId, setMessageId] = useState(null);

  const [messagesKind] = useDialogMessages(kind, messageId);

  const isMessagesChosed = useAppSelector((state) => state.mails.chosenIds.size);

  const isBookmarks = useAppSelector((state) => state.mails.isBookmarks);

  const contactsMessageCount = useAppSelector((state) => state.mails.messageCounters?.bookmarksUnseen);

  const headerActions = [
    {
      icon: <TrashIcon />,
      onClick: () => dispatch(deleteSelectedMessagesAction(messagesKind)),
    },
    {
      icon: <ArchiveIcon />,
      onClick: () => dispatch(markAsReadSelectedMessagesAction(messagesKind)),
    },
  ];

  const contactMessagesSwitcher = useCallback(
    (state: boolean) => {
      dispatch(setIsBookmarksAction(state));
    },
    [dispatch]
  );

  return (
    <Container maxWidth={width} className={classes.contentWrapper} style={stylesOverrides.contentWrapper}>
      <DialogWriteMail open={isWriteDialogOpen} closeHandler={hideDialog} />
      <DialogGift open={isGiftDialogOpen} closeHandler={hideGiftDialog} />
      <BaseCard wrapperOverrideStyle={{ margin: 0, paddingBottom: 10 }}>
        {messageOptionEl && (
          <DialogMessageMenu
            anchorEl={messageOptionEl}
            setAnchorEl={setMessageOptionEl}
            isContactMessagesDisplayed={isBookmarks}
            contactMessagesSwitcher={contactMessagesSwitcher}
            contactsMessageCount={contactsMessageCount}
          />
        )}

        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: smAndUp ? 'row' : 'column',
              width: smAndUp || messageId ? '100%' : '312px',
            }}
          >
            <div className={classes.menuWrapper}>
              <span
                onClick={(e: any) => downLg && handleMessageOptions(e)}
                ref={messageOptionsRef as HtmlElementRefType}
              >
                <div className={classes.menu}>
                  <Typography variant='h2'>Mail</Typography>
                  <ArrowIcon
                    style={{
                      marginLeft: 6,
                      width: 18,
                      height: 18,
                      transform: messageOptionEl ? 'rotate(180deg)' : '',
                    }}
                    className='arrow'
                  />
                </div>
              </span>

              <div className={classes.menuIconWrapper}>
                {!messageId && isMessagesChosed && !smAndUp
                  ? headerActions.map((action, idx) => (
                      <BaseButton
                        key={idx}
                        inverted
                        text=''
                        className={classes.ctaIcon}
                        textStyle=''
                        onClick={action.onClick}
                        Icon={action.icon}
                        disabled={!isMessagesChosed}
                      />
                    ))
                  : null}
              </div>
            </div>

            <div className={classes.messageWidgetContainer} style={{ width: messageId ? '100%' : 'auto' }}>
              <MessagesDialogHeader
                kind={messagesKind}
                messageId={messageId}
                enableMessageLayout={!!messageId}
                setMessageId={() => setMessageId(null)}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div className={classes.navWrapper}>
              <div className={classes.navigationListWrapper}>
                <div className={classes.navigationHeader}>
                  <BaseButton
                    text='Write'
                    color='primary'
                    wrapperClass={classes.headerCta}
                    onClick={openDialog}
                    Icon={<EditIcon />}
                  />
                  {/* <BaseButton
                    text="Gift"
                    color="primary"
                    wrapperClass={classes.headerCta}
                    onClick={openGiftDialog}
                    Icon={<PresentIcon />}
                  /> */}
                  <div className={classes.subHeader}>
                    <Button
                      disableElevation
                      color={isBookmarks ? 'info' : 'primary'}
                      variant='contained'
                      style={stylesOverrides.subHeaderButton}
                      onClick={() => contactMessagesSwitcher(false)}
                    >
                      <Typography color='currentColor'>All</Typography>
                    </Button>
                    <Button
                      disableElevation
                      color={isBookmarks ? 'primary' : 'info'}
                      variant='contained'
                      style={stylesOverrides.subHeaderButton}
                      startIcon={<NewContactsIcon />}
                      onClick={() => contactMessagesSwitcher(true)}
                    >
                      <Typography color='currentColor'>{contactsMessageCount}</Typography>
                    </Button>
                  </div>
                </div>
                <MessagesNavbarDialog setMessageOptionEl={setMessageOptionEl} setKind={setKind} kind={kind} />
              </div>
              {messageId ? (
                <DialogUserMessageOpenned messageId={messageId} setMessageId={() => setMessageId(null)} kind={messagesKind}/>
              ) : (
                <DialogMessagesSection
                  messagesKind={messagesKind}
                  writeMessagehandler={openDialog}
                  setMessageId={setMessageId}
                />
              )}
            </div>
          </div>
        </div>
      </BaseCard>
    </Container>
  );
};

export default DialogMails;
