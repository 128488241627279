import { useAppSelector } from "@/app/hooks";
import { UserRolesEnum } from "@/components/chat-container/enums/user-roles.enum";

const useIsStaffUserRole = () => {
  const isStaff = useAppSelector((state) => state?.users?.currentUser?.role === UserRolesEnum.STAFF);

  return isStaff
};

export default useIsStaffUserRole;
