import { useAppSelector } from "@/app/hooks";
import React from "react";
import { useParams } from "react-router-dom";

const useProfileRelation = () => {
  const { id } = useParams<{
    id: string;
  }>();

  const currentUser = useAppSelector((state) => state.users.currentUser);
  const isMyProfile = id === currentUser?.id;

  return [isMyProfile];
};

export default useProfileRelation;
