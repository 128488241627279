import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import profilePicture from '@/assets/images/demo-avatar.jpeg';
import { ChatDialog, ISettings, UserProfile } from '@/types/user-profile.types';
import clsx from 'clsx';
import { formatAMPM, getGoogleTranslationKey } from '@/helpers/helpers';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { translateMessageSuccess } from '@/app/users/users.actions';
import { Gallery } from 'react-photoswipe-gallery';
import ProfileUserSlider from '@/components/sliders/profile-user-slider.component';
import { RevealPhotoComponent } from './reveal-photo.dialog.component';
import { RevealVideoComponent } from './reveal-video.dialog.component';
import useBalanceCheckHook from '@/hooks/useBalanceCheckHook';
import UploadVideoItem from '@/components/base/upload-video-item';

export interface IProps {
  referenceUser: UserProfile;
  currentUser: UserProfile;
  isOpenVideoChat?: boolean;
  initialMessage?: string;
  translateHisText(message: ChatDialog): void;
  revealPhoto(message: ChatDialog): void;
  revealVideo(message: ChatDialog): void;
}

const useStyles = makeStyles<Theme, IProps>((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
  },
  scrollContainer: {
    overflowY: 'scroll',
    padding: (props) => (props.isOpenVideoChat ? '20px 28px 0 28px' : '0'),
    marginTop: (props) => (props.isOpenVideoChat ? '20px' : '0'),
    height: '100%',
    // height: (props: IProps) =>
    [theme.breakpoints.up('md')]: {
      // height: (props: IProps) =>
      //   props.isOpenVideoChat ? 'calc(100vh - 565px)' : 'calc(100% - 140px)',
    },
    [theme.breakpoints.up('lg')]: {
      // height: (props: IProps) =>
      //   props.isOpenVideoChat ? 'calc(100vh - 545px)' : 'calc(100% - 200px)',
    },
  },
  avatar: {
    width: '56px',
    height: '56px',
    borderRadius: '60px',
    [theme.breakpoints.down('sm')]: {
      width: '30px',
      height: '30px',
    },
  },
  avatarImage: {
    width: '56px',
    height: '56px',
    borderRadius: '60px',
    [theme.breakpoints.down('sm')]: {
      width: '30px',
      height: '30px',
    },
  },
  avatarRight: {
    paddingRight: '16px',
    '&::after': {
      background: '#FEF3F3',
    },
  },
  avatarLeft: {
    paddingLeft: '16px',
    '&::after': {
      background: '#F0FBFE',
    },
  },
  message: {
    textAlign: 'left',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  customMessage: {
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '28px',
    color: '#7D7D7F',
    background: 'rgba(215, 211, 238, 0.25)',
    borderRadius: '50px',
    margin: '28px 0 0 0',
  },
  messageDate: {
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '20px',
    color: '#808080',
  },
  leftMessageBackground: {
    background: '#FEF3F3',
  },
  rightMessageBackground: {
    background: '#F0FBFE',
  },
  male: {
    width: '100%',
    borderRadius: '10px',
  },
  female: {
    width: '100%',
    borderRadius: '10px',
  },
  ownMessage: {
    display: 'flex',
    paddingTop: (props) => (props.isOpenVideoChat ? '10px' : '28px'),
  },
  leftMessage: {
    padding: (props) => (props.isOpenVideoChat ? '10px 72px 0 0' : '28px 72px 0 0'),
    [theme.breakpoints.down('sm')]: {
      padding: (props) => (props.isOpenVideoChat ? '10px 0 0 0' : '28px 0 0 0'),
    },
  },
  rightMessage: {
    padding: '28px 0 0 72px',
    [theme.breakpoints.down('sm')]: {
      padding: (props) => (props.isOpenVideoChat ? '10px 0 0 0' : '28px 0 0 0'),
    },
  },
  messageBody: {
    padding: '10px 28px 28px 14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      padding: '10px 8px 28px 14px',
    },
  },
  messageWrapper: {
    position: 'relative',
    textAlign: 'left',
  },
  time: {
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '20px',
    color: '#808080',
    padding: '0 0 10px 14px',
    position: 'absolute',
    bottom: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      padding: '0 0 0px 14px',
    },
  },
  maleLeftBox: {
    position: 'relative',
    '&::after': {
      content: "''",
      position: 'absolute',
      top: '17px',
      left: '-16px',
      width: '22px',
      height: '22px',
      transition: 'opacity',
      borderRadius: '2px',
      transform: 'rotate(45deg)',
      [theme.breakpoints.down('sm')]: {
        top: '5px',
      },
    },
  },
  femaleLeftBox: {
    position: 'relative',
    '&::after': {
      content: "''",
      position: 'absolute',
      top: '17px',
      left: '-16px',
      width: '22px',
      height: '22px',
      transition: 'opacity',
      borderRadius: '2px',
      transform: 'rotate(45deg)',
      [theme.breakpoints.down('sm')]: {
        top: '5px',
      },
    },
  },
  maleRightBox: {
    position: 'relative',
    '&::after': {
      content: "''",
      position: 'absolute',
      top: '17px',
      right: '-16px',
      width: '22px',
      height: '22px',
      transition: 'opacity',
      borderRadius: '2px',
      transform: 'rotate(45deg)',
      [theme.breakpoints.down('sm')]: {
        top: '5px',
      },
    },
  },
  femaleRightBox: {
    position: 'relative',
    '&::after': {
      content: "''",
      position: 'absolute',
      top: '17px',
      right: '-16px',
      width: '22px',
      height: '22px',
      transition: 'opacity',
      borderRadius: '2px',
      transform: 'rotate(45deg)',
      [theme.breakpoints.down('sm')]: {
        top: '5px',
      },
    },
  },
  profileSlideWrapper: {
    marginBottom: 24,

    '& .pswp__zoom-wrap': {
      display: 'flex',
      justifyContent: 'center',
    },

    '& .pswp__img': {
      width: 'auto !important',
      position: 'relative !important',
    },
    '& .pswp__img--placeholder': {
      display: 'none',
    },
  },
}));

const ChatMessages = ({ ...props }: IProps) => {
  const classes = useStyles(props);
  const dispatch = useAppDispatch();

  const windowWidth = useMemo(() => window.innerWidth, []);
  const windowHeight = useMemo(() => window.innerHeight - 90, []);

  const { referenceUser, currentUser, initialMessage, translateHisText, revealPhoto, revealVideo } = props;

  const messagesEndRef = useRef(null);

  const [openPhotoDialog, setPhotoOpenDialog] = useState(false);
  const [openVideoDialog, setVideoOpenDialog] = useState(false);

  const [revealPhotoMessage, setPhotoRevealMessage] = useState(null);
  const [revealVideoMessage, setVideoRevealMessage] = useState(null);

  const handlePhotoCloseDialog = useCallback(() => {
    setPhotoRevealMessage(null);
    setPhotoOpenDialog(false);
  }, []);

  const handleVideoCloseDialog = useCallback(() => {
    setVideoRevealMessage(null);
    setVideoOpenDialog(false);
  }, []);

  const revealPhotoCallback = useCallback(() => {
    revealPhoto(revealPhotoMessage);
    handlePhotoCloseDialog();
  }, [handlePhotoCloseDialog, revealPhotoMessage, revealPhoto]);

  const revealVideoCallback = useCallback(() => {
    revealVideo(revealVideoMessage);
    handleVideoCloseDialog();
  }, [handleVideoCloseDialog, revealVideoMessage, revealVideo]);

  const { creditsChatPhotoOpen, creditsChatVideoOpen } = useAppSelector<ISettings>((state) => state.users.settings);
  const wallet = useAppSelector((state) => state.users.wallet);
  const { checkBalance } = useBalanceCheckHook(wallet?.balance || 0);

  const handlePhotoOpenDialog = useCallback((message: ChatDialog) => {
    checkBalance(creditsChatPhotoOpen, () => {
      setPhotoRevealMessage(message);
      setPhotoOpenDialog(true);
    });
  }, []);

  const handleVideoOpenDialog = useCallback((message: ChatDialog) => {
    checkBalance(creditsChatVideoOpen, () => {
      setVideoRevealMessage(message);
      setVideoOpenDialog(true);
    });
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getLeftAvatarClass = (gender: string) => `${gender}LeftBox`;
  const getRightAvatarClass = (gender: string) => `${gender}RightBox`;

  useEffect(() => {
    scrollToBottom();
  }, [referenceUser]);

  return (
    <div className={classes.scrollContainer}>
      {initialMessage && (
        <div>
          <div className={clsx(classes.ownMessage, classes.leftMessage)} key={'initial message'}>
            <div className={classes.message}>
              <div
                className={clsx(
                  classes.avatar,
                  classes.avatarRight,
                  classes[getRightAvatarClass(referenceUser.gender)]
                )}
              >
                <img alt='' className={classes.avatarImage} src={referenceUser.avatar || profilePicture} />
              </div>
            </div>
            <div className={clsx(classes[referenceUser.gender], classes.leftMessageBackground, classes.messageWrapper)}>
              <div className={classes.messageBody}>{initialMessage}</div>
              <div className={classes.time}>{formatAMPM(new Date())}</div>
            </div>
          </div>
        </div>
      )}
      {(referenceUser.chat || []).map((message) => (
        <div ref={messagesEndRef}>
          {message.id === currentUser?.id && (
            <div className={clsx(classes.ownMessage, classes.rightMessage)} key={new Date(message.date).getTime()}>
              <div
                className={clsx(classes[currentUser.gender], classes.rightMessageBackground, classes.messageWrapper)}
              >
                <div className={classes.messageBody}>
                  {message.translate || message.text}
                  {message.img && message.img?.small ? (
                    <div>
                      <img alt={`${message?.img?.small}`} src={message?.img?.small} />
                    </div>
                  ) : (
                    ''
                  )}
                  {message.video && message.video?.origin ? (
                    <UploadVideoItem
                      id={message?.video.videoId}
                      isExpended={false}
                      thumbnail={message?.video?.thumbnail}
                      isPrivate={true}
                      withoutIcon={true}
                      isEditable={false}
                      origin={message?.video?.origin}
                    />
                  ) : (
                    ''
                  )}
                </div>
                <div className={classes.time}>{formatAMPM(message.date)}</div>
              </div>
              <div className={classes.message}>
                <div
                  className={clsx(classes.avatar, classes.avatarLeft, classes[getLeftAvatarClass(currentUser.gender)])}
                >
                  <img alt='' className={classes.avatarImage} src={currentUser.avatar || profilePicture} />
                </div>
              </div>
            </div>
          )}
          {message.id === referenceUser.id && message.img && message.img.photoId && message.img.isPaid && (
            <div className={clsx(classes.ownMessage, classes.leftMessage)} key={message.date as unknown as string}>
              <div className={classes.message}>
                <div
                  className={clsx(
                    classes.avatar,
                    classes.avatarRight,
                    classes[getRightAvatarClass(referenceUser.gender)]
                  )}
                >
                  <img alt='' className={classes.avatarImage} src={referenceUser.avatar || profilePicture} />
                </div>
              </div>
              <div
                className={clsx(classes[referenceUser.gender], classes.leftMessageBackground, classes.messageWrapper)}
              >
                <div className={classes.messageBody}>
                  <div className={classes.profileSlideWrapper}>
                    <Gallery>
                      <ProfileUserSlider
                        defaultValue={[message.img]}
                        windowHeight={windowHeight - 90}
                        windowWidth={windowWidth}
                      />
                    </Gallery>
                  </div>
                </div>

                <div className={classes.time}>{formatAMPM(message.date)}</div>
              </div>
            </div>
          )}
          {message.id === referenceUser.id && message.img && message.img.photoId && !message.img.isPaid && (
            <div
              className={clsx(classes.ownMessage, classes.leftMessage)}
              key={message.date as unknown as string}
              onClick={() => {
                handlePhotoOpenDialog(message);
              }}
            >
              <div className={classes.message}>
                <div
                  className={clsx(
                    classes.avatar,
                    classes.avatarRight,
                    classes[getRightAvatarClass(referenceUser.gender)]
                  )}
                >
                  <img alt='' className={classes.avatarImage} src={referenceUser.avatar || profilePicture} />
                </div>
              </div>
              <div
                className={clsx(classes[referenceUser.gender], classes.leftMessageBackground, classes.messageWrapper)}
              >
                <div className={classes.messageBody}>
                  <img alt={`${message?.img?.small}`} src={message?.img?.small} />
                </div>

                <div className={classes.time}>{formatAMPM(message.date)}</div>
              </div>
            </div>
          )}
          {message.id === referenceUser.id && message.video && message.video.videoId && message.video.isPaid && (
            <div className={clsx(classes.ownMessage, classes.leftMessage)} key={message.date as unknown as string}>
              <div className={classes.message}>
                <div
                  className={clsx(
                    classes.avatar,
                    classes.avatarRight,
                    classes[getRightAvatarClass(referenceUser.gender)]
                  )}
                >
                  <img alt='' className={classes.avatarImage} src={referenceUser.avatar || profilePicture} />
                </div>
              </div>
              <div
                className={clsx(classes[referenceUser.gender], classes.leftMessageBackground, classes.messageWrapper)}
              >
                <div className={classes.messageBody}>
                  <div className={classes.profileSlideWrapper}>
                    <UploadVideoItem
                      id={message?.video.videoId}
                      isExpended={false}
                      thumbnail={message?.video?.thumbnail}
                      isPrivate={true}
                      withoutIcon={true}
                      isEditable={false}
                      origin={message?.video?.origin}
                    />
                  </div>
                </div>

                <div className={classes.time}>{formatAMPM(message.date)}</div>
              </div>
            </div>
          )}
          {message.id === referenceUser.id && message.video && message.video.videoId && !message.video.isPaid && (
            <div className={clsx(classes.ownMessage, classes.leftMessage)} key={message.date as unknown as string}>
              <div className={classes.message}>
                <div
                  className={clsx(
                    classes.avatar,
                    classes.avatarRight,
                    classes[getRightAvatarClass(referenceUser.gender)]
                  )}
                >
                  <img alt='' className={classes.avatarImage} src={referenceUser.avatar || profilePicture} />
                </div>
              </div>
              <div
                className={clsx(classes[referenceUser.gender], classes.leftMessageBackground, classes.messageWrapper)}
              >
                <div className={classes.messageBody}>
                  <UploadVideoItem
                    id={message?.video.videoId}
                    isExpended={false}
                    thumbnail={message?.video?.thumbnail}
                    isPrivate={true}
                    withoutIcon={true}
                    isEditable={false}
                    origin={message?.video?.origin}
                    handleClick={() => handleVideoOpenDialog(message)}
                  />
                </div>

                <div className={classes.time}>{formatAMPM(message.date)}</div>
              </div>
            </div>
          )}
          {message.id === referenceUser.id && !message.img && !message.video && (
            <div
              className={clsx(classes.ownMessage, classes.leftMessage)}
              key={message.date as unknown as string}
              onClick={() => {
                translateHisText(message);
              }}
            >
              <div className={classes.message}>
                <div
                  className={clsx(
                    classes.avatar,
                    classes.avatarRight,
                    classes[getRightAvatarClass(referenceUser.gender)]
                  )}
                >
                  <img alt='' className={classes.avatarImage} src={referenceUser.avatar || profilePicture} />
                </div>
              </div>
              <div
                className={clsx(classes[referenceUser.gender], classes.leftMessageBackground, classes.messageWrapper)}
              >
                <div className={classes.messageBody}>{message.translate || message.text} </div>

                <div className={classes.time}>{formatAMPM(message.date)}</div>
              </div>
            </div>
          )}
          {message.id !== currentUser?.id && message.id !== referenceUser.id && (
            <div className={clsx(classes.customMessage)} key={message.date as unknown as string} onClick={() => {}}>
              {message.text}
            </div>
          )}
        </div>
      ))}
      <RevealPhotoComponent
        handleCloseDialog={handlePhotoCloseDialog}
        openDialog={openPhotoDialog}
        message={revealPhotoMessage}
        revealPhoto={revealPhotoCallback}
      />
      <RevealVideoComponent
        handleCloseDialog={handleVideoCloseDialog}
        openDialog={openVideoDialog}
        message={revealVideoMessage}
        revealVideo={revealVideoCallback}
      />
    </div>
  );
};

export default ChatMessages;
