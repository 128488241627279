class PersistentTabManager {
  static instance = null;
  openedWindow: Window | null = null;
  private url = '/chat';

  constructor(url: string) {
    if (!PersistentTabManager.instance) {
      this.url = url;
      PersistentTabManager.instance = this;
    }
    return PersistentTabManager.instance;
  }

  getInstance(url: string): PersistentTabManager {
    if (!PersistentTabManager.instance) {
      this.url = url;
      PersistentTabManager.instance = this;
    }
    return PersistentTabManager.instance;
  }

  openTab(newUrl: string) {
    this.url = newUrl;
    if (this.openedWindow && !this.openedWindow.closed) {
      // this.openedWindow.location.replace(newUrl);
      this.openedWindow.focus();
    } else {
      this.openedWindow = window.open(this.url);
    }
  }

  getOpenedWindow() {
    return this.openedWindow;
  }
}

export default PersistentTabManager;
