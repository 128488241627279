import { useAppSelector } from '@/app/hooks';
import { ISettings } from '@/types/user-profile.types';
import { Container } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 'calc(100% - 56px)',
    padding: '28px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  headTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '26px',
    paddingBottom: '33px',
    width: '100%',
    textAlign: 'center',
  },
  footerTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '26px',
    paddingBottom: '33px',
    width: '100%',
    textAlign: 'center',
    marginTop: '30px',
  },
  paragraphFirst: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
  },
  paragraph: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
    paddingLeft: '40px',
  },
  greyParagraph: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
  },
  headline: {
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '56px',
    color: '#000000',
    marginTop: '54px',
    '& > ::marker': {
      color: '#EA4160',
    },
  },
  listItem: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#808080',
  },
  breakline: {
    background: '#E7E6E6',
    height: '1px',
    width: '100%',
    marginBottom: '10px',
  },
  verySmallItem: {
    display: 'flex',
    padding: '6px 0px 6px 60px',
  },
  smallItem: {
    display: 'flex',
    padding: '12px 0px 12px 40px',
  },
  sml: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    marginRight: '6px',
    minWidth: '40px',
  },
  smallContent: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#808080',
    '& > b': {
      color: 'black',
    },
  },
  additionalText: {
    padding: '10px 40px 0 40px',
  },
}));

const TermsOfUse = () => {
  const classes = useStyles();
  const { companyAddress, companyName, companyCountry, supportEmail, siteUrl, siteName, rnCompany } =
    useAppSelector<ISettings>((state) => state.users.settings);

  return (
    <Container maxWidth={'xl'}>
      <div className={classes.container}>
        <div className={classes.headTitle}>Terms Of Use Agreement</div>
        <div className={classes.paragraphFirst}>
          The terms of the user agreement and any disputes arising in connection with them are regulated and resolved in
          accordance with the laws of {companyCountry}. If there are any inaccuracies or discrepancies in the meaning of
          the text during translation, the English version will be taken as a basis.
        </div>
        <ol start={1} className={classes.headline}>
          <li>Basic provisions</li>
        </ol>
        <div className={classes.paragraph}>
          If you do not agree with the terms of this agreement, refrain from using our service and apply to delete your
          account.
        </div>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>1.1</div>
          <div className={classes.smallContent}>
            {siteName} is a social network on the internet. The main goal of {siteName} is to create a space where
            people can find each other, communicate, meet and share personal information. {siteName} is located on the
            Internet at {siteUrl} (hereinafter referred to as service, chat, application). Your use of {siteName} means
            that communication should be friendly, just as it is important for us and you that our service remains a
            good and safe place of communication. Also, using our service, you agree to comply with all terms and
            conditions.
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>1.2</div>
          <div className={classes.smallContent}>
            If you have any questions about how {siteName} uses your personal information, read our privacy policy.
          </div>
        </div>
        <ol start={2} className={classes.headline}>
          <li>Rights to become a user of {siteName}</li>
        </ol>
        <div className={classes.paragraph}>Parties between whom the agreement is concluded</div>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>2.1</div>
          <div className={classes.smallContent}>
            This agreement is a legal document between the user and the company
            {companyName} registered Ciekuralna 2. Linija 30A, Riga, LV-1026
          </div>
        </div>
        <div className={classes.additionalText}>In order to become a user of our service, you must:</div>
        <div className={classes.verySmallItem}>
          <div className={classes.sml}>2.1.1</div>
          <div className={classes.smallContent}>
            You can register on the website and use its services and other applications of {siteName}{' '}
            <b>
              only if you are already 18 years old (or more, if another age of majority is established in your country
              of residence)
            </b>
            .
          </div>
        </div>
        <div className={classes.verySmallItem}>
          <div className={classes.sml}>2.1.2</div>
          <div className={classes.smallContent}>
            By accepting this agreement, you confirm that you have the right and the ability to bind yourself by the
            obligation to comply with the terms and conditions of this agreement. Using {siteName}, You agree not to
            violate the provisions of international law and the laws of your country, as well as this agreement. You are
            solely responsible for compliance with all existing local laws and regulations, as well as this agreement.
          </div>
        </div>
        <div className={classes.verySmallItem}>
          <div className={classes.sml}>2.1.3</div>
          <div className={classes.smallContent}>
            By accepting this agreement, you also confirm that you have never been found guilty by the court of scam,
            financial fraud, socially dangerous assault, physical harm or sexual harassment, in sexual crimes. You also
            confirm, that you are not a criminal, prosecuted for crimes of sexual nature, are not convicted of any crime
            of moral debauchery, and you are not being prosecuted due to the above specified crimes.
          </div>
        </div>
        <div className={classes.verySmallItem}>
          <div className={classes.sml}>2.1.4</div>
          <div className={classes.smallContent}>
            If we find out that you cannot be a member of the {siteName} service, your account will be deleted.
          </div>
        </div>
        <ol start={3} className={classes.headline}>
          <li>Term of the agreement validity</li>
        </ol>
        <div className={classes.paragraph}>Entry into force of the present agreement and its termination</div>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>3.1</div>
          <div className={classes.smallContent}>
            This agreement becomes valid from the moment when users first enter {siteName} web-site, regardless of their
            registration, so it should be read carefully. By taking any actions (including entering, using, registering
            or receiving services) on the website and services from {siteName}, you accept and agree not to violate the
            terms and conditions of this agreement.
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>3.2</div>
          <div className={classes.smallContent}>
            This agreement is valid for the entire period of using the {siteName} web site and its services, until the
            completion of all your actions on this resource, or the termination of your account (suspension or removal).
          </div>
        </div>

        <ol start={4} className={classes.headline}>
          <li>Using the web application and the content handling rules.</li>
        </ol>
        <div className={classes.paragraph}>Possibility to have accounts in other social networks</div>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>4.1</div>
          <div className={classes.smallContent}>
            The users have the right to use other social networks with the following rule: since having an account on{' '}
            {siteName}, he/she must monitor the status, photos and other materials in other social networks that could
            remain after previous relationship or last marriage. Otherwise, we will take this as the deception on user’s
            part in relation to our service, which will entail the sanctions described in sec. 4.7.
          </div>
        </div>
        <div className={classes.additionalText}>Publishing the materials on {siteName}</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>4.2</div>
          <div className={classes.smallContent}>
            On {siteName} users are able to post any materials, including infographics, photographs, videos, texts and
            other materials (hereinafter "Content"), which are not subject to third-party copyrights. The posted
            materials should not contradict the terms of this agreement, the norms of international and local
            legislation. Users must not upload or send the content, which:
            <ul>
              <li>
                contains obscene language or insults, which can offend the interlocutors of your clients and the
                administration of the service;
              </li>
              <li>is obscene, pornographic or otherwise insulting another person;</li>
              <li>is offensive, degrading or threatening, or encourages the racism, sexism, hatred and intolerance;</li>
              <li>
                calls for illegal activities and illegal actions, including terrorism, race hatred, extortion of
                material goods and financial resources, or the publication of such materials, which is the criminal
                offense;
              </li>
              <li>is slanderous;</li>
              <li>
                related to the commercial activities (including, without limitation, sales, advertising, links to other
                sites or paid phone numbers) or is spam;
              </li>
              <li>is related to a commercial or other kind of secret;</li>
              <li>
                contains source codes or links to spyware, adware, viruses, corrupted files, worm programs or other
                malicious code designed to disrupt, damage, restrict or in any way interrupt the operation of any
                software, hardware, telecommunications, networks, servers; "Trojan horses" designed to damage,
                intervene, unlawfully intercept or obtain any information (including personal information), whether
                through {siteName} or without its use;
              </li>
              <li>
                material that, in itself, or the fact of placement of which violates the rights of a third party
                (including, without limitation, intellectual rights and confidentiality);
              </li>
              <li>
                is an image of another person if the Content was created/distributed without the consent of that person.
              </li>
              <li>
                Prohibit the promotion or facilitation of human trafficking, sex trafficking, or any form of abuse.
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.additionalText}>Rules regarding the Content of user`s personal information</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>4.3</div>
          <div className={classes.smallContent}>
            <div>
              You are not allowed to publish or distribute (in any way) anybody's personal, contact or bank information
              on your page hosted on our service (the "Profile"), or in any other place on our service (for example:
              names, home and postal addresses, phone numbers, e-mail addresses, URLs, bank card numbers).
            </div>
            <div>
              Your profile must contain only true information in accordance with all sections and graphs present in the
              form for filling in the application for registration or placement of content on our service.
            </div>
            <div>
              On the website {siteName} you are personally forbidden, bypassing the rules existing on the service, to
              disclose your personal contact information to the other party of opposite sex.
            </div>
            <div>
              We have the right to disclose your personal information to a third party in the event that we receive a
              complaint from him that the Content uploaded by you into our web application violates the copyright of a
              third party, its right to confidentiality or any other legislative act.
            </div>
          </div>
        </div>
        <div className={classes.additionalText}>Use of personal information of other users</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>4.4</div>
          <div className={classes.smallContent}>
            <div>
              You are not allowed to use the personal, contact, banking or any other information of other {siteName}{' '}
              users, even if this information was transferred to you on their free will. You are not allowed to use any
              information about other users, which is beyond the information sufficient for acquaintance and maintaining
              a dialogue on our service.
            </div>
            <div>
              The use of such information for commercial purposes, for sending spam, threats, extortion, harassment,
              blackmail or other illegal purposes is excluded.
            </div>
            <div>
              Providing information, such as login and password, to other people to log into your account under your
              name
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>4.5</div>
          <div className={classes.smallContent}>
            <div>
              Only You have the right to access Your account on {siteName}. You must not transfer your login and
              password to anyone, as this compromises the security of your personal information and Content uploaded by
              you to {siteName}. You are responsible for the safety of your login and password.
            </div>
            <div>
              In case of such violation, {siteName} is not responsible for unauthorized access to Your profile.
              Unauthorized access to the account leads to its full and irreversible deactivation.
            </div>
            <div>
              If You suspect that someone has access Your account, You should immediately inform us about this through a
              feedback form or in other ways. Also, You have to change Your login and password.
            </div>
            <div>
              {siteName} reserves the right to delete Your account if the rules of login and password are violated by
              You.
            </div>
          </div>
        </div>
        <div className={classes.additionalText}>The ability to see Your content published on {siteName}</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>4.6</div>
          <div className={classes.smallContent}>
            <div>
              When You upload Content to Your profile or the site {siteName}, it can be seen by all users. If You do not
              want other users to see Your Content, do not post it to {siteName}. If someone, based on Your personal
              information and/or personal content, created an account at our service, You have the right to inform our
              service about it through feedback with the current and dated video-message, attached to Your message (with
              the identity document presented in the camera frame), and we will deactivate this account within 14
              calendar days.
            </div>
            <div>
              We reserve the right, without or with notice, to delete, edit, restrict or block access to Your account,
              and also to perform these actions with respect to content placed in Your profile, if we have reasonable
              grounds to believe that Your profile contains content that violates the provisions of this agreement or
              international law.
            </div>
            <div>
              We reserve the right to publish Your content with or without notice to advertise our service and
              third-party services (except for private photos, e-mail addresses, contact phone number and passport
              data), in the public part of our service and in mailing letters to users, as well as on landings or
              advertising banners, or sliders.
            </div>
            <div>
              We have the right to verify Your Content and information (including personal data) posted on {siteName}{' '}
              for authenticity and validity, by verifying the email address, contact phone number and uploaded
              video-confirmation (subject to the requirements described in 4.2 and 4.3), for compliance with this
              agreement and international or local law.
            </div>
            <div>
              Moreover, we have the right to track Your personal Content and information (including personal data) for
              the entire period of Your profile's existence on our service, and also to track how and for what purpose
              You use {siteName}.
            </div>
          </div>
        </div>
        <div className={classes.additionalText}>Liability for violations of the rules for material placement</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>4.7</div>
          <div className={classes.smallContent}>
            <div>
              We are not responsible for the materials, personal data or any other information that You post on{' '}
              {siteName} through the site or its services in person or in the case when these actions were performed by
              another person from Your account.
            </div>
            <div>
              Please use common sense when placing the Content on {siteName}, as You are fully responsible for it.
              Otherwise, Your account will be permanently blocked, and materials about illegal activities will be
              forwarded to Interpol and law enforcement agencies of Your country for further investigation.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>4.8</div>
          <div className={classes.smallContent}>
            <div>
              In order to use {siteName}, You can log in using your Facebook login. If You log in to the system in this
              way, You provide us with access and permission to use certain information on Your Facebook account, in
              particular, Your public Facebook profile and information about friends on Facebook that are shared with
              other {siteName} users. For more information about the data we received from You, as well as how they are
              used, see our Privacy Policy.
            </div>
            <div>
              You are responsible for maintaining the confidentiality of Your credentials that You use to register with{' '}
              {siteName}. In doing so, You are also solely responsible for all actions that occur with the use of these
              accounting data. If You think that someone has gained access to Your account, immediately write to{' '}
              {supportEmail}
            </div>
          </div>
        </div>

        <ol start={5} className={classes.headline}>
          <li>Property rights to the Content</li>
        </ol>
        <div className={classes.paragraph}>Property rights to the Content after it is uploaded on {siteName}</div>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>5.1</div>
          <div className={classes.smallContent}>
            <div>
              You do not lose the right to the Content uploaded by You, provided that you are its copyright holder and
              you can approve it.
            </div>
            <div>
              Please note that when uploading the Content to {siteName}, You thereby confirm that You have the right to
              do so. You automatically transfer to us a non-exclusive, royalty-free, permanent, global license to use
              this Content in any way (including, without limitation, editing, copying, altering, translating,
              reformatting, using in other materials, manufacturing derivative materials, advertising, distribution and
              publication of Content for a wide range of users, in whole or in part, in any format and on any medium
              that already exists or will be developed in the future, excluding the passport data of you as a citizen of
              the country, private photos and e-mail address, as well as personal contact phone number).
            </div>
            <div>
              We can transfer the above license to affiliated companies and assignees without any agreement with you.
            </div>
            <div>
              By uploading the Content to {siteName}, you acknowledge that you are the exclusive author and owner of
              this Content and agree to waive the author's moral rights to this Content (including without limitation
              the right to be further considered an author).
            </div>
          </div>
        </div>
        <div className={classes.additionalText}>Ownership of all other Content on {siteName} website</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>5.2</div>
          <div className={classes.smallContent}>
            <div>
              All text, graphics, the user interface, trademarks, logos, sounds and decorating are property of{' '}
              {siteName} web site, are controlled or are licensed by us, therefore are protected by the property right,
              the trademark and other intellectual property rights.
            </div>
          </div>
        </div>
        <div className={classes.additionalText}>Use of the content not belonging to you</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>5.2</div>
          <div className={classes.smallContent}>
            <div>
              You cannot use the Content of {siteName}, that you do not have any rights to. You agree that by Your
              actions You do not violate the rights of third parties. It means that You will not copy, change,
              distribute, publish or sell Content of {siteName} (except Your own). In case of violating section 5 of
              this agreement, You bear responsibility according to section 4.7.
            </div>
          </div>
        </div>
        <ol start={6} className={classes.headline}>
          <li>The Information we collect</li>
        </ol>
        <div className={classes.paragraph}>
          Obviously, we are not able to help You make new acquaintances if we do not have some information about You,
          such as basic profile data and characteristics of people with whom You want to meet. We have the right to
          collect information that is generated when you use our services, for example, access logs. If You want to get
          additional information, we will tell about it more detail below.
        </div>
        <div className={classes.breakline}></div>
        <div className={classes.additionalText}>The Information You provide us with</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>6.1</div>
          <div className={classes.smallContent}>
            When using our services, You provide us with certain information. It includes the following:
          </div>
        </div>
        <div className={classes.verySmallItem}>
          <div className={classes.sml}>6.1.1</div>
          <div className={classes.smallContent}>
            When You create an account, You provide us with your username and password, as well as basic information
            necessary for the service: your gender, date of birth, a description of Your character, information about
            lifestyle, interests and other information, as well as photos and video. In order to verify Your account and
            confirm the age of majority, You provide us with Your phone number, e-mail address. To add specific content,
            such as images or videos, You can give us access to Your camera or photo album. Some of the information You
            can give us is considered special or confidential in some countries, for example, Your racial or ethnic
            origin, sexual orientation and religious beliefs. By submitting this information to us, You agree to its
            processing by us.
          </div>
        </div>
        <div className={classes.verySmallItem}>
          <div className={classes.sml}>6.1.2</div>
          <div className={classes.smallContent}>
            During participation in our contests or events, our site collects information provided by You during
            registration or upon entering the system.
          </div>
        </div>
        <div className={classes.verySmallItem}>
          <div className={classes.sml}>6.1.3</div>
          <div className={classes.smallContent}>
            When You contact our customer support center, we collect the information You provide to us during chats and
            conversation. Sometimes we record or track conversations for educational purposes and in order to provide
            high quality services.
          </div>
        </div>
        <div className={classes.verySmallItem}>
          <div className={classes.sml}>6.1.4</div>
          <div className={classes.smallContent}>
            If You have a desire to contact someone outside the service, as well as to send Your e-mail to the person
            who requested it from You, we will collect Your information and fulfill Your request.
          </div>
        </div>
        <div className={classes.verySmallItem}>
          <div className={classes.sml}>6.1.5</div>
          <div className={classes.smallContent}>
            To ensure the functioning of the services, we also process Your communication in a chat with other users and
            the Content that You publish.
          </div>
        </div>
        <div className={classes.additionalText}>Information collected when using our services</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>6.2</div>
          <div className={classes.smallContent}>
            In addition to the information that You provide to us directly, we also receive information about You from
            others, including:
          </div>
        </div>
        <div className={classes.verySmallItem}>
          <div className={classes.sml}>6.2.1</div>
          <div className={classes.smallContent}>
            <b>Other users.</b> Other users may provide information about You when they use our services. For example,
            we may collect information about You from other users, if they contact us to provide it.
          </div>
        </div>
        <div className={classes.verySmallItem}>
          <div className={classes.sml}>6.2.2</div>
          <div className={classes.smallContent}>
            <b>Other partners.</b> We may receive information about You from our partners, for example, the one that is
            published on partner sites and platforms (in this case, they can transmit information about the success of
            an advertising campaign).
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>6.3</div>
          <div className={classes.smallContent}>Information collected when using our services</div>
        </div>
        <div className={classes.verySmallItem}>
          <div className={classes.sml}>6.3.1</div>
          <div className={classes.smallContent}>
            <b>Usage information.</b> We collect information about Your usage of our services, for example, how You use
            it (date and time of login, functions You use, search, clicks and pages that are displayed for You,
            addresses of pages from which You were redirected, the advertisement You click on and how You communicate
            with other users (for example, the users with whom You communicate and interact, the time and date of Your
            contact, the number of messages You receive and send).
          </div>
        </div>
        <div className={classes.verySmallItem}>
          <div className={classes.sml}>6.3.2</div>
          <div className={classes.smallContent}>
            <b>Information about the device.</b> We collect information from Your devices with the help of which You use
            our services, including: information about hardware and software, such as IP address, device identifier and
            its type, browser type, version and language, operating system, time zones, identifiers related to cookies
            or other technologies that can identify your personal device or browser (for example, IMEI / UDID and MAC
            address);
          </div>
        </div>
        <div className={classes.verySmallItem}>
          <div className={classes.sml}>6.3.3</div>
          <div className={classes.smallContent}>
            Information about Your wireless or mobile Internet connection, such as Your service provider and signal
            strength;
          </div>
        </div>
        <div className={classes.verySmallItem}>
          <div className={classes.sml}>6.3.4</div>
          <div className={classes.smallContent}>
            Information about the sensors of Your device, such as accelerometers, gyroscopes and compasses.
          </div>
        </div>
        <div className={classes.verySmallItem}>
          <div className={classes.sml}>6.3.3</div>
          <div className={classes.smallContent}>
            <b>Other information provided with Your Consent.</b> With Your own permission, we may collect information
            about Your exact location (latitude and longitude) using various means, depending on which services and
            device You use, including GPS, Bluetooth or Wi-Fi connections. Your location may be collected in the
            background, even when You are not using our services, if You have given us permission to collect this
            information. If You do not give us permission to receive information about Your location, we will not
            collect it. Similarly, with Your Consent, we can collect Your photos and videos (for example, if you want to
            publish a photo, video, or live broadcast on one of our services).
          </div>
        </div>
        <ol start={7} className={classes.headline}>
          <li>The main services of your account in the web application {siteName}</li>
        </ol>
        <div className={classes.paragraph}>Gifts receiving and sending at {siteName}</div>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>7.1</div>
          <div className={classes.smallContent}>
            <div>
              Gift section. {siteName} gives You the chance to receive gifts from interlocutors through {siteName}{' '}
              service and send gifts to other users.
            </div>
          </div>
        </div>
        <div className={classes.additionalText}>Information collected when using our services</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>7.2</div>
          <div className={classes.smallContent}>
            <div>
              You may not resend gifts received through {siteName} from users to other users. Confirmation period for
              intention of receiving a gift sent via {siteName} by a user. You have 5 days to confirm or cancel the
              application for a gift from the courier in Your region/country, sent by Your interlocutor through{' '}
              {siteName}. After confirmation, Your gift will be delivered by international post within 10 calendar days.
            </div>
          </div>
        </div>
        <div className={classes.additionalText}>Virtual gifts receiving and sending at {siteName}</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>7.3</div>
          <div className={classes.smallContent}>
            <div>
              Gift section. {siteName} gives You the chance to receive virtual gifts from interlocutors through{' '}
              {siteName} service and send virtual gifts to other users.
            </div>
          </div>
        </div>
        <div className={classes.additionalText}>Correspondence Addressees</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>7.4</div>
          <div className={classes.smallContent}>
            <div>
              You are free to write to any registered user of the opposite sex (regardless of whether he has online
              status) a letter of at least 200 and no more than 5000 characters, only in English. You can attach to a
              letter no more than 3 pieces of Content (photos and videos from Your public and private gallery).
            </div>
            <div>
              It is forbidden to send any links, addresses of third-party sites, e-mail addresses, contact phone numbers
              in letters It is also prohibited to use in the correspondence any materials, including text that
              contradicts section 4 of this agreement.
            </div>
          </div>
        </div>
        <div className={classes.additionalText}>Chat availability on {siteName}</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>7.5</div>
          <div className={classes.smallContent}>
            <div>
              {siteName} provides text, video chats. The video chat cannot be started if You have no webcam or it is
              disconnected, and also if interlocutor rejected the request for the video chat and passed into the text
              chat.
            </div>
          </div>
        </div>
        <div className={classes.additionalText}>The ability to invite users to chat</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>7.6</div>
          <div className={classes.smallContent}>
            <div>You are free to invite all registered users of opposite sex, who are online.</div>
          </div>
        </div>
        <div className={classes.additionalText}>Chatting with a user on {siteName}</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>7.7</div>
          <div className={classes.smallContent}>
            <div>
              You need to find a suitable interlocutor among all registered users with the online status and send him
              the first message through the chat room (hereinafter “Invite"). Your interlocutor begins to communicate
              with you or rejects Your invite. Your interlocutor can also send you an invite, which is valid for 120
              seconds.
            </div>
            <div>Text or video chat is interrupted after:</div>
            <ul>
              <li>pressing the button to end one of the interlocutors;</li>
              <li>
                with a zero balance of the virtual wallet after the expiration of credits in it from one of the
                interlocutors;
              </li>
              <li>disconnection of one of the interlocutors.</li>
            </ul>
          </div>
        </div>
        <div className={classes.additionalText}>Necessary facilities for high-quality chat</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>7.8</div>
          <div className={classes.smallContent}>
            <div>
              Your computer and mobile device must meet the recommended system requirements of {siteName} and its
              services. Also, You need a good Internet connection through a dedicated Internet network with a connection
              speed of at least 50 Mb/s and a ping of not more than 100ms, or a qualitative connection via a mobile
              operator. It is also not recommended to use outdated web cameras with a resolution of less than 720p.
            </div>
          </div>
        </div>
        <div className={classes.additionalText}>Sharing Your personal contact information with interlocutors</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>7.9</div>
          <div className={classes.smallContent}>
            <div>
              To share Your contact information, Your interlocutor must submit an application for receiving Your
              contacts through the {siteName} service. In order for Your interlocutor to send such a request, You must
              be in contact with each other and have a certain time of communication through correspondence and chat.
            </div>
            <div>
              After You accept such a request, You must re-perform the actions described in clause 4.3 of this
              agreement. After all the procedures have been completed by You, our service will disclose contact
              information to Your interlocutor.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>7.10</div>
          <div className={classes.smallContent}>
            <div>
              In case of section 7 provisions violation of this agreement, You are solely responsible for Your actions.
              In this case, your account may be permanently or temporarily blocked, and materials about illegal
              activities can be transferred to Interpol and law enforcement agencies of Your country for further
              investigation.
            </div>
          </div>
        </div>
        <ol start={8} className={classes.headline}>
          <li>Access to the web application {siteName}</li>
        </ol>
        <div className={classes.paragraph}>Guaranteed integrity and long-term of the service {siteName}</div>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>8.1</div>
          <div className={classes.smallContent}>
            Unfortunately, we cannot give such guarantee, as from time to time we should carry out repair work, so the
            web application can meet unexpected circumstances. Therefore, the services of {siteName} are provided “as
            is”. We do not give any guarantees regarding the quality, accuracy, functionality, availability and
            efficiency of {siteName} and reserve the right to suspend, deactivate, supplement or modify the
            functionality, design or services of {siteName}, without warning and occurrence of any obligations in
            relation to You.
          </div>
        </div>
        <div className={classes.additionalText}>Access to the service via mobile devices</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>8.2</div>
          <div className={classes.smallContent}>
            <div>
              You can easily login to our service with the help of Your mobile device. Your task is to do everything
              that depends on You, to ensure Your access to {siteName} (including, but not limited to, payments to the
              Internet provider and mobile phone provider, as well as any other payments related to access).
            </div>
            <div>
              We are not responsible in cases where the functionality and / or appearance of the {siteName} services are
              violated, or limited, or displayed in part as a result of Internet connection errors, accidents on the
              transmission lines, or accidents at cellular towers, or as the result of other malfunctions of Your
              equipment or equipment of Your providers, regardless of the device or applications that You use or will
              use (existing or which will be created in the future) to enter our services.
            </div>
            <div>
              By connecting to {siteName} or by agreeing to receive messages and notifications from {siteName} on Your
              mobile phone and/or any other device, you are aware that your Internet provider or mobile phone provider
              may charge You an additional fee. That is not our responsibility.
            </div>
            <div>I did not log in, and certain content {siteName} is inaccessible. Why?</div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>8.3</div>
          <div className={classes.smallContent}>
            <div>
              Unregistered or unauthorized users can only see the public portion of the Content. They do not have a
              profile and there is no way to upload Content.
            </div>
            <div>
              We are not responsible in cases where the functionality and / or appearance of the {siteName} services are
              violated, or limited, or displayed in part as a result of Internet connection errors, accidents on the
              transmission lines, or accidents at cellular towers, or as the result of other malfunctions of Your
              equipment or equipment of Your providers, regardless of the device or applications that You use or will
              use (existing or which will be created in the future) to enter our services.
            </div>
            <div>
              We can sometimes modify or supplement publicly available materials at our own discretion and without prior
              notice.
            </div>
          </div>
        </div>
        <ol start={9} className={classes.headline}>
          <li>Termination of access</li>
        </ol>
        <div className={classes.paragraph}>Blocking of Your Profile</div>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>9.1</div>
          <div className={classes.smallContent}>
            <div>
              If we have reasonable grounds to believe that You have violated the provisions of this agreement and/or
              the rules of international and/or local law, we reserve the right at our own discretion, without incurring
              any obligation for You (financial or other):
            </div>
            <ul>
              <li>
                permanently or temporarily block Your account (if it exists), and we may revoke Your right to use the{' '}
                {siteName} and/or its services in the future;
              </li>
              <li>
                use any operational, technological, legal and other means to ensure compliance with the terms
                (including, without limitation, the blocking of certain IP addresses).
              </li>
            </ul>
            <div>
              We will try (but we do not have to) to notify You if Your access to {siteName} and/or Your profile is
              subject to suspension or termination.
            </div>
          </div>
        </div>
        <ol start={10} className={classes.headline}>
          <li>Account removal</li>
        </ol>
        <div className={classes.paragraph}>Deleting a profile from {siteName} site</div>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>10.1</div>
          <div className={classes.smallContent}>
            <div>
              You can easily delete Your profile at any time, in case if You are registered on our website. For this You
              must provide an message to support {siteName} requesting the removal of the profile from our service. We
              will remove the account to the basket within 14 calendar days.
            </div>

            <div>
              The above data should be sent via the feedback form, through the cabinet of Your account or through the
              guest entry of {siteName}. If You forgot Your login and password for Your account, please specify the
              e-mail address and id number of Your account on the {siteName} service.
            </div>
            <div>
              We save Your profile in case You decide to restore it. Many users deactivate their accounts for temporary
              reasons and accordingly expect that we will save their information by the time they return to {siteName}.
              Therefore, You have the opportunity, through an application, to restore the account and profile in full
              for an indefinite period after they have been deactivated. After deactivating Your account, we reserve the
              right to remove any Content uploaded by You to {siteName}. For more information, please read our Privacy
              Policy.
            </div>
            <div>
              The Content that You uploaded to our service, except Your profile (for example, comments or messages in
              correspondence with Your interlocutors), can be displayed on the website after deactivating Your profile.
            </div>
          </div>
        </div>
        <ol start={11} className={classes.headline}>
          <li>Abuse and complaints</li>
        </ol>
        <div className={classes.paragraph}>Complaints about the terms of use violation on the site</div>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>11.1</div>
          <div className={classes.smallContent}>
            <div>
              You can contact us through the feedback page in order to report a violation or to complain about Content
              placed on {siteName}, and briefly state the essence of the violation.
            </div>
            <div>We always try to help in solving any problem that You may face when using our service.</div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>11.2</div>
          <div className={classes.smallContent}>
            <div>If you have any complains you need to contact us though our email or though the "contact us" form</div>
          </div>
        </div>
        <ol start={12} className={classes.headline}>
          <li>Privacy policy</li>
        </ol>
        <div className={classes.paragraph}>
          IF YOU DO NOT AGREE WITH OUR PRIVACY POLICY, ABANDON THE USE OF THE SITE AND SUBMIT YOUR APPLICATION FOR
          REMOVING YOUR ACCOUNT.
        </div>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>12.1</div>
          <div className={classes.smallContent}>
            <div>
              We know that the use of someone else's personal data is a big problem for social networking users, so we
              at {siteName} developed this Privacy Policy ("Policy") for You to know how we use personal information
              ("personal data" or "personal information") of all users of the service.
            </div>
            <div>
              This Policy is effective every time You use www.{siteUrl} {siteName} or "browser application") and/or its
              services, and it acts in conjunction with all the rules of this agreement. By using {siteName} as a user,
              You agree to the Privacy Policy and give permission for the collection, storage, processing, use and
              distribution of personal information in accordance with this Policy.
            </div>
            <div>
              {siteName} keeps the right to change the Privacy Policy at any time without prior notice. We ask You
              periodically review this section in order to stay informed of how we use Your information.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>12.2</div>
          <div className={classes.smallContent}>
            <div>
              We can use any of Your information or materials that You publish on {siteName} for publicity purposes on
              the websites and/or applications of our partners. We consider that it will allow us to improve our website
              and to enhance Your online experience to satisfy Your requirements.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>12.3</div>
          <div className={classes.smallContent}>
            <div>
              We use Your personal and other information as the user for settlement of disputes, elimination of
              inaccuracies and ensuring compliance with provisions of this partner agreement.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>12.4</div>
          <div className={classes.smallContent}>
            <div>
              Remember that it is forbidden to use personal or other information about other users of our services for
              illegal purposes, commercial purposes, spam, blackmail, threats and insults.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>12.5</div>
          <div className={classes.smallContent}>
            <div>
              We collect additional information on You as the user by allocation of cookies on the computer or the
              mobile phone. Cookies is a piece of text, stored on a computer or mobile device by Your web browser.
              Cookies store information about a visit to a website or partner administrative panel, learn the
              preferences of users at each visit to {siteName} and provide the functionality of the website, allowing us
              to maintain the existing functionality and develop improvements for CPA partners and users of {siteName}.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>12.6</div>
          <div className={classes.smallContent}>
            <div>
              When You visit {siteName} we use cookies for session saving. Cookies with a session are deleted when the
              browser is closed. We also store persistent cookies (also known as "local storage devices") on users'
              computers, so that the {siteName} service remembers users and makes the user authorization process faster
              and easier. We can use persistent cookies on Your mobile device, mainly for security purposes, for
              example, to prevent fishing (actions to intercept logins and passwords), for protection against scammers,
              unauthorized logging attempts and to help You access in case when Your account was hacked. We do not use
              the information after You log out.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>12.7</div>
          <div className={classes.smallContent}>
            <div>
              In the settings of the browser or mobile device, You can block the saving of cookies and the operation of
              local storage devices, but with such blocking You are not able to fully use the functions of the{' '}
              {siteName} services.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>12.8</div>
          <div className={classes.smallContent}>
            <div>
              We take reasonable precaution measures against possible violations of safety of our application, website,
              databases of users, however any application, the website or data transmission via the Internet are not
              completely safe and we cannot guarantee that illegal access, cracking, data loss or other violations are
              excluded completely. We invoke You to take necessary measures for ensuring confidentiality of the personal
              data, including login, and also we recommend to log out of the account after use and to clear the saved
              information from the browser.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>12.9</div>
          <div className={classes.smallContent}>
            <div>
              We cannot guarantee the security of Your personal data at the time when they are being transmitted to our
              website. Any data transfer takes place at Your own risk. Once we receive Your information, we have
              security features that help prevent unauthorized access.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>12.10</div>
          <div className={classes.smallContent}>
            <div>
              If You have not logged in to Your profile within 6 months, we can deactivate it during our data cleaning
              process.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>12.11</div>
          <div className={classes.smallContent}>
            <div>
              Your {siteName} website user account, with all content, is not transferable, and all rights to it will be
              revoked in the event of Your death.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>12.12</div>
          <div className={classes.smallContent}>
            <div>
              You are solely responsible for violating the provisions of section 12 of this agreement. In this case,
              Your account may be permanently or temporarily blocked, and materials about illegal activities can be
              transferred to Interpol and law enforcement agencies of Your country for further investigation.
            </div>
          </div>
        </div>
        <ol start={13} className={classes.headline}>
          <li>Links</li>
        </ol>
        <div className={classes.paragraph}>Related links that are available in our web app {siteName}</div>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>13.1</div>
          <div className={classes.smallContent}>
            <div>
              {siteName} and/or its services may contain links to other sites, resources with the ability to make
              purchases, provided by third parties. These links are provided to Your reference. If You follow these
              links, You can be redirected to third-party’s sites. Third-party sites have their own terms of service and
              privacy policy, which may differ from ours. Displaying links to third-party sites does not imply our
              support of third-party content, sites or resources.
            </div>
            <div>
              Please note that we have no control and no liability for the content of third-party sites or their
              resources, including (but not limited to) compliance by any third party with any applicable laws or
              regulations or provisions of this agreement.
            </div>
          </div>
        </div>
        <ol start={14} className={classes.headline}>
          <li>Warranty disclaimer and limitation of liability</li>
        </ol>
        <div className={classes.paragraph}>Responsibility for non-compliance with the behavior rules on the site</div>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>14.1</div>
          <div className={classes.smallContent}>
            <div>
              If Your actions abuse other users, in this case You and only You are responsible for the consequences. We
              disclaim liability for Your behavior and actions, or the behavior and actions of any other user of{' '}
              {siteName}, and we also disclaim responsibility for the Content uploaded by You or any other users.
            </div>
          </div>
        </div>
        <div className={classes.additionalText}>Responsibility of {siteName} website and / or its services</div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>14.2</div>
          <div className={classes.smallContent}>
            <div>
              Nothing in this agreement, regardless of other provisions, can be accepted to hold our service liable for
              Your injury to any degree, including those that could have caused death, as a result of Your careless
              actions and / or because of inattention, when using and/or resulting from the use of the {siteName} web
              site and/or its services. We exclude any of our responsibilities under the applicable international and/or
              local laws (regulations), which may result from Your careless actions and / or inattention, use and/or use
              of the {siteName} website and/or its services.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>14.3</div>
          <div className={classes.smallContent}>
            <div>
              In the maximum degree, stipulated by the legislation, {siteName} excludes all conditions, warranties,
              guarantees and other conditions which could be presumed by legal acts, the general law or the right of
              justice, and any responsibility for damages, suffered by You as a result of use {siteName}, services of
              the website, including responsibility in claims, fines, requirements, losses, obligations, expenses of any
              character and the type: straight, indirect, accidental, punitive or indirect losses (including
              negligence); impossibility of use, data loss, the damage caused to the computer by influence electronic
              viruses, loss of the income or profit, expenditure of office time, violation of terms of the contract or
              claims of the third parties or other losses of any kind and character even if {siteName} was warned about
              the possibility of such damage or losses arising from or in connection with use the website {siteName}{' '}
              and/or its services or use of the partner administrative panel.
            </div>
            <div>
              This limitation of liability extends, but is not limited to, to the transfer any devices or viruses, which
              can infect Your equipment, cause a malfunction of mechanical or electrical equipment or communication
              lines, telephone equipment or other communication problems (for example, you cannot access the Internet),
              unauthorized access, theft, body injures (except those that were caused by our negligence), material
              damage, operator's mistakes, strikes or force majeure, including but not limited to liability for loss of
              profits, loss of income or contracts, loss of business, loss of expected savings, loss of reputation, loss
              of data, loss of office time and any other loss or damage of any kind.
            </div>
          </div>
        </div>
        <ol start={15} className={classes.headline}>
          <li>Warranty against damage</li>
        </ol>
        <div className={classes.paragraph}>
          Bringing to court for non-compliance with the behavior rules on the site
        </div>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>15.1</div>
          <div className={classes.smallContent}>
            <div>
              If our service or company is litigated as a result of how You use the {siteName} web site or its services,
              we have the right to defend or settle the relevant claim as we consider it necessary. If we ask this, you
              have fully cooperate with us in accordance with the requirements of the relevant lawsuit.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>15.2</div>
          <div className={classes.smallContent}>
            <div>
              You are agree to protect us and our service, our employees, directors, agents, representatives, partners
              from any third party claims, damages (direct and/or indirect), litigation, claims, losses, expenses
              (including reasonable attorney's fees), incurred by us and arising as a result or in connection with Your
              actions on {siteName} and/or its services, or the Content that You uploaded to {siteName} and/or its
              services, or by Your behavior, other than as in accordance with the provisions of this Agreement or in
              accordance with applicable law. {siteName} reserves the exclusive right to settle and pay any claims or
              the bases for the claims which are turned against us without Your prior consent. If necessary, we will be
              forced to recover from You the damage caused to us.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>15.3</div>
          <div className={classes.smallContent}>
            <div>
              When violating the provisions of section 15 of this agreement, You are solely responsible for Your
              actions. In this case, Your account may be permanently or temporarily blocked, and materials about illegal
              activities can be transferred to Interpol and law enforcement agencies of Your country for further
              investigation.
            </div>
          </div>
        </div>
        <ol start={16} className={classes.headline}>
          <li>Trade secret</li>
        </ol>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>16.1</div>
          <div className={classes.smallContent}>
            <div>
              The content of this agreement, of each section and paragraph thereof, as well as any information about
              this document, is included in the list of company documents that are a trade secret.
            </div>
            <div>The right to become familiarized with this document has been transferred only to You. </div>
            <div>
              You are forbidden to disclose these data to any other third party (both individuals or legal entity),
              without voluntary consent of the authorized representative of the {siteName} company (which fact You will
              have to confirm afterwards). You bear complete legal responsibility according to the existing
              international and/or local legislation for violation of this provision.
            </div>
            <div>
              In case that the authorized state law enforcement or judicial authorities will require, in the prescribed
              form, to disclose any information regarding this document from You, You are required to notify (through
              the feedback form on the site or through telephone or through correspondence) the authorized
              representative of {siteName} and obtain his consent to these actions (the fact of which You will have to
              confirm subsequently).
            </div>
            <div>
              We warn that {siteName} will be compelled to protect this provision by all legal means, and may also
              permanently or temporarily block Your account.
            </div>
          </div>
        </div>
        <ol start={17} className={classes.headline}>
          <li>Disputes</li>
        </ol>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>17.1</div>
          <div className={classes.smallContent}>
            <div>
              You acknowledge that all transactions involving Your profile on our web service and applications,
              including the purchase of goods or services, occur territorially in {companyAddress}. You agree that any
              disputes or claims, in any way connected with Your visit or use of the website and/or its services, or
              associated with the use of any products and/or services of the company {siteName}, You will first try to
              resolve these disputes informally by contacting us. If the dispute is not resolved within 30 days after
              Your appeal, You or our company must resolve any claims, related to these terms, services or web service
              of our company, by appealing to the arbitration court, located territorially at the location of our
              company. The decision of this court will be final and binding, unless You have the right to assert Your
              claims in a small lawsuit court.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>17.2</div>
          <div className={classes.smallContent}>
            <div>
              Prohibition of group suits. You have the right to regulate the disputes with us individually but You have
              no right to take any legal actions both joint or group.
            </div>
          </div>
        </div>
        <ol start={18} className={classes.headline}>
          <li>Other terms</li>
        </ol>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>18.1</div>
          <div className={classes.smallContent}>
            <div>
              {siteName} takes all possible measures for the general availability, reliability, completeness of the
              information contained on the {siteName} website and its service, and also undertakes to provide timely
              information on an "as-is" basis. {siteName} does not give any direct or indirect guarantees and
              obligations in relation to information which is contained on {siteName}. You assume the responsibility for
              using {siteName} and its services, and any materials available in the application at Your own risk.{' '}
              {siteName} does not bear any responsibility for the loss of data during the transfer and use, as well as
              for the information of incorrect (Content) provided by users and/or partners. You assume the
              responsibility for all possible precautions to avoid infecting computer with viruses while using the{' '}
              {siteName} web site and its services.
            </div>
            <div>
              By this agreement, You accept that during the use of the application errors and failures that cannot be
              corrected can occur, and that {siteName}, its services or the server can be attacked by viruses, spyware,
              Trojans or other malicious software. {siteName} does not bear responsibility for damage of technical
              equipment or the software, for any damage or harm of property character, including the claim concerning
              compensation of any damage caused in connection with any error, omission, virus, unauthorized access,
              fraud, cracking, delays in operation of application. {siteName} does not bear responsibility for any
              damage caused in connection with any malfunctions on the Internet or any other technical failures.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>18.2</div>
          <div className={classes.smallContent}>
            <div>
              We reserve the right at own discretion and without any obligation to adjust and make changes to this
              agreement at any time.
            </div>
            <div>
              All changes will be published on this page (at the end of this agreement) with the date of entry into
              force indication. In some cases, we can e-mail You about changes. You undertake to periodically review
              this page to avoid misunderstanding due to eventual changes.
            </div>
            <div>
              If you do not accept any of the changes to these terms in this agreement, you must immediately stop using{' '}
              {siteName} and apply for deactivation of your account. If you continue to use the website {siteName}, its
              services after changes in this agreement, you automatically accept the updated conditions.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>18.3</div>
          <div className={classes.smallContent}>
            <div>
              If, for any reason, any of the terms of this agreement is recognized by the court of the relevant
              jurisdiction as unlawful or legally invalid, this provision will be removed from the agreement or edited
              without any changes to the other mandatory conditions and their legal effect.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>18.4</div>
          <div className={classes.smallContent}>
            <div>
              These terms and conditions are an agreement between us and You as a user who uses our information
              resource, and replace all (oral or written) previous agreements, statements and agreements between us.
              This paragraph does not in any way limit or exclude any liability for fraudulent misrepresentation or
              willful misrepresentation of facts.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>18.5</div>
          <div className={classes.smallContent}>
            <div>
              Non-performance or delay in implementation of any right, power or the privilege according to this
              agreement is not the waiver of such right or acceptance of any changes in provisions, and also partial
              implementation of any of the parties of any right, power or the privilege does not exclude further
              implementation of this right or implementation of any other right, power or the privilege.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>18.6</div>
          <div className={classes.smallContent}>
            <div>
              Nothing in provisions provides or aims to grant any other third party the benefit and the rights of
              providing any other agreement or other agreements (The rights of the third parties).
            </div>
          </div>
        </div>
        <div className={classes.footerTitle}>
          {companyName} {rnCompany} {companyAddress}
        </div>
      </div>
    </Container>
  );
};

export default TermsOfUse;
