import BirtdaySlider from '@/components/sliders/birthday-silder.component';
import NewsService from '@/services/news.service';
import { Chip, CircularProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import background from '../../../assets/images/news-birtday.png'

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    borderRadius: 10,
    padding: '20px 21px ',
    background: `url(${background}) no-repeat`,
    backgroundSize: 'cover',
    width: 926,
    marginBottom: 22,

    [theme.breakpoints.down("xl")]: {
      width: 700,
    },
    [theme.breakpoints.down("lg")]: {
      width: 'auto'
    },
    [theme.breakpoints.down("md")]: {
      padding: '20px 15px',
    },
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '60%',
  },

}));

const Birthdays = () => {
  const classes = useStyles();

  const [birthdays, setBirthdays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchBirthdays = async () => {
      setIsLoading(true);

      try {
        const response = await NewsService.getBirthdays();

        setBirthdays(response)
      } catch {
        console.log('fetch birthdays error');
      }

      setIsLoading(false);
    }

    fetchBirthdays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  if (!isLoading && !birthdays.length) {
    return null;
  }

  if (isLoading) {
    return <CircularProgress color="secondary" style={{
      width: '20px',
      height: '20px',
      margin: '0px auto',
      display: 'flex',
      padding: '50px',
    }} />
  }

  return <Box className={classes.box}>
    <BirtdaySlider
      disablePadding={true}
      data={birthdays}
    >
      <Box className={classes.headerBox}>
        <Typography variant="h3" sx={{
          fontWeight: 600,
          fontSize: '22px',
          lineHeight: '36px',
          color: '#FFFFFF',
          position: 'relative',
        }}>
          They celebrate their birthday <Chip label={birthdays.length} color='primary' sx={{
            height: 20, marginLeft: 1, position: 'absolute',
            bottom: 6
          }} />
        </Typography>
      </Box>
    </BirtdaySlider>
  </Box>
}

export default Birthdays;
