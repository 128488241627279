import { resetPasswordSchema } from "@/utils/validationSchemas";
import { FormControl, FormHelperText, Theme, Typography } from "@mui/material";
import { Formik } from "formik";
import React, { Fragment } from "react";
import BaseButton from "../base/button.component";
import BaseTextField from "../base/text-field.component";
import { makeStyles } from "@mui/styles";

type ForgotPasswordLayoutProps = {
  onSubmit: (values: any) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  dialogTitle: {
    textAlign: "center",
    height: 48,
  },
  formLabelText: {
    fontSize: 14,
    color: "#000",
  },
  formLabelButton: {
    "&:focus": {
      outline: `2px solid ${theme.palette.radioSelected.main}`,
      outlineOffset: 2,
    },
  },
  genderForm: {
    justifyContent: "space-between",
    "& > $formRadioContainer:last-of-type": {
      marginLeft: 20,

      [theme.breakpoints.down(405)]: {
        marginLeft: 10,
      },
    },
  },
  formRadioContainer: {},
  formTextFieldsContainer: {
    minWidth: 322,
    marginTop: 20,
    marginBottom: 20,
    "& > $formTextField:not(:first-child)": {
      marginTop: 20,
    },
  },
  formTextField: {},
  formActions: {
    marginTop: 30,
    width: "100%",
  },
  formSignUpBtn: {
    width: "100%",
  },
}));

const ForgotPasswordLayout = ({ onSubmit }: ForgotPasswordLayoutProps) => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.dialogTitle}>
        <Typography variant="h2" component="h2" gutterBottom>
          Forgot Password
        </Typography>
      </div>
      <div style={{ maxWidth: 320, textAlign: "center" }}>
        <Typography variant="body1" component="p" gutterBottom>
          {
            "Plese enter your validation code\n from email in order to reset the password"
          }
        </Typography>
      </div>
      <Formik
        initialValues={{
          code: "",
          password: "",
        }}
        validationSchema={resetPasswordSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className={classes.formContainer}>
            <FormControl>
              <div className={classes.formTextFieldsContainer}>
                <BaseTextField
                  label="code"
                  onChange={handleChange}
                  type="text"
                  placeholder={"code"}
                  baseClass={classes.formTextField}
                />
                <BaseTextField
                  label="password"
                  onChange={handleChange}
                  type="password"
                  placeholder={"password"}
                  baseClass={classes.formTextField}
                />
              </div>

              <div className={classes.formActions}>
                <BaseButton
                  loading={false}
                  type="submit"
                  color="primary"
                  text="Reset"
                  className={classes.formSignUpBtn}
                />
              </div>
            </FormControl>
          </form>
        )}
      </Formik>
    </Fragment>
  );
};

export default ForgotPasswordLayout;
