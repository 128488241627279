import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { ReactComponent as EditIcon } from "@/assets/icons/edit.svg";
import { ReactComponent as NewContactsIcon } from "@/assets/icons/new-mail-contacts.svg";
import { ReactComponent as ArchiveIcon } from "@/assets/icons/archive.svg";
import { ReactComponent as TrashIcon } from "@/assets/icons/trash.svg";

import BaseCard from "@/components/base/base-card.component";
import BaseButton from "@/components/base/button.component";
import DialogGiftMenu from "@/components/dialogs/gift-menu.dialog";
import DialogGift from "@/components/dialogs/send-gift.dialog.component";
import { useAppBreakpoints, useContainerWidth } from "@/hooks";
import { useBinarySwitcher } from "@/hooks/useBinarySwitcher";
import { Button, Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useRef, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import GiftsNavbar from "./components/gifts-navbar";
import GiftsSection from "./components/gifts-section";
import { useGifts } from "./gifts.hooks";
import UserGiftOpenned from "./user-gifts-openned/user-gift-openned.component";
import { ReactComponent as ArrowIcon } from "@/assets/icons/option-arrow.svg";
import { setIsBookmarksAction } from "@/app/gifts/gifts.actions";
import {  markAsReadSelectedGiftsAction } from "@/app/gifts/gifts.actions";

type HtmlElementRefType =
  | ((instance: any) => void)
  | React.MutableRefObject<any>
  | null;

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    [theme.breakpoints.down("xl")]: {
      marginTop: 24,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 14,
    },
  },

  navWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    marginTop: 46,
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
  },

  navigationListWrapper: {
    width: "25%",
    flexShrink: 0,

    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },

  giftWidgetContainer: {
    flexBasis: "75%",
  },
  navigationHeader: {
    width: "75%",
  },
  headerCta: {
    padding: "10px 55px !important",
  },
  subHeader: {
    display: "flex",
    margin: "24px 0",
    justifyContent: "space-between",
    width: "100%",
  },
  menu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "& > .arrow": {
      [theme.breakpoints.up("lg")]: {
        display: 'none'
      },
    }
  },
  menuWrapper: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: '14px',
      alignSelf: "flex-start",
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      minHeight: '42px',
      alignItems: 'center'
    },
  },
  menuIconWrapper: {
    [theme.breakpoints.down("sm")]: {
      width: '95px',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  ctaIcon: {
    maxWidth: 42,
    maxHeight: 42,
    color: `${theme.palette.radioSelected.main} !important`,
    "& > .MuiButton-startIcon": {
      margin: 0,
    },
    minWidth: "0 !important",
  },
}));

const stylesOverrides = {
  contentWrapper: {
    padding: 0,
    maxWidth: 968,
  },
  subHeaderButton: {
    minWidth: 80,
    minHeight: 0,
    height: 24,
  },
};

const Gifts = () => {
  const width = useContainerWidth();
  const classes = useStyles();
  const { downLg, smAndUp } = useAppBreakpoints();
  const dispatch = useAppDispatch();
  const [giftOptionEl, setGiftOptionEl] = useState(null);
  const giftOptionsRef = useRef<HTMLDivElement>();
  const [isWriteDialogOpen, openDialog, hideDialog] = useBinarySwitcher();
  const [giftsKind] = useGifts();
  const handleGiftOptions = (event: any) => {
    setGiftOptionEl(giftOptionsRef.current as any);
  };
  const { id: giftId } = useParams();

  const isGiftsChosed = useAppSelector((state) => state.gifts.chosenIds.size);

  const isBookmarks = useAppSelector((state) => state.gifts.isBookmarks);

  const contactsGiftCount = useAppSelector((state) => state.gifts.giftCounters?.bookmarksUnseen);

  const headerActions = [
    {
      icon: <ArchiveIcon />,
      onClick: () => dispatch(markAsReadSelectedGiftsAction(giftsKind)),
    },
  ];


  const contactGiftsSwitcher = useCallback((state: boolean) => {
    dispatch(setIsBookmarksAction(state));
  }, [dispatch])

  return (
    <Container
      maxWidth={width}
      className={classes.contentWrapper}
      style={stylesOverrides.contentWrapper}
    >
      <DialogGift open={isWriteDialogOpen} closeHandler={hideDialog} />
      <BaseCard wrapperOverrideStyle={{ margin: 0, paddingBottom: 10 }}>
        {giftOptionEl && (
          <DialogGiftMenu
            anchorEl={giftOptionEl}
            setAnchorEl={setGiftOptionEl}
            isContactGiftsDisplayed={isBookmarks}
            contactGiftsSwitcher={contactGiftsSwitcher}
            contactsGiftCount={contactsGiftCount}
          />
        )}

        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: smAndUp ? "row" : "column",
              width: smAndUp || giftId ? "100%" : "312px",
            }}
          >
            <div className={classes.menuWrapper}>
              <span
                onClick={(e: any) => downLg && handleGiftOptions(e)}
                ref={giftOptionsRef as HtmlElementRefType}
              >
                <div className={classes.menu}>
                  <Typography variant="h2">Gift</Typography>
                  <ArrowIcon
                    style={{
                      marginLeft: 6,
                      width: 18,
                      height: 18,
                      transform: giftOptionEl ? "rotate(180deg)" : "",
                    }}
                    className='arrow'
                  />
                </div>
              </span>

              <div className={classes.menuIconWrapper}>
                {(!giftId && isGiftsChosed && !smAndUp) ?
                  headerActions.map((action, idx) => (
                    <BaseButton
                      key={idx}
                      inverted
                      text=""
                      className={classes.ctaIcon}
                      textStyle=""
                      onClick={action.onClick}
                      Icon={action.icon}
                      disabled={!isGiftsChosed}
                    />
                  )) : null}
              </div>
            </div>

            <div className={classes.giftWidgetContainer} style={{ width: giftId ? '100%' : 'auto' }}>
              <Outlet />
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div className={classes.navWrapper}>
              <div className={classes.navigationListWrapper}>
                <div className={classes.navigationHeader}>
                  <BaseButton
                    text="Send"
                    color="primary"
                    wrapperClass={classes.headerCta}
                    onClick={openDialog}
                    Icon={<EditIcon />}
                  />
                  <div className={classes.subHeader}>
                  </div>
                </div>
                <GiftsNavbar setGiftOptionEl={setGiftOptionEl} />
              </div>
              {giftId ? (
                <UserGiftOpenned giftId={giftId} />
              ) : (
                <GiftsSection
                  giftsKind={giftsKind}
                  writeGifthandler={openDialog}
                />
              )}
            </div>
          </div>
        </div>
      </BaseCard>
    </Container >
  );
};

export default Gifts;
