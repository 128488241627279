import { useAppDispatch } from '@/app/hooks';
import { addToBookmarksAction } from '@/app/mails/mails.actions';
import { MailKind } from '@/app/mails/mails.types';
import { ReactComponent as AddContactIcon } from '@/assets/icons/profile-actions/add-contact.svg';
import { ReactComponent as PresentIcon } from '@/assets/icons/profile-actions/present.svg';
import { ReactComponent as EmailMiniIcon } from '@/assets/icons/profile-actions/email-mini.svg';
import BaseButton from '@/components/base/button.component';
import StatusOnline from '@/components/base/statuses/status-online.component';
import DialogMessageMetaActions from '@/components/dialogs/message-meta-actions';
import DialogWriteMail from '@/components/dialogs/write-mail.dialog.component';
import { calculateAge } from '@/helpers/helpers';
import { useAppBreakpoints } from '@/hooks';
import { useBinarySwitcher } from '@/hooks/useBinarySwitcher';
import useBlankProfileAvatart from '@/hooks/useBlankProfileAvatart';
import { MailShape } from '@/services/mail.service';
import { formatCreatedAtDate, isToday } from '@/utils/formatDate';
import { Checkbox, Divider, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { format, differenceInMinutes } from 'date-fns';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import DialogGift from '@/components/dialogs/send-gift.dialog.component';

export interface StyleProps {
  avatar: string;
}

type HtmlElementRefType = ((instance: any) => void) | React.MutableRefObject<any> | null;

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  profilePhoto: ({ avatar }) => ({
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 10,
    height: '100%',
    backgroundImage: `url("${avatar}")`,
  }),
  photoWrapper: {
    position: 'relative',
    marginRight: 20,
    maxWidth: 124,
    aspectRatio: '116 / 130',
    width: '100%',

    [theme.breakpoints.down('md')]: {
      maxWidth: 72,
      maxHeight: 76,
    },
  },
  photoInner: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 14,
    borderRadius: 10,
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      '&:hover $profileCta': {
        opacity: 1,
      },
    },
  },
  messageInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0',
  },
  messageHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  messageFooter: {
    color: '#808080',

    marginTop: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },
  profileInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  profileName: {
    overflowWrap: 'anywhere',
    width: '18vw',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    [theme.breakpoints.down('lg')]: {
      width: '53vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '39vw',
    },
  },
  profileSubject: {
    width: '25vw',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    [theme.breakpoints.down('lg')]: {
      width: '60vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50vw',
    },
  },
  profileText: {
    width: '25vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [theme.breakpoints.down('lg')]: {
      width: '60vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50vw',
    },
  },
  profileWrapper: {
    width: '100%',
  },
  profileActions: {
    display: 'flex',
    gap: 10,
    color: '#808080',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  checkBox: {
    marginRight: '0 !important',
  },
  profileMeta: {
    display: 'flex',
    alignItems: 'center',
    gap: 9,
  },
  divider: {
    paddingTop: 15,
  },
  metaCtaIcon: {
    maxWidth: 42,
    maxHeight: 42,

    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },
  profileCta: {
    display: 'flex',
    gap: 24,
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      backgroundColor: 'white',
      bottom: '5px',
      right: '5px',
      padding: '10px',
      borderRadius: '10px',
      boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
      opacity: 0,
      transition: 'opacity .3s',
    },
  },
  profilePhotoStatus: {
    position: 'absolute',
    bottom: 8,
    left: 8,
  },
  statusRead: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
  },
  statusUnread: {
    backgroundColor: '#F6F6F6',
  },
}));

type UserMessageProps = {
  userId: string;
  wrapperClassName?: string;
  isChecked: boolean;
  isUnread: boolean;
  timestamp: Date;
  name: string;
  age: string;
  subject: string;
  avatar: string;
  message: MailShape;
  onChange: (message: any) => void;
  kind: MailKind;
  setMessageId: (messageId: string) => void;
};

const DialogUserMessage = ({
  timestamp,
  isUnread,
  userId,
  name,
  age,
  avatar,
  subject,
  isChecked,
  wrapperClassName = '',
  onChange,
  message,
  kind,
  setMessageId
}: UserMessageProps) => {
  const dispatch = useAppDispatch();
  const { oppositeUserPicture } = useBlankProfileAvatart();
  const classes = useStyles({ avatar: avatar || oppositeUserPicture });
  const { downLg, lgAndUp, mdAndDown } = useAppBreakpoints();
  const [isWriteDialogOpen, openDialog, hideDialog] = useBinarySwitcher();
  const [isGiftDialogOpen, openGiftDialog, hideGiftDialog] = useBinarySwitcher();

  const [isBookmark, setIsbookmark] = useState(message.isBookmark);

  const isUserActiveNow = useMemo(
    () => differenceInMinutes(new Date(), new Date(message.activeAt)) < 15,
    [message.activeAt]
  );

  const metaActions = useMemo(
    () =>
      isBookmark
        ? [
            {
              icon: <EmailMiniIcon />,
              onClick: openDialog,
            },
            {
              icon: <PresentIcon />,
              onClick: openGiftDialog,
            },
          ]
        : [
            {
              icon: <EmailMiniIcon />,
              onClick: openDialog,
            },
            {
              icon: <PresentIcon />,
              onClick: openGiftDialog,
            },
            {
              icon: <AddContactIcon />,
              onClick: () => {
                setIsbookmark(true);
                dispatch(addToBookmarksAction(userId, kind));
              },
            },
          ],
    [dispatch, isBookmark, kind, openDialog, userId]
  );

  const [messageOptionEl, setMessageOptionEl] = useState(null);
  const messageOptionsRef = useRef<HTMLDivElement>();
  const handleMessageOptions = (event: any) => {
    setMessageOptionEl(messageOptionsRef.current as any);
  };

  const metaButtons = useMemo(
    () => (
      <div
        className={classes.profileCta}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {metaActions.map((action, idx) => (
          <BaseButton
            key={idx}
            inverted
            text=''
            className={classes.metaCtaIcon}
            textStyle=''
            onClick={action.onClick}
            Icon={action.icon}
          />
        ))}
      </div>
    ),
    [classes.metaCtaIcon, classes.profileCta, metaActions]
  );

  const formatedCreatedAtDate = useMemo(() => {
    const month = format(timestamp, 'MMM do');
    const localeTime = formatCreatedAtDate(timestamp);

    return isToday(timestamp) ? `${localeTime}` : `${month}`;
  }, [timestamp]);

  const handleChange = useCallback((event: any) => onChange(message), [onChange, message]);

  return (
    <div
      className={clsx(classes.cardContainer, wrapperClassName, isUnread ? classes.statusUnread : classes.statusRead)}
      onClick={() => lgAndUp && setMessageId(message.id)}
    >
      {messageOptionEl && (
        <DialogMessageMetaActions
          anchorEl={messageOptionEl}
          setAnchorEl={setMessageOptionEl}
          metaButtons={metaButtons}
          isBookmark={message.isBookmark}
        />
      )}

      <div onClick={(e) => e.stopPropagation()}>
        <DialogWriteMail
          open={isWriteDialogOpen}
          closeHandler={hideDialog}
          receiver={{
            id: userId,
            name: name,
            avatar: avatar || oppositeUserPicture,
          }}
        />
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <DialogGift
          receiver={{ id: userId, name: name, avatar: avatar || oppositeUserPicture }}
          open={isGiftDialogOpen}
          closeHandler={hideGiftDialog}
        />
      </div>
      <div
        className={classes.photoWrapper}
        onClick={() => mdAndDown && setMessageId(message.id)}
      >
        <div className={classes.photoInner}>
          <div className={classes.profilePhoto} />
        </div>
        {isUserActiveNow ? (
          <div className={classes.profilePhotoStatus}>
            <StatusOnline small />
          </div>
        ) : null}
      </div>

      <div className={classes.messageInfo}>
        <div className={classes.messageHeader}>
          <div
            onClick={(e: any) => downLg && handleMessageOptions(e)}
            className={classes.profileWrapper}
            ref={messageOptionsRef as HtmlElementRefType}
          >
            <div className={classes.profileInfo}>
              <Typography variant='body1'>{`ID: ${message.profilePageId}`}</Typography>
              <div className={classes.profileMeta}>
                <Typography variant='body2' color='currentcolor' className={classes.profileName}>
                  {name}
                  {age ? `, ${calculateAge(age)}` : null}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.profileActions} onClick={(e) => e.stopPropagation()}>
            <Typography color='currentColor' fontWeight={400}>
              {formatedCreatedAtDate}
            </Typography>
            <Checkbox
              checked={isChecked}
              name='isOnline'
              onChange={handleChange}
              color='warning'
              className={classes.checkBox}
            />
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.messageFooter}>
          <div className={classes.profileInfo}>
            <Typography variant='body3' className={classes.profileSubject}>
              {subject}
            </Typography>
            <Typography fontWeight={400} color='currentcolor' className={classes.profileText}>
              {message.text}
            </Typography>
          </div>
          {!downLg && metaButtons}
        </div>
      </div>
    </div>
  );
};

export default DialogUserMessage;
