import { useAppBreakpoints } from '@/hooks';
import useIsStaffUserRole from '@/hooks/useIsStaffUserRole';
import WdLogo from '@/ui/wd-logo.ui';
import { Container, Grid, Link, List, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import {
  footerListServices,
  footerListSocial,
  footerMenu1,
  footerMenu2,
  footerMenuForAuthorized,
} from './core/footer-config';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { ISettings } from '@/types/user-profile.types';
import { getAcquiringRequest, getBLockedUsersRequest, getSettingsRequest } from '@/app/users/users.actions';

const useStyles = makeStyles((theme: Theme) => ({
  smt: {
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '12px !important',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  ml: {
    '& > $footerListItem:not(:first-child)': {
      marginLeft: 28,
    },
  },
  pt: {
    paddingTop: '28px !important',
  },

  root: {
    backgroundColor: theme.palette.footer['main'],
  },
  container: {
    padding: '60px 48px !important',
    [theme.breakpoints.up('lg')]: {
      //   padding: "60px 24px",
    },
  },

  //TODO: Rewrite, too much intersactions
  containerInnerSmall: {
    padding: 0,

    '& > $footerMenuListSecondary, $footerListSocial, $footerMenuList, $footerListServices': {
      marginTop: 40,
      padding: 0,
    },
  },
  containerInner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #000',
    paddingBottom: 60,
  },
  footerMenuListSecondary: {
    width: '100%',
    display: 'flex',
    order: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderTop: '1px solid #000',
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      '& > $footerListItem:not(:first-child)': {
        marginTop: 28,
      },
    },

    [theme.breakpoints.up('md')]: {
      order: 4,
      margin: '0 28px',
      flexDirection: 'row',

      '& > $footerListItem:not(:first-child)': {
        marginLeft: 28,
      },
    },
  },

  footerListSocial: {
    display: 'flex',
    order: 3,
    flexWrap: 'wrap',

    [theme.breakpoints.up('md')]: {
      order: 5,
    },
  },
  footerMenuList: {
    display: 'flex',
    flexWrap: 'wrap',
    order: 2,
    marginTop: 40,
  },
  footerListServices: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    order: 4,
    [theme.breakpoints.up('md')]: {
      order: 3,
      paddingTop: '36px !important',
      borderTop: '1px solid #000',
    },
  },

  //used as a pointer
  footerListItem: {},
  serviceImage: {
    objectFit: 'contain',
    display: 'block',
    height: 20,
  },

  internalResources: {
    margin: '6px 0',
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { mdAndUp, lgAndDown, lg } = useAppBreakpoints();
  const isStaff = useIsStaffUserRole();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSettingsRequest());
    dispatch(getAcquiringRequest());
  }, []);

  const { companyAddress, companyName, siteName } = useAppSelector<ISettings>((state) => state.users.settings);

  const currentUser = useAppSelector((state) => state.users.currentUser);

  useEffect(() => {
    if(currentUser) {
      dispatch(getBLockedUsersRequest());
    }
  }, [currentUser, dispatch]);

  return (
    <footer className={classes.root}>
      <Container maxWidth={lg ? 'lg' : 'xl'} className={classes.container}>
        <Grid
          display={{
            lg: 'none',
            xs: 'flex',
          }}
          container
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
          className={classes.containerInnerSmall}
        >
          <Link href='/'>
            <WdLogo vertical={mdAndUp && lgAndDown} />
          </Link>
          <List className={clsx(classes.footerMenuList, classes.ml)}>
            {currentUser ? (
              <>
                {footerMenuForAuthorized.map((link) => (
                  <li key={link.name} className={classes.footerListItem}>
                    <Link href={link.path} underline='none'>
                      <Typography variant='body2'>{link.name}</Typography>
                    </Link>
                  </li>
                ))}
              </>
            ) : (
              <>
                {footerMenu1.map((link) => (
                  <li key={link.name} className={classes.footerListItem}>
                    <Link href={link.path} underline='none'>
                      <Typography variant='body2'>{link.name}</Typography>
                    </Link>
                  </li>
                ))}
              </>
            )}
          </List>

          <List className={clsx(classes.footerListSocial, classes.ml)}>
            {footerListSocial.map(({ icon: Icon, ...link }) => (
              <li key={link.name} className={classes.footerListItem}>
                <Link target='_blank' rel='noreferrer' href={link.path} underline='none'>
                  {/*// @ts-ignore */}
                  <Icon />
                </Link>
              </li>
            ))}
          </List>

          <List className={clsx(classes.footerListServices, classes.ml)}>
            {footerListServices.map((link) => (
              <li key={link.name} className={classes.footerListItem}>
                <img className={classes.serviceImage} alt={link.name} src={link.imageUrl} />
              </li>
            ))}
          </List>
          <List className={clsx(classes.footerMenuListSecondary, classes.pt)}>
            {footerMenu2.flatMap((link) => {
              if (isStaff && link.name === 'Refund Policy') return [];

              return (
                <li key={link.name} className={classes.footerListItem}>
                  <Link href={link.path} underline='none'>
                    <Typography variant='body2'>{link.name}</Typography>
                  </Link>
                </li>
              );
            })}
          </List>

          <Grid item xs={12} justifyContent='center' width='100%' textAlign='center' mt='40px' order={6}>
            <div>
              <Typography variant='caption'> © 2021 {siteName}. All rights reserved</Typography>
            </div>
            <div>
              <Typography variant='caption'> {companyName}</Typography>
            </div>
            <div>
              <Typography variant='caption'>
                {' '}
                <div>{companyAddress}</div>
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid
          display={{
            lg: 'flex',
            xs: 'none',
          }}
          container
          justifyContent='center'
          direction='column'
          width='100%'
        >
          <div className={classes.containerInner}>
            <Link href='/'>
              <WdLogo />
            </Link>

            <div className={classes.internalResources}>
              <List className={clsx(classes.row, classes.ml)}>
                {currentUser ? (
                  <>
                    {footerMenuForAuthorized.map((link) => (
                      <li key={link.name} className={classes.footerListItem}>
                        <Link href={link.path} underline='none'>
                          <Typography variant='body2' className={classes.smt}>
                            {link.name}
                          </Typography>
                        </Link>
                      </li>
                    ))}
                  </>
                ) : (
                  <>
                    {footerMenu1.map((link) => (
                      <li key={link.name} className={classes.footerListItem}>
                        <Link href={link.path} underline='none'>
                          <Typography variant='body2' className={classes.smt}>
                            {link.name}
                          </Typography>
                        </Link>
                      </li>
                    ))}
                  </>
                )}
              </List>

              <List className={clsx(classes.row, classes.ml)}>
                {footerMenu2.map((link) => {
                  if (isStaff && link.name === 'Refund Policy') return [];

                  return (
                    <li key={link.name} className={classes.footerListItem}>
                      <Link href={link.path} underline='none'>
                        <Typography variant='body2' className={classes.smt}>
                          {link.name}
                        </Typography>
                      </Link>
                    </li>
                  );
                })}
              </List>
            </div>
            <List className={clsx(classes.row, classes.ml)}>
              {footerListSocial.map(({ icon: Icon, ...link }) => (
                <li key={link.name} className={classes.footerListItem}>
                  <Link target='_blank' rel='noreferrer' href={link.path} underline='none'>
                    {/*// @ts-ignore */}
                    <Icon />
                  </Link>
                </li>
              ))}
            </List>
          </div>

          <Grid
            item
            justifyContent='space-between'
            alignItems='center'
            flexWrap='wrap'
            width='100%'
            mt='40px'
            order={6}
            container
            display='flex'
            direction='row'
          >
            <div>
              <Typography variant='caption'> © 2021 {siteName}. All rights reserved</Typography>
            </div>
            <div>
              <Typography variant='caption'> {companyName}</Typography>
            </div>
            <div>
              <Typography variant='caption'>
                {' '}
                <div>{companyAddress}</div>
              </Typography>
            </div>
            <List className={clsx(classes.row, classes.ml)}>
              {footerListServices.map((link) => (
                <li key={link.name} className={classes.footerListItem}>
                  <img className={classes.serviceImage} alt={link.name} src={link.imageUrl} />
                </li>
              ))}
            </List>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
