import { call, fork, put, takeEvery, select } from 'redux-saga/effects';
import {
  GET_MAILS_REQUEST,
  SEND_MAIL_REQUEST,
  DELETE_SELECTED_MESSAGES,
  MARK_AS_READ_SELECTED_MESSAGES,
  ADD_TO_BOOKMARKS,
  GET_USER_MESSAGE_REQUEST,
  GET_CLOSED_ATTACH_REQUEST,
  GET_MESSAGE_COUNTERS,
} from './mails.types';
import {
  getMailsSuccess,
  getMailsRequest,
  getMailsFailure,
  sendMailFailure,
  sendMailRequest,
  sendMailSuccess,
  setPaginationAction,
  setPaginationOverAction,
  deleteSelectedMessagesAction,
  markAsReadSelectedMessagesAction,
  checkFewMessages,
  resetPaginationAndMailsAction,
  resetSelectedMessages,
  markAsReadSelectedMessagesSuccessAction,
  addToBookmarksAction,
  addToBookmarksSuccessAction,
  getUserMessageRequest,
  getUserMessageSuccess,
  getUserMessageFail,
  getClosedAttachRequest,
  getMessageCountersAction,
  getMessageCountersSuccessAction,
} from './mails.actions';
import MailService from '@/services/mail.service';
import { openNotEnoughBalance, toggleModalOpen, toggleSnackbarOpen } from '../ui/ui.actions';
import { useAppSelector } from '../hooks';

function* sendMailWorkerSaga(action: ReturnType<typeof sendMailRequest>) {
  try {
    const { payload: mailData } = action;
    const response = yield call(() => MailService.sendMail(mailData));
    yield put(sendMailSuccess(response));
    yield action.payload.closeHandler();
    yield put(toggleSnackbarOpen('Your message was successfully sent', 'success'));
  } catch (e) {
    yield put(sendMailFailure(e));
    if (e.message === 'Profile blocked by yourself') {
      yield put(toggleModalOpen('You have blocked that user, you can go to profile and unblock', 'error', action.payload.receiverId));
    }
    yield put(
      toggleSnackbarOpen(
        e?.message,
        'error'
      )
    );
  }
}

function* getMailsWorkerSaga(action: ReturnType<typeof getMailsRequest>) {
  const {
    mails: { isBookmarks, pagination, searchProfileId, sorting },
  } = yield select();
  try {
    const { payload: kind } = action;
    const response = yield call(() => MailService.getMails(kind, isBookmarks, pagination, searchProfileId, sorting));

    if (response.length < 20) {
      yield put(setPaginationOverAction(true));
    }

    yield put(setPaginationAction(pagination.offset + response.length));

    yield put(getMailsSuccess(kind, response));
  } catch (e) {
    yield put(getMailsFailure(e));
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
  }
}

function* deleteMailsWorkerSaga(action: ReturnType<typeof deleteSelectedMessagesAction>) {
  const {
    mails: { chosenIds },
  } = yield select();
  try {
    const { payload: kind } = action;

    yield call(() => MailService.deleteMails([...chosenIds]));

    yield put(toggleSnackbarOpen('You have deleted selected messages', 'success'));

    yield put(checkFewMessages([]));
    yield put(resetSelectedMessages());
    yield put(resetPaginationAndMailsAction(kind));
    yield put(getMailsRequest(kind));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request to delete messages', 'error'));
  }
}

function* markReadMailsWorkerSaga(action: ReturnType<typeof markAsReadSelectedMessagesAction>) {
  const {
    mails: { chosenIds },
  } = yield select();
  try {
    const { payload: kind } = action;
    yield call(() => MailService.markAsReadMails([...chosenIds]));

    yield put(toggleSnackbarOpen('You marked as read selected messages', 'success'));
    yield put(resetSelectedMessages());
    yield put(checkFewMessages([]));
    yield put(markAsReadSelectedMessagesSuccessAction(kind, chosenIds));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request to read messages', 'error'));
  }
}

function* addToBookmarksSaga(action: ReturnType<typeof addToBookmarksAction>) {
  try {
    const {
      payload: { id, kind },
    } = action;
    yield call(() => MailService.addToBookmarks(id));

    yield put(toggleSnackbarOpen('You have successfully bookmarked the user', 'success'));

    yield put(addToBookmarksSuccessAction({ id, kind }));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during bookmarking the user', 'error'));
  }
}

function* getUserMailSaga(action: ReturnType<typeof getUserMessageRequest>) {
  try {
    const { id } = action.payload;

    const response = yield call(() => MailService.getUserMail(id));

    yield put(getUserMessageSuccess(response));
  } catch (e) {
    const { onErrorHandler } = action.payload;
    yield put(getUserMessageFail());

    if (e?.message === 'Not enough credits') {
      onErrorHandler();
      yield put(openNotEnoughBalance(yield select((state) => state.users.settings.creditsMessageOpen)));
    }

    yield put(
      toggleSnackbarOpen(
        e?.message,
        'error'
      )
    );
  }
}

function* getClosedAttachSaga(action: ReturnType<typeof getClosedAttachRequest>) {
  try {
    const {
      payload: { attachId, messageId, isVideo },
    } = action;

    yield call(() => MailService.getClosedAttach(attachId, isVideo));
    yield put(getUserMessageRequest(messageId, () => {}));
  } catch (e) {

    if (e?.message === 'Not enough credits') {
      yield put(openNotEnoughBalance(yield select((state) => state.users.settings.creditsMessagePhotoOpen)));
    }
    yield put(
      toggleSnackbarOpen(
        e?.message,
        'error'
      )
    );
  }
}

function* getMessageCountersSaga(action: ReturnType<typeof getMessageCountersAction>) {
  try {
    const response = yield call(() => MailService.getMessageCounters());

    yield put(getMessageCountersSuccessAction(response));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request closed attachment', 'error'));
  }
}

function* sendMail() {
  yield takeEvery(SEND_MAIL_REQUEST, sendMailWorkerSaga);
}

function* getMails() {
  yield takeEvery(GET_MAILS_REQUEST, getMailsWorkerSaga);
}

function* deleteMails() {
  yield takeEvery(DELETE_SELECTED_MESSAGES, deleteMailsWorkerSaga);
}

function* markReadMails() {
  yield takeEvery(MARK_AS_READ_SELECTED_MESSAGES, markReadMailsWorkerSaga);
}

function* addToBookmarks() {
  yield takeEvery(ADD_TO_BOOKMARKS, addToBookmarksSaga);
}

function* getUserMail() {
  yield takeEvery(GET_USER_MESSAGE_REQUEST, getUserMailSaga);
}

function* getClosedAttach() {
  yield takeEvery(GET_CLOSED_ATTACH_REQUEST, getClosedAttachSaga);
}

function* getMessageCounters() {
  yield takeEvery(GET_MESSAGE_COUNTERS, getMessageCountersSaga);
}

const mailSagas = [
  fork(sendMail),
  fork(getMails),
  fork(deleteMails),
  fork(markReadMails),
  fork(addToBookmarks),
  fork(getUserMail),
  fork(getClosedAttach),
  fork(getMessageCounters),
];

export default mailSagas;
