import { useAppSelector, useAppDispatch } from '@/app/hooks';
import {
  toggleAvailableUsersBar,
  toggleLastUsersBar,
} from '@/app/ui/ui.actions';
import { IconButton } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useCallback } from 'react';
import { ReactComponent as ArrowLeft } from '@/assets/icons/chats/arrow-left.svg';
import { ReactComponent as ArrowRight } from '@/assets/icons/chats/arrow-right.svg';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    padding: '40px 14px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  availableChatBtn: {
    background: '#eee!important',
  },
  lastChatBtn: {
    background: '#eee!important',
  },
  emptyChatWrapper: {},
  availableChatWrapper: {
    position: 'absolute',
    top: '100px',
    left: '16px',
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  lastChatsWrapper: {
    position: 'absolute',
    top: '100px',
    right: '16px',
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  genderChatBtn: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
    paddingLeft: '12px',
  },
  chatsBtn: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
    paddingLeft: '12px',
  },
}));

const EmptyDialog = () => {
  const classes = useStyles();
  const currentUser = useAppSelector((state) => state.users.currentUser);

  const dispatch = useAppDispatch();

  const toggleAvailableDrawer = useCallback(() => {
    dispatch(toggleAvailableUsersBar());
  }, [dispatch]);

  const toggleLastChatDrawer = useCallback(() => {
    dispatch(toggleLastUsersBar());
  }, [dispatch]);

  const gender = currentUser.profile.gender;

  const genderLabel = gender === 'male' ? ' Girl' : 'Man';

  return (
    <div className={classes.container}>
      <div
        className={classes.availableChatWrapper}
        onClick={toggleAvailableDrawer}
      >
        <IconButton className={classes.availableChatBtn}>
          <ArrowRight />
        </IconButton>
        <div className={classes.genderChatBtn}>{genderLabel}</div>
      </div>
      <div className={classes.emptyChatWrapper}>
        select {genderLabel} to start dialog
      </div>
      <div className={classes.lastChatsWrapper} onClick={toggleLastChatDrawer}>
        <IconButton className={classes.lastChatBtn}>
          <ArrowLeft />
        </IconButton>
        <div className={classes.chatsBtn}>Dialogs</div>
      </div>
    </div>
  );
};

export default EmptyDialog;
