import React, { useState } from 'react';
import PersistentDialogTabManager from './persistance-dialog-tab-manage';

const useDialogPersistentTab = (url: string): [(url: string) => void, Window | null] => {
  const [openedWindow, setOpenedWindow] = useState<Window | null>(null);
  const persistentDialogTabManager = new PersistentDialogTabManager(url);

  const openDialogTab = (url: string): void => {
    // if (openedWindow && !openedWindow.closed) {
    //   openedWindow.location.replace(url);
    //   openedWindow.focus();
    // } else {
    //   const newWindow = window.open(url);
    //   setOpenedWindow(newWindow);
    // }
    persistentDialogTabManager.openDialogTab(url);
  };

  return [openDialogTab, openedWindow];
};

export default useDialogPersistentTab;
