import { ReactComponent as CameraIcon } from '@/assets/icons/camera-status.svg';
import { ReactComponent as CameraIconWhite } from '@/assets/icons/camera-status-white.svg';
import { ReactComponent as ContactsIcon } from '@/assets/icons/contacts.svg';
import { ReactComponent as WhitePinIcon } from '@/assets/icons/chats/white-pin-contact.svg';
import { ReactComponent as PinIcon } from '@/assets/icons/chats/pin-contact.svg';
import { ReactComponent as ContactsIconWhite } from '@/assets/icons/contacts-white.svg';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { readMessage } from '@/app/users/users.actions';

type UserCardProps = {
  userName: string;
  uid: string;
  isSelected: boolean;
  avatar: string;
  handleClick: (uid: string) => void;
  pageId: string;
  isActive: boolean;
  isInBookmarks: boolean;
  isNewMessage: boolean;
  unreadCount?: number;
  isPin?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    width: '100%',
    height: '64px',
    borderRadius: '10px',
    marginBottom: '12px',
  },
  activeCard: {
    backgroundColor: '#ea416018',
    color: '#000',
  },
  selectedCard: {
    backgroundColor: '#503EB6',
    color: '#fff',
  },
  isNewMessage: {
    position: 'relative',
  },
  newMessageDot: {
    position: 'absolute',
    bottom: '70%',
    left: '20%',
    transform: 'translateX(-50%)',
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    animation: '$blinkAnimation 2s infinite',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '10px',
  },
  '@keyframes blinkAnimation': {
    '0%': {
      opacity: 0,
      backgroundColor: 'transparent',
    },
    '20%': {
      opacity: 1,
      backgroundColor: theme.palette.primary.main,
    },
    '80%': {
      opacity: 1,
      backgroundColor: theme.palette.primary.main,
    },
    '100%': {
      opacity: 0,
      backgroundColor: 'transparent',
    },
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  image: {
    width: '64px',
    height: '64px',
    borderRadius: '10px',
    objectFit: 'cover',
  },
  imageWrapper: {
    marginRight: '10px',
    position: 'relative',
  },
  userName: {
    fontSize: 16,
    lineHeight: '18px',
    paddingTop: '6px',
    fontWeight: '500',
    height: '18px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  id: {
    fontSize: 12,
    lineHeight: '18px',
    height: '18px',
    padding: '2px 0 3px 0',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '83px',
  },
  icon: {
    paddingLeft: '1px',
    paddingRight: '9px',
    width: 12,
    height: 12,
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    alignItems: 'end',
  },
}));

const UserCard = ({
  userName,
  uid,
  isSelected,
  isActive,
  avatar,
  handleClick,
  pageId,
  isInBookmarks,
  isNewMessage,
  unreadCount = 0,
  isPin,
}: UserCardProps) => {
  const classes = useStyles();
  const onClick = useCallback(
    (event) => {
      handleClick(uid);
    },
    [handleClick, uid]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSelected && isNewMessage) {
      dispatch(readMessage(uid));
    }
  }, [isSelected, isNewMessage, uid, dispatch]);

  return (
    <div
      onClick={onClick}
      className={clsx(
        classes.container,
        isSelected && classes.selectedCard,
        isActive && classes.activeCard,
        isNewMessage && classes.isNewMessage
      )}
    >
      <div className={clsx(classes.imageWrapper, classes.image)}>
        {isNewMessage && <div className={classes.newMessageDot}>{unreadCount ? unreadCount : ''}</div>}
        <img className={classes.image} src={avatar} />
      </div>
      <div className={classes.infoWrapper}>
        <div className={classes.userName}>{userName}</div>
        <div className={classes.id}>ID: {pageId}</div>
        {isSelected ? (
          <div className={classes.icons}>
            <CameraIconWhite className={classes.icon} />
            {isInBookmarks && <ContactsIconWhite className={classes.icon} />}
            {isPin && <WhitePinIcon className={classes.icon} />}
          </div>
        ) : (
          <div className={classes.icons}>
            <CameraIcon className={classes.icon} />
            {isInBookmarks && <ContactsIcon className={classes.icon} />}
            {isPin && <PinIcon className={classes.icon} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserCard;
