import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { UserProfileState } from '@/types/user-profile.types';
import React, { useEffect, useState } from 'react';
import { getProfilePhotosRequest } from '@/app/users/users.actions';

import { UserPhotoType } from '@/app/users/users.types';
import { Gallery } from 'react-photoswipe-gallery';
import { useDispatch } from 'react-redux';
import ChatProfileUserSlider from '@/components/sliders/chat-profile-user-slider.component';
import { defaultUserPhotosData } from '@/app/users/users.dto';

export interface IProps {
  referenceUser: UserProfileState;
  isPhotosSliderOpen: boolean;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    width: '100%',
    maxWidth: '700px',
  },
}));

const ChatUserPhotos = ({ ...props }: IProps) => {
  const { referenceUser, isPhotosSliderOpen } = props;
  const dispatch = useDispatch();
  const [photos, setPhotos] = useState(defaultUserPhotosData);

  useEffect(() => {
    if (!referenceUser['publicPhotos']?.data.length) {
      dispatch(getProfilePhotosRequest(referenceUser.id, UserPhotoType.PUBLIC_VIEW));
    }
  }, [referenceUser.id]);

  useEffect(() => {
    if (referenceUser) {
      const publicImages = referenceUser['publicPhotos'] || defaultUserPhotosData;

      setPhotos({
        prevPage: publicImages.prevPage,
        nextPage: publicImages.nextPage,
        data: [...publicImages.data],
      });
    }
  }, [referenceUser, referenceUser.publicPhotos]);

  const classes = useStyles(props);
  if (!isPhotosSliderOpen) return null;

  return (
    <div className={classes.container}>
      <Gallery>
        <ChatProfileUserSlider defaultValue={photos} />
      </Gallery>
    </div>
  );
};

export default ChatUserPhotos;
