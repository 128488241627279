import { DateOfBirth } from "@/services/utils";
import { FormHelperText, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { FormikErrors } from "formik";
import React, { useMemo, useRef } from "react";
import SelectField from "./select-field.component";

function generateYears(minAge: number, maxAge: number): Array<any> {
  const years = [{ value: 0, name: "Year", disabled: true }];
  const year = new Date().getFullYear();
  const start = year - minAge;
  const count = start - maxAge;
  for (let i = start; i >= count; i--) {
    years.push({
      value: i,
      name: i.toString(),
      disabled: false,
    });
  }
  return years;
}

function generateDays(month: number, year: number) {
  const MONTH_DAYS =
    month && year ? dayjs(`${year}-${month}-01`).daysInMonth() : 31;
  const days = [{ value: -1, name: "Day", disabled: true }];
  for (let i = 1; i <= MONTH_DAYS; i++) {
    days.push({
      value: i,
      name: ("0" + i).slice(-2),
      disabled: false,
    });
  }
  return days;
}

type DatePickerProps = {
  birthday: DateOfBirth;
  error?: FormikErrors<DateOfBirth> | undefined;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  optionsWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    gap: 6,
    width: "100%",

    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
    },
    '& > div': {
      minWidth: '100px',
    },
  },
  optionsTitle: {
    width: "33.333%",
    flexShrink: 0,
    [theme.breakpoints.down("md")]: {
      marginRight: "auto",
      paddingLeft: 10,
      paddingBottom: 10,
    },
  },
}));

const DatePicker = ({ setFieldValue, birthday, error }: DatePickerProps) => {
  const classes = useStyles();
  const setDate = (key: string, value: number) =>
    setFieldValue("birthday", { ...birthday, [key]: value });

  const months = useRef([
    { value: -1, name: "Month", disabled: true },
    { value: 1, name: "January", disabled: false },
    { value: 2, name: "February", disabled: false },
    { value: 3, name: "March", disabled: false },
    { value: 4, name: "April", disabled: false },
    { value: 5, name: "May", disabled: false },
    { value: 6, name: "June", disabled: false },
    { value: 7, name: "July", disabled: false },
    { value: 8, name: "August", disabled: false },
    { value: 9, name: "September", disabled: false },
    { value: 10, name: "October", disabled: false },
    { value: 11, name: "November", disabled: false },
    { value: 12, name: "December", disabled: false },
  ]);

  const days = useMemo(() => {
    const currentDate = new Date();
    const year = birthday?.year ? birthday?.year : currentDate.getFullYear();
    const month = birthday?.month ? birthday?.month : currentDate.getMonth() + 1;

    return generateDays(month, year);
  }, [birthday?.month, birthday?.day, birthday?.year]);
  const years = useMemo(() => generateYears(18, 80), []);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.optionsTitle}>
          <Typography variant="body1" fontWeight="400">
            Date of birth
          </Typography>
        </div>
        <div className={classes.optionsWrapper}>
          <SelectField
            list={months.current}
            label="birthday"
            placeholder="Month"
            onChnage={(val) => {
              if (val === "Month") return;
              let monthId = -1;
              for (const { name, value } of months.current) {
                if (name === val) {
                  monthId = value;
                }
              }
              setDate("month", monthId);
            }}
          />
          <SelectField
            placeholder="Day"
            list={days}
            label="birthday"
            onChnage={(val) => val !== "Day" && setDate("day", +val)}
          />
          <SelectField
            placeholder="Year"
            list={years}
            label="birthday"
            onChnage={(val) => val !== "Year" && setDate("year", +val)}
          />
        </div>
      </div>
      {error && (
        <FormHelperText style={{ marginLeft: "auto" }} error={true}>
          {error}
        </FormHelperText>
      )}
    </>
  );
};

export default DatePicker;
