import { useAppSelector } from '@/app/hooks';
import SearchIcon from '@/assets/icons/search.svg';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import UserLink from './user-link';
import profilePicture from '@/assets/images/demo-avatar.jpeg';
import PrimaryBaseTextField from '@/components/base/text-field-base.component';

type LastUsersComponentProps = {
  onClick?: () => void;
  mobile?: boolean;
  userDialogs: any;
  getMoreUserDialogCallback: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '172px',
    padding: '14px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '0 10px 10px 0',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '56px',
    fontSize: '22px',
    fontWeight: '600',
    color: '#000000',
  },
  buttonsWrapper: {
    paddingTop: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mainButton: {
    width: '80px',
    height: '24px',
    minHeight: '24px!important',
    padding: '0',
    boxShadow: 'none!important',
  },
  usersWrapper: {
    marginTop: '27px',
    overflowY: 'scroll',
    height: 'calc(100vh - 352px)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100vh - 200px)',
    },
  },
  searchedWrapper: {
    display: 'flex',
    maxHeight: 30,
    width: '100%',
  },
  searchedInput: {
    width: '100%',
    paddingRight: 6,
    backgroundSize: 14,
    lineHeight: '30px',
    outline: ' 8px ridge rgba(170, 50, 220, .6)',
    backgroundPosition: '94% 50%',
    background: `url(${SearchIcon}) no-repeat right`,
  },
  searchUsers: {
    marginTop: '14px',
  },
  loadMorePhoto: {
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
    color: '#503EB6',
    background: '#EEEEEE',
    padding: '10px 9px 11px 12px',
    borderRadius: '20px',
    cursor: 'pointer',
  },
}));

const LatsUsers = ({ mobile = false, onClick, userDialogs, getMoreUserDialogCallback }: LastUsersComponentProps) => {
  const classes = useStyles();
  const currentUser = useAppSelector((state) => state.users.currentUser);

  const [filterUsers, setFilterUsers] = useState('');

  useEffect(() => {
    return console.log('onlogout');
  }, []);

  return (
    <div className={classes.container} style={mobile ? { paddingBottom: 23, paddingTop: 27 } : {}}>
      <div className={classes.title}>Last Dialogs</div>
      <div className={classes.searchUsers}>
        <div className={classes.searchedWrapper}>
          <PrimaryBaseTextField
            onChange={(event) => setFilterUsers(event.target.value)}
            label='Name or ID '
            inputClassName={classes.searchedInput}
          />
        </div>
      </div>
      <div className={classes.usersWrapper}>
        {userDialogs.data
          .map((dialog) => {
            return dialog.receiverProfile.id === currentUser?.profile?.id
              ? { ...dialog.senderProfile, seen: dialog.seen, isPin: dialog.isPin, unreadCount: dialog.unreadCount }
              : { ...dialog.receiverProfile, seen: dialog.seen, isPin: dialog.isPin, unreadCount: dialog.unreadCount };
          })
          .filter((user: { name: string }) =>
            // (user?.name.includes(filterUsers) ||
            //   `${user?.pageId}`.includes(filterUsers))
            user?.name.includes(filterUsers)
          )
          .map(({ name, id, avatar, pageId, seen, isPin, unreadCount }) => (
            <UserLink
              onClick={onClick}
              userName={name}
              user={currentUser}
              key={id}
              uid={id}
              isPin={isPin}
              isNewMessage={!seen}
              unreadCount={unreadCount}
              avatar={avatar || profilePicture}
              pageId={`${pageId}`}
            />
          ))}
        {userDialogs.nextPage && (
          <div className={classes.loadMorePhoto} onClick={getMoreUserDialogCallback}>
            {' '}
            load previous messages
          </div>
        )}
      </div>
    </div>
  );
};

export default LatsUsers;
