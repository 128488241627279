import { UserProfileDTO } from '@/app/users/users.reducer';
import AdvertisementBanner from '@/assets/images/advertisement.png';
import useIsStaffUserRole from '@/hooks/useIsStaffUserRole';
import { UserProfile } from '@/types/user-profile.types';
import React, { useCallback } from 'react';
import DrawerLink from './drawer-link.component';
import { useNavigate } from 'react-router-dom';
import usePersistentTab from '@/components/base/use-persistance-tab-hook';
import { Badge, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactComponent as LogoutIcon } from '@/assets/icons/drawer-icons/logout.svg';
import { Theme } from '@emotion/react';
import { makeStyles } from '@mui/styles';
import useDialogPersistentTab from '@/components/base/use-dialog-persistance-tab-hook';

type DrawerBottomProps = {
  user: UserProfileDTO | undefined;
  onToggleMenu?: (event?: any) => void;
  handleLogout?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '7px 30px',
    fontSize: 16,
    fontWeight: 500,
    fontFamily: ' Readex Pro',
    textDecoration: 'none',
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  label: {
    display: 'inline-block',
  },
  defaultLink: {
    color: '#000',

    '& path': {
      fill: '#503EB6',
    },
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 14,
    minWidth: 16,
  },
  counter: {
    display: 'inline-block',
  },
}));

const DrawerBottom = ({ user, onToggleMenu = () => {}, handleLogout }: DrawerBottomProps) => {
  const isStaff = useIsStaffUserRole();
  const navigate = useNavigate();

  const navItems = [
    {
      route: '/user/settings',
      label: 'Settings',
      iconName: 'settings',
      counter: false,
      count: null,
    },
    {
      route: '/user/payments',
      label: 'Payments',
      iconName: 'payments',
      counter: false,
      count: null,
    },
    // {
    //   route: "logout",
    //   label: "Logout",
    //   iconName: "logout",
    //   counter: false,
    //   count: null,
    // },
  ];

  const [openTab] = usePersistentTab(`/chat/${user?.profile?.id}`);
  const openTabCallBack = useCallback(() => {
    openTab(`/chat/${user?.profile?.id}`);
  }, [openTab, user?.profile?.id]);

  const [openDialogTab] = useDialogPersistentTab(`/dialog/${user?.profile?.id}`);
  const openDialogTabCallBack = useCallback(() => {
    openDialogTab(`/dialog/${user?.profile?.id}`);
  }, [openDialogTab, user?.profile?.id]);

  const classes = useStyles();

  return (
    <div style={{ paddingTop: 25 }}>
      {navItems.flatMap(({ route, iconName, label, counter, count }) => {
        if (isStaff && label === 'Payments') return [];

        return (
          <DrawerLink
            handleLogout={handleLogout}
            onToggleMenu={onToggleMenu}
            route={route}
            label={label}
            iconName={iconName}
            key={label}
            user={user}
            counter={counter}
            count={`${count}` || null}
            openTab={openTabCallBack}
            openDialogTab={openDialogTabCallBack}
          />
        );
      })}

      <div className={clsx(classes.iconWrapper, classes.link)} onClick={handleLogout}>
        <Badge color='primary' style={{ padding: '2px 4px' }}>
          {/*// @ts-ignore */}
          {<LogoutIcon />}
        </Badge>
        <Typography
          fontSize='inherit'
          className={classes.label}
          style={{
            marginLeft: 10,
            color: '#000',
          }}
        >
          Log Out
        </Typography>
      </div>
      <div
        style={{
          backgroundSize: 'cover',
          borderRadius: 10,
          width: 160,
          height: 340,
          backgroundImage: `url(${AdvertisementBanner})`,
          marginTop: 30,
          marginLeft: 25,
          marginBottom: 32,
          cursor: 'pointer',
        }}
        onClick={() => navigate('/user/credits')}
      />
    </div>
  );
};

export default DrawerBottom;
