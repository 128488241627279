export enum MailKind {
  INBOX = "inbox",
  SENT = "outbox",
  INBOX_BOOKMARKS = "inbox-bookmarks",
  SENT_BOOKMARKS = "outbox-bookmarks",
}

export enum SelectMessagesOption {
  NO_SELECTION = "No selection",
  ALL = "All",
  NEW = "New",
  READ = "Read",
};

export enum SortlistNames {
  DATEASC = "Date-Ascending",
  DATEDESC = "Date-Descending",
  NAMEASC = "Name A-Z",
  NAMEDESC = "Name Z-A"
};

export const SEND_MAIL_REQUEST = "SEND_MAIL_REQUEST";
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";
export const SEND_MAIL_FAILURE = "SEND_MAIL_FAILURE";

export const GET_MAILS_REQUEST = "GET_MAILS_REQUEST";
export const GET_MAILS_SUCCESS = "GET_MAILS_SUCCESS";
export const GET_MAILS_FAILURE = "GET_MAILS_FAILURE";

export const CHECK_MESSAGE = "CHECK_MESSAGE";
export const CHECK_FEW_MESSAGES = "CHECK_FEW_MESSAGES";

export const SET_IS_BOOKMARKS = "SET_IS_BOOKMARKS";
export const SET_SEARCH_PROFILE_ID = "SET_SEARCH_PROFILE_ID";
export const SET_SORTING = "SET_SORTING";
export const RESET_PAGINATION_AND_MAILS = "RESET_PAGINATION_AND_MAILS";

export const SET_PAGINATION = "SET_PAGINATION";
export const SET_PAGINATION_IS_OVER = "SET_PAGINATION_IS_OVER";

export const DELETE_SELECTED_MESSAGES = "DELETE_SELECTED_MESSAGES";
export const MARK_AS_READ_SELECTED_MESSAGES = "MARK_AS_READ_SELECTED_MESSAGES";
export const MARK_AS_READ_SELECTED_MESSAGES_SUCCESS = "MARK_AS_READ_SELECTED_MESSAGES_SUCCESS";

export const SET_SELECTED_MESSAGES = "SET_SELECTED_MESSAGES";
export const RESET_SELECTED_MESSAGES = "RESET_SELECTED_MESSAGES";

export const ADD_TO_BOOKMARKS = "ADD_TO_BOOKMARKS";
export const ADD_TO_BOOKMARKS_SUCCESS = "ADD_TO_BOOKMARKS_SUCCESS";

export const GET_USER_MESSAGE_REQUEST = "GET_USER_MESSAGE_REQUEST";
export const GET_USER_MESSAGE_SUCCESS = "GET_USER_MESSAGE_SUCCESS";
export const GET_USER_MESSAGE_FAIL = "GET_USER_MESSAGE_FAIL";
export const RESET_USER_MESSAGE_FAIL = "RESET_USER_MESSAGE_FAIL";

export const GET_CLOSED_ATTACH_REQUEST = "GET_CLOSED_ATTACH_REQUEST";
export const GET_CLOSED_ATTACH_SUCCESS = "GET_CLOSED_ATTACH_SUCCESS";
export const GET_CLOSED_ATTACH_FAIL = "GET_CLOSED_ATTACH_FAIL";

export const GET_MESSAGE_COUNTERS = "GET_MESSAGE_COUNTERS";
export const GET_MESSAGE_COUNTERS_SUCCESS = "GET_MESSAGE_COUNTERS_SUCCESS";
export const SOCKET_UPDATE_MESSAGES_COUNT = "SOCKET_UPDATE_MESSAGES_COUNT";


