import GiftService from '@/services/gifts.service';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { toggleModalOpen, toggleSnackbarOpen } from '../ui/ui.actions';
import {
  addToBookmarksAction,
  addToBookmarksSuccessAction,
  checkFewGifts,
  getGiftsFailure,
  getGiftsRequest,
  getGiftsSuccess,
  getGiftCountersAction,
  getGiftCountersSuccessAction,
  getUserGiftFail,
  getUserGiftRequest,
  getUserGiftSuccess,
  markAsReadSelectedGiftsAction,
  markAsReadSelectedGiftsSuccessAction,
  resetPaginationAndGiftsAction,
  resetSelectedGifts,
  setPaginationAction,
  setPaginationOverAction,
  getAvailableToSendGiftsRequest,
  getAvailableToSendGiftsSuccess,
  getAvailableToSendGiftsFailure,
  postGiftSuccess,
  postGiftFailure,
  postGiftRequest,
  getGiftsFromSocketRequest,
  getGiftsFromSocketSuccess,
  getGiftsFromSocketFailure,
} from './gifts.actions';
import GiftsService from '@/services/gifts.service';
import {
  ADD_TO_BOOKMARKS,
  GET_CLOSED_ATTACH_REQUEST,
  GET_GIFTS_REQUEST,
  GET_GIFT_COUNTERS,
  GET_USER_GIFT_REQUEST,
  MARK_AS_READ_SELECTED_GIFTS,
  POST_USER_GIFT_REQUEST,
  GET_AVAILABLE_TO_SEND_GIFTS_REQUEST,
  GiftsDTO,
  PostGiftDTO,
  GiftKind,
  GET_GIFTS_FROM_SOCKET_REQUEST,
} from './gifts.types';
import WalletsService from '@/services/wallets.service';
import { updateBalanceSuccess } from '../users/users.actions';

function* getGiftsByTypeWorkerSaga(action: ReturnType<typeof getGiftsRequest>) {
  const {
    gifts: { pagination, searchProfileId, sorting },
  } = yield select();
  try {
    const { payload: kind } = action;
    const response = yield call(() => GiftsService.getGiftsByType(kind, pagination, searchProfileId, sorting));

    if (response.length < 20) {
      yield put(setPaginationOverAction(true));
    }

    yield put(setPaginationAction(pagination.offset + response.length));

    yield put(getGiftsSuccess(kind, response));
  } catch (e) {
    yield put(getGiftsFailure(e));
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
  }
}

function* markReadGiftsWorkerSaga(action: ReturnType<typeof markAsReadSelectedGiftsAction>) {
  const {
    gifts: { chosenIds },
  } = yield select();
  try {
    const { payload: kind } = action;
    yield call(() => GiftService.markAsReadGifts([...chosenIds]));

    yield put(toggleSnackbarOpen('You marked as read selected gifts', 'success'));
    yield put(resetSelectedGifts());
    yield put(checkFewGifts([]));
    yield put(markAsReadSelectedGiftsSuccessAction(kind, chosenIds));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request to read gifts', 'error'));
  }
}

function* addToBookmarksSaga(action: ReturnType<typeof addToBookmarksAction>) {
  try {
    const {
      payload: { id, kind },
    } = action;
    yield call(() => GiftService.addToBookmarks(id));

    yield put(toggleSnackbarOpen('You have successfully bookmarked the user', 'success'));

    yield put(addToBookmarksSuccessAction({ id, kind }));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during bookmarking the user', 'error'));
  }
}

function* getUserGiftSaga(action: ReturnType<typeof getUserGiftRequest>) {
  try {
    const { id, type } = action.payload;

    const response = yield call(() => GiftService.getUserGift(id, type));

    yield put(getUserGiftSuccess(response));

    yield put(markAsReadSelectedGiftsSuccessAction(type as GiftKind, new Set(id)));

    const counter = yield call(() => GiftService.getGiftCounters());

    yield put(getGiftCountersSuccessAction(counter));
    const wallet: { balance: number } = yield call(() => WalletsService.getBalance());

    yield put(updateBalanceSuccess(wallet));

  } catch (e) {
    yield put(getUserGiftFail());
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
  }
}

function* getAvailableToSendGiftsWorkerSaga(action: ReturnType<typeof getAvailableToSendGiftsRequest>) {
  try {
    const gifts: GiftsDTO[] = yield call(() => GiftsService.getGifts());
    yield put(getAvailableToSendGiftsSuccess(gifts));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getAvailableToSendGiftsFailure(e));
  }
}

function* createGiftsWorkerSaga(action: ReturnType<typeof postGiftRequest>) {
  const { body } = action;
  try {
    if(!body.virtualGiftId) {
      yield put(toggleSnackbarOpen('Gift should be attached', 'error'));
      return;
    }
    const gift: PostGiftDTO = yield call(() => GiftsService.postGift(body));
    yield put(postGiftSuccess(gift));
    yield put(toggleSnackbarOpen('Gift has been sent', 'success'));
    body.closeHandler()
  } catch (e) {
    if (e.message === 'Profile blocked by yourself') {
      yield put(toggleModalOpen('You have blocked that user, you can go to profile and unblock', 'error', action.body.receiverId));
    }
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(postGiftFailure(e));
  }
}


function* getGiftCountersSaga(action: ReturnType<typeof getGiftCountersAction>) {
  try {
    const response = yield call(() => GiftService.getGiftCounters());

    yield put(getGiftCountersSuccessAction(response));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request closed attachment', 'error'));
  }
}

function* getGiftsFromSocketWorkerSaga(action: ReturnType<typeof getGiftsFromSocketRequest>) {
  try {
    const { gift, kind } = action.payload;

    yield put(getGiftsFromSocketSuccess(kind, gift));
  } catch (e) {
    yield put(getGiftsFromSocketFailure(e));
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
  }
}

function* getGiftsFromSocket() {
  yield takeEvery(GET_GIFTS_FROM_SOCKET_REQUEST, getGiftsFromSocketWorkerSaga);
}

function* getAvailableGiftsToSend() {
  yield takeEvery(GET_AVAILABLE_TO_SEND_GIFTS_REQUEST, getAvailableToSendGiftsWorkerSaga);
}

function* postGift() {
  yield takeEvery(POST_USER_GIFT_REQUEST, createGiftsWorkerSaga);
}

function* getGifts() {
  yield takeEvery(GET_GIFTS_REQUEST, getGiftsByTypeWorkerSaga);
}

function* markReadGifts() {
  yield takeEvery(MARK_AS_READ_SELECTED_GIFTS, markReadGiftsWorkerSaga);
}

function* addToBookmarks() {
  yield takeEvery(ADD_TO_BOOKMARKS, addToBookmarksSaga);
}

function* getUserGift() {
  yield takeEvery(GET_USER_GIFT_REQUEST, getUserGiftSaga);
}

function* getGiftCounters() {
  yield takeEvery(GET_GIFT_COUNTERS, getGiftCountersSaga);
}

const giftSagas = [
  fork(getAvailableGiftsToSend),
  fork(getGiftsFromSocket),
  fork(postGift),
  fork(getGifts),
  fork(markReadGifts),
  fork(addToBookmarks),
  fork(getUserGift),
  fork(getGiftCounters),
];

export default giftSagas;
