import { MediaConnection } from 'peerjs';

export const showVideo = (
  stream: MediaStream,
  video: HTMLVideoElement,
  muted?: boolean
) => {
  if (stream) {
    video.srcObject = stream;
    // video.volume = muted ? 0 : 1;//todo remove commennt
    video.volume = 0; //todo remove line
    video.onloadedmetadata = () => video.play();
  }
};

