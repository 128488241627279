import { ReactComponent as InfoIcon } from '@/assets/icons/edit-icons/info.svg';
import { makeStyles } from '@mui/styles';
import { Modal, Theme } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import UserAvatar from './user-avatar.component';
import { ReactComponent as ChatDots } from '@/assets/icons/chats/dots.svg';
import ActionsButtonsMobile from './actions-buttons-mobile.component';

export interface IProps {
  profilePicture: string;
  name: string;
  age: string;
  openDialogHandler: () => void;
  contactsClickHandler?: () => void;
  giftClickHandler?: () => void;
  mailClickHandler?: () => void;
  togglePhotoHandler?: () => void;
  isPhotosSliderOpen?: boolean;
  isOpenVideoChat: boolean;
  isOnline: boolean;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
  },
  nameWrapper: {
    display: 'flex',
    padding: '0 8px 0 28px',
    alignItems: 'flex-start',
    [theme.breakpoints.down('lg')]: {
      padding: '0 8px 0 12px',
      display: 'none',
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    height: '62px',
    width: '32px',
    justifyContent: 'space-between',
  },
  name: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
    [theme.breakpoints.down('lg')]: {
      fontSize: '24px',
      lineHeight: '42px',
    },
  },
  modalWrapper: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
    borderRadius: '10px',
    padding: '4px 18px 18px 18px',
  },
  mobileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',

    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  userInfoWrapperVideo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

const UserInfo = ({ ...props }: IProps) => {
  const {
    openDialogHandler,
    profilePicture,
    name,
    age,
    contactsClickHandler,
    giftClickHandler,
    mailClickHandler,
    togglePhotoHandler,
    isPhotosSliderOpen,
    isOpenVideoChat,
    isOnline = false,
  } = props;
  const modalRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      {!isOpenVideoChat && (
        <>
          <UserAvatar profilePicture={profilePicture} isOnline={isOnline} />
          <div className={classes.userInfoWrapperVideo}>
            <div
              className={classes.mobileWrapper}
              ref={modalRef}
              id='simple-modal-title'
            >
              <div className={classes.name}>
                {name}
                {age}
              </div>
              <div className={classes.icon}>
                <InfoIcon onClick={openDialogHandler} />
                <ChatDots onClick={toggleOpen} />
              </div>
              <Modal
                open={isOpen}
                onClose={toggleOpen}
                className={classes.modal}
                aria-labelledby='simple-modal-title'
                container={() => modalRef.current}
              >
                <div className={classes.modalWrapper}>
                  <ActionsButtonsMobile
                    mailClickHandler={() => {
                      mailClickHandler();
                      toggleOpen();
                    }}
                    togglePhotoHandler={() => {
                      togglePhotoHandler();
                      toggleOpen();
                    }}
                    isPhotosSliderOpen={isPhotosSliderOpen}
                    contactsClickHandler={() => {
                      contactsClickHandler();
                      toggleOpen();
                    }}
                    giftClickHandler={giftClickHandler}
                  />
                </div>
              </Modal>
            </div>
            <div className={classes.nameWrapper}>
              <div className={classes.icon} onClick={openDialogHandler}>
                <InfoIcon />
              </div>
              <div className={classes.name}>
                {name}
                {age}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserInfo;
