import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { StorageService } from '@/services/storage.service';
import { getMeRequest } from '@/app/auth/auth.actions';
import { CircularProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import jwtDecode from 'jwt-decode';

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    margin: '0 auto',
    padding: '10px',
    width: '200px',
    display: 'flex',
    justifyContent: 'center',
  }
}));

const RedirectSignIn = () => {
  const { token } = useParams<{
    token: string;
  }>();

  const classes = useStyles();
  const currentUser = useAppSelector((state) => state.users.currentUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const userToken = StorageService.getToken();
    if (userToken && !currentUser) {
      dispatch(getMeRequest());
    } else {
      StorageService.setToken(token);
      dispatch(getMeRequest());
      const { profile: { id } }: any = jwtDecode(token);
      navigate(`/user/${id}`);
    }
  }, []);

  return (
    <div className={classes.loader}>
      <CircularProgress color='secondary' style={{ margin: '0 auto' }} />
    </div>
  );
};

export default RedirectSignIn;
