import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  closeQuestionsForDaysSuccess,
  closeQuestionsRequest,
  filterQuestionsRequest,
  updateQuestionsRequest,
} from '@/app/users/users.actions';
import { useCallback, useEffect, useState } from 'react';
import { QuestionsDialog } from './dialogs/questions-dialog.component';
import { CloseQuestionsComponent } from './chat-container/components/close-questions.dialog.component';
import { useBinarySwitcher } from '@/hooks/useBinarySwitcher';

const QuestionsSubscriber = () => {
  const currentUser = useAppSelector((state) => state.users.currentUser);
  const questions = useAppSelector((state) => state.users.questions);
  const questionsForget = useAppSelector((state) => state.users.questionsForget);

  const dispatch = useAppDispatch();

  const init = useCallback(() => {
    dispatch(updateQuestionsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      init();
    }
  }, [currentUser, init]);

  const onCloseHandler = useCallback(() => {
    dispatch(filterQuestionsRequest(questions[0].id));
  }, [dispatch, questions]);

  const onCloseModalHandler = useCallback(() => {
    dispatch(closeQuestionsRequest(questions[0].id));
  }, [dispatch, questions]);

  const onCloseForDays = useCallback(() => {
    dispatch(closeQuestionsForDaysSuccess());
  }, [dispatch]);

  const [openCloseModal, setOpenCloseModal, setCloseCloseModal] = useBinarySwitcher(false);

  if (!questions || !questions.length) return null;

  if (questionsForget && new Date(questionsForget) > new Date()) {
    return null;
  }

  return (
    <>
      {currentUser && currentUser.profile && (
        <>
          <QuestionsDialog
            questions={questions[0]}
            onClose={onCloseHandler}
            onCloseModalHandler={setOpenCloseModal}
            open={!!questions[0]}
          />
          <CloseQuestionsComponent
            closeForEver={onCloseModalHandler}
            closeForDays={onCloseForDays}
            id={questions[0].id}
            openDialog={openCloseModal}
            handleCloseDialog={setCloseCloseModal}
          />
        </>
      )}
    </>
  );
};

export default QuestionsSubscriber;
