// const allCountries: Array<[string, string, string, ...any]> = [
//   ["Afghanistan (‫افغانستان‬‎)", "af", "93"],
//   ["Albania (Shqipëri)", "al", "355"],
//   ["Algeria (‫الجزائر‬‎)", "dz", "213"],
//   ["American Samoa", "as", "1684"],
//   ["Andorra", "ad", "376"],
//   ["Angola", "ao", "244"],
//   ["Anguilla", "ai", "1264"],
//   ["Antigua and Barbuda", "ag", "1268"],
//   ["Argentina", "ar", "54"],
//   ["Armenia (Հայաստան)", "am", "374"],
//   ["Aruba", "aw", "297"],
//   ["Australia", "au", "61", 0],
//   ["Austria (Österreich)", "at", "43"],
//   ["Azerbaijan (Azərbaycan)", "az", "994"],
//   ["Bahamas", "bs", "1242"],
//   ["Bahrain (‫البحرين‬‎)", "bh", "973"],
//   ["Bangladesh (বাংলাদেশ)", "bd", "880"],
//   ["Barbados", "bb", "1246"],
//   ["Belarus (Беларусь)", "by", "375"],
//   ["Belgium (België)", "be", "32"],
//   ["Belize", "bz", "501"],
//   ["Benin (Bénin)", "bj", "229"],
//   ["Bermuda", "bm", "1441"],
//   ["Bhutan (འབྲུག)", "bt", "975"],
//   ["Bolivia", "bo", "591"],
//   ["Bosnia and Herzegovina (Босна и Херцеговина)", "ba", "387"],
//   ["Botswana", "bw", "267"],
//   ["Brazil (Brasil)", "br", "55"],
//   ["British Indian Ocean Territory", "io", "246"],
//   ["British Virgin Islands", "vg", "1284"],
//   ["Brunei", "bn", "673"],
//   ["Bulgaria (България)", "bg", "359"],
//   ["Burkina Faso", "bf", "226"],
//   ["Burundi (Uburundi)", "bi", "257"],
//   ["Cambodia (កម្ពុជា)", "kh", "855"],
//   ["Cameroon (Cameroun)", "cm", "237"],
//   [
//     "Canada",
//     "ca",
//     "1",
//     1,
//     [
//       "204",
//       "226",
//       "236",
//       "249",
//       "250",
//       "289",
//       "306",
//       "343",
//       "365",
//       "387",
//       "403",
//       "416",
//       "418",
//       "431",
//       "437",
//       "438",
//       "450",
//       "506",
//       "514",
//       "519",
//       "548",
//       "579",
//       "581",
//       "587",
//       "604",
//       "613",
//       "639",
//       "647",
//       "672",
//       "705",
//       "709",
//       "742",
//       "778",
//       "780",
//       "782",
//       "807",
//       "819",
//       "825",
//       "867",
//       "873",
//       "902",
//       "905",
//     ],
//   ],
//   ["Cape Verde (Kabu Verdi)", "cv", "238"],
//   ["Caribbean Netherlands", "bq", "599", 1],
//   ["Cayman Islands", "ky", "1345"],
//   ["Central African Republic (République centrafricaine)", "cf", "236"],
//   ["Chad (Tchad)", "td", "235"],
//   ["Chile", "cl", "56"],
//   ["China (中国)", "cn", "86"],
//   ["Christmas Island", "cx", "61", 2],
//   ["Cocos (Keeling) Islands", "cc", "61", 1],
//   ["Colombia", "co", "57"],
//   ["Comoros (‫جزر القمر‬‎)", "km", "269"],
//   ["Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)", "cd", "243"],
//   ["Congo (Republic) (Congo-Brazzaville)", "cg", "242"],
//   ["Cook Islands", "ck", "682"],
//   ["Costa Rica", "cr", "506"],
//   ["Côte d’Ivoire", "ci", "225"],
//   ["Croatia (Hrvatska)", "hr", "385"],
//   ["Cuba", "cu", "53"],
//   ["Curaçao", "cw", "599", 0],
//   ["Cyprus (Κύπρος)", "cy", "357"],
//   ["Czech Republic (Česká republika)", "cz", "420"],
//   ["Denmark (Danmark)", "dk", "45"],
//   ["Djibouti", "dj", "253"],
//   ["Dominica", "dm", "1767"],
//   [
//     "Dominican Republic (República Dominicana)",
//     "do",
//     "1",
//     2,
//     ["809", "829", "849"],
//   ],
//   ["Ecuador", "ec", "593"],
//   ["Egypt (‫مصر‬‎)", "eg", "20"],
//   ["El Salvador", "sv", "503"],
//   ["Equatorial Guinea (Guinea Ecuatorial)", "gq", "240"],
//   ["Eritrea", "er", "291"],
//   ["Estonia (Eesti)", "ee", "372"],
//   ["Ethiopia", "et", "251"],
//   ["Falkland Islands (Islas Malvinas)", "fk", "500"],
//   ["Faroe Islands (Føroyar)", "fo", "298"],
//   ["Fiji", "fj", "679"],
//   ["Finland (Suomi)", "fi", "358", 0],
//   ["France", "fr", "33"],
//   ["French Guiana (Guyane française)", "gf", "594"],
//   ["French Polynesia (Polynésie française)", "pf", "689"],
//   ["Gabon", "ga", "241"],
//   ["Gambia", "gm", "220"],
//   ["Georgia (საქართველო)", "ge", "995"],
//   ["Germany (Deutschland)", "de", "49"],
//   ["Ghana (Gaana)", "gh", "233"],
//   ["Gibraltar", "gi", "350"],
//   ["Greece (Ελλάδα)", "gr", "30"],
//   ["Greenland (Kalaallit Nunaat)", "gl", "299"],
//   ["Grenada", "gd", "1473"],
//   ["Guadeloupe", "gp", "590", 0],
//   ["Guam", "gu", "1671"],
//   ["Guatemala", "gt", "502"],
//   ["Guernsey", "gg", "44", 1],
//   ["Guinea (Guinée)", "gn", "224"],
//   ["Guinea-Bissau (Guiné Bissau)", "gw", "245"],
//   ["Guyana", "gy", "592"],
//   ["Haiti", "ht", "509"],
//   ["Honduras", "hn", "504"],
//   ["Hong Kong (香港)", "hk", "852"],
//   ["Hungary (Magyarország)", "hu", "36"],
//   ["Iceland (Ísland)", "is", "354"],
//   ["India (भारत)", "in", "91"],
//   ["Indonesia", "id", "62"],
//   ["Iran (‫ایران‬‎)", "ir", "98"],
//   ["Iraq (‫العراق‬‎)", "iq", "964"],
//   ["Ireland", "ie", "353"],
//   ["Isle of Man", "im", "44", 2],
//   ["Israel (‫ישראל‬‎)", "il", "972"],
//   ["Italy (Italia)", "it", "39", 0],
//   ["Jamaica", "jm", "1876"],
//   ["Japan (日本)", "jp", "81"],
//   ["Jersey", "je", "44", 3],
//   ["Jordan (‫الأردن‬‎)", "jo", "962"],
//   ["Kazakhstan (Казахстан)", "kz", "7", 1],
//   ["Kenya", "ke", "254"],
//   ["Kiribati", "ki", "686"],
//   ["Kosovo", "xk", "383"],
//   ["Kuwait (‫الكويت‬‎)", "kw", "965"],
//   ["Kyrgyzstan (Кыргызстан)", "kg", "996"],
//   ["Laos (ລາວ)", "la", "856"],
//   ["Latvia (Latvija)", "lv", "371"],
//   ["Lebanon (‫لبنان‬‎)", "lb", "961"],
//   ["Lesotho", "ls", "266"],
//   ["Liberia", "lr", "231"],
//   ["Libya (‫ليبيا‬‎)", "ly", "218"],
//   ["Liechtenstein", "li", "423"],
//   ["Lithuania (Lietuva)", "lt", "370"],
//   ["Luxembourg", "lu", "352"],
//   ["Macau (澳門)", "mo", "853"],
//   ["Macedonia (FYROM) (Македонија)", "mk", "389"],
//   ["Madagascar (Madagasikara)", "mg", "261"],
//   ["Malawi", "mw", "265"],
//   ["Malaysia", "my", "60"],
//   ["Maldives", "mv", "960"],
//   ["Mali", "ml", "223"],
//   ["Malta", "mt", "356"],
//   ["Marshall Islands", "mh", "692"],
//   ["Martinique", "mq", "596"],
//   ["Mauritania (‫موريتانيا‬‎)", "mr", "222"],
//   ["Mauritius (Moris)", "mu", "230"],
//   ["Mayotte", "yt", "262", 1],
//   ["Mexico (México)", "mx", "52"],
//   ["Micronesia", "fm", "691"],
//   ["Moldova (Republica Moldova)", "md", "373"],
//   ["Monaco", "mc", "377"],
//   ["Mongolia (Монгол)", "mn", "976"],
//   ["Montenegro (Crna Gora)", "me", "382"],
//   ["Montserrat", "ms", "1664"],
//   ["Morocco (‫المغرب‬‎)", "ma", "212", 0],
//   ["Mozambique (Moçambique)", "mz", "258"],
//   ["Myanmar (Burma) (မြန်မာ)", "mm", "95"],
//   ["Namibia (Namibië)", "na", "264"],
//   ["Nauru", "nr", "674"],
//   ["Nepal (नेपाल)", "np", "977"],
//   ["Netherlands (Nederland)", "nl", "31"],
//   ["New Caledonia (Nouvelle-Calédonie)", "nc", "687"],
//   ["New Zealand", "nz", "64"],
//   ["Nicaragua", "ni", "505"],
//   ["Niger (Nijar)", "ne", "227"],
//   ["Nigeria", "ng", "234"],
//   ["Niue", "nu", "683"],
//   ["Norfolk Island", "nf", "672"],
//   ["North Korea (조선 민주주의 인민 공화국)", "kp", "850"],
//   ["Northern Mariana Islands", "mp", "1670"],
//   ["Norway (Norge)", "no", "47", 0],
//   ["Oman (‫عُمان‬‎)", "om", "968"],
//   ["Pakistan (‫پاکستان‬‎)", "pk", "92"],
//   ["Palau", "pw", "680"],
//   ["Palestine (‫فلسطين‬‎)", "ps", "970"],
//   ["Panama (Panamá)", "pa", "507"],
//   ["Papua New Guinea", "pg", "675"],
//   ["Paraguay", "py", "595"],
//   ["Peru (Perú)", "pe", "51"],
//   ["Philippines", "ph", "63"],
//   ["Poland (Polska)", "pl", "48"],
//   ["Portugal", "pt", "351"],
//   ["Puerto Rico", "pr", "1", 3, ["787", "939"]],
//   ["Qatar (‫قطر‬‎)", "qa", "974"],
//   ["Réunion (La Réunion)", "re", "262", 0],
//   ["Romania (România)", "ro", "40"],
//   ["Russia (Россия)", "ru", "7", 0],
//   ["Rwanda", "rw", "250"],
//   ["Saint Barthélemy", "bl", "590", 1],
//   ["Saint Helena", "sh", "290"],
//   ["Saint Kitts and Nevis", "kn", "1869"],
//   ["Saint Lucia", "lc", "1758"],
//   ["Saint Martin (Saint-Martin (partie française))", "mf", "590", 2],
//   ["Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)", "pm", "508"],
//   ["Saint Vincent and the Grenadines", "vc", "1784"],
//   ["Samoa", "ws", "685"],
//   ["San Marino", "sm", "378"],
//   ["São Tomé and Príncipe (São Tomé e Príncipe)", "st", "239"],
//   ["Saudi Arabia (‫المملكة العربية السعودية‬‎)", "sa", "966"],
//   ["Senegal (Sénégal)", "sn", "221"],
//   ["Serbia (Србија)", "rs", "381"],
//   ["Seychelles", "sc", "248"],
//   ["Sierra Leone", "sl", "232"],
//   ["Singapore", "sg", "65"],
//   ["Sint Maarten", "sx", "1721"],
//   ["Slovakia (Slovensko)", "sk", "421"],
//   ["Slovenia (Slovenija)", "si", "386"],
//   ["Solomon Islands", "sb", "677"],
//   ["Somalia (Soomaaliya)", "so", "252"],
//   ["South Africa", "za", "27"],
//   ["South Korea (대한민국)", "kr", "82"],
//   ["South Sudan (‫جنوب السودان‬‎)", "ss", "211"],
//   ["Spain (España)", "es", "34"],
//   ["Sri Lanka (ශ්‍රී ලංකාව)", "lk", "94"],
//   ["Sudan (‫السودان‬‎)", "sd", "249"],
//   ["Suriname", "sr", "597"],
//   ["Svalbard and Jan Mayen", "sj", "47", 1],
//   ["Swaziland", "sz", "268"],
//   ["Sweden (Sverige)", "se", "46"],
//   ["Switzerland (Schweiz)", "ch", "41"],
//   ["Syria (‫سوريا‬‎)", "sy", "963"],
//   ["Taiwan (台灣)", "tw", "886"],
//   ["Tajikistan", "tj", "992"],
//   ["Tanzania", "tz", "255"],
//   ["Thailand (ไทย)", "th", "66"],
//   ["Timor-Leste", "tl", "670"],
//   ["Togo", "tg", "228"],
//   ["Tokelau", "tk", "690"],
//   ["Tonga", "to", "676"],
//   ["Trinidad and Tobago", "tt", "1868"],
//   ["Tunisia (‫تونس‬‎)", "tn", "216"],
//   ["Turkey (Türkiye)", "tr", "90"],
//   ["Turkmenistan", "tm", "993"],
//   ["Turks and Caicos Islands", "tc", "1649"],
//   ["Tuvalu", "tv", "688"],
//   ["U.S. Virgin Islands", "vi", "1340"],
//   ["Uganda", "ug", "256"],
//   ["Ukraine (Україна)", "ua", "380"],
//   ["United Arab Emirates (‫الإمارات العربية المتحدة‬‎)", "ae", "971"],
//   ["United Kingdom", "gb", "44", 0],
//   ["United States", "us", "1", 0],
//   ["Uruguay", "uy", "598"],
//   ["Uzbekistan (Oʻzbekiston)", "uz", "998"],
//   ["Vanuatu", "vu", "678"],
//   ["Vatican City (Città del Vaticano)", "va", "39", 1],
//   ["Venezuela", "ve", "58"],
//   ["Vietnam (Việt Nam)", "vn", "84"],
//   ["Wallis and Futuna (Wallis-et-Futuna)", "wf", "681"],
//   ["Western Sahara (‫الصحراء الغربية‬‎)", "eh", "212", 1],
//   ["Yemen (‫اليمن‬‎)", "ye", "967"],
//   ["Zambia", "zm", "260"],
//   ["Zimbabwe", "zw", "263"],
//   ["Åland Islands", "ax", "358", 1],
// ];
//
// export default allCountries.map((country) => ({
//   name: country[0],
//   iso2: country[1].toUpperCase(),
//   dialCode: country[2],
//   priority: country[3] || 0,
//   areaCodes: country[4] || null,
// }));

export default [
  {
    id: 30,
    name: "Afghanistan",
  },
  {
    id: 21,
    name: "Albania",
  },
  {
    id: 22,
    name: "Algeria",
  },
  {
    id: 23,
    name: "American Samoa",
  },
  {
    id: 26,
    name: "Andorra",
  },
  {
    id: 25,
    name: "Angola",
  },
  {
    id: 24,
    name: "Anguilla",
  },
  {
    id: 27,
    name: "Antigua and Barbuda",
  },
  {
    id: 28,
    name: "Argentina",
  },
  {
    id: 6,
    name: "Armenia",
  },
  {
    id: 29,
    name: "Aruba",
  },
  {
    id: 19,
    name: "Australia",
  },
  {
    id: 20,
    name: "Austria",
  },
  {
    id: 5,
    name: "Azerbaijan",
  },
  {
    id: 31,
    name: "Bahamas",
  },
  {
    id: 34,
    name: "Bahrain",
  },
  {
    id: 32,
    name: "Bangladesh",
  },
  {
    id: 33,
    name: "Barbados",
  },
  {
    id: 3,
    name: "Belarus",
  },
  {
    id: 36,
    name: "Belgium",
  },
  {
    id: 35,
    name: "Belize",
  },
  {
    id: 37,
    name: "Benin",
  },
  {
    id: 38,
    name: "Bermuda",
  },
  {
    id: 47,
    name: "Bhutan",
  },
  {
    id: 40,
    name: "Bolivia",
  },
  {
    id: 235,
    name: "Bonaire, Sint Eustatius and Saba",
  },
  {
    id: 41,
    name: "Bosnia and Herzegovina",
  },
  {
    id: 42,
    name: "Botswana",
  },
  {
    id: 43,
    name: "Brazil",
  },
  {
    id: 52,
    name: "British Virgin Islands",
  },
  {
    id: 44,
    name: "Brunei Darussalam",
  },
  {
    id: 39,
    name: "Bulgaria",
  },
  {
    id: 45,
    name: "Burkina Faso",
  },
  {
    id: 46,
    name: "Burundi",
  },
  {
    id: 91,
    name: "Cambodia",
  },
  {
    id: 92,
    name: "Cameroon",
  },
  {
    id: 10,
    name: "Canada",
  },
  {
    id: 90,
    name: "Cape Verde",
  },
  {
    id: 149,
    name: "Cayman Islands",
  },
  {
    id: 213,
    name: "Central African Republic",
  },
  {
    id: 214,
    name: "Chad",
  },
  {
    id: 216,
    name: "Chile",
  },
  {
    id: 97,
    name: "China",
  },
  {
    id: 98,
    name: "Colombia",
  },
  {
    id: 99,
    name: "Comoros",
  },
  {
    id: 100,
    name: "Congo",
  },
  {
    id: 101,
    name: "Congo, Democratic Republic",
  },
  {
    id: 150,
    name: "Cook Islands",
  },
  {
    id: 102,
    name: "Costa Rica",
  },
  {
    id: 103,
    name: "Côte d`Ivoire",
  },
  {
    id: 212,
    name: "Croatia",
  },
  {
    id: 104,
    name: "Cuba",
  },
  {
    id: 138,
    name: "Curaçao",
  },
  {
    id: 95,
    name: "Cyprus",
  },
  {
    id: 215,
    name: "Czech Republic",
  },
  {
    id: 73,
    name: "Denmark",
  },
  {
    id: 231,
    name: "Djibouti",
  },
  {
    id: 74,
    name: "Dominica",
  },
  {
    id: 75,
    name: "Dominican Republic",
  },
  {
    id: 54,
    name: "East Timor",
  },
  {
    id: 221,
    name: "Ecuador",
  },
  {
    id: 76,
    name: "Egypt",
  },
  {
    id: 166,
    name: "El Salvador",
  },
  {
    id: 222,
    name: "Equatorial Guinea",
  },
  {
    id: 223,
    name: "Eritrea",
  },
  {
    id: 14,
    name: "Estonia",
  },
  {
    id: 224,
    name: "Ethiopia",
  },
  {
    id: 208,
    name: "Falkland Islands",
  },
  {
    id: 204,
    name: "Faroe Islands",
  },
  {
    id: 205,
    name: "Fiji",
  },
  {
    id: 207,
    name: "Finland",
  },
  {
    id: 209,
    name: "France",
  },
  {
    id: 210,
    name: "French Guiana",
  },
  {
    id: 211,
    name: "French Polynesia",
  },
  {
    id: 56,
    name: "Gabon",
  },
  {
    id: 59,
    name: "Gambia",
  },
  {
    id: 7,
    name: "Georgia",
  },
  {
    id: 65,
    name: "Germany",
  },
  {
    id: 60,
    name: "Ghana",
  },
  {
    id: 66,
    name: "Gibraltar",
  },
  {
    id: 71,
    name: "Greece",
  },
  {
    id: 70,
    name: "Greenland",
  },
  {
    id: 69,
    name: "Grenada",
  },
  {
    id: 61,
    name: "Guadeloupe",
  },
  {
    id: 72,
    name: "Guam",
  },
  {
    id: 62,
    name: "Guatemala",
  },
  {
    id: 236,
    name: "Guernsey",
  },
  {
    id: 63,
    name: "Guinea",
  },
  {
    id: 64,
    name: "GuineaBissau",
  },
  {
    id: 58,
    name: "Guyana",
  },
  {
    id: 57,
    name: "Haiti",
  },
  {
    id: 67,
    name: "Honduras",
  },
  {
    id: 68,
    name: "Hong Kong",
  },
  {
    id: 50,
    name: "Hungary",
  },
  {
    id: 86,
    name: "Iceland",
  },
  {
    id: 80,
    name: "India",
  },
  {
    id: 81,
    name: "Indonesia",
  },
  {
    id: 84,
    name: "Iran",
  },
  {
    id: 83,
    name: "Iraq",
  },
  {
    id: 85,
    name: "Ireland",
  },
  {
    id: 147,
    name: "Isle of Man",
  },
  {
    id: 8,
    name: "Israel",
  },
  {
    id: 88,
    name: "Italy",
  },
  {
    id: 228,
    name: "Jamaica",
  },
  {
    id: 229,
    name: "Japan",
  },
  {
    id: 237,
    name: "Jersey",
  },
  {
    id: 82,
    name: "Jordan",
  },
  {
    id: 4,
    name: "Kazakhstan",
  },
  {
    id: 94,
    name: "Kenya",
  },
  {
    id: 96,
    name: "Kiribati",
  },
  {
    id: 105,
    name: "Kuwait",
  },
  {
    id: 11,
    name: "Kyrgyzstan",
  },
  {
    id: 106,
    name: "Laos",
  },
  {
    id: 12,
    name: "Latvia",
  },
  {
    id: 109,
    name: "Lebanon",
  },
  {
    id: 107,
    name: "Lesotho",
  },
  {
    id: 108,
    name: "Liberia",
  },
  {
    id: 110,
    name: "Libya",
  },
  {
    id: 111,
    name: "Liechtenstein",
  },
  {
    id: 13,
    name: "Lithuania",
  },
  {
    id: 112,
    name: "Luxembourg",
  },
  {
    id: 116,
    name: "Macau",
  },
  {
    id: 117,
    name: "Macedonia",
  },
  {
    id: 115,
    name: "Madagascar",
  },
  {
    id: 118,
    name: "Malawi",
  },
  {
    id: 119,
    name: "Malaysia",
  },
  {
    id: 121,
    name: "Maldives",
  },
  {
    id: 120,
    name: "Mali",
  },
  {
    id: 122,
    name: "Malta",
  },
  {
    id: 125,
    name: "Marshall Islands",
  },
  {
    id: 124,
    name: "Martinique",
  },
  {
    id: 114,
    name: "Mauritania",
  },
  {
    id: 113,
    name: "Mauritius",
  },
  {
    id: 126,
    name: "Mexico",
  },
  {
    id: 127,
    name: "Micronesia",
  },
  {
    id: 15,
    name: "Moldova",
  },
  {
    id: 129,
    name: "Monaco",
  },
  {
    id: 130,
    name: "Mongolia",
  },
  {
    id: 230,
    name: "Montenegro",
  },
  {
    id: 131,
    name: "Montserrat",
  },
  {
    id: 123,
    name: "Morocco",
  },
  {
    id: 128,
    name: "Mozambique",
  },
  {
    id: 132,
    name: "Myanmar",
  },
  {
    id: 133,
    name: "Namibia",
  },
  {
    id: 134,
    name: "Nauru",
  },
  {
    id: 135,
    name: "Nepal",
  },
  {
    id: 139,
    name: "Netherlands",
  },
  {
    id: 143,
    name: "New Caledonia",
  },
  {
    id: 142,
    name: "New Zealand",
  },
  {
    id: 140,
    name: "Nicaragua",
  },
  {
    id: 136,
    name: "Niger",
  },
  {
    id: 137,
    name: "Nigeria",
  },
  {
    id: 141,
    name: "Niue",
  },
  {
    id: 148,
    name: "Norfolk Island",
  },
  {
    id: 173,
    name: "North Korea",
  },
  {
    id: 174,
    name: "Northern Mariana Islands",
  },
  {
    id: 144,
    name: "Norway",
  },
  {
    id: 146,
    name: "Oman",
  },
  {
    id: 152,
    name: "Pakistan",
  },
  {
    id: 153,
    name: "Palau",
  },
  {
    id: 154,
    name: "Palestine",
  },
  {
    id: 155,
    name: "Panama",
  },
  {
    id: 156,
    name: "Papua New Guinea",
  },
  {
    id: 157,
    name: "Paraguay",
  },
  {
    id: 158,
    name: "Peru",
  },
  {
    id: 206,
    name: "Philippines",
  },
  {
    id: 159,
    name: "Pitcairn Islands",
  },
  {
    id: 160,
    name: "Poland",
  },
  {
    id: 161,
    name: "Portugal",
  },
  {
    id: 162,
    name: "Puerto Rico",
  },
  {
    id: 93,
    name: "Qatar",
  },
  {
    id: 163,
    name: "Réunion",
  },
  {
    id: 165,
    name: "Romania",
  },
  {
    id: 1,
    name: "Russia",
  },
  {
    id: 164,
    name: "Rwanda",
  },
  {
    id: 172,
    name: "Saint Helena",
  },
  {
    id: 178,
    name: "Saint Kitts and Nevis",
  },
  {
    id: 179,
    name: "Saint Lucia",
  },
  {
    id: 180,
    name: "Saint Pierre and Miquelon",
  },
  {
    id: 177,
    name: "Saint Vincent and the Grenadines",
  },
  {
    id: 167,
    name: "Samoa",
  },
  {
    id: 168,
    name: "San Marino",
  },
  {
    id: 169,
    name: "São Tomé and Príncipe",
  },
  {
    id: 170,
    name: "Saudi Arabia",
  },
  {
    id: 176,
    name: "Senegal",
  },
  {
    id: 181,
    name: "Serbia",
  },
  {
    id: 175,
    name: "Seychelles",
  },
  {
    id: 190,
    name: "Sierra Leone",
  },
  {
    id: 182,
    name: "Singapore",
  },
  {
    id: 234,
    name: "Sint Maarten",
  },
  {
    id: 184,
    name: "Slovakia",
  },
  {
    id: 185,
    name: "Slovenia",
  },
  {
    id: 186,
    name: "Solomon Islands",
  },
  {
    id: 187,
    name: "Somalia",
  },
  {
    id: 227,
    name: "South Africa",
  },
  {
    id: 226,
    name: "South Korea",
  },
  {
    id: 232,
    name: "South Sudan",
  },
  {
    id: 87,
    name: "Spain",
  },
  {
    id: 220,
    name: "Sri Lanka",
  },
  {
    id: 188,
    name: "Sudan",
  },
  {
    id: 189,
    name: "Suriname",
  },
  {
    id: 219,
    name: "Svalbard and Jan Mayen",
  },
  {
    id: 171,
    name: "Swaziland",
  },
  {
    id: 218,
    name: "Sweden",
  },
  {
    id: 217,
    name: "Switzerland",
  },
  {
    id: 183,
    name: "Syria",
  },
  {
    id: 192,
    name: "Taiwan",
  },
  {
    id: 16,
    name: "Tajikistan",
  },
  {
    id: 193,
    name: "Tanzania",
  },
  {
    id: 191,
    name: "Thailand",
  },
  {
    id: 194,
    name: "Togo",
  },
  {
    id: 195,
    name: "Tokelau",
  },
  {
    id: 196,
    name: "Tonga",
  },
  {
    id: 197,
    name: "Trinidad and Tobago",
  },
  {
    id: 199,
    name: "Tunisia",
  },
  {
    id: 200,
    name: "Turkey",
  },
  {
    id: 17,
    name: "Turkmenistan",
  },
  {
    id: 151,
    name: "Turks and Caicos Islands",
  },
  {
    id: 198,
    name: "Tuvalu",
  },
  {
    id: 201,
    name: "Uganda",
  },
  {
    id: 2,
    name: "Ukraine",
  },
  {
    id: 145,
    name: "United Arab Emirates",
  },
  {
    id: 49,
    name: "United Kingdom",
  },
  {
    id: 203,
    name: "Uruguay",
  },
  {
    id: 53,
    name: "US Virgin Islands",
  },
  {
    id: 9,
    name: "USA",
  },
  {
    id: 18,
    name: "Uzbekistan",
  },
  {
    id: 48,
    name: "Vanuatu",
  },
  {
    id: 233,
    name: "Vatican",
  },
  {
    id: 51,
    name: "Venezuela",
  },
  {
    id: 55,
    name: "Vietnam",
  },
  {
    id: 202,
    name: "Wallis and Futuna",
  },
  {
    id: 78,
    name: "Western Sahara",
  },
  {
    id: 89,
    name: "Yemen",
  },
  {
    id: 77,
    name: "Zambia",
  },
  {
    id: 79,
    name: "Zimbabwe",
  },
];
