import { ReactComponent as ArchiveIcon } from '@/assets/icons/archive.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/search.svg';
import { ReactComponent as TrashIcon } from '@/assets/icons/trash.svg';
import BaseButton from '@/components/base/button.component';
import SelectField from '@/components/base/select-field.component';
import { useAppBreakpoints } from '@/hooks';
import { IconButton, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as ArrowLeftIcon } from '@/assets/icons/left-arrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import BaseAutocomplete from '@/components/base/base-autocomplete';
import useDebounce from '@/hooks/useDebounce';
import ProfilesService from '@/services/profiles.service';
import { UserProfile } from '@/types/user-profile.types';
import CloseIcon from '@mui/icons-material/Close';
import { GiftKind, SelectGiftsOption, SortlistNames } from '@/app/gifts/gifts.types';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  checkFewGifts,
  checkGift,
  markAsReadSelectedGiftsAction,
  resetUserGift,
  setSearchProfileIdAction,
  setSelectedGifts,
  setSortingAction,
} from '@/app/gifts/gifts.actions';

const sortList = [
  { id: 1, name: SortlistNames.DATEASC },
  { id: 2, name: SortlistNames.DATEDESC },
  { id: 3, name: SortlistNames.NAMEASC },
  { id: 4, name: SortlistNames.NAMEDESC },
];

const selectList = [
  { id: 1, name: SelectGiftsOption.NO_SELECTION },
  { id: 2, name: SelectGiftsOption.ALL },
  { id: 3, name: SelectGiftsOption.NEW },
  { id: 4, name: SelectGiftsOption.READ },
];

const useStyles = makeStyles((theme: Theme) => ({
  selectField: {
    minWidth: 176,
    [theme.breakpoints.down('md')]: {
      minWidth: 113,
      '& .MuiFilledInput-input': {
        maxWidth: '75px !important',
      },
    },
  },
  subSelectField: {
    minWidth: 120,
    [theme.breakpoints.down('md')]: {
      minWidth: 113,
      '& .MuiFilledInput-input': {
        maxWidth: '75px !important',
      },
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchHeader: {
    width: '100%',
    padding: '0 10px',
    [theme.breakpoints.down('sm')]: {
      minWidth: 113,
      padding: '0 5px',
      '& input': {
        width: '97px !important',
      },
    },
  },
  headerdWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 26,
  },
  searchWrapper: {
    height: '42px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    paddingLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '12px',
    },
  },

  headerCta: {
    display: 'flex',
    gap: 24,
    marginLeft: 24,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 12,
    },
  },
  ctaButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: '#000',
  },
  ctaIcon: {
    maxWidth: 42,
    maxHeight: 42,
    color: `${theme.palette.radioSelected.main} !important`,
    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },
  backBtn: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },
}));

type GiftsHeaderProps = {
  enableGiftLayout?: boolean | undefined;
};

const GiftsHeader = ({ enableGiftLayout }: GiftsHeaderProps) => {
  const classes = useStyles();
  const { downLg, smAndUp } = useAppBreakpoints();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id: giftId } = useParams();
  const { kind } = useParams<{
    kind: GiftKind;
  }>();
  const gifts = useAppSelector((state) => state.gifts[kind + 'Gifts']);
  const chosenSorting = useAppSelector((state) => state.gifts.sorting);
  const selectedGiftsType = useAppSelector((state) => state.gifts.selectedGifts);

  const isGiftsChosed = useAppSelector((state) => state.gifts.chosenIds.size);

  const selectHandler = useCallback(
    (value: string) => {
      dispatch(setSelectedGifts(value));

      switch (value) {
        case SelectGiftsOption.NO_SELECTION:
          dispatch(checkFewGifts([]));
          break;

        case SelectGiftsOption.ALL:
          const allGiftIds = gifts.map((gift) => gift.id);
          dispatch(checkFewGifts(allGiftIds));
          break;

        case SelectGiftsOption.NEW:
          const newGiftIds = gifts.filter((gift) => !gift.seen).map((gift) => gift.id);
          dispatch(checkFewGifts(newGiftIds));
          break;

        case SelectGiftsOption.READ:
          const readGiftIds = gifts.filter((gift) => gift.seen).map((gift) => gift.id);
          dispatch(checkFewGifts(readGiftIds));
          break;
      }
    },
    [dispatch, gifts]
  );

  const getControlledValue = useMemo(() => {
    if (selectedGiftsType === SelectGiftsOption.NEW) {
      return selectList[2];
    }

    if (selectedGiftsType === SelectGiftsOption.READ) {
      return selectList[3];
    }

    if (selectedGiftsType === SelectGiftsOption.ALL) {
      return selectList[1];
    }

    return selectList[0];
  }, [selectedGiftsType]);

  const getControlledSortValue = useMemo(() => {
    switch (chosenSorting) {
      case SortlistNames.DATEASC:
        return sortList[0];
      case SortlistNames.DATEDESC:
        return sortList[1];
      case SortlistNames.NAMEASC:
        return sortList[2];
      case SortlistNames.NAMEDESC:
        return sortList[3];
      default:
        return sortList[0];
    }
  }, [chosenSorting]);

  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);
  const headerActions = [
    {
      icon: <ArchiveIcon />,
      onClick: () => dispatch(markAsReadSelectedGiftsAction(kind)),
    },
  ];

  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState<UserProfile[]>([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);

      ProfilesService.getAllProfilesByPageId(debouncedSearchTerm).then((results) => {
        setIsSearching(false);
        setResults(results.data);
      });
    } else {
      setResults([]);
    }
  }, [debouncedSearchTerm]);

  if (enableGiftLayout) {
    return (
      <div className={classes.header}>
        <div className={classes.backBtn}>
          <BaseButton
            inverted
            text=''
            className={classes.ctaIcon}
            textStyle=''
            onClick={() => {
              dispatch(checkGift(giftId));
              dispatch(resetUserGift());
              navigate(-1);
            }}
            Icon={<ArrowLeftIcon />}
          />
          <Typography variant='body3'>Back</Typography>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <Formik
        initialValues={{
          sort: '',
        }}
        onSubmit={(values) => {
          //   dispatch(getSearchUsersRequest(values));
        }}
      >
        {({ setFieldValue }) => (
          <div className={classes.header}>
            <div className={classes.headerdWrapper}>
              <SelectField
                placeholder='Sort by'
                list={sortList}
                label='sort'
                className={classes.selectField}
                onChnage={(value: any) => dispatch(setSortingAction(value))}
                controlledValue={getControlledSortValue}
              />
            </div>

            {isSearchMode ? (
              <>
                <div className={classes.searchHeader}>
                  <BaseAutocomplete
                    setOptions={setResults}
                    name='receiverId'
                    placeholder='Search (ID):'
                    onChange={(e, val) => {
                      setFieldValue('receiverId', val);
                      if (val?.id) {
                        dispatch(setSearchProfileIdAction(val.id));
                      }
                    }}
                    onInput={(e: any) => {
                      if (e.target.validity.valid) {
                        setSearchTerm(e.currentTarget.value);
                      }
                    }}
                    options={results}
                    loading={isSearching}
                  />
                </div>
                <IconButton
                  size='small'
                  onClick={() => {
                    dispatch(setSearchProfileIdAction(null));
                    setSearchTerm('');
                    setIsSearchMode(false);
                  }}
                >
                  <CloseIcon color='inherit' />
                </IconButton>
              </>
            ) : (
              <>
                <div className={classes.searchWrapper} onClick={() => setIsSearchMode(true)}>
                  <SearchIcon />
                </div>

                <div className={classes.headerCta}>
                  {!downLg || (smAndUp && isGiftsChosed)
                    ? headerActions.map((action, idx) => (
                        <BaseButton
                          key={idx}
                          inverted
                          text=''
                          className={classes.ctaIcon}
                          style={{ opacity: isGiftsChosed ? '1' : '.3' }}
                          textStyle=''
                          onClick={action.onClick}
                          Icon={action.icon}
                          disabled={!isGiftsChosed}
                        />
                      ))
                    : null}
                  <SelectField
                    placeholder='Select'
                    list={selectList}
                    label='select'
                    className={classes.subSelectField}
                    onChnage={(value: string) => {
                      selectHandler(value);
                    }}
                    controlledValue={getControlledValue}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </Formik>
    </Fragment>
  );
};

export default GiftsHeader;
