import {
  ReligionOptions,
  EducationOptions,
  SmokeOptions,
  DrinkOptions,
  ChildrenOptions,
  BodyOptions,
  EyeColorOptions,
  HairOptions,
  MaritalOptions,
} from "@/types/edit-select.types";
import { UserChildrenEnum } from "@/types/user-profile.types";

export const religionList: ReligionOptions[] = [
  { value: "Christianity", name: "Christianity" },
  { value: "Catholicism", name: "Catholicism" },
  { value: "Buddhism", name: "Buddhism" },
  { value: "Hinduism", name: "Hinduism" },
  { value: "Islam", name: "Islam" },
  { value: "other", name: "Other" },
];
export const educationList: EducationOptions[] = [
  { value: "no", name: "No" },
  { value: "Middle School", name: "Middle School" },
  { value: "High school", name: "High school" },
  { value: "Student", name: "Student" },
  { value: "Bachelor's Degree", name: "Bachelor's Degree" },
  { value: "Master's Degree", name: "Master's Degree" },
  { value: "Doctoral Level", name: "Doctoral Level" },
];

export const smokeList: SmokeOptions[] = [
  { value: "rarely", name: "Rarely" },
  { value: "often", name: "Often" },
  { value: "I do not smoke", name: "I do not smoke" },
];

export const drinkList: DrinkOptions[] = [
  { value: "rarely", name: "Rarely" },
  { value: "occasionally", name: "Occasionally" },
  { value: "often", name: "Often" },
  { value: "I do not drink", name: "I do not drink" },
];

export const childrenList: ChildrenOptions[] = [
  { value: "no", name: "No" },
  {
    value: "no but I want to have children",
    name: UserChildrenEnum["no but I want to have children"],
  },
  { value: "have children", name: UserChildrenEnum["have children"] },
  {
    value: "have children but living separately",
    name: UserChildrenEnum["have children but living separately"],
  },
  {
    value: "have adult children",
    name: UserChildrenEnum["have adult children"],
  },
];

export const familyList: MaritalOptions[] = [
  { value: "no", name: "No" },
  {
    value: "single",
    name: "single",
  },
  { value: "divorced", name: "divorced" },
  {
    value: "widow",
    name: "widow",
  },
  {
    value: "never been married",
    name: "never been married",
  },
];

export const bodyList: BodyOptions[] = [
  { value: "athletic", name: "Athletic" },
  { value: "overweight", name: "Overweight" },
  { value: "strong", name: "Strong" },
  { value: "plump", name: "Plump" },
  { value: "medium-build", name: "Medium-build" },
  { value: "slim", name: "Slim" },
  { value: "thin", name: "Thin" },
];

export const eyeColorOptions: EyeColorOptions[] = [
  { value: "black", name: "Black" },
  { value: "green", name: "Green" },
  { value: "gray", name: "Gray" },
  { value: "brown", name: "Brown" },
  { value: "light-brown", name: "Light-brown" },
  { value: "blue", name: "Blue" },
];

export const hairList: HairOptions[] = [
  { value: "dark", name: "Dark" },
  { value: "fair", name: "Fair" },

  { value: "red", name: "Red" },

  { value: "brown", name: "Brown" },

  { value: "blond", name: "Blond" },
  { value: "gray", name: "Gray" },
];
