import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BaseButton from '@/components/base/button.component';

export const CloseQuestionsComponent = ({
  closeForEver,
  closeForDays,
  id,
  openDialog,
  handleCloseDialog,
}: {
  closeForDays: Function;
  closeForEver: Function;
  id: string;
  openDialog: boolean;
  handleCloseDialog: any;
}) => {
  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>Do you want to close questions?</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <BaseButton text={'back'} onClick={handleCloseDialog} color='secondary' />
        <BaseButton
          text='close for ever'
          onClick={() => {
            handleCloseDialog();
            closeForEver(id);
          }}
          color='primary'
        />
        <BaseButton
          text='close for 3 days'
          onClick={() => {
            handleCloseDialog();
            closeForDays(id);
          }}
          color='primary'
        />
      </DialogActions>
    </Dialog>
  );
};
