import { CountersShape, MailDto, MailShape, SendMailResponse } from '@/services/mail.service';
import {
  SEND_MAIL_REQUEST,
  SEND_MAIL_SUCCESS,
  SEND_MAIL_FAILURE,
  GET_MAILS_REQUEST,
  GET_MAILS_FAILURE,
  GET_MAILS_SUCCESS,
  CHECK_MESSAGE,
  CHECK_FEW_MESSAGES,
  SET_IS_BOOKMARKS,
  SET_SEARCH_PROFILE_ID,
  SET_SORTING,
  RESET_PAGINATION_AND_MAILS,
  SET_PAGINATION,
  SET_PAGINATION_IS_OVER,
  DELETE_SELECTED_MESSAGES,
  MARK_AS_READ_SELECTED_MESSAGES,
  MARK_AS_READ_SELECTED_MESSAGES_SUCCESS,
  SET_SELECTED_MESSAGES,
  RESET_SELECTED_MESSAGES,
  ADD_TO_BOOKMARKS,
  ADD_TO_BOOKMARKS_SUCCESS,
  GET_USER_MESSAGE_REQUEST,
  GET_USER_MESSAGE_SUCCESS,
  GET_USER_MESSAGE_FAIL,
  RESET_USER_MESSAGE_FAIL,
  MailKind,
  SortlistNames,
  GET_CLOSED_ATTACH_REQUEST,
  GET_CLOSED_ATTACH_SUCCESS,
  GET_CLOSED_ATTACH_FAIL,
  GET_MESSAGE_COUNTERS,
  GET_MESSAGE_COUNTERS_SUCCESS,
  SOCKET_UPDATE_MESSAGES_COUNT,
} from './mails.types';

export const getMailsRequest = (kind: MailKind) => ({
  type: GET_MAILS_REQUEST,
  payload: kind,
});

export const getMailsSuccess = (kind: MailKind, payload: Array<MailShape>) => ({
  type: GET_MAILS_SUCCESS,
  payload,
  kind,
});

export const getMailsFailure = (error: unknown) => ({
  type: GET_MAILS_FAILURE,
  error,
});

export const sendMailRequest = (payload: MailDto & { closeHandler: Function }) => ({
  type: SEND_MAIL_REQUEST,
  payload,
});

export const sendMailSuccess = (payload: SendMailResponse) => ({
  type: SEND_MAIL_SUCCESS,
  payload,
});

export const sendMailFailure = (error: unknown) => ({
  type: SEND_MAIL_FAILURE,
  error,
});

export const checkMessage = (messageId: string) => ({
  type: CHECK_MESSAGE,
  payload: messageId,
});

export const checkFewMessages = (messageIds: Array<string>) => ({
  type: CHECK_FEW_MESSAGES,
  payload: messageIds,
});

export const setIsBookmarksAction = (isBookmarksState: boolean) => ({
  type: SET_IS_BOOKMARKS,
  payload: isBookmarksState,
});

export const setSearchProfileIdAction = (id: string | null) => ({
  type: SET_SEARCH_PROFILE_ID,
  payload: id,
});

export const setSortingAction = (sort: SortlistNames | null) => ({
  type: SET_SORTING,
  payload: sort,
});

export const resetPaginationAndMailsAction = (kind: MailKind) => ({
  type: RESET_PAGINATION_AND_MAILS,
  payload: kind,
});

export const setPaginationAction = (paginationOffset: number) => ({
  type: SET_PAGINATION,
  payload: paginationOffset,
});

export const setPaginationOverAction = (isOver: boolean) => ({
  type: SET_PAGINATION_IS_OVER,
  payload: isOver,
});

export const deleteSelectedMessagesAction = (kind: MailKind) => ({
  type: DELETE_SELECTED_MESSAGES,
  payload: kind,
});

export const markAsReadSelectedMessagesAction = (kind: MailKind) => ({
  type: MARK_AS_READ_SELECTED_MESSAGES,
  payload: kind,
});

export const markAsReadSelectedMessagesSuccessAction = (kind: MailKind, chosenIds: Set<string>) => ({
  type: MARK_AS_READ_SELECTED_MESSAGES_SUCCESS,
  payload: { kind, chosenIds },
});

export const setSelectedMessages = (type: string) => ({
  type: SET_SELECTED_MESSAGES,
  payload: type,
});

export const resetSelectedMessages = () => ({
  type: RESET_SELECTED_MESSAGES,
});

export const addToBookmarksAction = (id: string, kind: string) => ({
  type: ADD_TO_BOOKMARKS,
  payload: { id, kind },
});

export const addToBookmarksSuccessAction = (payload: { id: string; kind: string }) => ({
  type: ADD_TO_BOOKMARKS_SUCCESS,
  payload,
});

export const getUserMessageRequest = (id: string, onErrorHandler: () => void) => ({
  type: GET_USER_MESSAGE_REQUEST,
  payload: {
    id,
    onErrorHandler,
  },
});

export const getUserMessageSuccess = (payload: MailShape) => ({
  type: GET_USER_MESSAGE_SUCCESS,
  payload,
});

export const getUserMessageFail = () => ({
  type: GET_USER_MESSAGE_FAIL,
});

export const resetUserMessage = () => ({
  type: RESET_USER_MESSAGE_FAIL,
});

export const getClosedAttachRequest = (attachId: string, messageId: string, isVideo: boolean) => ({
  type: GET_CLOSED_ATTACH_REQUEST,
  payload: { attachId, messageId, isVideo },
});

export const getClosedAttachSuccess = (payload: MailShape) => ({
  type: GET_CLOSED_ATTACH_SUCCESS,
  payload,
});

export const getClosedAttachFail = () => ({
  type: GET_CLOSED_ATTACH_FAIL,
});

export const getMessageCountersAction = () => ({
  type: GET_MESSAGE_COUNTERS,
});

export const getMessageCountersSuccessAction = (payload: CountersShape) => ({
  type: GET_MESSAGE_COUNTERS_SUCCESS,
  payload,
});

export const socketUpdateMessagesCount = (payload: CountersShape) => ({
  type: SOCKET_UPDATE_MESSAGES_COUNT,
  payload,
});
