import {
  Box,
  List,
  ListItem,
  Popover,
  PopoverProps,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useCallback } from "react";

type DialogMessagesProps = {
  anchorEl: PopoverProps["anchorEl"];
  setAnchorEl: React.Dispatch<React.SetStateAction<null>>;
  metaButtons: JSX.Element;
  isBookmark: boolean | null;
  anchorOrigin?: {
    vertical: string;
    horizontal: string;
  }
  wihoutTriangle?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogInner: {
    "& > .MuiTypography-root": {
      color: "inherit",
    },

    display: "flex",
    flexDirection: "column",
    width: 210,
    height: 72,
    padding: 0,
    paddingTop: 0,
    justifyContent: "center",
  },
  listItem: {
    justifyContent: 'center !important'
  },
  listItemButton: {
    "&:hover > .MuiListItemText-root	": {
      color: "#503EB6",
    },
  },
}));

const stylesOverrides = {
  dialogInner: {
    paddingTop: 0,
  },
  listItemButton: {
    padding: "6px 16px",
  }
};

const DialogMessageMetaActions = ({
  anchorEl,
  setAnchorEl,
  metaButtons,
  isBookmark,
  wihoutTriangle
}: DialogMessagesProps) => {
  const classes = useStyles();

  const handleClose = useCallback((state?: boolean) => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const open = Boolean(anchorEl);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      transformOrigin={{
        vertical: 55,
        horizontal: 70,
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          borderRadius: 10,
          overflow: "initial",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          mt: "10px",
          "&::before": wihoutTriangle ? {} : {
            backgroundColor: "white",
            content: '""',
            display: "block",
            position: "absolute",
            width: 12,
            height: 12,
            bottom: -86,
            transform: "rotate(45deg)",
            left: "97px",
          },
        }}
      />
      <List className={classes.dialogInner} style={stylesOverrides.dialogInner}>
        <Fragment>
          <ListItem className={classes.listItem}>
            {metaButtons}
          </ListItem>
        </Fragment>
      </List>
    </Popover>
  );
};

export default DialogMessageMetaActions;
