import { IUserVideosData } from '@/app/users/users.dto';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  CircularProgress,
  Dialog,
  Fade,
  IconButton,
  IconButtonProps,
  styled,
  Theme,
  Typography,
  Zoom,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { Fragment, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { useAppSelector } from '../../app/hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

import { Gallery, Item } from 'react-photoswipe-gallery';
import UploadVideoItem from '../base/upload-video-item';

type DialogVideoFileUploadProps = {
  title?: JSX.Element;
  className: string;
  titleClassName?: string;
  handleFileUploading: (image: File) => void;
  handleRemoveFile?: (id: string) => void;
  defaultValue: IUserVideosData;
  isOpen: boolean;
  onClose: () => void;
  handleClickVideo?: (
    id: string,
    thumbnail: string,
    origin: string,
    openHandler: () => void,
    onClose: () => void
  ) => void;
  attachedVideoIds?: Set<string>;
};

type DeleteIconStyledType = React.ComponentType<IconButtonProps>;

export const DeleteIconStyled: DeleteIconStyledType = styled((props) => (
  <IconButton {...props} size='small'>
    <DeleteIcon color='inherit' />
  </IconButton>
))(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    right: 10,
    top: 10,
  },

  [theme.breakpoints.down(640)]: {
    right: -5,
    top: -5,
  },
  width: 28,
  height: 28,
  position: 'absolute',
  right: -10,
  top: -10,
  backgroundColor: '#DEDEDE',
  color: theme.palette.radioSelected.main,
  '& > .MuiSvgIcon-root': {
    color: '#000',
  },
  ':hover': {
    backgroundColor: theme.palette.grey[400],
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: 20,
    padding: 24,
  },

  root: {
    border: `2px dashed ${theme.palette.radioSelected.main}`,
    padding: 12,
    borderRadius: 10,
    flexWrap: 'wrap',
    display: 'flex',
    flexwrap: 'wrap',
    alignContent: 'space-between',
    overflowY: 'auto',
  },
  dropzone: {
    cursor: 'pointer',
    color: theme.palette.radioSelected.main,
    outline: 'none',
    margin: 12,
    borderRadius: 15,
    width: 180,
    height: 180,
    backgroundColor: theme.palette.info.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerZone: {
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    borderRadius: 'inherit',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
  },
  dropIcon: {
    fontSize: '2.7rem',
  },
  paper: {
    padding: 24,
  },
}));

type CloseIconStyledType = React.ComponentType<IconButtonProps>;

export const CloseIconStyled: CloseIconStyledType = styled((props) => (
  <IconButton {...props} size='small'>
    <CloseIcon color='inherit' />
  </IconButton>
))(({ theme }) => ({
  width: 28,
  height: 28,
  position: 'absolute',
  right: -10,
  top: -10,
  backgroundColor: '#DEDEDE',
  color: theme.palette.grey[500],
  '& > .MuiSvgIcon-root': {
    color: '#000',
  },
  ':hover': {
    backgroundColor: theme.palette.grey[400],
  },
}));

const DialogVideoFileUpload = ({
  title,
  handleFileUploading,
  handleRemoveFile,
  className,
  titleClassName,
  defaultValue,
  isOpen,
  onClose,
  handleClickVideo,
  attachedVideoIds,
}: DialogVideoFileUploadProps) => {
  const isUploading = useAppSelector((state) => state.users.videosIsUploading);
  const classes = useStyles();

  const handleDrop = useCallback(
    (acceptedFiles: any) => {
      handleFileUploading(acceptedFiles[0]);
    },
    [handleFileUploading]
  );

  return (
    <Dialog
      TransitionComponent={Zoom}
      open={isOpen}
      onClose={onClose}
      className={classes.container}
      classes={{
        paper: classes.paper,
      }}
    >
      <CloseIconStyled onClick={onClose} />
      {title}
      <Dropzone
        onDrop={handleDrop}
        accept='video/mp4, video/webm, video/ogg, application/mp4, video/quicktime, application/mp4, video/x-msvideo, video/x-flv'
      >
        {({ getRootProps, getInputProps }) => (
          <section className={clsx(classes.root, className)}>
            <div {...getRootProps()} className={classes.dropzone}>
              <input {...getInputProps()} />
              <AddCircleOutlineIcon fontSize='medium' color='inherit' className={classes.dropIcon} />
            </div>
            {defaultValue.data.map(({ thumbnail, origin, id, private: isPrivate }) => (
              <div key={thumbnail} style={{ position: 'relative' }}>
                <div className={classes.dropzone}>
                  <UploadVideoItem
                    id={id}
                    isExpended={true}
                    thumbnail={thumbnail}
                    isPrivate={true}
                    withoutIcon={handleRemoveFile ? false : true}
                    isEditable={true}
                    handleRemoveFile={handleRemoveFile}
                    origin={origin}
                    handleSelect={handleClickVideo ? () => handleClickVideo(id, thumbnail, origin, () => {}, onClose) : null}
                    />
                </div>
              </div>
            ))}

            <Fade in={isUploading}>
              <div className={classes.dropzone}>
                <CircularProgress color='inherit' size={24} />
              </div>
            </Fade>
          </section>
        )}
      </Dropzone>
    </Dialog>
  );
};

export default DialogVideoFileUpload;
