import { MailKind, SortlistNames } from '@/app/mails/mails.types';
import { HttpService } from './http.service';
import { ErrorWrapper } from './utils';
import { StorageService } from './storage.service';

export interface MailDto {
  receiverId: string;
  subject: string;
  text: string;
  photoIds?: string[];
  videoIds?: string[];
}

export interface SendMailResponse {
  id: string;
  senderId: string;
  receiverId: string;
  subject: string;
  text: string;
  createdAt: Date;
  seen: Date;
  profileId: string;
  profileName: string;
  profileAvatar: string;
}

export interface MailShape {
  id: string;
  subject: string;
  text: string;
  seen: string | null;
  profileId: string;
  profileName: string;
  profileAvatar: string;
  createdAt: string;
  activeAt: string;
  isBookmark: boolean | null;
  profileBirthday: string | null;
  profilePageId: string;
}
export interface CountersShape {
  unseen: number;
  bookmarksUnseen: number;
}

const getSortQuery = (sorting: SortlistNames | 'Sort by') => {
  switch (sorting) {
    case SortlistNames.DATEASC:
      return '&sortField=created_at&sortOrder=ASC';
    case SortlistNames.DATEDESC:
      return '&sortField=created_at&sortOrder=DESC';
    case SortlistNames.NAMEASC:
      return '&sortField=name&sortOrder=ASC';
    case SortlistNames.NAMEDESC:
      return '&sortField=name&sortOrder=DESC';
    default:
      return '';
  }
};

const getQueryParams = (searchProfileId, sorting, pagination) => {
  if (!searchProfileId && !sorting && !pagination) return '';

  return `?limit=${pagination.limit}&offset=${pagination.offset}${
    searchProfileId ? `&profileId=${searchProfileId}` : ''
  }${getSortQuery(sorting)}`;
};

export default class MailService {
  static get entity(): string {
    return 'messages';
  }

  static async sendMail(data: MailDto): Promise<SendMailResponse> {
    try {
      const response = await HttpService.post(`/${this.entity}`, data);
      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.message === 'Not enough credits') {
        throw new ErrorWrapper(error, error.response.data.message);
      }

      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getMails(
    type: MailKind,
    isBookmarks: boolean,
    pagination: { limit: number; offset: number },
    searchProfileId: number | null,
    sorting: SortlistNames
  ): Promise<Array<MailShape>> {
    const serviceRequest = `/${this.entity}/${type}${isBookmarks ? '/bookmarks' : ''}${getQueryParams(
      searchProfileId,
      sorting,
      pagination
    )}`;

    try {
      const response = await HttpService.get(serviceRequest);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async deleteMails(chosenIds: Array<string>): Promise<Array<MailShape>> {
    const config = {
      data: {
        ids: chosenIds,
      },
    };

    try {
      const response = await HttpService.delete(`/${this.entity}`, config);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async markAsReadMails(chosenIds: Array<string>): Promise<Array<MailShape>> {
    try {
      const response = await HttpService.post(`/${this.entity}/seen`, {
        ids: chosenIds,
      });

      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async addToBookmarks(id: string): Promise<Array<MailShape>> {
    try {
      const response = await HttpService.post(`/bookmarks`, {
        profileId: id,
      });

      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getUserMail(id: string): Promise<MailShape> {
    try {
      const response = await HttpService.get(`/${this.entity}/${id}`);

      return response.data;
    } catch (error: any) {
      console.log('error', error?.response?.data);
      if (error?.response?.data?.message === 'Not enough credits') {
        throw new ErrorWrapper(error, error.response.data.message);
      }
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getClosedAttach(id: string, isVideo: boolean): Promise<MailShape> {
    try {
      const response = await HttpService.post(`/transactions/message-${isVideo ? 'video': 'photo'}-open`, {
        attachmentId: id,
      });

      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.message === 'Not enough credits') {
        throw new ErrorWrapper(error, error.response.data.message);
      }

      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getMessageCounters(): Promise<CountersShape> {
    try {
      const token = StorageService.getToken();
      if (!token) {
        return { unseen: 0, bookmarksUnseen: 0 };
      }
      const response = await HttpService.get(`/counter/messages`);

      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
