import GradientBg from '@/assets/images/bg-line.jpg';
import { useAppBreakpoints } from '@/hooks';
import { Container, SwipeableDrawer, Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';
import AvailableUsers from './components/available-users.component';
import LastUsers from './components/last-users.component';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  getAvailableUsersRequest,
  updateBalanceRequest,
  getSettingsRequest,
  getUsersDialogsRequest,
  getDialogCountersAction,
  getSearchUsersRequest,
  getAcquiringRequest,
} from '@/app/users/users.actions';
import { toggleAvailableUsersBar, toggleLastUsersBar } from '@/app/ui/ui.actions';
import { getMessageCountersAction } from '@/app/mails/mails.actions';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  mobileDrawerWrapper: {
    width: '100%',
  },
  sidebar: {
    zIndex: 10,
  },
  rightSidebar: {
    display: 'flex',
    marginTop: 60,
    position: 'sticky',
    top: 0,
    alignSelf: 'flex-start',

    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  leftSidebar: {
    display: 'flex',
    marginTop: 60,
    position: 'sticky',
    top: 0,
    alignSelf: 'flex-start',

    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  centerBlock: {
    display: 'flex',
    width: 'calc(100% - 4px)',
    height: 'calc(100vh - 118px)',
    marginTop: '40px',
    position: 'sticky',
    zIndex: 90,
    margin: '0 -2px 0 -2px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
      marginTop: '24px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
      height: 'calc(100vh - 118px)',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
      height: 'calc(100vh - 118px)',
    },
  },
  abstract: {
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 28,
    },
    '&:after': {
      content: "''",
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      height: 30,
      backgroundImage: `url(${GradientBg})`,
      background: 'linear-gradient(81.45deg, #503EB6 0%, #EA4160 100%)',
      [theme.breakpoints.down('sm')]: {
        height: 28,
      },
    },
  },
  drawerWrapper: {
    width: '100%',
  },
  backdrop: {
    opacity: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: -30,
    left: 0,
    background: 'rgba(0, 0, 0, .5)',
    zIndex: 40,
    outline: 'none',
    border: 'none',
    transition: 'all .5s',
  },
  availableChatBtn: {},
  lastChatBtn: {},
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px!important',
      paddingRight: '0px!important',
    },
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'minmax(0, 1fr)!important',
    },
  },
}));

type ChatContainerProps = {
  children: React.ReactNode;
};

const DialogContainer = ({ children }: ChatContainerProps) => {
  const { lg } = useAppBreakpoints();

  const { availableUsers, userDialogs, searchUsers } = useAppSelector((state) => state.users);
  const { isOpenVideoChat } = useAppSelector((state) => state.ui);

  const classes = useStyles({ isOpenVideoChat });

  const overrideStyles = {
    container: {
      display: 'flex',
      justifyContent: isOpenVideoChat ? 'center' : 'flex-start',
    },
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAvailableUsersRequest({}));
    dispatch(updateBalanceRequest());
    dispatch(getSettingsRequest());
    dispatch(getAcquiringRequest());
    dispatch(getUsersDialogsRequest({ offset: 0 }));
    dispatch(getMessageCountersAction());
    dispatch(getDialogCountersAction());
    dispatch(getSearchUsersRequest({}));
  }, []);

  const isAvailableDrawerOpen = useAppSelector((state) => state.ui.availableUsersOpen);
  const isLastChatDrawerOpen = useAppSelector((state) => state.ui.lastChatOpen);

  const toggleAvailableDrawer = useCallback(() => {
    dispatch(toggleAvailableUsersBar());
  }, [dispatch]);

  const toggleLastChatDrawer = useCallback(() => {
    dispatch(toggleLastUsersBar());
  }, [dispatch]);

  const getMoreUserDialogCallback = useCallback(() => {
    dispatch(getUsersDialogsRequest({ offset: userDialogs.data.length }));
  }, [dispatch, userDialogs.data.length]);

  return (
    <div className={classes.abstract}>
      <Container maxWidth={lg ? 'lg' : 'xl'} className={classes.container} style={overrideStyles.container}>
        <aside className={clsx(classes.rightSidebar, classes.sidebar)}>
          <SwipeableDrawer
            anchor='left'
            open={isAvailableDrawerOpen}
            onClose={toggleAvailableDrawer}
            onOpen={toggleAvailableDrawer}
          >
            <AvailableUsers searchUsers={searchUsers} availableUsers={availableUsers} onClick={toggleAvailableDrawer} />
          </SwipeableDrawer>
          <AvailableUsers searchUsers={searchUsers} availableUsers={availableUsers} />
        </aside>
        <div className={classes.centerBlock}>{children}</div>
        <aside className={clsx(classes.leftSidebar, classes.sidebar)}>
          <SwipeableDrawer
            anchor='right'
            open={isLastChatDrawerOpen}
            onClose={toggleLastChatDrawer}
            onOpen={toggleLastChatDrawer}
          >
            <LastUsers
              userDialogs={userDialogs}
              onClick={toggleLastChatDrawer}
              getMoreUserDialogCallback={getMoreUserDialogCallback}
            />
          </SwipeableDrawer>
          <LastUsers userDialogs={userDialogs} getMoreUserDialogCallback={getMoreUserDialogCallback} />
        </aside>
      </Container>
    </div>
  );
};

export default DialogContainer;
