import { useAppSelector } from '@/app/hooks';
import { ISettings } from '@/types/user-profile.types';
import { Container } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 'calc(100% - 56px)',
    padding: '28px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  headTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '26px',
    paddingBottom: '33px',
    width: '100%',
    textAlign: 'center',
  },
  paragraphFirst: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
  },
  paragraph: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
    paddingLeft: '40px',
  },
  greyParagraph: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
  },
  headline: {
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '56px',
    color: '#000000',
    marginTop: '54px',
    '& > ::marker': {
      color: '#EA4160',
    },
  },
  listItem: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#808080',
  },
  breakline: {
    background: '#E7E6E6',
    height: '1px',
    width: '100%',
    marginBottom: '10px',
  },
  verySmallItem: {
    display: 'flex',
    padding: '6px 0px 6px 60px',
  },
  smallItem: {
    display: 'flex',
    padding: '12px 0px 12px 40px',
  },
  sml: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    marginRight: '6px',
    minWidth: '40px',
  },
  smallContent: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#808080',
    '& > b': {
      color: 'black',
    },
  },
  additionalText: {
    padding: '10px 40px 0 40px',
  },
}));

const AntiScamPolicy = () => {
  const classes = useStyles();
  const { siteName } = useAppSelector<ISettings>((state) => state.users.settings);

  return (
    <Container maxWidth={'xl'}>
      <div className={classes.container}>
        <div className={classes.headTitle}>Anti scam-policy</div>
        <div className={classes.paragraphFirst}>
          <div>
            Our web application {siteName} is a brand of high quality and we try to preserve it the proper level and we
            are proud of it. To ensure our users to be protected from scammers while using the website our
            administration keeps the policy against the scam. The trust of our users is very important to us.
          </div>
          <div>
            This website cooperates with CPA (Cost Per Action) partners who are engaged in attracting users. All these
            users are adults. All users want to make acquaintances despite their religious preferences, nationality or
            place of residence and are in search of serious and long-term relationships. The registered customers can
            use our web service for free to view profiles.. However, their firm intention and having an account,
            registered through the CPA partner, for their profiles on our website to become active, because users must
            go through a verification procedure to confirm their identity, the purpose of registering the profile and
            the rights to the content uploaded by them to the site.
          </div>
        </div>
        <ol start={1} className={classes.headline}>
          <li>Situations that are not considered as scamming:</li>
        </ol>
        <div className={classes.paragraph}>
          <ul>
            <li>If the girl or man decides to end the relationship with you at any time during your communication;</li>
            <li>In case when the girl or man have changed the status of her profile activity on the website;</li>
            <li>
              If the activity of the user’s profile is often high or, on the contrary, the profile is long offline;
            </li>
            <li>
              If the user wears a ring. It follows that wearing a ring on any finger does not mean that user is married;
            </li>
            <li>
              When in any of the social networks or by means of e-mail the user does not respond to all your questions;
            </li>
            <li>
              When a girl or man does not answer calls and / or video calls made by any means of communication available
              outside the site;
            </li>
            <li>If the user refuses to provide her personal photos of an intimate nature for any reason;</li>
            <li>
              When a user has photos of friends, relatives, former partners and other personal information posted on
              another social network, including on our website
            </li>
            <li>
              When photo and / or user information is used for negative reviews on other sites. The exceptions are those
              websites that are licensed to carry out anti-scam activities.
            </li>
          </ul>
        </div>
        <ol start={2} className={classes.headline}>
          <li>
            All girls and men who want to use our service must provide their account details for the verification
            procedure:
          </li>
        </ol>
        <div className={classes.smallItem}>
          <div className={classes.sml}>2.1</div>
          <div className={classes.smallContent}>
            Firstly, the site administration analyzes in detail all the text data that is used to form a profile. CPA
            partners are liable to us for all content and its compliance with our rules. CPA partners are responsible
            for the content of the personal pages of users who have registered on our service through their affiliate
            administrative panel. Secondly, we also check email addresses and mobile contact numbers of all users.
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>2.2</div>
          <div className={classes.smallContent}>
            We check the correspondence between the data and the photos indicated in the copies of the documents
            provided to us and the content of the profile (text and multimedia) posted on the website in order to avoid
            using the profile of the user by another person.
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>2.3</div>
          <div className={classes.smallContent}>
            If the administration has any doubts related to the accuracy of the data, then we are able to verify the
            identity using a Skype video call or through another video calling service, since we can communicate and see
            all of our users.
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>2.4</div>
          <div className={classes.smallContent}>
            In case you doubt the good faith of any girl or man first make sure that this is the same user whose profile
            you have preferred. Further, in the video chat, you can ask her/his to turn on the webcam for visual
            contact. However, if the user refuses to turn on the camera for a long time, and also refuses to answer why
            she or he does not want to use the webcam, then you can complain about this user through feedback with our
            administration or by other convenient means and we will investigate this case in detail.
          </div>
        </div>

        <ol start={3} className={classes.headline}>
          <li>A few tips on how to avoid communication with scammers:</li>
        </ol>
        <div className={classes.smallItem}>
          <div className={classes.sml}>3.1</div>
          <div className={classes.smallContent}>
            You should not send the money to your passion before your relationship has gone beyond the site. You can not
            dispute the fact that most girls love shopping, and some of them are real beggars. Therefore, you should not
            provoke them with your financial capabilities. It is possible that after receiving your money, they will
            begin to spend it on cosmetics or clothing.
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>3.2</div>
          <div className={classes.smallContent}>
            Do not rush to conclusions, believing that you fell in love with a person after 1 or 2 months of
            communication. Moreover, do not think that your love is mutual, despite the fact that the girl or man says
            so. To find out about a person’s feelings, ask her or him to turn on video chat more often, so that you can
            see your interlocutor, or try to express your thoughts in the form of letters and talk to her or him about
            feelings in a regular chat. You have all reasons to believe that you know user in better way, know her
            thoughts and behavior, but at the same time you are not fully aware of her or him true feelings and
            character. Therefore, a meeting in real life remains the most reliable way to check whether you have not
            been deceived. To avoid scam, it is better to immediately get the contact details of your interlocutor and
            arrange a meeting. After that, with the help of our service, you can get assistance in organizing your trip
            to the country where she or he lives or we can help your interlocutor come to your country. It is extremely
            important to spend as much time with your interlocutor as possible in the real world (let it be at least a
            week) in order to understand whether you need each other or not.
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>3.3</div>
          <div className={classes.smallContent}>
            The help of a professional translator is one of the tools that will help avoid scammers, as well as support
            the search for your love. When communicating with a girlie a man, you think that you have 100% understanding
            between, but this may be not true. Many of our clients are not from English-speaking countries, they studied
            English at school, and some of them continue to learn the language at the university. At the same time, most
            users are confident in their excellent knowledge of the language, although they do not have enough speaking
            practice to develop their conversational skills. Moreover, some of the girls and men know only primary
            English. To understand your messages, many people use electronic online translators. Nevertheless, even
            modern electronic translators can not convey your thoughts reliably. But there is a way out; you can
            organize additional language training for users. Unfortunately, not all users have a chance to study in
            language courses, but we are sure that this should not be an obstacle to find a decent life partner on our
            service. Our obligation is to help our users, and for this purpose we recommend to use the services of
            professional translators at live meetings, who will help to communicate when someone among participants of
            the meeting has a problem with understanding foreign speech. Moreover, the officially invited translator has
            a responsibility to you. If you wish to meet your soul mate without advising our service, then our team
            reminds you that you do this at your own risk.
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>3.4</div>
          <div className={classes.smallContent}>
            When you need to make sure that the person you are chatting with and that beautiful human you see in the
            photo is the same person, you should invite her or him to the video chat more often. As a rule, scammers use
            stolen photographs of girls or men and mislead male users on behalf of users. Therefore, if you ask them to
            send new photos, then the deceivers have no chances to provide the new ones. In order not to deal with such
            situations, ask your darling to send you a lot of updated photos. But remember that many respectable women
            and men whose photos were stolen and used to deceive users are blacklisted as scammers. However, they are
            victims themselves and do not even suspect this.
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>3.5</div>
          <div className={classes.smallContent}>
            Girls or women may be married, as indicated in the paragraph on marital status, and most likely they are not
            looking for a life partner, but they are interested in communicating and practicing foreign languages. Some
            of them may hide their marital status. They betray their husbands/wives and can hardly be desirable partners
            for you in the future. Our team suggests to avoid such humans. They can hide the fact of marriage, as they
            are scammers and want to deceive you. Big social networks for dating, such as {siteName}, value their
            reputation, verify the data of users to make sure that they are adults in their country and that they are
            not married. Any women and men can become our users.
          </div>
        </div>

        <ol start={4} className={classes.headline}>
          <li>Sites with "black lists" of scammers who tricked humen.</li>
        </ol>
        <div className={classes.paragraph}>
          <div>
            You can find many sites with "black lists" of scammers who tricked humen. On such sites, deceived humans
            post photos of unscrupulous women/men and complain about them. Some of these lists can be trusted, but it
            happens that offended humans in this way want to take revenge on those women/men who did not reciprocate or
            reject them. It happens that humans pay for the services of anti-scam sites so that information about such
            women/men is included in the “black list”. Therefore, it is impossible to determine exactly who is really
            trustworthy, and in this regard, {siteName} recommends not using similar sites.
          </div>
        </div>
        <div className={classes.breakline}></div>
        <ol start={5} className={classes.headline}>
          <li>Protecting the interests of our users.</li>
        </ol>
        <div className={classes.paragraph}>
          A lot of social networks for dating and communication are doing everything possible to protect their users
          from scamming. They thoroughly check the content (text and multimedia), as well as other documents, but these
          efforts are not enough to create a strong relationship between a man and a woman. None of the services will
          ever give you a 100% guarantee that you are perfectly matched to each other. Your relationship has a chance to
          grow into a happy marriage or instead of this to turn into disappointment, regardless of whether they are real
          or virtual. No one is insured from the bad person’s character, harmful habits and indelicate behavior of their
          partners. But despite this, it is crucial for our website that our customers feel protected from scamming. On
          the Internet, anyone can face the fact of scamming, but no one wants to become its victim. The objectives and
          obligations of the administration of our site are to protect the interests of our users in case of any trouble
          associated with scammers.
        </div>
        <div className={classes.breakline}></div>
        <ol start={6} className={classes.headline}>
          <li>Victim of a scam and refund. </li>
        </ol>
        <div className={classes.paragraph}>
          Where you may become a victim of a scam as a result of using our website and or its services, then at our own
          discretion, our company has the opportunity to repay a part of credits or an equivalent amount of cash that
          you spent in communication with a scammer. This is real if the fact of scam can be verified and confirmed. In
          any case, such situations are very rarely found on our website, we are sure of it. Our company cares and
          protects the safety of its customers. It is very important for us that you find your happiness, your soul mate
          and love through {siteName}. We do our best to make your search safe and fruitful.
        </div>
        <div className={classes.breakline}></div>
      </div>
    </Container>
  );
};

export default AntiScamPolicy;
