import { UserProfile } from '@/types/user-profile.types';

export const getSocketUrl = (api = process.env.REACT_APP_WS_BASEURL as string) => {
  return `${api}`;
};

export const getWebsocketsPath = () => `${getSocketUrl(process.env.REACT_APP_WS_BASEURL)}/socket.io`;

export const getGoogleTranslationKey = () => `${process.env.REACT_APP_GOOGLE_TRANSLATION_KEY}`;

export const shuffle = (a: Array<any>) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

export const delay = <T extends any>(count = 2000) =>
  new Promise<T>((res) => {
    setTimeout(res, count);
  });

export const onloadImage = (imgUrl: string): Promise<HTMLImageElement> => {
  const img = new Image();

  const onLoad = new Promise<HTMLImageElement>((resolve) => {
    try {
      img.onload = () => resolve(img);
      img.onerror = () => resolve(img);
    } catch (error) {
      resolve(img);
    }
  });

  img.src = imgUrl;

  return onLoad;
};

export const getRemainingDateParts = (rTime: number) => {
  const diff = Math.abs(rTime);
  const msInDay = 1000 * 60 * 60 * 24;
  const years = Math.floor(diff / (msInDay * 365));
  const months = Math.floor(diff / (msInDay * 30));
  const weeks = Math.floor(diff / (msInDay * 7));
  const days = Math.floor(diff / msInDay);
  let restDays = days;
  const hours = Math.floor(diff / (1000 * 60 * 60)) % 24;
  const minutes = Math.floor(diff / (1000 * 60)) % 60;
  const seconds = Math.floor(diff / 1000) % 60;
  if (months) {
    const rest = days - months * 30;
    if (rest > 0) {
      restDays = rest;
    }
  }
  return {
    years,
    months,
    weeks,
    days,
    restDays,
    hours,
    minutes,
    seconds,
    diff,
  };
};

export const calculateAge = (birthday: string) => {
  var ageDifMs = Date.now() - new Date(birthday).getTime();
  var ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const formatAMPM = (messageDate: Date) => {
  const date = new Date(messageDate);

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const monthName = monthNames[date.getMonth()];

  const day = date.getDate();

  const hours = date.getHours() % 12;
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'AM' : 'PM';
  const displayHours = hours || 12;
  const displayMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${monthName} ${day} ${displayHours}:${displayMinutes} ${ampm}`;
};

export const getClientDimensions = () => {
  const win = window,
    doc = document,
    docElem = doc.documentElement,
    body = doc.getElementsByTagName('body')[0],
    width = win.innerWidth || docElem.clientWidth || body.clientWidth,
    height = win.innerHeight || docElem.clientHeight || body.clientHeight;
  return { width, height };
};

export const getSafeAreas = () => {
  const safeAreas = {
    top: 0,
    bottom: 0,
  };

  safeAreas.bottom = parseInt(
    getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-bottom'),
    10
  );

  safeAreas.top = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-top'), 10);

  return safeAreas;
};

export const serialize = (obj: { [key: string]: any }) =>
  Object.keys(obj)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join('&');

export const showPropsDiff = (componentName: string, prevProps: any, props: any, prevState?: any, state?: any) => {
  console.log(`--------------- render ${componentName} ---------------`);
  console.log('------props-----');
  Object.keys(prevProps).forEach(
    (key) => prevProps[key] !== props[key] && console.log(key, prevProps[key], props[key])
  );
  console.log('------state-----');
  if (state && prevState) {
    Object.keys(prevState).forEach((key) => prevState[key] !== state[key] && console.log(key));
  }
  console.log(`--------------- finish render ${componentName} ---------------`);
};

export const normalizePatchProfileData = (patchUserData: UserProfile) => {
  const newData = { ...patchUserData };

  Object.entries(newData).forEach(([k, v]) => {
    if (v === '') {
      newData[k] = null;
    }
  });

  return newData;
};

export const parseJwt = (token: string | null) => {
  if (!token) return null;

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  return JSON.parse(jsonPayload);
};
