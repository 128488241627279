import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { CSSProperties, useCallback, useMemo } from 'react';
import { Item } from 'react-photoswipe-gallery';
import { DeleteIconStyled } from '../dialogs/upload-photo-file.dialog.component';
import { ReactComponent as CoinInvertedIcon } from '@/assets/icons/coins-inverted.svg';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  itemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    fontWeight: 600,
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  dropzone: () => ({
    cursor: 'pointer',
    color: theme.palette.radioSelected.main,
    outline: 'none',
    margin: 12,
    borderRadius: 15,
    width: 56,
    height: 56,
    backgroundColor: theme.palette.info.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  innerZone: {
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    borderRadius: 'inherit',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
  },
  dropIcon: {
    fontSize: '2.7rem',
    top: 0,
  },
  imageType: {
    borderRadius: 10,
    color: '#fff',
    fontSize: 12,
    fontWeight: 600,
    padding: '2px 6px',
  },
  publicImage: {
    backgroundColor: theme.palette.success.main,
  },
  privateImage: {
    backgroundColor: theme.palette.primary.main,
  },
  credits: {
    display: 'flex',
    fontWeight: 600,
    '& span': {
      paddingLeft: '5px' 
    }
  }
}));

type UploadGiftProps = {
  id: string;
  name: string;
  credits: number;
  image: string;
  profileId?: string | null;
  handleRemoveFile?: (id: string) => void;
  handleSelect?: (file: unknown) => void;
  withoutIcon?: boolean;
  handleClick?: ({ id, image, open }: { id: string; image: string; open: () => void }) => void;
  windowWidth?: number;
  windowHeight?: number;
};

const UploadGift = ({
  id,
  name,
  image,
  credits,
  profileId,
  handleRemoveFile,
  handleSelect,
  withoutIcon,
  handleClick,
  windowWidth,
  windowHeight,
}: UploadGiftProps) => {
  const overridedHandleSelect = (file) => {
    typeof handleSelect === 'function' && handleSelect(file);
  };

  const itemWidth = useMemo(() => windowWidth || 1024, [windowWidth]);
  const itemHeight = useMemo(() => windowHeight || 768, [windowHeight]);

  const classes = useStyles();

  const stylesOverrides = {
    closeIcon: {
      position: 'absolute',
      right: 0,
      top: 5,
      zIndex: 1000,
    },
  };

  const getIcon = useCallback(() => {
    if (withoutIcon) return null;

    return <DeleteIconStyled />;
  }, [withoutIcon]);

  const handleRemove = useCallback(
    (id: string) => {
      if (handleRemoveFile) {
        handleRemoveFile(id);
      }
    },
    [handleRemoveFile]
  );

  return (
    <div
      key={id}
      style={{ position: 'relative' }}
      onClick={() =>
        overridedHandleSelect({
          id,
          image,
          credits,
        })
      }
    >
      <Item original={image} thumbnail={image} width={itemWidth} height={itemHeight}>
        {({ ref, open }) => (
          <div className={classes.itemContainer}>
            <div className={classes.dropzone}>
              <div
                ref={ref as any}
                onClick={() => (handleClick ? handleClick({ id, image, open }) : open())}
                className={classes.innerZone}
                style={{
                  backgroundImage: `url("${image}")`,
                }}
              ></div>
            </div>
            <div>{name}</div>
            <div className={classes.credits}>{credits} <span><CoinInvertedIcon /></span></div>
          </div>
        )}
      </Item>
      <div style={stylesOverrides.closeIcon as CSSProperties} onClick={() => handleRemove(id)}>
        {getIcon()}
      </div>
    </div>
  );
};

export default UploadGift;
