import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserProfileDTO } from '@/app/users/users.reducer';
import UserCard from './user-card.component';
import { useAppSelector } from '@/app/hooks';

type UserLinkProps = {
  userName: string;
  uid: string;
  user: UserProfileDTO;
  unreadCount?: number;
  avatar: string;
  pageId: string;
  isNewMessage: boolean;
  onClick: () => void;
  isPin?: boolean;
};

const UserLink = ({ userName, uid, user, avatar, pageId, onClick = () => {}, isNewMessage, isPin, unreadCount = 0 }: UserLinkProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const usersWithActiveTextChat =
    useAppSelector((state) => state.users.usersWithActiveTextChat) || [];
  
  const bookmarks =
    useAppSelector((state) => state.users.bookmarks) || [];
  
  useEffect(() => {
    setIsActive((usersWithActiveTextChat.map((userWithActiveChat) => userWithActiveChat.id)).includes(uid));
  }, [user, usersWithActiveTextChat]);

  const [isActive, setIsActive] = useState(false)

  const isSelected = location.pathname.includes(uid);
  const route = `/dialog/${user?.profile?.id}/${uid}`;

  const isInBookmarks = bookmarks.map(bookmark => bookmark.id).includes(uid);

  const handleClick = useCallback(
    () => {
      navigate(route);
      onClick();
    },
    [route, navigate, onClick]
  );

  return (
    <UserCard
      handleClick={handleClick}
      userName={userName}
      uid={uid}
      pageId={pageId}
      avatar={avatar}
      isSelected={isSelected}
      isActive={isActive}
      isPin={isPin}
      isNewMessage={isNewMessage}
      unreadCount={unreadCount}
      isInBookmarks={isInBookmarks}
    />
  );
};

export default UserLink;
