import { useCallback, useState } from "react";

type BaseFile = {
  id: string;
  thumbnail: string;
  origin: string;
  private: boolean;
};

type UseFileUtils = [
  BaseFile[],
  (fileToRemove: unknown) => void,
  (fileToAdd: unknown) => void,
  () => void
];

const useVideoFileUtils = <T extends unknown & BaseFile>(
  initFiles = []
): UseFileUtils => {
  const [files, setFiles] = useState<Array<T>>(initFiles);

  const removeFile = useCallback(
    (fileToRemove: T) => {
      const existing = files.find((file) => file.id === fileToRemove.id);
      if (existing) {
        const normalized = files.filter((item) => item.id !== fileToRemove.id);
        setFiles(normalized);
      }
    },
    [files, setFiles]
  );

  const appendFile = useCallback(
    (fileToAdd: T) => {
      const existing = files.find((file) => file.id === fileToAdd.id);
      if (existing) return;
      setFiles([...files, fileToAdd]);
    },
    [files, setFiles]
  );

  const resetAttachments = () => setFiles([]);
  return [files, removeFile, appendFile, resetAttachments];
};

export default useVideoFileUtils;
