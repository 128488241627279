import React from "react";

type BaseIconProps = {
  color?: string;
};

const MaleIcon = ({ color = "#503EB6" }: BaseIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 16.533C13.0951 16.1235 12.2297 16.0732 12.565 15V13.5C12.2486 13.3328 11.3447 12.1838 11.2491 11.286C11.1929 11.0738 11.1493 10.3147 11.1493 10.1437C11.1493 9.207 10.1626 8.2705 9.61295 6.75475C9.80484 5.73625 9.30603 6.87825 10.3808 6.765C10.6683 6.25725 11.4049 6 12.363 6C14.1946 6.0705 14.5862 5.90475 13.8172 9.1605C13.9662 9.252 14.2122 9.543 14.1504 10.059C14.0351 11.0212 13.6443 11.265 13.3954 11.2852C13.3005 12.1838 14.3959 13.3328 14.0796 13.5V15C14.7825 17.25 19 15 19 21H10.565C10.565 18.339 13.1661 17.1923 12 16.533Z"
        fill={color}
      />
      <path
        d="M17 16.533C15.9049 16.1235 14.7703 16.0732 14.435 15V13.5C14.7514 13.3328 15.6553 12.1838 15.7509 11.286C15.8071 11.0738 15.8507 10.3147 15.8507 10.1437C15.8507 9.207 15.8374 7.2705 16.3871 5.75475C16.1952 4.73625 15.694 3.87825 14.6192 3.765C14.3317 3.25725 13.5951 3 12.637 3C8.80536 3.0705 8.41383 5.90475 9.18282 9.1605C9.0338 9.252 8.78778 9.543 8.84964 10.059C8.96492 11.0212 9.35574 11.265 9.60458 11.2852C9.69947 12.1838 10.6041 13.3328 10.9204 13.5V15C10.2175 17.25 6 15 6 21H14.435C14.435 18.339 15.8339 17.1923 17 16.533Z"
        fill={color}
      />
    </svg>
  );
};

export default MaleIcon;
