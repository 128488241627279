export enum GiftKind {
  INBOX = 'inbox',
  SENT = 'outbox',
  INBOX_BOOKMARKS = 'inbox-bookmarks',
  SENT_BOOKMARKS = 'outbox-bookmarks',
}

export enum SelectGiftsOption {
  NO_SELECTION = 'No selection',
  ALL = 'All',
  NEW = 'New',
  READ = 'Read',
}

export enum SortlistNames {
  DATEASC = 'Date-Ascending',
  DATEDESC = 'Date-Descending',
  NAMEASC = 'Name A-Z',
  NAMEDESC = 'Name Z-A',
}

export const SEND_GIFT_REQUEST = 'SEND_GIFT_REQUEST';
export const SEND_GIFT_SUCCESS = 'SEND_GIFT_SUCCESS';
export const SEND_GIFT_FAILURE = 'SEND_GIFT_FAILURE';

export const GET_GIFTS_REQUEST = 'GET_GIFTS_REQUEST';
export const GET_GIFTS_SUCCESS = 'GET_GIFTS_SUCCESS';
export const GET_GIFTS_FAILURE = 'GET_GIFTS_FAILURE';

export const GET_GIFTS_FROM_SOCKET_REQUEST = 'GET_GIFTS_FROM_SOCKET_REQUEST';
export const GET_GIFTS_FROM_SOCKET_SUCCESS = 'GET_GIFTS_FROM_SOCKET_SUCCESS';
export const GET_GIFTS_FROM_SOCKET_FAILURE = 'GET_GIFTS_FROM_SOCKET_FAILURE';

export const CHECK_GIFT = 'CHECK_GIFT';
export const CHECK_FEW_GIFTS = 'CHECK_FEW_GIFTS';

export const SET_IS_BOOKMARKS = 'SET_IS_BOOKMARKS';
export const SET_SEARCH_PROFILE_ID = 'SET_SEARCH_PROFILE_ID';
export const SET_SORTING = 'SET_SORTING';
export const RESET_PAGINATION_AND_GIFTS = 'RESET_PAGINATION_AND_GIFTS';

export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_PAGINATION_IS_OVER = 'SET_PAGINATION_IS_OVER';

export const MARK_AS_READ_SELECTED_GIFTS = 'MARK_AS_READ_SELECTED_GIFTS';
export const MARK_AS_READ_SELECTED_GIFTS_SUCCESS = 'MARK_AS_READ_SELECTED_GIFTS_SUCCESS';

export const SET_SELECTED_GIFTS = 'SET_SELECTED_GIFTS';
export const RESET_SELECTED_GIFTS = 'RESET_SELECTED_GIFTS';

export const ADD_TO_BOOKMARKS = 'ADD_TO_BOOKMARKS';
export const ADD_TO_BOOKMARKS_SUCCESS = 'ADD_TO_BOOKMARKS_SUCCESS';

export const GET_USER_GIFT_REQUEST = 'GET_USER_GIFT_REQUEST';
export const GET_USER_GIFT_SUCCESS = 'GET_USER_GIFT_SUCCESS';
export const GET_USER_GIFT_FAIL = 'GET_USER_GIFT_FAIL';
export const RESET_USER_GIFT_FAIL = 'RESET_USER_GIFT_FAIL';

export const GET_CLOSED_ATTACH_REQUEST = 'GET_CLOSED_ATTACH_REQUEST';
export const GET_CLOSED_ATTACH_SUCCESS = 'GET_CLOSED_ATTACH_SUCCESS';
export const GET_CLOSED_ATTACH_FAIL = 'GET_CLOSED_ATTACH_FAIL';

export const GET_GIFT_COUNTERS = 'GET_GIFT_COUNTERS';
export const GET_GIFT_COUNTERS_SUCCESS = 'GET_GIFT_COUNTERS_SUCCESS';
export const SOCKET_UPDATE_GIFTS_COUNT = 'SOCKET_UPDATE_GIFTS_COUNT';

export const GET_AVAILABLE_TO_SEND_GIFTS_REQUEST = 'GET_AVAILABLE_TO_SEND_GIFTS_REQUEST';
export const GET_AVAILABLE_TO_SEND_GIFTS_SUCCESS = 'GET_AVAILABLE_TO_SEND_GIFTS_SUCCESS';
export const GET_AVAILABLE_TO_SEND_GIFTS_FAILURE = 'GET_AVAILABLE_TO_SEND_GIFTS_FAILURE';

export const POST_USER_GIFT_REQUEST = 'POST_USER_GIFT_REQUEST';
export const POST_USER_GIFT_SUCCESS = 'POST_USER_GIFT_SUCCESS';
export const POST_USER_GIFT_FAILURE = 'POST_USER_GIFT_FAILURE';

export type GiftsDTO = {
  id: string;
  name: string;
  image: string;
  category: GiftsEnum;
  credits: number;
  giftCredits: number;
};

export interface PostGiftDTO {
  virtualGiftId: string;
  receiverId: string;
  text: string;
  closeHandler?: () => void;
  isDialog: boolean;
}

export enum GiftsEnum {
  birthday = 'birthday',
  friendship = 'friendship',
  romance = 'romance',
  halloween = 'halloween',
  xxl = 'xxl',
  christmas = 'christmas',
  valentines_day = 'valentines_day',
  easter = 'easter',
  patricks_day = 'patricks_day',
  food_and_drinks = 'food_and_drinks',
  clothes = 'clothes',
  shopping = 'shopping',
  gadgets = 'gadgets',
  most_popular = 'most_popular',
}

export const giftLabel: Record<GiftsEnum, string> = {
  birthday: 'Birthday',
  friendship: 'Friendship',
  romance: 'Romance',
  halloween: 'Halloween',
  xxl: 'XXL',
  christmas: 'Christmas',
  valentines_day: 'Valentines day',
  easter: 'Easter',
  patricks_day: 'Patricks day',
  food_and_drinks: 'Food and drinks',
  clothes: 'Clothes',
  shopping: 'Shopping',
  gadgets: 'Gadgets',
  most_popular: 'Most_popular',
};

export const categoryOptions = [
  { name: 'birthday', value: 'birthday' },
  { name: 'friendship', value: 'friendship' },
  { name: 'romance', value: 'romance' },
  { name: 'halloween', value: 'halloween' },
  { name: 'xxl', value: 'xxl' },
  { name: 'christmas', value: 'christmas' },
  { name: 'valentines_day', value: 'valentines_day' },
  { name: 'easter', value: 'easter' },
  { name: 'patricks_day', value: 'patricks_day' },
  { name: 'food_and_drinks', value: 'food_and_drinks' },
  { name: 'clothes', value: 'clothes' },
  { name: 'shopping', value: 'shopping' },
  { name: 'gadgets', value: 'gadgets' },
  { name: 'most_popular', value: 'most_popular' },
];
