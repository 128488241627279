import StatusOnline from '@/components/base/statuses/status-online.component';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import React from 'react';
import profilePicture from '@/assets/images/demo-avatar.jpeg';

export interface IProps {
  profilePicture: string;
  isOnline: boolean;
}

const useStyles = makeStyles<Theme, IProps>((theme: Theme) => ({
  photoWrapper: {
    maxWidth: '100px',
    maxHeight: '100px',
    aspectRatio: '100 / 100',
    width: '100%',

    position: 'relative',
    [theme.breakpoints.down('md')]: {
      maxWidth: 72,
      maxHeight: 76,
    },
  },
  photoInner: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  profilePhoto: {
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 10,
    height: '100%',
    backgroundImage: (props: IProps) => `url(${props.profilePicture ? props.profilePicture : profilePicture})`,
  },
  profilePhotoStatus: {
    position: 'absolute',
    bottom: 8,
    left: 8,
  },
}));

const UserAvatar = ({ ...props }: IProps) => {
  const classes = useStyles(props);
  return (
    <div className={classes.photoWrapper}>
      <div className={classes.photoInner}>
        <div className={classes.profilePhoto} />
      </div>
      <div className={classes.profilePhotoStatus}>{props.isOnline && <StatusOnline small />}</div>
    </div>
  );
};

export default UserAvatar;
