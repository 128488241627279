import { parseJwt } from "@/helpers/helpers";
import { StorageService } from "@/services/storage.service";
import React, { useMemo, useEffect } from "react";
import {
  Navigate, useNavigate
} from 'react-router-dom';

type SecureRouteProps = {
  component: any;
  isPrivate?: boolean;
};

const SecureRoute = ({
  component: Component,
  isPrivate = true,
  ...props
}: SecureRouteProps) => {
  const navigate = useNavigate();
  const userToken = StorageService.getToken();

  const parsedUserToken = useMemo(() => userToken && parseJwt(userToken),[userToken])

  useEffect(() => {
    if (parsedUserToken?.exp < new Date().getTime() / 1000) {
      StorageService.removeToken();
      navigate('/');
    }
  },[navigate, parsedUserToken])

  return !userToken ? <Navigate to='/' /> : <Component {...props} />;
};

export default SecureRoute;
