import { AnyAction } from "redux";
import {
  GET_MAILS_REQUEST,
  GET_MAILS_SUCCESS,
  GET_MAILS_FAILURE,
  SEND_MAIL_REQUEST,
  SEND_MAIL_SUCCESS,
  SEND_MAIL_FAILURE,
  CHECK_MESSAGE,
  CHECK_FEW_MESSAGES,
  SET_IS_BOOKMARKS,
  SET_SEARCH_PROFILE_ID,
  SET_SORTING,
  RESET_PAGINATION_AND_MAILS,
  SET_PAGINATION,
  SET_PAGINATION_IS_OVER,
  SET_SELECTED_MESSAGES,
  RESET_SELECTED_MESSAGES,
  MARK_AS_READ_SELECTED_MESSAGES_SUCCESS,
  ADD_TO_BOOKMARKS_SUCCESS,
  GET_USER_MESSAGE_REQUEST,
  GET_USER_MESSAGE_SUCCESS,
  GET_USER_MESSAGE_FAIL,
  RESET_USER_MESSAGE_FAIL,
  GET_MESSAGE_COUNTERS_SUCCESS,
  SOCKET_UPDATE_MESSAGES_COUNT
} from "./mails.types";

const initialState = {
  isLoading: false,
  isSending: false,
  inboxMails: [],
  outboxMails: [],
  error: null,
  chosenIds: new Set(),
  isBookmarks: false,
  pagination: {
    limit: 20,
    offset: 0
  },
  isPaginationOver: false,
  searchProfileId: null,
  sorting: null,
  selectedMessages: null,
  userMessage: null,
  isUserMessageLoading: false,
  messageCounters: null,
};

const mails = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SEND_MAIL_REQUEST:
      return {
        ...state,
        isSending: true,
      };
    case SEND_MAIL_SUCCESS:
      return {
        ...state,
        isSending: false,
        outboxMails: [...state.outboxMails, action.payload],
      };
    case SEND_MAIL_FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.error,
      };
    case GET_MAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        [action.kind + "Mails"]: [...state[action.kind + "Mails"], ...action.payload],
      };
    case GET_MAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case SET_IS_BOOKMARKS:
      return {
        ...state,
        isBookmarks: action.payload,
      };
    case RESET_PAGINATION_AND_MAILS:
      return {
        ...state,
        pagination: {
          limit: 20,
          offset: 0
        },
        isPaginationOver: false,
        [action.payload + "Mails"]: [],
      };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: {
          limit: 20,
          offset: action.payload
        },
      };
    case SET_PAGINATION_IS_OVER:
      return {
        ...state,
        isPaginationOver: true,
      };
    case SET_SEARCH_PROFILE_ID:
      return {
        ...state,
        searchProfileId: action.payload
      };
    case SET_SORTING:
      return {
        ...state,
        sorting: action.payload
      };
    case CHECK_MESSAGE: {
      let tempSelectedMessages = new Set(state.chosenIds);

      //@ts-ignore
      const isMessageAlreadySeted = tempSelectedMessages.has(action.payload);

      if (isMessageAlreadySeted) {
        //@ts-ignore
        tempSelectedMessages.delete(action.payload);
      } else {
        //@ts-ignore
        tempSelectedMessages.add(action.payload);
      }

      return {
        ...state,
        chosenIds: tempSelectedMessages,
      };
    }
    case CHECK_FEW_MESSAGES: {
      return {
        ...state,
        chosenIds: new Set(action.payload),
      };
    }
    case SET_SELECTED_MESSAGES: {
      return {
        ...state,
        selectedMessages: action.payload,
      };
    }
    case RESET_SELECTED_MESSAGES: {
      return {
        ...state,
        selectedMessages: null,
      };
    }
    case MARK_AS_READ_SELECTED_MESSAGES_SUCCESS: {
      const { kind, chosenIds } = action.payload;

      const tempMails = [...state[kind + "Mails"]];

      const selectedMessages = tempMails.filter(item => chosenIds.has(item.id))

      selectedMessages.forEach(message => message.seen = new Date());

      return {
        ...state,
        [action.kind + "Mails"]: tempMails,
      };
    }
    case ADD_TO_BOOKMARKS_SUCCESS: {
      const { id, kind } = action.payload;

      const tempMails = [...state[kind + "Mails"]];

      const selectedMessage = tempMails.find(item => item.id === id)

      selectedMessage.isBookmark = true;

      return {
        ...state,
        [action.kind + "Mails"]: tempMails,
      };
    }
    case GET_USER_MESSAGE_REQUEST: {
      return {
        ...state,
        isUserMessageLoading: true,
      };
    }
    case GET_USER_MESSAGE_SUCCESS: {
      return {
        ...state,
        userMessage: action.payload,
        isUserMessageLoading: false,
      };
    }
    case GET_USER_MESSAGE_FAIL: {
      return {
        ...state,
        userMessage: null,
        isUserMessageLoading: false,
      };
    }
    case RESET_USER_MESSAGE_FAIL: {
      return {
        ...state,
        userMessage: null,
        isUserMessageLoading: false,
      };
    }
    case GET_MESSAGE_COUNTERS_SUCCESS: {
      return {
        ...state,
        messageCounters: action.payload
      };
    }
    case SOCKET_UPDATE_MESSAGES_COUNT: {
      return {
        ...state,
        messageCounters: action.payload
      };
    }
    default:
      return state;
  }
};

export default mails;
