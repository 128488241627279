import { useAppSelector } from '@/app/hooks';
import { IUserVideosData, defaultUserVideosData } from '@/app/users/users.dto';
import { UserProfileDTO } from '@/app/users/users.reducer';
import { UserVideoType } from '@/app/users/users.types';
import VideosService from '@/services/videos.service';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleSnackbarOpen } from '@/app/ui/ui.actions';

type ResponseUseUserVideos = [isLoading: boolean, videos: IUserVideosData];

const useUserVideos = (): ResponseUseUserVideos => {
  const [isLoading, setLoading] = useState(true);
  const myVideos = useAppSelector((state) => state.users['publicVideos']);
  const [videos, setVideos] = useState<IUserVideosData>(defaultUserVideosData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, type } = useParams<{
    id: string;
    type: UserVideoType;
  }>();
  const currentProfile: UserProfileDTO = useAppSelector((state) => state.users.currentUser);

  const fetchVideos = useCallback(
    async (id: string, type: UserVideoType) => {
      try {
        const response = await VideosService.getUserVideos(id, type);
        setVideos(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        dispatch(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
      }
    },
    [id]
  );

  const backToProfile = useCallback(() => navigate(`/user/${currentProfile?.profile.id}`), [currentProfile, navigate]);

  useEffect(() => {
    const isMyProfile = id === currentProfile?.id;
    const isTryingToAccessPrivate = type === 'private' && id !== currentProfile?.id;

    if (isTryingToAccessPrivate) {
      return backToProfile();
    }
    if (isMyProfile) {
      setVideos(myVideos);
    }
    if (!isMyProfile && id) {
      fetchVideos(id, UserVideoType.PUBLIC_VIEW);
    }
    if (id && type) {
      fetchVideos(isMyProfile ? currentProfile?.profile.id : id, type);
    }
  }, [fetchVideos, currentProfile, id, type, backToProfile, myVideos]);

  return [isLoading, videos];
};

export default useUserVideos;
