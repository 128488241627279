import { Theme } from "@mui/material";
import React from "react";
import { Item } from "react-photoswipe-gallery";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  dropzone: () => ({
    cursor: "pointer",
    color: theme.palette.radioSelected.main,
    outline: "none",
    borderRadius: 10,
    width: 75,
    height: 86,
    backgroundColor: theme.palette.info.main,
  }),
  innerZone: {
    borderRadius: "inherit",
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
  },
}));

type NewsPhotoProps = {
  small: string;
  origin: string;
  windowHeight: number;
  windowWidth: number;
};

const NewsPhoto = ({
  small,
  origin,
  windowHeight,
  windowWidth
}: NewsPhotoProps) => {
  const classes = useStyles();

  return (
    <div key={small}>
      <Item original={origin} thumbnail={small} width={windowWidth} height={windowHeight}>
        {({ ref, open }) => (
          <div className={classes.dropzone}>
            <div
              ref={ref as any}
              onClick={open}
              className={classes.innerZone}
              style={{
                backgroundImage: `url("${small}")`,
              }}
            ></div>
          </div>
        )}
      </Item>
    </div>
  );
};

export default NewsPhoto;
