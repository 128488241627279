import { ReactComponent as ArrowLeftIcon } from "@/assets/icons/left-arrow.svg";
import { ReactComponent as ArrowRightIcon } from "@/assets/icons/right-arrow.svg";
import { useAppBreakpoints } from "@/hooks";
import useBlankProfileAvatart from "@/hooks/useBlankProfileAvatart";
import { BirthdayShape } from "@/services/news.service";
import { Container, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Navigation, SwiperOptions } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import BaseButton from "../base/button.component";

export interface SliderSlidesPerView {
  xs?: number | "auto" | undefined;
  s?: number | "auto" | undefined;
  sm?: number | "auto" | undefined;
  md?: number | "auto" | undefined;
  lg?: number | "auto" | undefined;
  xl?: number | "auto" | undefined;
}

type UsersSliderProps = {
  disablePadding?: boolean;
  children: React.ReactNode;
  data: Array<BirthdayShape>
};

const useStyles = makeStyles((theme: Theme) => ({
  ml: {
    "& > *:not(:first-child)": {
      marginLeft: 28,
    },
  },
  sliderNavigation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16
  },
  sliderNavigationControl: {
    display: "flex",
  },

  disabledNavigationBtn: {
    backgroundColor: "rgb(229, 229, 229) !important",
    cursor: "not-allowed !important",
    boxShadow: "none !important",
  },

  navigationBtn: {
    minHeight: 28,
    height: 28,
    minWidth: 28,
  },

  slideContainer: {
    cursor: 'pointer',
    maxWidth: 109,
    [theme.breakpoints.down("xl")]: {
      maxWidth: 106,

    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 72,
    },
  },

  slide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '&>img': {
      width: '100%'
    },

    '& p': {
      margin: 0,

    }
  },

  slideInfo: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    marginTop: 17,
    width: '100%',

    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '18px',
  },

  slideName: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

  },
  slideId: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    marginTop: 2,
  }

}));

const BirtdaySlider = ({
  children,
  disablePadding = false,
  data
}: UsersSliderProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { oppositeUserPicture } = useBlankProfileAvatart();

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const { lg } = useAppBreakpoints();
  const defaultSlidesPerView: SliderSlidesPerView = {
    xs: 4,
    s: 6,
    sm: 7,
    md: 7,
    lg: 6,
    xl: 7,
  };

  const modules = [Navigation];

  const slidesPreView: SliderSlidesPerView = Object.assign(
    defaultSlidesPerView,
  );

  const swiperOption: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 16,
    breakpoints: {
      0: {
        slidesPerView: slidesPreView.xs,
        spaceBetween: 30,
      },
      470: {
        slidesPerView: slidesPreView.s,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: slidesPreView.sm,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: slidesPreView.md,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: slidesPreView.lg,
        spaceBetween: 30,
      },
      1280: {
        slidesPerView: slidesPreView.xl,
        spaceBetween: 32,
      },
    },
  };

  const containerInnerOverrides = {
    padding: "0 24px",
  };

  return (
    <Container
      disableGutters={disablePadding}
      maxWidth={lg ? "lg" : "xl"}
      style={{ padding: disablePadding ? "0 !important" : "0 24px" }}
    >
      <div style={!disablePadding ? containerInnerOverrides : {}}>
        <div className={classes.sliderNavigation}>
          {children}
          <div className={clsx(classes.sliderNavigationControl, classes.ml)}>
            <BaseButton
              text=""
              variant="contained"
              size="small"
              ref={navigationPrevRef}
              Icon={<ArrowLeftIcon />}
              color="primary"
            // disableElevation
            />
            <BaseButton
              text=""
              variant="contained"
              size="small"
              ref={navigationNextRef}
              Icon={<ArrowRightIcon />}
              color="primary"
            // disableElevation
            />
          </div>
        </div>
        <div>
          <Swiper
            breakpoints={swiperOption.breakpoints}
            spaceBetween={swiperOption.spaceBetween}
            slidesPerView={swiperOption.slidesPerView}
            modules={modules}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
              disabledClass: classes.disabledNavigationBtn,
            }}
            onBeforeInit={(swiper: any) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
          >
            {
              data.map((profile, idx) => (
                <SwiperSlide className={classes.slideContainer}>
                  <div className={classes.slide} onClick={() => {
                    navigate(`/user/${profile.id}`);
                  }}>
                    <img src={profile.avatar || oppositeUserPicture} alt={`birthday-${idx}`} />
                    <div className={classes.slideInfo}>
                      <p className={classes.slideName}>{profile.name}</p>
                      <p className={classes.slideId}>Id: {profile.id}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
      </div>
    </Container>
  );
};

export default BirtdaySlider;
