import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { toggleSnackbarOpen } from '@/app/ui/ui.actions';
import CreditsService from '@/services/credits.service';
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogProps,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  Zoom,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BaseButton from '../base/button.component';
import { CloseIconStyled } from './sign-up-dialog.component';
import { IAcquiring, ISettings } from '@/types/user-profile.types';
import { ReactComponent as PriceListIcon } from '@/assets/icons/price_list.svg';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '35px 45px',

    [theme.breakpoints.down('sm')]: {
      padding: '40px 12px',
    },
  },
  dialogPaper: {
    overflowY: 'visible',
  },
  dialogTitle: {
    textAlign: 'center',
    height: 48,
  },

  buyButton: {
    height: 42,
    width: 140,
  },

  link: {
    color: '#ea4160',
  },

  info: {
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
  },

  check: {
    marginLeft: 15,
  },

  site: {
    marginTop: 15,
    marginBottom: 10,
    textAlign: 'center',
  },

  city: {
    fontSize: '12px',
    color: 'grey',
    textAlign: 'center',
  },

  loader: {
    margin: '0 auto',
    padding: '10px',
    width: '200px',
    display: 'flex',
    justifyContent: 'center',
  },
  choosePaymentProviderContainer: {
    marginTop: 15,
  },
  cardWrapper: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
    },
  },
  icon: {
    paddingLeft: '4px',
    paddingRight: '4px',
    width: 20,
    height: 20,
  },
  priceListBtn: {
    color: `${theme.palette.radioSelected.main} !important`,
    width: '100%',
    maxWidth: 140,
  },
  priceListTextStyle: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px !important',
    },
  },
}));

type DialogWriteMailProps = {
  open: boolean;
  closeHandler: () => void;
  chosenCreditpack: any;
  openPriceListFromDialogBuyCredits: () => void;
} & DialogProps;

const DialogBuyCredits = ({
  open,
  closeHandler,
  chosenCreditpack,
  openPriceListFromDialogBuyCredits,
}: DialogWriteMailProps) => {
  console.log('chosenCreditpack', chosenCreditpack);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {
    companyAddress,
    emailForPaypal,
    siteUrl,
    paymentsFlexpay,
    paymentsStripe,
    paymentsStripebg01,
    paymentsCcbill,
    paymentsInoviopay,
    paymentsInoviopay3ds2,
    paymentsNetvalve,
  } = useAppSelector<ISettings>((state) => state.users.settings);

  const acquiring = useAppSelector<string[]>((state) => state.users.acquiring);

  const [isAgreed, setIsAgreed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentLink, setPaymentLink] = useState(null);

  const [chosenProvider, setChosenProvider] = useState(() => {
    if (acquiring.includes('netvalve') && chosenCreditpack?.price < 270) {
      return 'netvalve';
    } else if (acquiring.includes('inoviopay3ds2')) {
      return 'inoviopay3ds2';
    } else if (acquiring.includes('flexpay')) {
      return 'flexpay';
    } else if (acquiring.includes('stripe')) {
      return 'stripe';
    } else if (acquiring.includes('ccbill')) {
      return 'ccbill';
    } else if (acquiring.includes('inoviopay')) {
      return 'inoviopay';
    } else if (acquiring.includes('stripebg01')) {
      return 'stripebg01';
    } else if (paymentsNetvalve && chosenCreditpack?.price < 270) {
      return 'netvalve';
    } else if (paymentsInoviopay3ds2) {
      return 'inoviopay3ds2';
    } else if (paymentsFlexpay) {
      return 'flexpay';
    } else if (paymentsStripe) {
      return 'stripe';
    } else if (paymentsCcbill) {
      return 'ccbill';
    } else if (paymentsInoviopay) {
      return 'inoviopay';
    } else if (paymentsStripebg01) {
      return 'stripebg01';
    } else {
      return '';
    }
  });

  const closeWithResetHandler = useCallback(() => {
    setIsAgreed(false);
    setIsLoading(false);
    closeHandler();
  }, [closeHandler]);

  const openLinkHandler = useCallback(() => {
    if (paymentLink) {
      window.open(paymentLink, '_blank');
      closeWithResetHandler();
    }
  }, [closeWithResetHandler, paymentLink]);

  const handleProviderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChosenProvider(event.target.value);
  };

  useEffect(() => {
    const fetchPaymentLink = async () => {
      setIsLoading(true);

      try {
        const res = await CreditsService.getPaymentLink(chosenCreditpack.id, chosenProvider);

        setPaymentLink(res.url);
      } catch (e) {
        closeWithResetHandler();
        dispatch(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
      }

      setIsLoading(false);
    };

    if (chosenCreditpack?.id && chosenProvider) {
      fetchPaymentLink();
    }
  }, [chosenCreditpack?.id, chosenProvider, closeWithResetHandler, dispatch]);

  return (
    <>
      <Dialog
        classes={{
          root: classes.dialogPaper,
        }}
        TransitionComponent={Zoom}
        open={open}
        onClose={closeWithResetHandler}
      >
        <div className={classes.container}>
          <div className={classes.cardWrapper}>
            <header className={classes.header}>
              <BaseButton
                inverted
                text='Price list'
                className={classes.priceListBtn}
                textStyle={classes.priceListTextStyle}
                Icon={<PriceListIcon className={classes.icon} />}
                onClick={() => openPriceListFromDialogBuyCredits()}
              />
            </header>
          </div>
          <div className={classes.choosePaymentProviderContainer}>
            <RadioGroup
              aria-label='paymentProvider'
              name='paymentProvider'
              value={chosenProvider}
              onChange={handleProviderChange}
            >
              {acquiring.length ? (
                <>
                  {acquiring.includes('netvalve') && chosenCreditpack?.price < 270 && (
                    <>
                      <FormControlLabel value='netvalve' control={<Radio />} label='Netvalve' />
                      <div style={{ fontSize: '10px', marginBlockStart: 0, marginTop: '-5px', marginLeft: '40px' }}>
                        Support Apple Pay and Google pay.
                      </div>
                    </>
                  )}
                  {acquiring.includes('inoviopay3ds2') && (
                    <FormControlLabel value='inoviopay3ds2' control={<Radio />} label='Inoviopay with 3ds' />
                  )}
                  {acquiring.includes('flexpay') && (
                    <FormControlLabel value='flexpay' control={<Radio />} label='Flexpay' />
                  )}
                  {acquiring.includes('stripe') && (
                    <FormControlLabel value='stripe' control={<Radio />} label='Stripe' />
                  )}
                  {acquiring.includes('stripebg01') && (
                    <FormControlLabel value='stripebg01' control={<Radio />} label='Stripe' />
                  )}
                  {acquiring.includes('ccbill') && (
                    <FormControlLabel value='ccbill' control={<Radio />} label='CCBill' />
                  )}
                  {acquiring.includes('inoviopay') && (
                    <FormControlLabel value='inoviopay' control={<Radio />} label='Inoviopay' />
                  )}
                </>
              ) : (
                <>
                  {paymentsNetvalve && chosenCreditpack?.price < 270 && (
                    <>
                      <FormControlLabel value='netvalve' control={<Radio />} label='Netvalve' />
                      <div style={{ fontSize: '10px', marginBlockStart: 0, marginTop: '-5px', marginLeft: '40px' }}>
                        Support Apple Pay and Google pay.
                      </div>
                    </>
                  )}
                  {paymentsInoviopay3ds2 && (
                    <FormControlLabel value='inoviopay3ds2' control={<Radio />} label='Inoviopay with 3ds' />
                  )}
                  {paymentsFlexpay && <FormControlLabel value='flexpay' control={<Radio />} label='Flexpay' />}
                  {paymentsStripe && <FormControlLabel value='stripe' control={<Radio />} label='Stripe' />}
                  {paymentsStripebg01 && <FormControlLabel value='stripebg01' control={<Radio />} label='Stripe' />}
                  {paymentsCcbill && <FormControlLabel value='ccbill' control={<Radio />} label='CCBill' />}
                  {paymentsInoviopay && <FormControlLabel value='inoviopay' control={<Radio />} label='Inoviopay' />}
                </>
              )}
            </RadioGroup>
          </div>
          <div className={classes.dialogTitle}>
            <Typography variant='h2' component='h2' gutterBottom>
              To buy {chosenCreditpack?.credits} credits for {chosenCreditpack?.price}€
            </Typography>
          </div>
          {!paymentsFlexpay &&
            !paymentsStripe &&
            !paymentsStripebg01 &&
            !paymentsCcbill &&
            !paymentsInoviopay &&
            !paymentsInoviopay3ds2 &&
            !paymentsNetvalve && (
              <>
                <div>
                  Due to issues with our payment system, we are temporarily unable to accept payments. We apologize for
                  any inconvenience this may cause. In the meantime, we kindly ask you to make your payment for the
                  service via PayPal, and please{' '}
                  <u>
                    <b>include your user ID in the payment description</b>
                  </u>
                  . As a token of our appreciation, we will credit you with an additional 25% more credits than you
                  purchased for each credit package during the payment system downtime. Thank you for your
                  understanding. Paypal:
                  <u>
                    <b>{emailForPaypal}</b>
                  </u>
                </div>
                <div>
                  <b>
                    Watch a video instruction{' '}
                    <a
                      href='https://www.youtube.com/watch?v=sL5CRku9Iuo&feature=youtu.be'
                      rel='noreferrer'
                      target='_blank'
                    >
                      here
                    </a>
                  </b>
                </div>
              </>
            )}

          {isLoading ? (
            <div className={classes.loader}>
              <CircularProgress color='secondary' style={{ margin: '0 auto' }} />
            </div>
          ) : (
            <div>
              <div className={classes.info}>
                <BaseButton
                  text={'Buy'}
                  color='primary'
                  className={classes.buyButton}
                  onClick={openLinkHandler}
                  disabled={!isAgreed}
                />
                <div className={classes.check}>
                  <Checkbox
                    checked={isAgreed}
                    value={isAgreed}
                    name='isAgreed'
                    onChange={() => setIsAgreed(!isAgreed)}
                    color='primary'
                  />
                  I agree with{' '}
                  <Link to={'/terms-of-use'} className={classes.link}>
                    terms of use
                  </Link>
                  {', '}
                  <Link to={'/privacy-policy'} className={classes.link}>
                    refund policy
                  </Link>{' '}
                  and{' '}
                  <Link to={'/refund-policy'} className={classes.link}>
                    refund policy
                  </Link>
                </div>
              </div>

              <Divider style={{ height: 1 }} sx={{ width: '100%' }} />
              <div className={classes.site}>
                <Link to={'/'} className={classes.link}>
                  {siteUrl}
                </Link>
              </div>
              <div className={classes.city}>{companyAddress}</div>
            </div>
          )}
        </div>
        <CloseIconStyled onClick={closeWithResetHandler} />
      </Dialog>
    </>
  );
};

export default DialogBuyCredits;
