import { createTheme, responsiveFontSizes } from "@mui/material";
// import { createBrea} from "@mui/material/styles";
import { PaletteColorOptions } from "@mui/material/styles";
import { TypographyPropsVariantOverrides } from "@mui/material/Typography";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    "sm-": true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    "2xl": true;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    heroBannerTitle: React.CSSProperties;
    heroBannerSubtitle: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    sliderTitle: React.CSSProperties;
    sliderTitleMini: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    heroBannerTitle: React.CSSProperties;
    heroBannerSubtitle: React.CSSProperties;
    body3: React.CSSProperties;

    sliderTitle: React.CSSProperties;
    sliderTitleMini: React.CSSProperties;
    link: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    heroBannerTitle: true;
    heroBannerSubtitle: true;
    body3: true;
    sliderTitle: true;
    sliderTitleMini: true;
    link: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    radioSelected: {
      main: string;
    };
    footer: {
      main: string;
    };
  }
  interface PaletteOptions {
    radioSelected: PaletteColorOptions;
    footer: PaletteColorOptions;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    radioSelected: true;
    footer: true;
  }
}

const { palette, breakpoints } = createTheme();
let theme = createTheme({
  components: {
    // MuiListItemText-primary
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: "inherit",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        disableElevation: {
          boxShadow: "none",
        },
        root: {
          boxShadow: "0px 10px 30px rgba(234, 65, 96, 0.5)",
          borderRadius: 50,
          paddingLeft: 38,
          paddingRight: 38,
          paddingTop: 10,
          paddingBottom: 10,

          [breakpoints.down("sm")]: {
            paddingLeft: 20,
            paddingRight: 20,
          },

          [breakpoints.down(405)]: {
            paddingLeft: 15,
            paddingRight: 15,
          },
        },

        containedSizeSmall: {
          boxShadow: "0px 10px 30px rgba(234, 65, 96, 0.5)",
          minHeight: 28,
          minWidth: 28,
          padding: 0,
          "& > .MuiButton-startIcon": {
            margin: 0,
          },

          // padding: "8px 16px",
          // minHeight: 34,
        },
        containedSizeMedium: {
          padding: "10px 16px",
          minHeight: 42,
        },
        containedSizeLarge: {
          padding: "12px 20px",
          minHeight: 50,
        },

        outlinedSizeSmall: {},
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    /////////////

    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          background: "rgba(249, 248, 253,0.95)",
          borderRadius: "50%",
          height: "100%",

          display: "flex",
          alignItems: "center",
          top: "calc(50% - 20px)",
        },
        popupIndicator: {},
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: 4,
          width: 22,
          height: 22,
          marginRight: 8,
          ":checked": {
            backgroundImage:
              "url(data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e)",
            borderColor: "transparent",
            backgroundColor: "#503EB6",
            color: "#503EB6",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "& + .MuiFormControl": {
            padding: "0 !important",
          },
          textAlign: "start",
          backgroundColor: "transparent !important",
          lineHeight: "20px",
          minHeight: 40,
          borderRadius: 50,
          padding: "0 !important",

          ":after": {
            display: "none",
          },
          ":before": {
            display: "none",
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: "#503EB6 ",
          // padding: "15px !important",
        },
        thumb: {
          height: 11,
          width: 11,
          color: "#EA4160 ",
        },
        rail: {
          height: 3,
          borderRadius: 3,
        },
        track: {
          height: 3,
          borderRadius: 3,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          outline: "none",
          fontFamily:
            "'Readex Pro', 'Roboto', 'Helvetica', 'Arial', 'sans-serif' ",
          fontWeight: 400,
          flex: "1 1 auto",
          padding: 0,
          border: "none",
          background: "transparent",
          fontSize: 14,
          lineHeight: "20px",
        },
      },
    },
  },
  palette: {
    footer: palette.augmentColor({
      color: {
        main: "rgb(243, 244, 246)",
      },
    }),

    radioSelected: palette.augmentColor({
      color: {
        main: "#503EB6",
      },
    }),
    warning: {
      main: "#503EB6",
    },
    primary: {
      main: "#EA4160",
    },
    secondary: palette.augmentColor({
      color: {
        main: "#8C8C8C",
      },
    }),

    success: {
      main: "#00E899",
    },
    info: {
      main: "#EEEEEE",
    },
  },

  // 1280 - xl, lg - 1024, md - 768,
  typography: {
    fontFamily: "'Readex Pro', 'Roboto', 'Helvetica', 'Arial', 'sans-serif' ",
    heroBannerTitle: {
      color: "#fff",
      fontSize: 48,
      lineHeight: 1.25,
      fontWeight: 700,
      [breakpoints.up("xl")]: {
        fontSize: 60,
      },
      [breakpoints.up("lg")]: {
        fontSize: 48,
      },
      [breakpoints.up("md")]: {
        fontSize: 60,
      },
    },
    link: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "20px",
      outline: "2px solid transparent",
      outlineOffset: "2px",
      color: "rgb(80, 62, 182)",
      textDecorationStyle: "dotted",
      textDecorationLine: "underline",
    },
    sliderTitle: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: "28px",

      [breakpoints.up("md")]: {
        fontSize: 34,
      },
    },
    sliderTitleMini: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: "28px",

      [breakpoints.up("md")]: {
        fontSize: 22,
      },
    },
    heroBannerSubtitle: {
      fontFamily:
        "'Satisfy', 'Readex Pro', 'Roboto', 'Helvetica', 'Arial', 'sans-serif' ",
      color: "#fff",
      fontSize: 44,
      [breakpoints.up("xl")]: {
        fontSize: 72,
      },
      [breakpoints.up("lg")]: {
        fontSize: 60,
      },
      [breakpoints.up("md")]: {
        fontSize: 72,
      },
    },
    h2: {
      color: "inherit",
      fontFamily: "Readex Pro",
      fontSize: 22,
      fontWeight: 600,
    },
    h3: {
      color: "#000",
      fontWeight: 600,
      fontSize: 20,
      // lineHeight: "28px",
    },
    body1: {
      fontSize: 14,
      color: "#000",
      fontWeight: 600,
    },
    body2: {
      fontSize: 18,
      color: "#000",
      fontWeight: 600,
    },
    body3: {
      fontFamily: "'Readex Pro', 'Roboto', 'Helvetica', 'Arial', 'sans-serif' ",
      color: "#000",
      fontWeight: 500,
      fontSize: 16,
    },
    button: {
      color: "#fff",
      fontWeight: 500,
      textTransform: "none",
    },
    subtitle1: {
      color: "#503EB6",
    },
    caption: {
      color: "#000",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "16px",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      "sm-": 560,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      "2xl": 2560,
      mobile: 540,
      tablet: 720,
      laptop: 980,
      desktop: 1168,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
