import React from "react";

type BaseIconProps = {
  color?: string;
};

const FemaleIcon = ({ color = "#503EB6" }: BaseIconProps) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 21C5.5 17.087 9.5625 17.8696 10.375 15.751V14.7532C7.43294 14.7532 6.61637 13.5863 6.61637 13.5863C8.15362 12.3545 5.2465 3.78261 10.4806 3.78261C10.4806 3.78261 11.1875 3 12.1008 3C18.5 3 15.6124 12.3076 17.2674 13.6161C17.2674 13.6161 16.5817 14.743 13.625 14.743V15.751C14.4375 17.8696 18.5 17.087 18.5 21H5.5Z"
        fill={color}
      />
    </svg>
  );
};

export default FemaleIcon;
