import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

interface IProps {
  width: number;
  color: string;
  className?: string;
}

const useStyles = makeStyles<Theme, IProps>((theme: Theme) => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    background: '#EEEEEE',
    height: '6px',
    borderRadius: '4px',
    marginTop: '12px',
  },
  bar: {
    position: 'absolute',
    width: (props) => `calc(${props.width}% - 4px)`,
    height: 'calc(100% - 2px)',
    left: '1px',
    bottom: '1px',
    background: (props) => `${props.color}`,
    borderRadius: '4px',
  },
  progressTemplate: {
    zIndex: '10',
  },
}));

const ProgressBar: FC<IProps> = (props: IProps) => {
  const classes = useStyles(props);
  return (
    <div className={classes.wrapper} >
      <div className={classes.bar} />
    </div>
  );
};

export default ProgressBar;
