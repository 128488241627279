import { HttpService, WsService } from "./http.service";
import { ErrorWrapper } from "./utils";

function generateParams(data: any) {
  return Object.keys(data)
    .map((key) => (data[key] ? `${key}=${encodeURIComponent(data[key])}` : ""))
    .join("&");
}

export default class SettingsService {
  static get entity(): string {
    return "settings";
  }

  static async getSettings(): Promise<any> {
    try {
      const response = await HttpService.get(`/${this.entity}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async changePassword(password: string): Promise<any> {
    try {
      const response = await HttpService.post(`/auth/password`, { password });

      return response.data;
    } catch (error: any) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
