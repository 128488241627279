import GiftsNavbar from "@/pages/gifts/components/gifts-navbar";
import {
  Box,
  Button,
  List,
  ListItem,
  Popover,
  PopoverProps,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useCallback } from "react";
import { ReactComponent as NewContactsIcon } from "@/assets/icons/new-mail-contacts.svg";


type DialogGiftsProps = {
  anchorEl: PopoverProps["anchorEl"];
  setAnchorEl: React.Dispatch<React.SetStateAction<null>>;
  isContactGiftsDisplayed: boolean;
  contactGiftsSwitcher: (state: boolean) => void;
  contactsGiftCount: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogInner: {
    "& > .MuiTypography-root": {
      color: "inherit",
    },

    display: "flex",
    flexDirection: "column",
    width: 205,
    padding: 0,
    paddingTop: 0,
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  listItem: {},
  listItemButton: {
    "&:hover > .MuiListItemText-root	": {
      color: "#503EB6",
    },
  },
  navigationListWrapper: {
    width: "100%",
    flexShrink: 0,
  },
  navigationHeader: {
    width: "100%",
  },

  subHeader: {
    display: "flex",
    margin: "24px 0",
    justifyContent: "space-between",
    width: "100%",
  },
}));

const stylesOverrides = {
  dialogInner: {
    paddingTop: 0,
  },
  listItemButton: {
    padding: "6px 16px",
  },
  subHeaderButton: {
    minWidth: 80,
    minHeight: 0,
    height: 24,
  },
};

const DialogGiftMenu = ({
  anchorEl,
  setAnchorEl,
  isContactGiftsDisplayed,
  contactGiftsSwitcher,
  contactsGiftCount
}: DialogGiftsProps) => {
  const classes = useStyles();

  const handleClose = useCallback((state?: boolean) => {
    if (typeof state == "boolean") {
      contactGiftsSwitcher(state);
    }

    setAnchorEl(null);
  }, [contactGiftsSwitcher, setAnchorEl]);

  const open = Boolean(anchorEl);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      transformOrigin={{
        vertical: -15,
        horizontal: 15,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          borderRadius: 10,
          overflow: "initial",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          mt: "10px",
          "&::before": {
            backgroundColor: "white",
            content: '""',
            display: "block",
            position: "absolute",
            width: 12,
            height: 12,
            top: -16,
            transform: "rotate(45deg)",
            left: "28px",
          },
        }}
      />
      <List className={classes.dialogInner} style={stylesOverrides.dialogInner}>
        <Fragment>
          <ListItem className={classes.listItem}>
            <div className={classes.navigationListWrapper}>
              {/* <div className={classes.navigationHeader}>
                <div className={classes.subHeader}>
                  <Button
                    disableElevation
                    color={isContactGiftsDisplayed ? "info" : "primary"}
                    variant="contained"
                    style={stylesOverrides.subHeaderButton}
                    onClick={() => handleClose(false)}
                  >
                    <Typography color="currentColor">All</Typography>
                  </Button>
                  <Button
                    disableElevation
                    color={isContactGiftsDisplayed ? "primary" : "info"}
                    variant="contained"
                    style={stylesOverrides.subHeaderButton}
                    startIcon={<NewContactsIcon />}
                    onClick={() => handleClose(true)}
                  >
                    <Typography color="currentColor">{contactsGiftCount}</Typography>
                  </Button>
                </div>
              </div> */}
              <GiftsNavbar setGiftOptionEl={setAnchorEl} />
            </div>
          </ListItem>
        </Fragment>
      </List>
    </Popover>
  );
};

export default DialogGiftMenu;
