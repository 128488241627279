import { UserProfile } from "@/types/user-profile.types";
import { HttpService } from "./http.service";
import { ErrorWrapper } from "./utils";

export default class SlidersService {
  static get entity(): string {
    return "/profiles";
  }

  static async getLastJoined(): Promise<Array<UserProfile>> {
    const request = `${this.entity}/last-joined`

    try {
      const response = await HttpService.get(request);
      return response.data;
    } catch (error: any) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getPublicLastJoined(): Promise<Array<UserProfile>> {
    const request = `/public/last-joined`

    try {
      const response = await HttpService.get(request);
      return response.data;
    } catch (error: any) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getPublicTopUsers(): Promise<Array<UserProfile>> {
    const request = `/public/top20`

    try {
      const response = await HttpService.get(request);
      return response.data;
    } catch (error: any) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getOnlineUsers(): Promise<Array<UserProfile>> {
    const request = `${this.entity}/online`

    try {
      const response = await HttpService.get(request);
      return response.data;
    } catch (error: any) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
