import { useAppSelector } from "@/app/hooks";
import femalePicture from "@/assets/images/demo-avatar-f.png";
import malePicture from "@/assets/images/demo-avatar-m.png";


const useBlankProfileAvatart = () => {
  const gender = useAppSelector((state) => state?.users?.currentUser?.profile?.gender);

  return gender === 'male' ? {
    currentUserPicture: malePicture,
    oppositeUserPicture: femalePicture,
  } : {
    currentUserPicture: femalePicture,
    oppositeUserPicture: malePicture,
  };
};

export default useBlankProfileAvatart;
