import { UserPhotoType } from '@/app/users/users.types';
import { HttpService } from './http.service';
import { ErrorWrapper } from './utils';
import { GiftKind, GiftsDTO, PostGiftDTO, SortlistNames } from '@/app/gifts/gifts.types';
import { StorageService } from './storage.service';

const getSortQuery = (sorting: SortlistNames | 'Sort by') => {
  switch (sorting) {
    case SortlistNames.DATEASC:
      return '&sortField=created_at&sortOrder=ASC';
    case SortlistNames.DATEDESC:
      return '&sortField=created_at&sortOrder=DESC';
    case SortlistNames.NAMEASC:
      return '&sortField=name&sortOrder=ASC';
    case SortlistNames.NAMEDESC:
      return '&sortField=name&sortOrder=DESC';
    default:
      return '';
  }
};

const getQueryParams = (searchProfileId, sorting, pagination) => {
  if (!searchProfileId && !sorting && !pagination) return '';

  return `?limit=${pagination.limit}&offset=${pagination.offset}${
    searchProfileId ? `&profileId=${searchProfileId}` : ''
  }${getSortQuery(sorting)}`;
};

export class QueueNewVirtualGiftDto {
  id: string;
  name: string;
  text: string | null;
  image: string;
  seen: string | null;
  createdAt: string | null;
  senderId: string;
  receiverId: string;
  sender: QueueProfileDto;
  receiver: QueueProfileDto;
}

export class QueueProfileDto {
  id: string;
  name: string;
  avatar: string | null;
  activeAt: string | null;
  isBookmark: boolean | null;
  birthday: string | null;
  pageId: number;
}

export interface GiftShape {
  id: string;
  text: string;
  seen: string | null;
  profileId: string;
  profileName: string;
  profileAvatar: string;
  createdAt: string;
  activeAt: string;
  isBookmark: boolean | null;
  profileBirthday: string | null;
  pageId: number;
  virtualGiftImage: string;
}
export interface CountersShape {
  unseen: number;
  bookmarksUnseen: number;
}

export default class GiftsService {
  static get entity(): string {
    return 'virtual-gifts';
  }

  static async getGiftsByType(
    type: GiftKind,
    pagination: { limit: number; offset: number },
    searchProfileId: number | null,
    sorting: SortlistNames
  ): Promise<Array<GiftsDTO>> {
    const serviceRequest = `/${this.entity}/${type}${getQueryParams(searchProfileId, sorting, pagination)}`;

    try {
      const response = await HttpService.get(serviceRequest);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getGifts(): Promise<Array<GiftsDTO>> {
    try {
      const response = await HttpService.get(`/${this.entity}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getGiftById(id: string): Promise<GiftsDTO> {
    try {
      const response = await HttpService.get(`/${this.entity}/${id}`);

      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async postGift(body: PostGiftDTO): Promise<GiftsDTO> {
    try {
      if (body.isDialog) {
        const response = await HttpService.post(`/dialogs/virtual-gift`, body);
        return response.data;
      }
      const response = await HttpService.post(`/${this.entity}/send`, body);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async deleteGift(id: string, type: UserPhotoType): Promise<void> {
    try {
      const response = await HttpService.delete(`/${this.entity}/${id}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async deleteGifts(chosenIds: Array<string>): Promise<Array<GiftShape>> {
    const config = {
      data: {
        ids: chosenIds,
      },
    };

    try {
      const response = await HttpService.delete(`/${this.entity}`, config);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async markAsReadGifts(chosenIds: Array<string>): Promise<Array<GiftShape>> {
    try {
      const response = await HttpService.post(`/${this.entity}/seen`, {
        ids: chosenIds,
      });

      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async addToBookmarks(id: string): Promise<Array<GiftShape>> {
    try {
      const response = await HttpService.post(`/bookmarks`, {
        profileId: id,
      });

      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getUserGift(id: string, type: string): Promise<GiftShape> {
    try {
      const response = await HttpService.get(`/${this.entity}/${type}/${id}`);

      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getMessageCounters(): Promise<CountersShape> {
    try {
      const token = StorageService.getToken();
      if (!token) {
        return { unseen: 0, bookmarksUnseen: 0 };
      }
      const response = await HttpService.get(`/counter/messages`);

      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getGiftCounters(): Promise<CountersShape> {
    try {
      const response = await HttpService.get(`/counter/virtual-gifts`);

      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
