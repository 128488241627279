import { Dialog, DialogProps, Theme, Zoom } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { CloseIconStyled } from './sign-up-dialog.component';
import PriceList from '../additional/price-list';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '35px 45px',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 12px',
    },
  },
  dialogPaper: {
    overflowY: 'hidden',
  },
  dialogTitle: {
    textAlign: 'center',
    height: 48,
  },

  buyButton: {
    height: 42,
    width: 140,
  },

  link: {
    color: '#ea4160',
  },

  info: {
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
  },

  loader: {
    margin: '0 auto',
    padding: '10px',
    width: '200px',
    display: 'flex',
    justifyContent: 'center',
  },
  choosePaymentProviderContainer: {
    marginTop: 15,
  },
}));

type DialogWriteMailProps = {
  open: boolean;
  closeHandler: () => void;
} & DialogProps;

const DialogPriceList = ({ open, closeHandler }: DialogWriteMailProps) => {
  const classes = useStyles();
  return (
    <>
      <Dialog
        classes={{
          root: classes.dialogPaper,
        }}
        TransitionComponent={Zoom}
        open={open}
        onClose={closeHandler}
      >
        <PriceList className={classes.container} />
        <CloseIconStyled onClick={closeHandler} />
      </Dialog>
    </>
  );
};

export default DialogPriceList;
