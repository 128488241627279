
import { Gallery } from "react-photoswipe-gallery";
import NewsPhoto from "@/components/base/news-photo";
import { ReactComponent as ArrowIcon } from "@/assets/icons/option-arrow.svg";

import { NewsShape } from '@/services/news.service';
import { Chip, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BaseCard from "@/components/base/base-card.component";
import ProfileCard from '@/components/base/profile-card.component';
import { format, isToday } from 'date-fns';
import { formatCreatedAtDate } from '@/utils/formatDate';
import { useCallback, useState } from 'react';
import { Link } from "react-router-dom";
import useBlankProfileAvatart from "@/hooks/useBlankProfileAvatart";


const useStyles = makeStyles((theme: Theme) => ({
  itemCardOverrides: {
    padding: 0,
  },

  newsWrapper: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '18px',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    width: '100%'
  },

  profileCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',

    [theme.breakpoints.down("md")]: {
      display: 'none',
    },
  },

  infoWrapper: {
    width: '100%',
    height: '100%',
    marginLeft: 21
  },

  metaWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 19
  },

  info: {
    fontSize: '16px',
    lineHeight: '24px',
  },

  date: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#808080',

    [theme.breakpoints.down("md")]: {
      display: 'none',
    },
  },

  smallScreenDate: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#808080',

    [theme.breakpoints.up("md")]: {
      display: 'none',
    },
  },

  smallScreenMeta: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15,
    alignItems: 'center',

    [theme.breakpoints.up("md")]: {
      display: 'none',
    },

    [theme.breakpoints.down("sm")]: {
      marginBottom: 19,
    },
  },

  photoBlockWrapper: {
    overflow: 'hidden',
    width: '100%',
    marginBottom: '8px',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 75px)',
    gridGap: '8px',
    transition: 'all .5s',
    maxHeight: 176,

    '& .pswp__zoom-wrap': {
      display: 'flex',
      justifyContent: 'center',
    },

    '& .pswp__img': {
      width: 'auto !important',
      position: 'relative !important'
    },
    '& .pswp__img--placeholder': {
      display: 'none'
    }
  },

  expandedBlock: {
    maxHeight: 1900,
  },

  photoWrapper: {
    width: 75,
    height: 84,

    borderRadius: '10px',
    overflow: 'hidden',

    '&> img': {
      width: '100%',
      height: '100%',
      aspectRatio: "75 / 84",
      objectFit: 'cover'
    }
  },

  nameWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px'
  },

  smallNameWrapper: {
    display: 'flex',
    alignItems: 'center'
  },

  smallData: {
    display: 'flex',
    flexDirection: 'column',
    color: '#000000',
  },

  smallName: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '18px',
  },

  smallId: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px',
  },

  optionViewBtn: {
    marginTop: 'auto',
    cursor: "pointer",
    display: "flex !important",
    alignItems: "center !important",
    all: "unset",
  },

  photoInner: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  senderAvatar: {
    maxWidth: 56,
    minWidth: 56,
    maxHeight: 56,
    aspectRatio: "56 / 56",
    marginRight: '11px'
  },

  avatarInner: {
    borderRadius: "50%",
  },
}));

interface Props {
  news: NewsShape;
  windowHeight: number;
  windowWidth: number;
}

const PhotoNews = ({ news, windowHeight, windowWidth }: Props) => {
  const { oppositeUserPicture } = useBlankProfileAvatart();

  const classes = useStyles({ avatar: news?.profileAvatar || oppositeUserPicture });

  const [isOptionsOpen, setOptionsOpen] = useState(false);

  const formatedCreatedAtDate = useCallback((createdAt: Date) => {
    const timestamp = new Date();

    const month = format(timestamp, "MMM do");
    const localeTime = formatCreatedAtDate(createdAt);

    return isToday(timestamp) ? `${localeTime}` : `${month}`;
  }, []);

  return <BaseCard wrapperOverrideStyle={{ margin: 0, marginBottom: 10, padding: 20 }} cardOverrideStyle={classes.itemCardOverrides}>
    <div className={classes.newsWrapper} key={news.profilePageId}>
      <div className={classes.profileCard}>
        <ProfileCard user={{
          userId: news.profileId,
          pageId: news.profilePageId,
          name: news.profileName,
          avatar: news.profileAvatar,
          birthday: news.birthday,
          activeAt: news.activeAt
        }} />
      </div>

      <div className={classes.infoWrapper}>
        <div className={classes.smallScreenMeta}>
          <div className={classes.smallNameWrapper}>
            <Link to={`/user/${news.profileId}`}>
              <div className={classes.senderAvatar}>
                <img
                  src={news?.profileAvatar || oppositeUserPicture}
                  width="100%"
                  height="100%"
                  className={classes.avatarInner}
                  alt='avatar'
                />
              </div>
            </Link>
            <div className={classes.smallData}>
              <div className={classes.smallName}>{news.profileName}</div>
              <div className={classes.smallId}>Id: {news.profilePageId}</div>
            </div>
          </div>

          <div className={classes.smallScreenDate}>
            {formatedCreatedAtDate(news.dateForGroup)}
          </div>
        </div>
        <div className={classes.metaWrapper}>
          <div className={classes.info}>
            {news.profileName} uploaded
            <Chip label={news.photos?.length} color='primary' sx={{
              height: 20, margin: '0 8px'
            }} />
            photos
          </div>

          <div className={classes.date}>
            {formatedCreatedAtDate(news.dateForGroup)}
          </div>
        </div>

        <div className={`${classes.photoBlockWrapper} ${isOptionsOpen ? classes.expandedBlock : ''}`}>
          <Gallery>
            {news.photos?.map((image, index) => (
              <NewsPhoto key={image.small} {...image} windowHeight={windowHeight} windowWidth={windowWidth} />
            ))}
          </Gallery>
        </div>

        <button
          type="button"
          className={classes.optionViewBtn}
          onClick={() => setOptionsOpen(!isOptionsOpen)}
        >
          <Typography variant="link">{isOptionsOpen ? 'Hide' : 'View all'}</Typography>
          <ArrowIcon
            style={{
              marginLeft: 6,
              width: 18,
              height: 18,
              transform: isOptionsOpen ? "rotate(180deg)" : "",
            }}
          />
        </button>
      </div>
    </div>
  </BaseCard>
}

export default PhotoNews;
