import { Button, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as CameraLargeIcon } from "@/assets/icons/camera-large.svg";
import { ReactComponent as CrossIcon } from "@/assets/icons/cross.svg";

import clsx from "clsx";
import DialogPhotoFileUpload from "@/components/dialogs/upload-photo-file.dialog.component";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  deletePhotosRequest,
  getPhotosRequest,
  postPhotosRequest,
} from "@/app/users/users.actions";
import { UserPhotoType } from "@/app/users/users.types";
import useIsStaffUserRole from "@/hooks/useIsStaffUserRole";

type AlbumPhotoCoverProps = {
  imageURL?: string;
  type: UserPhotoType;
  photosCount?: number;
  handleWrapperClick?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: 10,
    height: "100%",
    backgroundColor: "#F6F6F6",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      opacity: 0.9,
    },
  },
  uploaderWrapper: {
    // margin: 16,
    overflow: "scroll",
  },
  stubWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  albumCta: {
    marginTop: 16,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  ctaText: {
    textTransform: "capitalize",
  },
  ctaBadge: {
    padding: 2,
    fontSize: 14,
    minWidth: 40,
    borderRadius: 20,
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    display: "inline-flex",
    justifyContent: "center",
    marginLeft: 8,
  },
  metaCtaIcon: {
    marginLeft: 8,
    "& > .MuiButton-startIcon": {
      margin: 0,
    },
  },
}));
const AlbumPhotoCover = ({
  imageURL = "",
  type,
  photosCount = 0,
  handleWrapperClick = () => { },
}: AlbumPhotoCoverProps) => {
  const classes = useStyles();
  const isStaff = useIsStaffUserRole();
  const [isModalVisible, setModalVisible] = useState(false);
  const userId = useAppSelector((state) => state.users.currentUser?.id);
  const images = useAppSelector((state) => state.users[type + "Photos"]);
  const staffRestriction = useMemo(() => type === 'public' && isStaff, [isStaff, type])

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPhotosRequest(userId, type, 0));
  }, [type, userId, getPhotosRequest]);

  const handleFileUploading = useCallback((image: FormData) => {
    dispatch(postPhotosRequest(type, image));
  }, []);

  const handleRemoveFile = useCallback((id: string) => {
    dispatch(deletePhotosRequest(type, id));
  }, []);

  const handleClose = () => setModalVisible(false);
  const handleOpen = () => setModalVisible(true);
  return (
    <Fragment>
      <DialogPhotoFileUpload
        className={classes.uploaderWrapper}
        handleFileUploading={handleFileUploading}
        handleRemoveFile={handleRemoveFile}
        defaultValue={images}
        isOpen={isModalVisible}
        onClose={handleClose}
      />

      {imageURL ? (
        <div
          className={classes.wrapper}
          onClick={handleWrapperClick}
          style={{
            backgroundImage: `url(${imageURL})`,
            cursor: imageURL ? "pointer" : "inherit",
          }}
        />
      ) : (
        <div className={clsx(classes.wrapper, classes.stubWrapper)}>
          <CameraLargeIcon />
        </div>
      )}
      <div className={classes.albumCta}>
        <Typography className={classes.ctaText} variant="body3">
          {type}:<span className={classes.ctaBadge}>{photosCount}</span>
        </Typography>

        {
          !staffRestriction &&
          <div>
            <Typography className={classes.ctaText} variant="body3">
              Add Photos
            </Typography>
            <Button
              onClick={handleOpen}
              component="span"
              style={{
                minWidth: 28,
                minHeight: 28,
                padding: 0,
                marginLeft: 12,
              }}
              disableRipple
              disableElevation
              color="info"
              className={classes.metaCtaIcon}
              variant="contained"
              startIcon={<CrossIcon />}
            />
          </div>
        }
      </div>
    </Fragment>
  );
};

export default AlbumPhotoCover;
