import React, { useState } from 'react';
import PersistentTabManager from './persistance-tab-manage';

const usePersistentTab = (url: string): [(url: string) => void, Window | null] => {
  const [openedWindow, setOpenedWindow] = useState<Window | null>(null);
  const persistentTabManager = new PersistentTabManager(url);

  const openTab = (url: string): void => {
    // if (openedWindow && !openedWindow.closed) {
    //   openedWindow.location.replace(url);
    //   openedWindow.focus();
    // } else {
    //   const newWindow = window.open(url);
    //   setOpenedWindow(newWindow);
    // }
    persistentTabManager.openTab(url);
  };

  return [openTab, openedWindow];
};

export default usePersistentTab;
