import { ReactComponent as ArchiveIcon } from "@/assets/icons/archive.svg";
import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";
import { ReactComponent as TrashIcon } from "@/assets/icons/trash.svg";
import BaseButton from "@/components/base/button.component";
import SelectField from "@/components/base/select-field.component";
import { useAppBreakpoints } from "@/hooks";
import { IconButton, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as ArrowLeftIcon } from "@/assets/icons/left-arrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import BaseAutocomplete from "@/components/base/base-autocomplete";
import useDebounce from "@/hooks/useDebounce";
import ProfilesService from "@/services/profiles.service";
import { UserProfile } from "@/types/user-profile.types";
import CloseIcon from "@mui/icons-material/Close";
import { MailKind, SelectMessagesOption, SortlistNames } from "@/app/mails/mails.types";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { checkFewMessages, checkMessage, deleteSelectedMessagesAction, markAsReadSelectedMessagesAction, resetUserMessage, setSearchProfileIdAction, setSelectedMessages, setSortingAction } from "@/app/mails/mails.actions";


const sortList = [
  { id: 1, name: SortlistNames.DATEASC },
  { id: 2, name: SortlistNames.DATEDESC },
  { id: 3, name: SortlistNames.NAMEASC },
  { id: 4, name: SortlistNames.NAMEDESC },
];

const selectList = [
  { id: 1, name: SelectMessagesOption.NO_SELECTION },
  { id: 2, name: SelectMessagesOption.ALL },
  { id: 3, name: SelectMessagesOption.NEW },
  { id: 4, name: SelectMessagesOption.READ },
];

const useStyles = makeStyles((theme: Theme) => ({
  selectField: {
    minWidth: 176,
    [theme.breakpoints.down("md")]: {
      minWidth: 113,
      "& .MuiFilledInput-input": {
        maxWidth: '75px !important'
      },
    },
  },
  subSelectField: {
    minWidth: 120,
    [theme.breakpoints.down("md")]: {
      minWidth: 113,
      "& .MuiFilledInput-input": {
        maxWidth: '75px !important'
      },
    },
  },
  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  searchHeader: {
    width: "100%",
    padding: '0 10px',
    [theme.breakpoints.down("sm")]: {
      minWidth: 113,
      padding: '0 5px',
      "& input": {
        width: '97px !important'
      },
    },
  },
  headerdWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 26,
  },
  searchWrapper: {
    height: '42px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    paddingLeft: '20px',
    [theme.breakpoints.down("sm")]: {
      paddingLeft: '12px',
    },
  },

  headerCta: {
    display: "flex",
    gap: 24,
    marginLeft: 24,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 12,
    },
  },
  ctaButton: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#000",
  },
  ctaIcon: {
    maxWidth: 42,
    maxHeight: 42,
    color: `${theme.palette.radioSelected.main} !important`,
    "& > .MuiButton-startIcon": {
      margin: 0,
    },
    minWidth: "0 !important",
  },
  backBtn: {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
}));

type MessagesHeaderProps = {
  enableMessageLayout?: boolean | undefined;
};

const MessagesHeader = ({ enableMessageLayout }: MessagesHeaderProps) => {
  const classes = useStyles();
  const { downLg, smAndUp } = useAppBreakpoints();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id: messageId } = useParams();
  const { kind } = useParams<{
    kind: MailKind;
  }>();
  const mails = useAppSelector((state) => state.mails[kind + "Mails"]);
  const chosenSorting = useAppSelector((state) => state.mails.sorting);
  const selectedMessagesType = useAppSelector((state) => state.mails.selectedMessages);

  const isMessagesChosed = useAppSelector((state) => state.mails.chosenIds.size);

  const selectHandler = useCallback((value: string) => {
    dispatch(setSelectedMessages(value));

    switch (value) {
      case SelectMessagesOption.NO_SELECTION:
        dispatch(checkFewMessages([]));
        break;

      case SelectMessagesOption.ALL:
        const allMailIds = mails.map(mail => mail.id);
        dispatch(checkFewMessages(allMailIds));
        break;

      case SelectMessagesOption.NEW:
        const newMailIds = mails.filter(mail => !mail.seen).map(mail => mail.id);
        dispatch(checkFewMessages(newMailIds));
        break;

      case SelectMessagesOption.READ:
        const readMailIds = mails.filter(mail => mail.seen).map(mail => mail.id);
        dispatch(checkFewMessages(readMailIds));
        break;
    }

  }, [dispatch, mails])

  const getControlledValue = useMemo(() => {

    if (selectedMessagesType === SelectMessagesOption.NEW) {
      return selectList[2];
    }

    if (selectedMessagesType === SelectMessagesOption.READ) {
      return selectList[3];
    }

    if (selectedMessagesType === SelectMessagesOption.ALL) {
      return selectList[1];
    }

    return selectList[0];

  }, [selectedMessagesType])

  const getControlledSortValue = useMemo(() => {
    switch (chosenSorting) {
      case SortlistNames.DATEASC: return sortList[0];
      case SortlistNames.DATEDESC: return sortList[1];
      case SortlistNames.NAMEASC: return sortList[2];
      case SortlistNames.NAMEDESC: return sortList[3];
      default: return sortList[0];
    }

  }, [chosenSorting])


  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);
  const headerActions = [
    {
      icon: <TrashIcon />,
      onClick: () => dispatch(deleteSelectedMessagesAction(kind)),
    },
    {
      icon: <ArchiveIcon />,
      onClick: () => dispatch(markAsReadSelectedMessagesAction(kind)),
    },
  ];


  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState<UserProfile[]>([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);

      ProfilesService.getAllProfilesByPageId(debouncedSearchTerm).then(
        (results) => {
          setIsSearching(false);
          setResults(results.data);
        }
      );
    } else {
      setResults([]);
    }
  }, [debouncedSearchTerm]);

  if (enableMessageLayout) {
    return (
      <div className={classes.header}>
        <div className={classes.backBtn}>
          <BaseButton
            inverted
            text=""
            className={classes.ctaIcon}
            textStyle=""
            onClick={() => {
              dispatch(resetUserMessage())
              navigate(-1)
            }}
            Icon={<ArrowLeftIcon />}
          />
          <Typography variant="body3">Back</Typography>
        </div>
        <BaseButton
          inverted
          text=""
          className={classes.ctaIcon}
          textStyle=""
          onClick={() => {
            dispatch(checkMessage(messageId));
            dispatch(deleteSelectedMessagesAction(kind));
            navigate(-1)
          }}
          Icon={<TrashIcon />}
        />
      </div>
    );
  }

  return (
    <Fragment>
      <Formik
        initialValues={{
          sort: "",
        }}
        onSubmit={(values) => {
          //   dispatch(getSearchUsersRequest(values));
        }}
      >
        {({
          setFieldValue,
        }) => (
          <div className={classes.header}>

            <div className={classes.headerdWrapper}>
              <SelectField
                placeholder="Sort by"
                list={sortList}
                label="sort"
                className={classes.selectField}
                onChnage={(value: any) => dispatch(setSortingAction(value))}
                controlledValue={getControlledSortValue}
              />
            </div>

            {
              isSearchMode ?
                <>
                  <div className={classes.searchHeader}>
                    <BaseAutocomplete
                      setOptions={setResults}
                      name="receiverId"
                      placeholder="Search (ID):"
                      onChange={(e, val) => {
                        setFieldValue("receiverId", val);
                        if (val?.id) {
                          dispatch(setSearchProfileIdAction(val.id))
                        }
                      }}
                      onInput={(e: any) => {
                        if (e.target.validity.valid) {
                          setSearchTerm(e.currentTarget.value);
                        }
                      }}
                      options={results}
                      loading={isSearching}
                    />
                  </div>
                  <IconButton size="small" onClick={() => {
                    dispatch(setSearchProfileIdAction(null));
                    setSearchTerm('');
                    setIsSearchMode(false);
                  }}>
                    <CloseIcon color="inherit" />
                  </IconButton>
                </>
                :
                <>
                  <div className={classes.searchWrapper} onClick={() => setIsSearchMode(true)}>
                    <SearchIcon />
                  </div>

                  <div className={classes.headerCta}>
                    {(!downLg || (smAndUp && isMessagesChosed)) ?
                      headerActions.map((action, idx) => (
                        <BaseButton
                          key={idx}
                          inverted
                          text=""
                          className={classes.ctaIcon}
                          style={{ opacity: isMessagesChosed ? '1' : '.3' }}
                          textStyle=""
                          onClick={action.onClick}
                          Icon={action.icon}
                          disabled={!isMessagesChosed}
                        />
                      )) : null}
                    <SelectField
                      placeholder="Select"
                      list={selectList}
                      label="select"
                      className={classes.subSelectField}
                      onChnage={(value: string) => { selectHandler(value) }}
                      controlledValue={getControlledValue}
                    />
                  </div>
                </>
            }

          </div>
        )}
      </Formik>
    </Fragment>
  );
};

export default MessagesHeader;
