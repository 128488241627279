import { IUserPhotosData, UserPhotosDTO } from '@/app/users/users.dto';
import { UserPhotoType } from '@/app/users/users.types';
import { HttpService } from './http.service';
import { ErrorWrapper } from './utils';
import { StorageService } from './storage.service';

export default class PhotosService {
  static get entity(): string {
    return 'photos';
  }

  static async getPhotos(offset = 0, viewType: UserPhotoType): Promise<IUserPhotosData> {
    try {
      const response = await HttpService.get(
        `/${this.entity}/${
          viewType === UserPhotoType.PRIVATE_VIEW ? 'private' : 'public'
        }?offset=${offset}&limit=999`
      );
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getUserPhotos(id: string, type: UserPhotoType): Promise<IUserPhotosData> {
    try {
      const token = StorageService.getToken();
      if (token) {
        const response = await HttpService.get(`/${this.entity}/profile/${id}/${type}?limit=999`);
        return response.data;
      }
      {
        const response = await HttpService.get(`/public/profiles/${id}`);
        return {
          nextPage: false,
          prevPage: false,
          data: response.data.photos,
        };
      }
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async postPhoto(file: FormData, type: UserPhotoType): Promise<UserPhotosDTO> {
    try {
      const response = await HttpService.post(`/${this.entity}/${type}`, file, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async deletePhoto(id: string, type: UserPhotoType): Promise<void> {
    try {
      const response = await HttpService.delete(`/${this.entity}/${id}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
