import { Container } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ReactComponent as MailIcon } from '@/assets/icons/white-mail.svg';
import { ReactComponent as PinIcon } from '@/assets/icons/white-pin.svg';
import { ReactComponent as PhoneIcon } from '@/assets/icons/phone-white.svg';
import Business from '@mui/icons-material/Business';
import { useAppSelector } from '@/app/hooks';
import { ISettings } from '@/types/user-profile.types';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 'calc(100% - 56px)',
    padding: '28px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  headTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '26px',
    paddingBottom: '33px',
    width: '100%',
  },
  paragraphFirst: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
  },
  secondParagraph: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#808080',
    paddingTop: '24px',
  },
  wrapperLines: {
    display: 'flex',
    flexDirection: 'column',
  },
  supportEmail: {
    width: '270px',

    background: '#503EB6',
    borderRadius: '10px',
    padding: '29px',
    marginLeft: '40px',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 40px)',
      marginLeft: 0,
      margin: '20px auto',
    },
  },
  bigLine: {
    fontSize: '54px',
    lineHeight: '64px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#000000',
  },
  iconWrapper: {
    paddingRight: '12px',
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  secondBigLine: {
    fontWeight: '500',
    fontSize: '34px',
    lineHeight: '44px',
    textAlign: 'center',
    color: '#808080',
  },
  titleContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  lineWrapper: { display: 'flex', paddingTop: '20px', color: 'white' },
  breakline: {
    background: '#E7E6E6',
    height: '1px',
    width: '100%',
    marginBottom: '10px',
    marginTop: '40px',
  },
}));

const AboutUs = () => {
  const classes = useStyles();

  const { companyAddress, companyName, companyPhone, supportEmail, siteUrl, siteName } =
  useAppSelector<ISettings>((state) => state.users.settings);

  return (
    <Container maxWidth={'xl'}>
      <div className={classes.container}>
        <div className={classes.headTitle}>About us </div>
        <div className={classes.titleContainer}>
          <div className={classes.wrapperLines}>
            <div className={classes.paragraphFirst}>
              Our website <b>{siteUrl}</b> was designed specifically as a social network for meeting and communicating
              people from all over the world.
            </div>
            <div className={classes.secondParagraph}>
              We are sure that there are no barriers for truly close and dear hearts, and neither distance, nor a
              different language and culture can prevent them from reuniting. Our convenient application, which is made
              according to the most modern technologies, will give people the opportunity to feel themselves a little
              closer to each other.
            </div>
          </div>
          <div className={classes.supportEmail}>
            <div className={classes.lineWrapper}>
              <div className={classes.iconWrapper}>
                <MailIcon />
              </div>
              <div>{supportEmail}</div>
            </div>
            <div className={classes.lineWrapper}>
              <div className={classes.iconWrapper}>
                <PinIcon />
              </div>
              <div>{companyAddress}</div>
            </div>
            <div className={classes.lineWrapper}>
              <div className={classes.iconWrapper}>
                <PhoneIcon />
              </div>
              <div>{companyPhone}</div>
            </div>
            <div className={classes.lineWrapper}>
              <div className={classes.iconWrapper}>
                <Business />
              </div>
              <div>{companyName}</div>
            </div>
          </div>
        </div>
        <div className={classes.breakline}></div>
        <div className={classes.bigLine}>
          {siteName} will give you the opportunity to meet and communicate with people around the world, regardless of age,
          nationality and country of residence.
        </div>

        <div className={classes.secondBigLine}>
          Look for friends and like-minded people around the world without leaving your home.
        </div>
      </div>
    </Container>
  );
};

export default AboutUs;
