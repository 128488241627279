import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BaseButton from '@/components/base/button.component';

export const RevealVideoComponent = ({
  revealVideo,
  message,
  openDialog,
  handleCloseDialog,
}: {
  revealVideo: Function;
  message: any;
  openDialog: boolean;
  handleCloseDialog: any;
}) => {
  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>Do you want to reveal Video?</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <BaseButton text={'close'} onClick={handleCloseDialog} color='secondary' />
        <BaseButton
          text='pay'
          onClick={() => {
            handleCloseDialog();
            revealVideo(message);
          }}
          color='primary'
        />
      </DialogActions>
    </Dialog>
  );
};
