import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';

type ClockComponentProps = {
  isRotating: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  clockWrapper: { marginRight: '20px' },
  svgContainer: { width: '15px', height: '15px' },
  c2: {
    strokeWidth: '30px',
    transformOrigin: '120px 180px',
  },
  c1: {
    animationIterationCount: 'infinite',
    animationDuration: '1s',
    strokeWidth: '30px',
    transformOrigin: '150px 180px',
    animationName: '$turnone',
    animationTimingFunction: 'linear',
  },
  '@keyframes turnone': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

export const Clock = ({ isRotating = false }: ClockComponentProps) => {
  const classes = useStyles();

  return (
    <div className={classes.clockWrapper}>
      <svg
        className={classes.svgContainer}
        viewBox='0 0 300 300'
        width='300'
        height='300'
      >
        <path
          d='M 268.724 106.623 C 288.985 127.604 300.039 153.319 300 179.844 C 300.241 206.342 289.383 232.126 269.273 253.175 C 249.193 274.25 220.952 289.381 188.963 296.239 C 156.998 303.128 123.08 301.326 92.515 291.16 C 61.929 280.991 36.415 263.021 19.935 240.052 C 3.453 217.065 -3.077 190.354 1.352 164.084 C 5.802 137.818 20.96 113.462 44.471 94.82 C 68.006 76.174 98.57 64.275 131.408 60.974 L 131.408 30.033 L 168.931 30.033 L 168.931 60.974 C 196.317 63.675 221.451 72.385 242.085 85.302 L 269.476 63.374 L 296.11 84.703 L 268.724 106.489 L 268.724 106.623 M 150.169 270.343 C 180.035 270.343 208.646 260.848 229.753 243.957 C 250.867 227.047 262.722 204.125 262.722 180.232 C 262.722 156.322 250.867 133.4 229.753 116.512 C 208.646 99.6 180.035 90.105 150.169 90.105 C 120.331 90.105 91.693 99.6 70.582 116.512 C 49.471 133.4 37.616 156.322 37.616 180.232 C 37.616 204.125 49.471 227.047 70.582 243.957 C 91.693 260.848 120.331 270.343 150.169 270.343 M 93.894 0 L 206.445 0 L 206.445 30.033 L 93.894 30.033 L 93.894 0'
          fill='black'
          data-bx-origin='0.507 0.603'
        />
        <line
          className={isRotating ? classes.c1 : classes.c2}
          x1='150'
          y1='180'
          x2='150'
          y2='85'
          style={{ stroke: '#000000' }}
        />
      </svg>
    </div>
  );
};
