import { IUserPhotosData, UserPhotosDTO } from "@/app/users/users.dto";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  CircularProgress,
  Dialog,
  Fade,
  IconButton,
  IconButtonProps,
  styled,
  Theme,
  Typography,
  Zoom,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { Fragment, useCallback } from "react";
import Dropzone from "react-dropzone";
import { useAppSelector } from "../../app/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";

import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import { Gallery, Item } from "react-photoswipe-gallery";

type DialogPhotoFileUploadProps = {
  title?: JSX.Element;
  className: string;
  titleClassName?: string;
  handleFileUploading: (image: FormData) => void;
  handleRemoveFile?: (id: string) => void;
  defaultValue: IUserPhotosData;
  isOpen: boolean;
  onClose: () => void;
  handleClickPhoto?: (id: string, small: string, origin: string, openHandler: () => void, onClose: () => void) => void;
  attachedPhotoIds?: Set<string>;
};

type DeleteIconStyledType = React.ComponentType<IconButtonProps>;

export const DeleteIconStyled: DeleteIconStyledType = styled((props) => (
  <IconButton {...props} size="small">
    <DeleteIcon color="inherit" />
  </IconButton>
))(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    right: 10,
    top: 10,
  },

  [theme.breakpoints.down(640)]: {
    right: -5,
    top: -5,
  },
  width: 28,
  height: 28,
  position: "absolute",
  right: -10,
  top: -10,
  backgroundColor: "#DEDEDE",
  color: theme.palette.radioSelected.main,
  "& > .MuiSvgIcon-root": {
    color: "#000",
  },
  ":hover": {
    backgroundColor: theme.palette.grey[400],
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: 20,
    padding: 24,
  },

  root: {
    border: `2px dashed ${theme.palette.radioSelected.main}`,
    padding: 12,
    borderRadius: 10,
    flexWrap: "wrap",
    display: "flex",
    flexwrap: "wrap",
    alignContent: "space-between",
    overflowY: 'auto'
  },
  dropzone: {
    cursor: "pointer",
    color: theme.palette.radioSelected.main,
    outline: "none",
    margin: 12,
    borderRadius: 15,
    width: 180,
    height: 180,
    backgroundColor: theme.palette.info.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  innerZone: {
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: "inherit",
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
  },
  dropIcon: {
    fontSize: "2.7rem",
  },
  paper: {
    padding: 24,
  },
}));

type CloseIconStyledType = React.ComponentType<IconButtonProps>;

export const CloseIconStyled: CloseIconStyledType = styled((props) => (
  <IconButton {...props} size="small">
    <CloseIcon color="inherit" />
  </IconButton>
))(({ theme }) => ({
  width: 28,
  height: 28,
  position: "absolute",
  right: -10,
  top: -10,
  backgroundColor: "#DEDEDE",
  color: theme.palette.grey[500],
  "& > .MuiSvgIcon-root": {
    color: "#000",
  },
  ":hover": {
    backgroundColor: theme.palette.grey[400],
  },
}));

const DialogPhotoFileUpload = ({
  title,
  handleFileUploading,
  handleRemoveFile,
  className,
  titleClassName,
  defaultValue,
  isOpen,
  onClose,
  handleClickPhoto,
  attachedPhotoIds
}: DialogPhotoFileUploadProps) => {
  const isUploading = useAppSelector((state) => state.users.photosIsUploading);
  const classes = useStyles();

  const handleDrop = useCallback(
    (acceptedFiles: any) => {
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      handleFileUploading(formData);
    },
    [handleFileUploading]
  );

  return (
    <Dialog
      TransitionComponent={Zoom}
      open={isOpen}
      onClose={onClose}
      className={classes.container}
      classes={{
        paper: classes.paper,
      }}
    >
      <CloseIconStyled onClick={onClose} />
      {title}
      <Gallery>
        <Dropzone onDrop={handleDrop} accept="image/png, image/gif, image/jpeg">
          {({ getRootProps, getInputProps }) => (
            <section className={clsx(classes.root, className)}>
              <div {...getRootProps()} className={classes.dropzone}>
                <input {...getInputProps()} />
                <AddCircleOutlineIcon
                  fontSize="medium"
                  color="inherit"
                  className={classes.dropIcon}
                />
              </div>
              {defaultValue.data.map(({ small, origin, id }) => (
                <div key={small} style={{ position: "relative" }}>
                  <Item
                    original={origin}
                    thumbnail={small}
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <div className={classes.dropzone}>
                        <div
                          ref={ref as any}
                          onClick={
                            () => handleClickPhoto ? handleClickPhoto(id, small, origin, open, onClose) : open()
                          }
                          className={classes.innerZone}
                          style={{
                            backgroundImage: `url(${small})`,
                          }}
                        ></div>
                      </div>
                    )}
                  </Item>
                  {
                    handleRemoveFile ?
                      <div
                        style={{
                          position: "absolute",
                          right: 10,
                          top: 10,
                          zIndex: 1000,
                        }}
                        onClick={() => handleRemoveFile(id)}
                      >
                        <DeleteIconStyled />
                      </div>
                      : null
                  }
                  {
                    !handleRemoveFile && attachedPhotoIds?.has(id) ?
                      <div
                        style={{
                          position: "absolute",
                          right: 5,
                          top: 5,
                          zIndex: 1000,
                        }}
                      >
                        <CheckCircle color="primary" />
                      </div>
                      : null
                  }

                </div>
              ))}

              <Fade in={isUploading}>
                <div className={classes.dropzone}>
                  <CircularProgress color="inherit" size={24} />
                </div>
              </Fade>
            </section>
          )}
        </Dropzone>
      </Gallery>
    </Dialog>
  );
};

export default DialogPhotoFileUpload;
