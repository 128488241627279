import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { GiftKind } from "@/app/gifts/gifts.types";
import { ReactComponent as GiftIcon } from "@/assets/icons/drawer-icons/mail.svg";
import { ReactComponent as SentIcon } from "@/assets/icons/sent.svg";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getGiftsRequest, getGiftCountersAction, resetPaginationAndGiftsAction } from "../../app/gifts/gifts.actions";

const LIMIT_GIFTS_PER_REQUEST = 20;

export const useGiftNavigationList = () => {
  const inboxNewGiftsCount = useAppSelector((state) => state.gifts.giftCounters?.unseen);

  return [
    {
      id: 1,
      path: "/user/gifts/inbox",
      name: "Inbox",
      icon: GiftIcon,
      counter: !!inboxNewGiftsCount,
      count: inboxNewGiftsCount,
    },
    {
      id: 2,
      path: "/user/gifts/outbox",
      name: "Sent",
      icon: SentIcon,
      counter: false,
    },
  ];
};

export const useGifts = (
  limit: number = LIMIT_GIFTS_PER_REQUEST
): [GiftKind] => {
  const searchProfileId = useAppSelector((state) => state.gifts.searchProfileId);
  const headerSorting = useAppSelector((state) => state.gifts.sorting);
  const location = useLocation();
  const { kind } = useParams<{
    kind: GiftKind;
  }>();
  const dispatch = useAppDispatch();
  const { id: giftId } = useParams();


  useEffect(() => {
    dispatch(getGiftCountersAction());

    if (!giftId){
      dispatch(resetPaginationAndGiftsAction(kind));

      dispatch(getGiftsRequest(kind));
    }
  }, [dispatch, kind, location.pathname, searchProfileId, headerSorting, giftId]);

  // const loadMorePosts = useCallback(async () => {
  //   setIsLoading(true);

  // }, []);

  return [kind];
};
