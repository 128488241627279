import BaseCard from "@/components/base/base-card.component";
import { useAppBreakpoints, useContainerWidth } from "@/hooks";
import { Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useContainerStyle from "../../hooks/useContainerStyle";
import AlbumVideoCover from "./components/video-album-cover";
import { useAppSelector } from "@/app/hooks";
import { UserProfileDTO } from "@/app/users/users.reducer";
import { UserVideoType } from "@/app/users/users.types";
import { defaultUserVideosData } from '@/app/users/users.dto';
const useStyles = makeStyles((theme: Theme) => ({
  albumContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "stretch",
    gap: 28,
    minHeight: 350,
    marginTop: 35,
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
      gap: 60,
    },
  },
  albumCollectionItem: {
    flex: "1 1 auto",
  },
  sliderContainer: {
    marginTop: 55,
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "14px 28px 42px",

    [theme.breakpoints.down("md")]: {
      padding: "14px 7px 42px",
    },
  },
}));
const UserVideoAlbums = () => {
  const width = useContainerWidth();
  const { downXl, lg } = useAppBreakpoints();
  const { wrapperClass, wrapperOverride } = useContainerStyle();
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const publicVideos =
    useAppSelector((state) => state.users["publicVideos"]) || defaultUserVideosData;
  const privateVideos =
    useAppSelector((state) => state.users["privateVideos"]) || defaultUserVideosData;

  const currentProfile: UserProfileDTO = useAppSelector(
    (state) => state.users.currentUser
  );

  const handleVideoAlbumClick = (type: "public" | "private") => {
    if (currentProfile?.id) {
      navigate(`/user/videos/${type}/${currentProfile?.id}`);
    }
  };

  useEffect(() => {
    //fetch logic
  }, [params.id]);
  const isForceScreen = downXl || lg;
  return (
    <Container
      maxWidth={isForceScreen ? "md" : width}
      className={wrapperClass}
      style={!isForceScreen ? wrapperOverride : {}}
    >
      <BaseCard wrapperOverrideStyle={{ margin: 0, paddingBottom: 10 }}>
        <div className={classes.innerContainer}>
          <Typography variant="h2">Video Albums</Typography>

          <div className={classes.albumContainer}>
            {/* <div className={classes.albumCollectionItem}>
              <AlbumVideoCover
                type={UserVideoType.PUBLIC_VIEW}
                imageURL={publicVideos.data[publicVideos.data.length - 1]?.large}
                videosCount={publicVideos.data.length}
                handleWrapperClick={() => handleVideoAlbumClick("public")}
              />
            </div> */}
            <div className={classes.albumCollectionItem}>
              <AlbumVideoCover
                videosCount={privateVideos.data.length}
                thumbnail={privateVideos.data[privateVideos.data.length - 1]?.thumbnail}
                type={UserVideoType.PRIVATE_VIEW}
                handleWrapperClick={() => handleVideoAlbumClick("private")}
              />
            </div>
          </div>
        </div>
      </BaseCard>

      {/* <div className={classes.sliderContainer}>
        <AlbumSlider>
          <Typography variant="sliderTitleMini">New Members</Typography>
        </AlbumSlider>
      </div> */}
    </Container>
  );
};

export default UserVideoAlbums;
