import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { toggleSnackbarClose } from "@/app/ui/ui.actions";
import { Snackbar, Theme, Typography } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import React from "react";

const Alert = React.forwardRef<any, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: "#EA4160",
  },
  error: {
    backgroundColor: "#00E899",
  },
}));

const GeneralSnackbar = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.ui.toggleSnackbar);
  const message = useAppSelector((state) => state.ui.snackbarMessage);
  const type = useAppSelector((state) => state.ui.snackbarType);
  function handleClose() {
    dispatch(toggleSnackbarClose());
  }
  return (
    isOpen && (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={isOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          <Typography variant="body1" style={{ color: "white" }}>
            {message}
          </Typography>
        </Alert>
      </Snackbar>
    )
  );
};

export default GeneralSnackbar;
